import React, { useReducer, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'apollo';

import jobPositionsQuery from 'pages/job-positions/JobPositionsContainer/jobPositionsQuery.gql';
import billingInformationQuery from 'components/ActivateOrChangePlanButton/billingInformationQuery.gql';

import withOrganizationPk from 'hoc/withOrganizationPk';

import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';

import { validator, isRequired, someRequired } from 'utils/validator';
import { showGenericError, showError } from 'utils/message';
import { logAndShowGenericError } from 'utils/log';
import { PLAN_TYPES } from 'consts/planTypes';

import { withRouter } from 'utils/withRouter';
import sessionStorage from 'utils/sessionStorage';
import createJobPositionMutation from './createJobPositionMutation.gql';

import JobPositionAdd from './JobPositionAdd';

const VALIDATE_FIELDS = {
  position: [isRequired],
  location: [someRequired('isRemote')],
  stageList: [isRequired],
};

const getValidationErrors = validator(VALIDATE_FIELDS);

const ACTIONS = {
  INIT: 'init',
  FIELD_CHANGE: 'field-change',
};

const formReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INIT:
      return {
        ...state,
        isActive: !!action.isActive,
        isInitialIsActiveSet: true,
      };
    case ACTIONS.FIELD_CHANGE:
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      throw new Error(); // action not defined
  }
};

function JobPositionAddContainer({
  billing,
  jobPositions,
  mutate,
  organizationPk,
  isJobPositionsLoading,
  isBillingInfoLoading,
  navigate,
}) {
  const {
    mutate: submit,
    fieldErrors,
    setFieldErrors,
    clearFieldError,
  } = useErrorFieldsMutation(mutate);

  const initialState = {
    position: sessionStorage.get('position') || '',
    location: sessionStorage.get('location') || '',
    isRemote: sessionStorage.get('isRemote') === 'true',
    isActive: sessionStorage.get('isActive') === 'true',
    isInitialIsActiveSet: false,
  };
  const [state, dispatch] = useReducer(formReducer, initialState);

  useEffect(() => {
    if (
      !state.isInitialIsActiveSet &&
      Object.keys(billing).length > 0 &&
      sessionStorage.get('isActive') === null // do not overwrite isActive state if it exists in sessionStorage
    ) {
      /**
       * Set initialStatus to active if user has available credits
       * or if the user is on free plan and has no other jobPositions
       */
      dispatch({
        type: ACTIONS.INIT,
        isActive:
          billing.availableCredits > 0 ||
          (billing.planType === PLAN_TYPES.FREE && jobPositions.length === 0),
      });
    }
  }, [state, billing, jobPositions]);

  const handleSubmit = useCallback(() => {
    const { position, location, stageList, isRemote, isActive } = state;

    const validationErrors = getValidationErrors({
      position,
      location,
      isRemote,
      stageList,
    });

    if (!validationErrors) {
      submit({
        variables: {
          organizationPk,
          position,
          location,
          stageList,
          isRemote,
          isActive,
        },
      })
        .then(({ data: { createJobPosition } }) => {
          if (createJobPosition.errors) {
            return createJobPosition.errors.message
              ? showError(createJobPosition.errors.message)
              : showGenericError();
          }
          sessionStorage.clear('position', 'location', 'isRemote', 'isActive');
          navigate(`/positions/add/vas/${createJobPosition.jobPosition.pk}`);
        })
        .catch(logAndShowGenericError('createJobPosition rejected', { state }));
    } else {
      setFieldErrors(validationErrors);
    }
  }, [state, navigate, organizationPk, setFieldErrors, submit]);

  const onFieldChange = useCallback(
    ({ field, value, relatedField }) => {
      if (fieldErrors[field]) {
        clearFieldError(field);
      }

      if (relatedField) {
        clearFieldError(relatedField);
      }

      dispatch({ type: ACTIONS.FIELD_CHANGE, field, value });
    },
    [fieldErrors, clearFieldError],
  );

  return (
    <JobPositionAdd
      onFieldChange={onFieldChange}
      billing={billing}
      jobPositions={jobPositions}
      fieldErrors={fieldErrors}
      handleSubmit={handleSubmit}
      isJobPositionsLoading={isJobPositionsLoading}
      isBillingInfoLoading={isBillingInfoLoading}
      {...state}
    />
  );
}

JobPositionAddContainer.propTypes = {
  billing: PropTypes.object,
  jobPositions: PropTypes.array,
  mutate: PropTypes.func,
  organizationPk: PropTypes.string.isRequired,
  isJobPositionsLoading: PropTypes.bool,
  isBillingInfoLoading: PropTypes.bool,
  navigate: PropTypes.func,
};

export default compose(
  withRouter,
  withOrganizationPk,
  graphql(createJobPositionMutation, {
    options: () => ({
      refetchQueries: [billingInformationQuery],
    }),
  }),
  graphql(billingInformationQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      billing: !loading && viewer ? viewer.organizations[0].billing : {},
      isBillingInfoLoading: loading,
    }),
  }),
  graphql(jobPositionsQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      jobPositions: !loading && viewer ? viewer.jobPositions.edges : [],
      isJobPositionsLoading: loading,
    }),
  }),
)(JobPositionAddContainer);

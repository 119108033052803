import { useCallback } from 'react';
import { useQuery, gql } from '@apollo/client';
import sortBy from 'lodash-es/sortBy';
import { insertAll, filter, mergeDeepRight } from 'ramda';

import useMembership from 'hooks/useMembership';
import customFieldValueFragment from './customFieldValueFragment.gql';
import customFieldMultiChoiceFragment from './customFieldMultiChoiceFragment.gql';

export const CUSTOM_FIELDS_VALUES_QUERY = gql`
  query CustomFieldsValuesQuery(
    $organizationPk: String!
    $candidatePk: String!
  ) {
    viewer {
      candidate(organizationPk: $organizationPk, candidatePk: $candidatePk) {
        id
        pk
        firstName
        lastName
        applications: application {
          edges {
            node {
              id
              pk
              customFields {
                ...customFieldValueFragment
              }
              customMultichoiceFields {
                ...customFieldMultiChoiceFragment
              }
            }
          }
        }
      }
    }
  }
  ${customFieldValueFragment}
  ${customFieldMultiChoiceFragment}
`;

/**
 * Hook for getting list of Custom Fields Values for a particular Application,
 * and updating list after mutations.
 */

const useCustomFieldValues = ({ candidatePk, applicationPk }) => {
  const [
    {
      organization: { pk: organizationPk },
    },
  ] = useMembership();

  const { data, loading, client } = useQuery(CUSTOM_FIELDS_VALUES_QUERY, {
    variables: { organizationPk, candidatePk },
  });

  // Find current application in a list of all candidate's applications.
  const currentApplication = data?.viewer.candidate.applications.edges.find(
    ({ node }) => node.pk === applicationPk,
  )?.node;

  const customFieldsValues =
    currentApplication &&
    sortBy(
      [
        ...currentApplication.customFields,
        ...currentApplication.customMultichoiceFields,
      ],
      ['field.name'],
    );

  const addCustomFieldValueUpdate = useCallback(
    (customFieldValue) => {
      client.cache.writeQuery({
        id: currentApplication.id,
        query: CUSTOM_FIELDS_VALUES_QUERY,
        data: {
          viewer: {
            ...data.viewer,
            candidate: {
              ...data.viewer.candidate,
              applications: {
                ...data.viewer.candidate.applications,
                edges: data.viewer.candidate.applications.edges.map((edge) => {
                  if (edge.node.pk === applicationPk) {
                    return {
                      ...edge,
                      node: {
                        ...edge.node,
                        customFields: [
                          ...edge.node.customFields,
                          customFieldValue,
                        ],
                      },
                    };
                  }

                  return edge;
                }),
              },
            },
          },
        },
      });
    },
    [client.cache, currentApplication, data, applicationPk],
  );

  const addCustomFieldMultichoiceValue = useCallback(
    (customFieldValues) => {
      // merge new CF multichoice values with existing ones
      const modifiedCustomMultichoiceFields = insertAll(
        currentApplication.customMultichoiceFields.length,
        customFieldValues,
        currentApplication.customMultichoiceFields,
      );

      // modify data object to include modified list of multichoice CFs
      const modifiedData = mergeDeepRight(data, {
        viewer: {
          candidate: {
            applications: {
              edges: [
                {
                  node: {
                    ...currentApplication,
                    customMultichoiceFields: modifiedCustomMultichoiceFields,
                  },
                  __typename: 'ApplicationCountableEdge',
                },
              ],
              __typename: 'ApplicationCountableConnection',
            },
          },
        },
      });

      client.cache.writeQuery({
        query: CUSTOM_FIELDS_VALUES_QUERY,
        variables: { organizationPk, candidatePk },
        data: modifiedData,
      });
    },
    [data, client, organizationPk, candidatePk, currentApplication],
  );

  const deselectCustomFieldValue = useCallback(
    (customChoicePk) => {
      client.cache.writeQuery({
        query: CUSTOM_FIELDS_VALUES_QUERY,
        variables: { organizationPk, candidatePk },
        data: {
          viewer: {
            ...data.viewer,
            candidate: {
              ...data.viewer.candidate,
              applications: {
                ...data.viewer.candidate.applications,
                edges: data.viewer.candidate.applications.edges.map((edge) => {
                  if (edge.node.pk === applicationPk) {
                    return {
                      ...edge,
                      node: {
                        ...edge.node,
                        customMultichoiceFields: filter(
                          (field) => field.pk !== customChoicePk,
                          edge.node.customMultichoiceFields,
                        ),
                      },
                    };
                  }

                  return edge;
                }),
              },
            },
          },
        },
      });
    },
    [data, client.cache, organizationPk, candidatePk, applicationPk],
  );

  return {
    customFieldsValues,
    loading,
    addCustomFieldValueUpdate,
    addCustomFieldMultichoiceValue,
    deselectCustomFieldValue,
  };
};

export default useCustomFieldValues;

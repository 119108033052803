import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import useJobPositionVAS from 'hooks/useJobPositionVas';

import useToggleState from 'hooks/useToggleState';
import VasRequestDetails from './VasRequestDetails';

function VasRequestDetailsContainer({ jobPositionPk }) {
  const { selectedVas, loading } = useJobPositionVAS({ jobPositionPk });
  const [isVasModalVisible, showVasModal, hideVasModal] = useToggleState(false);

  const onToggleVasModalVisibility = useCallback(() => {
    if (isVasModalVisible) {
      hideVasModal();
    } else {
      showVasModal();
    }
  }, [isVasModalVisible, showVasModal, hideVasModal]);

  return (
    <VasRequestDetails
      selectedVas={selectedVas}
      isLoading={loading}
      isVasModalVisible={isVasModalVisible}
      onToggleVasModalVisibility={onToggleVasModalVisibility}
      jobPositionPk={jobPositionPk}
    />
  );
}

VasRequestDetailsContainer.propTypes = {
  jobPositionPk: PropTypes.string,
};

export default VasRequestDetailsContainer;

const parseRangeFilters = (str) => {
  if (!str) return [];
  // range string is e.g. "3-40" so split it one time only
  const range = str.split('-', 2).map((n) => parseInt(n, 10));
  // make sure it's a valid int
  if (range.some((n) => n < 0 || n > 100 || Number.isNaN(n))) {
    return [];
  }
  return range;
};

export default parseRangeFilters;

import React, { useCallback, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';

import Form from 'components/Form';
import Button from 'components/Form/Button';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';

import { useMutation } from '@apollo/client';

import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';
import useCurrentOrganization from 'hooks/useCurrentOrganization';

import { validator, isRequired } from 'utils/validator';
import { logAndShowGenericError } from 'utils/log';
import { showError } from 'utils/message';
import EditCommentMutation from './editCommentMutation.gql';

import styles from './EditCommentForm.less';

const VALIDATE_FIELDS = {
  newContent: [isRequired],
};

const FIELD_NAMES = {
  NEW_CONTENT: 'newContent',
};

const getValidationErrors = validator(VALIDATE_FIELDS);

function EditCommentForm({
  onCancel,
  applicationPk,
  commentPk,
  initialContent,
}) {
  const [isSaving, toggleSaving] = useState(false);
  const { t } = useTranslation();
  const [organizationPk] = useCurrentOrganization();

  const [newContent, setNewContent] = useState(
    initialContent.props.children ? initialContent.props.children : '',
  );
  const [editComment] = useMutation(EditCommentMutation);

  const {
    mutate: editApplicationComment,
    fieldErrors,
    setFieldErrors,
    clearFieldError,
  } = useErrorFieldsMutation(editComment);

  // TODO: Since we're passing Markdown content, we access raw data via props.children
  // But data fetching in ApplicationComment should really be changed :(

  const onSubmit = useCallback(() => {
    const content = newContent.trim();

    const validationErrors = getValidationErrors({
      [FIELD_NAMES.NEW_CONTENT]: content,
    });

    if (validationErrors) {
      return setFieldErrors(validationErrors);
    }

    toggleSaving(true);
    editApplicationComment({
      variables: {
        organizationPk,
        applicationPk,
        commentPk,
        content,
      },
    })
      .then(({ data }) => {
        if (data.editApplicationComment.errors) {
          return data.editApplicationComment.errors.message
            ? showError(data.editApplicationComment.errors.message)
            : logAndShowGenericError('editComment rejected', {
                organizationPk,
                applicationPk,
                commentPk,
                content,
              });
        }
      })
      .catch(
        logAndShowGenericError('editComment rejected', {
          organizationPk,
          applicationPk,
          commentPk,
          content,
        }),
      )
      .finally(() => {
        toggleSaving(false);
        onCancel();
      });
  }, [
    newContent,
    editApplicationComment,
    organizationPk,
    applicationPk,
    commentPk,
    setFieldErrors,
    onCancel,
  ]);

  const handleContentChange = useCallback(
    (e) => {
      clearFieldError(FIELD_NAMES.NEW_CONTENT);
      setNewContent(e.target.value);
    },
    [clearFieldError],
  );

  return (
    <div className={styles.container}>
      <Form onFinish={onSubmit}>
        <Field error={fieldErrors.newContent}>
          <Input
            value={newContent}
            placeholder={t('EditCommentForm_contentPlaceholder')}
            onChange={handleContentChange}
            popupLabel
            labelDark
            textarea
            className={styles.commentTextInput}
            disabled={isSaving}
          />
        </Field>
      </Form>
      <div className={styles.buttonsBar}>
        <Button
          type="default"
          size="default"
          className={styles.cancelButton}
          onClick={onCancel}
          disabled={isSaving}
          data-testid="cancel"
        >
          {t('EditCommentForm_cancel')}
        </Button>
        <Button
          type="primary"
          size="default"
          onClick={onSubmit}
          disabled={isSaving}
          loading={isSaving}
          htmlType="submit"
        >
          {t('EditCommentForm_submit')}
        </Button>
      </div>
    </div>
  );
}

EditCommentForm.propTypes = {
  onCancel: PropTypes.func,
  applicationPk: PropTypes.string.isRequired,
  commentPk: PropTypes.string.isRequired,
  initialContent: PropTypes.object,
};

export default memo(EditCommentForm);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Form/Button';
import { useApolloClient } from '@apollo/client';
import jobPositionDetailsQuery from 'pages/job-positions/JobPositionDetails/jobPositionDetailsQuery.gql';
import { EditOutlined } from '@ant-design/icons';
import PermissionsDialog from './JobPositionAuthorizedUsersDialog';

export default function EditAuthorizedUsersButton({
  jobPositionPk,
  ...btnProps
}) {
  const client = useApolloClient();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        {...btnProps}
        onClick={() => {
          setIsOpen(true);
        }}
        iconOnly
        icon={<EditOutlined aria-hidden />}
        aria-label="edit users permission"
      />
      <PermissionsDialog
        visible={isOpen}
        jobPositionPk={jobPositionPk}
        onClose={async () => {
          setIsOpen(false);
          await client.refetchQueries({
            include: [jobPositionDetailsQuery],
          });
        }}
      />
    </>
  );
}

EditAuthorizedUsersButton.propTypes = {
  users: PropTypes.array,
  jobPositionPk: PropTypes.string,
};

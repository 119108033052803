import Rollbar from 'rollbar';
import LogRocket from 'logrocket';
import * as config from 'config';
import { getUsername } from './user';

/* global heap */

export default function identify(currentUser) {
  if (window.heap) {
    heap.identify(currentUser.email.toLowerCase());
  }

  if (!config.IS_DEVELOPMENT) {
    LogRocket.identify('405zcj/workate-app', {
      email: currentUser.email.toLowerCase(),
      name: getUsername(currentUser),
    });
  }

  if (window.Rollbar) {
    Rollbar.configure({
      payload: {
        person: {
          id: currentUser.pk,
          email: currentUser.email.toLowerCase(),
        },
      },
    });
  }
}

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ContactDetails from 'pages/candidates/CandidateDetails/ContactDetailsContainer';
import CandidateHeader from 'pages/candidates/CandidateHeader';
import CandidateFilesContainer from 'pages/candidates/CandidateDetails/CandidateFiles';
import CandidateApplicationsList from 'pages/candidates/CandidateDetails/CandidateApplicationsList';

import styles from './index.less';

function CandidateDetails({
  candidate,
  contactDetails,
  applications,
  activeApplicationPk,
  withLinkableHeaderNames,
  onCandidateDeleted,
  popupContainerRef,
  onApplicationSelect,
}) {
  const { firstName, lastName, createdAt, modifiedAt, pk } = candidate;

  const recentApplicationPk =
    applications.length > 0
      ? applications[applications.length - 1].node.pk
      : null;

  return (
    <div
      className={styles.candidateDetailsContainer}
      data-testid="CandidateDetails"
    >
      <CandidateHeader
        firstName={firstName}
        lastName={lastName}
        createdAt={createdAt}
        modifiedAt={modifiedAt}
        candidatePk={pk}
        withLinkableHeaderNames={withLinkableHeaderNames}
        onCandidateDeleted={onCandidateDeleted}
        recentApplicationPk={recentApplicationPk}
      />
      <CandidateFilesContainer candidatePk={pk} />
      <ContactDetails candidatePk={pk} contactDetails={contactDetails} />
      <CandidateApplicationsList
        candidatePk={pk}
        activeApplicationPk={activeApplicationPk}
        applications={applications}
        popupContainerRef={popupContainerRef}
        onApplicationSelect={onApplicationSelect}
      />
    </div>
  );
}

CandidateDetails.propTypes = {
  candidate: PropTypes.object.isRequired,
  contactDetails: PropTypes.array.isRequired,
  applications: PropTypes.array.isRequired,
  activeApplicationPk: PropTypes.string,
  withLinkableHeaderNames: PropTypes.bool,
  onCandidateDeleted: PropTypes.func,
  popupContainerRef: PropTypes.object,
  onApplicationSelect: PropTypes.func,
};

export default memo(CandidateDetails);

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import Loader from 'components/Loader';

import {
  REVIEW_REQUESTED,
  ACCEPTED,
  PUBLISHED,
  IN_PROGRESS,
} from 'consts/request';
import { PERMISSION_REQUEST_MANAGE } from 'consts/permissions';
import usePermissions from 'hooks/usePermissions';
import Button from 'components/Form/Button';
import { Trans } from 'react-i18next';
import ChangeVasModal from './ChangeVasModal';
import styles from './VasRequestDetails.less';

const VAS_LOCKED_STATUS = [REVIEW_REQUESTED, ACCEPTED, IN_PROGRESS, PUBLISHED];

function VasRequestDetails({
  isLoading,
  status,
  selectedVas,
  isVasModalVisible,
  onToggleVasModalVisibility,
  jobPositionPk,
}) {
  const { t } = useTranslation();

  const [hasRequestManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_REQUEST_MANAGE]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.sectionWrapper}>
      {selectedVas.length > 0 ? (
        selectedVas.map(({ id, name }) => (
          <div key={id} className={styles.vasWrapper}>
            <div className={styles.vasName}>{t(name)}</div>
            <Trans i18nKey={`VasItemDesc--${name}`}>
              <p>description_part1</p>
              {/* used only for the vas: Workate Attract */}
              <p>description_part2</p>
              <p>title</p>
              <p>item</p>
              <p>item</p>
              <p>item</p>
              <p>item</p>
              <br />
              <p>title</p>
              <p>item</p>
              <p>item</p>
              <p>item</p>
              <p>item</p>
              <p>item</p>
              <p>item</p>
            </Trans>
          </div>
        ))
      ) : (
        <div className={styles.noVasText}>
          {t('LPRequestDetails_noVasChosen')}
        </div>
      )}
      <Button
        type="primary"
        size="default"
        className={styles.changeVasButton}
        onClick={
          hasRequestManagePermission
            ? onToggleVasModalVisibility
            : showNoPermissionMessage
        }
        disabled={VAS_LOCKED_STATUS.includes(status)}
      >
        {t('LPRequestDetails_changeVasButton')}
      </Button>
      {VAS_LOCKED_STATUS.includes(status) && (
        <div className={styles.changePublishedVasText}>
          {t('LPRequestDetails_contactToChangeVas')}
        </div>
      )}

      <ChangeVasModal
        isVasModalVisible={isVasModalVisible}
        onToggleVasModalVisibility={onToggleVasModalVisibility}
        jobPositionPk={jobPositionPk}
      />
    </div>
  );
}

VasRequestDetails.propTypes = {
  isLoading: PropTypes.bool,
  status: PropTypes.string,
  selectedVas: PropTypes.array,
  isVasModalVisible: PropTypes.bool,
  onToggleVasModalVisibility: PropTypes.func,
  jobPositionPk: PropTypes.string,
};

export default VasRequestDetails;

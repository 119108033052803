import React from 'react';
import euFunds from './euFunds.svg';
import invest from './invest.svg';
import office from './office.svg';
import pl from './pl.svg';
import ue from './ue.svg';
import style from './index.less';

const LOGOS = [
  { src: euFunds, alt: 'EU funds Logo' },
  { src: pl, alt: 'Polish Flag' },
  { src: office, alt: 'Pomerania Marshall Office Logo' },
  { src: ue, alt: 'European Union Logo' },
];

function LiveMoreFooter() {
  return (
    <div className={style.container}>
      {LOGOS.map((o) => (
        <img key={o.alt} src={o.src} alt={o.alt} />
      ))}
      <img className={style.last} src={invest} alt="Invest in Pomerania Logo" />
    </div>
  );
}

export default LiveMoreFooter;

import React from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import JobPositionSelect from 'pages/candidates/AddApplication/JobPositionSelect';
import styles from './AddApplication.less';

function AddApplication({
  jobPositions,
  selectedJobPosition,
  onAddApplication,
  onClose,
  isLoading,
  popupContainerRef,
}) {
  return (
    <div className={styles.container}>
      <JobPositionSelect
        data-testid="job-position-select"
        jobPositions={jobPositions}
        className={styles.select}
        selectedJobPosition={selectedJobPosition}
        onAddApplication={onAddApplication}
        popupContainerRef={popupContainerRef}
        disabled={isLoading}
      />
      {onClose && (
        <CloseOutlined className={styles.closeBtn} onClick={onClose} />
      )}
      {isLoading && <LoadingOutlined className={styles.closeBtn} />}
    </div>
  );
}

AddApplication.propTypes = {
  jobPositions: PropTypes.array.isRequired,
  selectedJobPosition: PropTypes.string.isRequired,
  onAddApplication: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  popupContainerRef: PropTypes.object,
};

export default AddApplication;

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { Badge } from 'antd';
import Tooltip from 'components/Tooltip';
import styles from './index.less';

function UnseenDot({ t, title = 'You have unseen updates.', className }) {
  return (
    <Tooltip title={title}>
      <span>
        <Badge
          status="processing"
          title={t(title)}
          className={cx(styles.badge, className)}
        />
      </span>
    </Tooltip>
  );
}

UnseenDot.propTypes = {
  title: PropTypes.string,
  t: PropTypes.func,
  className: PropTypes.func,
};

export default withTranslation('translation')(UnseenDot);

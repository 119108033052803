import React from 'react';
import { useQuery } from '@apollo/client';

import useCurrentUser from 'hooks/useCurrentUser';
import useMembership from 'hooks/useMembership';

import ACTIVITIES_QUERY from './activitiesQuery.gql';
import JOB_POSITION_COUNT_QUERY from './jobPositionsCountQuery.gql';

import Home from './Home';

function HomeContainer() {
  const [currentUser] = useCurrentUser();
  const [currentMembership] = useMembership();
  const organizationPk = currentMembership && currentMembership.organization.pk;

  const { data: activitiesData, loading: isActivitiesLoading } = useQuery(
    ACTIVITIES_QUERY,
    {
      variables: { organizationPk },
      fetchPolicy: 'cache-and-network',
    },
  );
  const { data: jobPositionsData, loading: isJobPositionsLoading } = useQuery(
    JOB_POSITION_COUNT_QUERY,
    { variables: { organizationPk } },
  );
  const activitiesList = activitiesData
    ? activitiesData.viewer.activities.edges
    : [];
  const jobPositionsCount =
    !isJobPositionsLoading && jobPositionsData
      ? jobPositionsData.viewer.jobPositions.edges.length
      : null;
  const jobPositionsList =
    !isJobPositionsLoading && jobPositionsData
      ? jobPositionsData.viewer.jobPositions.edges
      : [];

  return (
    <Home
      activities={activitiesList}
      isActivitiesLoading={isActivitiesLoading}
      jobPositionsCount={jobPositionsCount}
      jobPositions={jobPositionsList}
      isJobPositionsLoading={isJobPositionsLoading}
      currentUser={currentUser}
    />
  );
}
export default HomeContainer;

import { useEffect } from 'react';
import { useTranslation } from 'i18n';
import useCurrentUser from 'hooks/useCurrentUser';
import moment from 'moment';

/**
 * Hook that sets current language to the one saved in user's settings
 */

const useDefaultUserLanguageEffect = () => {
  const [currentUser] = useCurrentUser();
  const { i18n } = useTranslation();

  useEffect(() => {
    // If current website language is not one fetched from Current User query, change it to the latter
    if (currentUser && currentUser.language !== i18n.language) {
      i18n.changeLanguage(currentUser.language);
    }
    moment.locale(i18n.language);
    // remove i18n dependency to prevent run on every language change
  }, [currentUser]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useDefaultUserLanguageEffect;

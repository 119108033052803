import { useEffect } from 'react';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import storage from 'utils/storage';

/**
 * Hook that saves last selected organization.
 */

const usePersistOrganizationEffect = () => {
  const [organizationPk, isLoading] = useCurrentOrganization();

  useEffect(() => {
    if (organizationPk && !isLoading) {
      storage.persist({ organizationPk });
    }
  }, [isLoading, organizationPk]);
};

export default usePersistOrganizationEffect;

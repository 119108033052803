import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import { Tag } from 'antd';
import { FIELD_TYPE_TEXT, FIELD_TYPE_MULTICHOICE } from 'consts/fieldTypes';
import Loader from 'components/Loader';
import Form from 'components/Form';
import useOrganizationCustomFields from 'pages/settings/custom-fields/useOrganizationCustomFields';
import useMembership from 'hooks/useMembership';
import useToggleState from 'hooks/useToggleState';
import cx from 'classnames';
import usePermissions from 'hooks/usePermissions';
import { PERMISSION_APPLICATION_DETAILS_MANAGE } from 'consts/permissions';
import Link from 'components/Link';
import CustomFieldTag from './CustomFieldTag';
import useCustomFieldValues from './useCustomFieldValues';

import styles from './index.less';

/**
 * Component for displaying list of Custom Fields Values in tags
 * and list of organization Custom Fields - CFs without values for current application
 */

// __typename for MULTICHOICE Custom Field Values
const fieldChoiceNode = 'AdditionalApplicationFieldChoiceNode';

/**
 * Filter Custom Fields to get list of
 * TEXT fields without values and MULTICHOICE fields with unused choices
 * for current application and display them at the end of the Custom Fields Values list (as they are empty).
 */
const getRestCFs = (organizationCustomFields, customFieldsValues) =>
  organizationCustomFields.filter((organizationField) => {
    // case for MULTICHOICE Organization Field
    if (organizationField.fieldType === FIELD_TYPE_MULTICHOICE) {
      // because TEXT type Custom Fields Values and MULTICHOICE type CFVs can contain the same PK
      // we have to filter MULTICHOICE Custom Fields Values
      const multichoiceCFVs = customFieldsValues.filter(
        (customField) => customField.__typename === fieldChoiceNode,
      );
      // the function that test if a choice from Organization Field is used as Custom Field Value
      const isChoiceIsUsedAsCFv = (choice) =>
        multichoiceCFVs.some((customField) => customField.pk === choice.pk);
      // check all Organization Field choices to see if all of them are used as Custom Field Values
      // and return in getRestCFs array only this Organization Field
      // which all choices are not already used as Custom Field Values,
      // thus the user will see CF-select-input if at least one choice is not used as CF-tag
      return !organizationField.choices.every(isChoiceIsUsedAsCFv);
    }
    // case for TEXT organization field:
    // return in getRestCFs array only this Organization Field
    // which is not already used as 'field' object in Custom Field Value,
    // thus the user will see CF-text-input only when it is not used as CF-tag
    return !customFieldsValues.some(
      (customField) => customField.field.pk === organizationField.pk,
    );
  });

const getFieldType = (typename) => {
  if (typename === fieldChoiceNode) {
    return FIELD_TYPE_MULTICHOICE;
  }
  return FIELD_TYPE_TEXT;
};

// display in dropdown choices only this choices, which are not yet added as values
const getChoices = (choices, customFieldPk, customFieldsValues) => {
  const tagsWithValuesFromThisCF = customFieldsValues.filter(
    (tag) => tag.field.pk === customFieldPk,
  );

  return choices.filter(
    (choice) =>
      !tagsWithValuesFromThisCF.some((tag) => tag.value === choice.value),
  );
};

function CustomFieldsList({ applicationPk, candidatePk }) {
  const { t } = useTranslation();
  const [isShowMoreOn, onShowMore, onShowLess] = useToggleState(false);
  const { customFieldsValues, loading: loadingValues } = useCustomFieldValues({
    applicationPk,
    candidatePk,
  });

  const [
    {
      organization: { pk: organizationPk },
    },
  ] = useMembership();

  const [organizationCustomFields, { loading }] = useOrganizationCustomFields({
    organizationPk,
  });
  const [hasApplicationDetailsManagePermission] = usePermissions([
    PERMISSION_APPLICATION_DETAILS_MANAGE,
  ]);

  const restOrganizationCustomFields =
    organizationCustomFields &&
    customFieldsValues &&
    getRestCFs(organizationCustomFields, customFieldsValues);

  if (!loading && !organizationCustomFields.length > 0) {
    return null;
  }

  if (loading || loadingValues || !organizationPk) {
    return <Loader fullSize />;
  }

  function renderCustomFieldTag(field) {
    return (
      <CustomFieldTag
        key={field.id}
        pk={field.pk}
        name={field.name}
        className={cx(styles.tag, !field.isEditable && styles.utm)}
        applicationPk={applicationPk}
        candidatePk={candidatePk}
        choices={getChoices(field.choices, field.pk, customFieldsValues)}
        fieldType={field.fieldType}
        hasApplicationDetailsManagePermission={
          hasApplicationDetailsManagePermission
        }
      />
    );
  }

  return (
    <Form layout="inline">
      {customFieldsValues?.length > 0 && (
        /**
         * List of Custom Fields Values for current application.
         */
        <>
          {customFieldsValues.map((cfv) => (
            <CustomFieldTag
              // because Custom Fields Values TEXT fields and MULTICHOICE fields can contain the same ID
              // we need to give a different name to the MULTICHOICE field keys
              key={`${cfv.__typename}-${cfv.id}`}
              pk={cfv.field.pk}
              name={cfv.field.name}
              initialValue={cfv.value}
              valuePk={cfv.pk}
              className={styles.customFieldWrapper}
              applicationPk={applicationPk}
              candidatePk={candidatePk}
              fieldType={getFieldType(cfv.__typename)}
              hasApplicationDetailsManagePermission={
                hasApplicationDetailsManagePermission
              }
            />
          ))}
        </>
      )}
      {
        /**
         *  List of organization Custom Fields -
         *  without values for current application.
         *  Show only 5 first organization Custom Fields.
         */
        hasApplicationDetailsManagePermission &&
          restOrganizationCustomFields?.slice(0, 5).map(renderCustomFieldTag)
      }
      {
        /**
         * If there are more than 5 organization Custom Fields,
         * display "show more" button -
         * while clicked present all organization Custom Fields and "show less" button.
         */
        hasApplicationDetailsManagePermission &&
          restOrganizationCustomFields?.length > 5 && (
            <>
              {isShowMoreOn &&
                restOrganizationCustomFields.slice(5).map(renderCustomFieldTag)}
              <Tag role="button" className={styles.buttonTag}>
                <button
                  type="button"
                  className={styles.button}
                  onClick={isShowMoreOn ? onShowLess : onShowMore}
                >
                  {isShowMoreOn
                    ? t('CustomFieldsList_showLess')
                    : t('CustomFieldsList_showMore')}
                </button>
              </Tag>
            </>
          )
      }
      <Tag role="button" className={styles.buttonTag}>
        <Link className={styles.link} to="/settings/custom-fields">
          {t('CustomFieldsList_manageCustomTags')}
        </Link>
      </Tag>
    </Form>
  );
}

CustomFieldsList.propTypes = {
  applicationPk: PropTypes.string,
  candidatePk: PropTypes.string,
};

export default CustomFieldsList;

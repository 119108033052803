import React from 'react';
import { Route, Routes } from 'react-router-dom';

// eslint-disable-next-line max-len
import CandidateWithApplicationsStandalone from 'pages/candidates/CandidateWithApplications/CandidateWithApplicationsStandalone';
import Page404 from 'components/Page404';
import CandidatesListContainer from 'pages/candidates/CandidatesList';
import Content from 'components/Content';

function Candidates() {
  return (
    <Routes>
      <Route index element={<CandidatesListContainer />} />
      <Route
        path=":candidatePk"
        element={
          <Content size="large">
            <CandidateWithApplicationsStandalone />
          </Content>
        }
      />
      <Route
        path=":candidatePk/:applicationPk"
        element={
          <Content size="large">
            <CandidateWithApplicationsStandalone />
          </Content>
        }
      />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}

export default Candidates;

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Divider } from 'antd';

import FileUpload from 'components/FileUpload';
import Button from 'components/Form/Button';
import { PERMISSION_CANDIDATE_DETAILS_MANAGE } from 'consts/permissions';
import usePermissions from 'hooks/usePermissions';
import { url } from 'utils/api';
import CandidateFile from './CandidateFile';

import styles from './index.less';

function CandidateFiles({ candidatePk, onFileUpload, files, t }) {
  const [hasCandidateDetailsManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_CANDIDATE_DETAILS_MANAGE]);

  const fileUploadRef = React.createRef();

  const onAddClick = () => {
    fileUploadRef.current.upload.uploader.fileInput.click();
  };

  const cvs = files.filter((file) => file.isCv === true);
  const otherFiles = files.filter((file) => file.isCv === false);

  return (
    <div className={styles.container}>
      <div className={styles.sectionTitle}>
        <span className={styles.sectionTitleText}>{t('files')}</span>
        <Button
          iconOnly
          icon={<PlusCircleOutlined className={styles.addButton} aria-hidden />}
          onClick={
            hasCandidateDetailsManagePermission
              ? onAddClick
              : showNoPermissionMessage
          }
          aria-label="add-files"
        />
      </div>
      {cvs.length > 0 &&
        cvs.map((file) => (
          <CandidateFile key={file.pk} file={file} candidatePk={candidatePk} />
        ))}
      {cvs.length > 0 && otherFiles.length > 0 && (
        <Divider className={styles.divider} dashed />
      )}
      {otherFiles.length > 0 &&
        otherFiles.map((file) => (
          <CandidateFile key={file.pk} file={file} candidatePk={candidatePk} />
        ))}
      {hasCandidateDetailsManagePermission && (
        <FileUpload
          ref={fileUploadRef}
          signingUrl={url(`/s3/sign/cv/${candidatePk}`)}
          onFileUpload={onFileUpload}
          acceptedFormats={['PDF', 'DOC', 'DOCX']}
          listType="text"
          visible={files.length === 0 && hasCandidateDetailsManagePermission}
          miniDropzone
          withFilesList={false}
        />
      )}
    </div>
  );
}

CandidateFiles.propTypes = {
  candidatePk: PropTypes.string.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  // From HoCs
  t: PropTypes.func.isRequired,
};

export default withTranslation('translation')(CandidateFiles);

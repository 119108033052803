import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import { nl2br } from 'utils/text';
import Alert from 'components/Alert';
import Loader from 'components/Loader';

import Button from 'components/Form/Button';
import emailPreviewQuery from './emailPreviewQuery.gql';

import styles from './PreviewBulkEmail.less';

function PreviewBulkEmail({
  emailTemplatePk,
  candidateSearchFilterInput,
  isSaving,
}) {
  const [organizationPk] = useCurrentOrganization();
  const { t } = useTranslation();

  const { data, loading, fetchMore } = useQuery(emailPreviewQuery, {
    variables: {
      organizationPk,
      emailTemplatePk,
      candidateSearchFilterInput,
      first: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const preview = data?.viewer.bulkEmailPreview.emailPreviews.edges[0]?.node;

  const { fromEmail, toEmail, emailTitle, emailContent } = preview || {};

  const { hasNextPage, hasPreviousPage, endCursor } =
    (!loading && data?.viewer.bulkEmailPreview.emailPreviews.pageInfo) || {};

  // define array to save cursors of all visited pages
  const [cursorStack] = useState([]);

  const onNext = () => {
    if (!hasNextPage) {
      return;
    }
    fetchMore({
      variables: {
        after: endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => fetchMoreResult,
    });
    // add cursor of visited page to array
    cursorStack.push(endCursor);
  };

  const onPrevious = () => {
    if (!hasPreviousPage) {
      return;
    }
    // remove last cursor from array
    cursorStack.splice(-1, 1);

    // set after (cursor) for last saved in cursorStack
    fetchMore({
      variables: {
        after: cursorStack[cursorStack.length - 1],
      },
      updateQuery: (previousResult, { fetchMoreResult }) => fetchMoreResult,
    });
  };

  if (loading && !preview) {
    return <Loader fullSize />;
  }

  return (
    <div>
      <Alert className={styles.infoAlert}>
        {t('PreviewBulkEmail_info--instruction')}
      </Alert>
      <div className={styles.previewContainer}>
        {(loading || isSaving) && (
          <div className={styles.loaderWrapper}>
            <Loader fullSize />
          </div>
        )}
        <Button
          iconOnly
          icon={
            <DoubleLeftOutlined
              data-testid="previous-preview-button"
              disabled={!hasPreviousPage}
              className={styles.previousButton}
              aria-hidden
            />
          }
          onClick={onPrevious}
          aria-label="previous"
        />
        <div>
          <p>
            {t('PreviewBulkEmail_from')}{' '}
            <span className={styles.boldField}>{fromEmail}</span>
          </p>
          <p>
            {t('PreviewBulkEmail_to')}{' '}
            <span className={styles.boldField}>{toEmail}</span>
          </p>
          <p className={styles.boldField}>{emailTitle}</p>
          <p>{nl2br(emailContent)}</p>
        </div>
        <Button
          iconOnly
          icon={
            <DoubleRightOutlined
              data-testid="next-preview-button"
              disabled={!hasNextPage}
              className={styles.nextButton}
              aria-hidden
            />
          }
          onClick={onNext}
          aria-label="next"
        />
      </div>
    </div>
  );
}

PreviewBulkEmail.propTypes = {
  emailTemplatePk: PropTypes.string,
  candidateSearchFilterInput: PropTypes.object,
  isSaving: PropTypes.bool,
};

export default PreviewBulkEmail;

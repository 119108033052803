import { useQuery } from '@apollo/client';
import allStagesQuery from 'pages/candidates/Stage/allStagesQuery.gql';
import useCurrentOrganization from './useCurrentOrganization';

function useOrganizationStageLists() {
  const [organizationPk] = useCurrentOrganization();

  const { data, loading } = useQuery(allStagesQuery, {
    variables: { organizationPk },
  });

  const stageLists = data?.viewer?.allStageLists;

  return { stageLists, loading };
}

export default useOrganizationStageLists;

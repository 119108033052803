import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'i18n';
import moment from 'moment';
import { compose } from 'apollo';

import Link from 'components/Link';
import Progress, { ProgressLegend } from 'components/Progress';
import Loader from 'components/Loader';
import Content, { Head } from 'components/Content';
import ActivateOrChangePlanButton from 'components/ActivateOrChangePlanButton';
import DateWithIcon from 'components/DateWithIcon';
import Button from 'components/Form/Button';
import Table from 'components/Table';
import Title from 'components/Title';

import formatCurrency from 'utils/formatCurrency';
import { formatDate } from 'utils/date';

import styles from './PlanPage.less';

const CONTENT_SIZE = 'medium';

const getColumns = (t, currency) => [
  {
    title: t('thBillName'),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: t('thBillDate'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'right',
    render: (date) => formatDate(date, 'short'),
  },
  {
    title: t('thBillAmount'),
    dataIndex: 'amount',
    key: 'amount',
    align: 'right',
    render: (amount) => formatCurrency(amount, currency),
  },
];

const renderListItems = (isPlanned, t) =>
  function listItemRenderer(o) {
    return (
      <ProgressLegend
        key={o.jobPositionPk}
        type={isPlanned ? 'planned' : 'progress'}
      >
        <span className={styles.currentUsageDetailsListMuted}>
          {isPlanned && `(${t('PlanPage_currentUsage--isPlanned')}) `}
          <Link to={`/positions/${o.jobPositionPk}`}>{o.jobPosition}</Link>{' '}
          {t('PlanPage_currentUsage--forPeriod')}{' '}
          {moment(o.startDate).format('DD/MM')}
          {' — '}
          {moment(o.endDate).format('DD/MM/YYYY')}
        </span>
      </ProgressLegend>
    );
  };

function PlanPage({ planInfo, isLoading, t }) {
  const [isUsageDetailsExpanded, toggleUsageDetailsExpanded] = useState(false);

  if (isLoading) return <Loader fullSize />;

  const {
    monthlyCredits,
    creditPrice,
    currency,
    planType,
    activeSubscription,
    currentUsage,
    plannedUsage,
    creditUsageList,
    plannedUsageList,
    invoices,
  } = planInfo;

  return (
    <>
      <Content
        title={t('PlanPage_title')}
        size={CONTENT_SIZE}
        // TODO: Implement solution for mobile
        customCTAButton={
          <ActivateOrChangePlanButton type="default" initialStage="changePlan">
            {t('PlanPage_changePlanButton')}
          </ActivateOrChangePlanButton>
        }
      >
        <Title withOrganizationTitle>{t('Title_planPage')}</Title>
        <>
          <div className={styles.infoBoxTopRow}>
            <span>
              <Trans
                i18nKey="PlanPage_infoBox--currentPlan"
                count={monthlyCredits}
              >
                <b>{{ monthlyCredits }}</b> open job positions / mo
              </Trans>
            </span>
            <span className={styles.infoBoxTopRowPrice}>
              <b>{formatCurrency(monthlyCredits * creditPrice, currency)}</b>
              {' / '}
              {t('PlanPage_infoBox--perMonth')}
            </span>
          </div>
          <div className={styles.infoBoxBottomRow}>
            <dl>
              <dt>
                <b>{t('PlanPage_infoBox--subscriptionType')}</b>
              </dt>
              <dd>{t(`PlanPage_infoBox--${planType}`)}</dd>
            </dl>
            <dl>
              <dt>
                <b>{t('PlanPage_infoBox--subscriptionRenew')}</b>
              </dt>
              <dd>
                {activeSubscription
                  ? moment(activeSubscription.renewDate).format('DD/MM/YY')
                  : '— —'}
              </dd>
            </dl>
            <dl>
              <dt>
                <b>{t('PlanPage_infoBox--billingPeriod')}</b>
              </dt>
              <dd>
                {activeSubscription ? (
                  <span>
                    {moment(activeSubscription.startDate).format('D MMM')}
                    {' - '}
                    {moment(activeSubscription.endDate).format('D MMM')}
                  </span>
                ) : (
                  '— —'
                )}
              </dd>
            </dl>
          </div>
        </>
      </Content>
      <Content
        size={CONTENT_SIZE}
        secondary
        title={t('PlanPage_currentUsage--title')}
        subtitle={
          <span>
            {t('PlanPage_currentUsage--planRenews')}{' '}
            {activeSubscription && (
              <DateWithIcon date={activeSubscription.renewDate} />
            )}
          </span>
        }
      >
        <>
          <div className={styles.currentUsagePositions}>
            <Head>{t('PlanPage_currentUsage--openPositions')}</Head>
            <Head>
              {currentUsage + plannedUsage} / {monthlyCredits}
            </Head>
          </div>
          <div className={styles.progressBarWrapper}>
            <Progress
              percent={(currentUsage / monthlyCredits) * 100}
              plannedPercent={
                ((currentUsage + plannedUsage) / monthlyCredits) * 100
              }
            />
          </div>
          <div className={styles.progressLegend}>
            <ProgressLegend type="progress">
              {t('PlanPage_progressLegend--currentUsage')}
            </ProgressLegend>
            <ProgressLegend type="planned">
              {t('PlanPage_progressLegend--plannedUsage')}
            </ProgressLegend>
            <ProgressLegend type="trail">
              {t('PlanPage_progressLegend--availableCredits')}
            </ProgressLegend>
          </div>
        </>
        <div className={styles.currentUsageDetails}>
          {isUsageDetailsExpanded ? (
            <div>
              <Head>{t('PlanPage_currentUsage--positionsListTitle')}</Head>
              <ul className={styles.currentUsageDetailsList}>
                {creditUsageList.map(renderListItems(false, t))}
                {plannedUsageList.map(renderListItems(true, t))}
              </ul>
            </div>
          ) : (
            <div className={styles.currentUsageButtonWrapper}>
              <Button
                size="small"
                onClick={() => toggleUsageDetailsExpanded(true)}
                className={styles.currentUsageButton}
              >
                {t('PlanPage_creditUsage--showMore')}
              </Button>
            </div>
          )}
        </div>
      </Content>
      <Content
        condensed
        size={CONTENT_SIZE}
        secondary
        title={t('PlanPage_bills--title')}
      >
        <Table
          dataSource={invoices.map(({ id, ...rest }) => ({
            key: id,
            ...rest,
          }))}
          columns={getColumns(t, currency)}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
      </Content>
    </>
  );
}

PlanPage.propTypes = {
  planInfo: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  // From HoCs
  t: PropTypes.func.isRequired,
};

export default compose(withTranslation('translation'))(PlanPage);

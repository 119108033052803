import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Form/Button';
import usePermissions from 'hooks/usePermissions';
import { PERMISSION_BILLING_PLAN_MANAGE } from 'consts/permissions';

import Icon from '@ant-design/icons';
import ChangePlanModalContainer from './ChangePlanModalContainer';

function ActivateOrChangePlanButton({
  children,
  type = 'primary',
  size = 'default',
  iconComponent,
  initialStage,
  jobPositionPk,
  dataRole,
}) {
  const [isModalVisible, toggleModalVisible] = useState(false);
  const [hasManagePlanPermission, { showNoPermissionMessage }] = usePermissions(
    [PERMISSION_BILLING_PLAN_MANAGE],
  );

  return (
    <>
      <Button
        onClick={
          hasManagePlanPermission
            ? () => toggleModalVisible(!isModalVisible)
            : showNoPermissionMessage
        }
        type={type}
        size={size}
        icon={
          iconComponent && (
            <Icon
              component={iconComponent}
              data-testid="antd-icon"
              aria-hidden
            />
          )
        }
        dataRole={dataRole}
      >
        {children}
      </Button>
      {isModalVisible && (
        <ChangePlanModalContainer
          initialStage={initialStage}
          jobPositionPk={jobPositionPk}
          isModalVisible={isModalVisible}
          toggleModalVisible={toggleModalVisible}
        />
      )}
    </>
  );
}

ActivateOrChangePlanButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  size: PropTypes.string,
  iconComponent: PropTypes.elementType,
  initialStage: PropTypes.string,
  jobPositionPk: PropTypes.string,
  dataRole: PropTypes.string,
};

export default ActivateOrChangePlanButton;

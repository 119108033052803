import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { pick } from 'ramda';

import { FIELD_TYPE_TEXT, FIELD_TYPE_MULTICHOICE } from 'consts/fieldTypes';
import Tooltip from 'components/Tooltip';
import Modal from 'components/Modal';
import Alert from 'components/Alert';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import Button from 'components/Form/Button';

import styles from './CustomFieldFormModal.less';

const getCustomFieldTypes = (t) => [
  {
    value: FIELD_TYPE_TEXT,
    label: t('CustomFieldFormModal_typeText'),
  },
  {
    value: FIELD_TYPE_MULTICHOICE,
    label: t('CustomFieldFormModal_typeMultichoice'),
  },
];

/**
 * Presentational component for displaying modal.
 * Whole logic is handled in parent components.
 */
function CustomFieldFormModal({
  isVisible,
  onCancel,
  onSave,
  title,
  initialName,
  choices,
  fieldType,
  setFieldErrors,
  fieldErrors,
  clearFieldError,
  generalError,
  isSaving,
}) {
  // If there are no inital choices, we set an empty array as the initial choice.
  // We do this, because when user want to create TEXT type CF,
  // we need to send mutation argument 'choices' with an empty array.
  const initialChoices = choices
    ? // We use pick from ramda to remove __typename property because we can't send it in a mutation
      choices.map((choice) => pick(['pk', 'value'], choice))
    : [];
  // If there are no initial choices, set the TEXT type as the inital type
  const initialFieldType =
    fieldType === FIELD_TYPE_MULTICHOICE
      ? FIELD_TYPE_MULTICHOICE
      : FIELD_TYPE_TEXT;
  const { t } = useTranslation();
  const customFieldTypes = getCustomFieldTypes(t);
  const [customFieldChoices, setCustomFieldChoices] = useState(initialChoices);
  const [customFieldName, setCustomFieldName] = useState(initialName);
  const [customFieldType, setCustomFieldType] = useState(initialFieldType);

  const onNameInputChange = useCallback(
    (e) => {
      setCustomFieldName(e.target.value);
      clearFieldError('name');
    },
    [setCustomFieldName, clearFieldError],
  );

  const addChoiceInput = useCallback(() => {
    if (customFieldChoices.find((choice) => choice.value === '')) {
      return setFieldErrors({
        choices: t(
          'CustomFieldFormModal_inputValidation-noChoiceFieldCanBeEmpty',
        ),
      });
    }
    setCustomFieldChoices([...customFieldChoices, { value: '' }]);
  }, [setCustomFieldChoices, customFieldChoices, setFieldErrors, t]);

  const removeChoiceInput = useCallback(
    (index) => {
      const listOfChoices = [...customFieldChoices];
      listOfChoices.splice(index, 1);
      setCustomFieldChoices(listOfChoices);
    },
    [setCustomFieldChoices, customFieldChoices],
  );

  const onCustomFieldTypeChange = useCallback(
    (value) => {
      setCustomFieldType(value);
      clearFieldError('choices');
      // If user changes type to TEXT while editing CF, set choices as an empty array
      if (value === FIELD_TYPE_TEXT) {
        setCustomFieldChoices([]);
      }
      // If user changes type to multichoice, display only blank input
      if (value === FIELD_TYPE_MULTICHOICE) {
        setCustomFieldChoices([{ value: '' }]);
      }
    },
    [setCustomFieldType, clearFieldError],
  );

  const onChoicesInputChange = useCallback(
    (e, index, pk) => {
      const { value } = e.target;
      const listOfChoices = [...customFieldChoices];
      // If choice has a pk then we edit the existing choice
      const customFieldChoiceIndex = pk
        ? listOfChoices.findIndex((choice) => choice.pk === pk)
        : index;
      listOfChoices[customFieldChoiceIndex].value = value;
      setCustomFieldChoices(listOfChoices);
      clearFieldError('choices');
    },
    [customFieldChoices, clearFieldError],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onSave(customFieldName, customFieldChoices, customFieldType);
    },
    [onSave, customFieldName, customFieldChoices, customFieldType],
  );

  return (
    <Modal
      visible={isVisible}
      maskClosable={false}
      title={title}
      onCancel={onCancel}
      onOk={onSubmit}
      okDisabled={isSaving}
      okText={t('CustomFieldFormModal_okText')}
    >
      {generalError && <Alert type="error" showIcon message={generalError} />}
      <Form layout="vertical" onFinish={onSubmit}>
        <Field
          label={t('CustomFieldFormModal_fieldName')}
          error={fieldErrors.name}
          colon={false}
        >
          <Input
            type="text"
            value={customFieldName}
            onChange={onNameInputChange}
            placeholder={t('CustomFieldFormModal_example')}
            autoFocus
          />
        </Field>
        <Field label={t('CustomFieldFormModal_fieldType')}>
          <Select
            choices={customFieldTypes}
            onChange={onCustomFieldTypeChange}
            value={customFieldType}
            data-testid="Select-fieldType"
            fullWidth
            aria-label={t('CustomFieldFormModal_fieldType')}
          />
        </Field>
        {customFieldType === FIELD_TYPE_MULTICHOICE && (
          <>
            {fieldErrors.choices && (
              <Alert type="error" showIcon message={fieldErrors.choices} />
            )}
            <div className={styles.addChoiceWrapper}>
              <span>{t('CustomFieldFormModal_addChoiceInputText')}</span>
              <Tooltip title={t('CustomFieldFormModal_choicesTooltip')}>
                <span>
                  <Button
                    iconOnly
                    icon={
                      <PlusCircleOutlined
                        className={styles.choiceButtonsIcons}
                        aria-hidden
                      />
                    }
                    className={styles.circleButton}
                    onClick={addChoiceInput}
                    type="text"
                    size="default"
                    data-testid="CustomFieldFormModal-addChoiceButton"
                    aria-label="add-choice"
                  />
                </span>
              </Tooltip>
            </div>
            {customFieldChoices.map((choice, i) => (
              <Field key={choice.pk ? `${choice.pk}pk` : i}>
                <Input
                  type="text"
                  value={choice.value}
                  onChange={(e) => onChoicesInputChange(e, i, choice.pk)}
                  placeholder={t('CustomFieldFormModal_choiceExample')}
                  className={styles.choiceInput}
                  onPressEnter={addChoiceInput}
                  autoFocus
                />
                {
                  /** If is only one input, remove the delete button so the user need to fill input
                   * or change to TEXT type
                   */
                  i > 0 && (
                    <Button
                      onClick={() => removeChoiceInput(i)}
                      size="default"
                      type="text"
                      icon={
                        <DeleteOutlined className={styles.choiceButtonsIcons} />
                      }
                      data-testid="CustomFieldFormModal-removeChoiceButton"
                    />
                  )
                }
              </Field>
            ))}
          </>
        )}
      </Form>
    </Modal>
  );
}

CustomFieldFormModal.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  title: PropTypes.string,
  initialName: PropTypes.string,
  fieldErrors: PropTypes.object,
  clearFieldError: PropTypes.func,
  isVisible: PropTypes.bool,
  generalError: PropTypes.string,
  isSaving: PropTypes.bool,
  choices: PropTypes.array,
  fieldType: PropTypes.string,
  setFieldErrors: PropTypes.func,
};
export default CustomFieldFormModal;

import React from 'react';
import PropTypes from 'prop-types';
import { roleName } from 'utils/translation';
import Avatar from 'components/Avatar';
import RoleTag from 'components/RoleTag';
import ChangeRoleButton from './ChangeRoleButton';
import styles from './Member.less';

function Member({ memberData, roles, canChangeRole, t }) {
  const {
    user: { initials, firstName, lastName, email },
    role: { name },
  } = memberData;

  return (
    <div className={styles.container} data-testid="membership">
      <Avatar initials={initials} size="small" email={email} />
      <span className={styles.name}>
        {firstName} {lastName} {email && `(${email})`}
      </span>
      <RoleTag>{roleName(t, name)}</RoleTag>
      {canChangeRole ? (
        <ChangeRoleButton roles={roles} memberData={memberData} t={t} />
      ) : null}
    </div>
  );
}

Member.propTypes = {
  memberData: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  canChangeRole: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default Member;

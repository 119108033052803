import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Form/Select';
import cx from 'classnames';
import { withTranslation } from 'i18n';
import styles from './index.less';

export const Option = Select.Option;

function RatingSelect({
  ratingList,
  className,
  rating,
  displayCards,
  nonChangeable,
  t,
  popupContainerRef,
  onChange,
  disabled,
  ...rest
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (selectedPk) => {
    setIsLoading(true);
    const ratingOption = ratingList.find((option) => option.pk === selectedPk);
    await onChange(ratingOption);
    setIsLoading(false);
  };

  const choices =
    ratingList?.map((ratingOption) => ({
      value: ratingOption.pk,
      label: ratingOption.value,
    })) || [];

  return nonChangeable ? (
    <div className={className}>{rating?.value || t('noRating')}</div>
  ) : (
    <Select
      data-testid={rest['data-testid'] || ''}
      className={cx(
        styles.ratingSelect,
        className,
        displayCards && styles.cardRatingSelect,
      )}
      value={rating?.value}
      choices={choices}
      withBorders={false}
      placeholder={t('placeholderRating')}
      onChange={handleChange}
      disabled={disabled || isLoading}
      popupContainer={popupContainerRef}
      {...rest}
    />
  );
}

const ratingPropType = PropTypes.shape({
  pk: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

RatingSelect.propTypes = {
  className: PropTypes.string,
  rating: ratingPropType,
  ratingList: PropTypes.arrayOf(ratingPropType),
  displayCards: PropTypes.bool,
  nonChangeable: PropTypes.bool,
  t: PropTypes.func,
  popupContainerRef: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default withTranslation('translation')(RatingSelect);

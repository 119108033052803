import React, { useCallback, useState } from 'react';
import { useTranslation } from 'i18n';
import { useMutation, gql } from '@apollo/client';
import { LockOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';

import { FIELD_TYPE_TEXT, FIELD_TYPE_MULTICHOICE } from 'consts/fieldTypes';
import { XS } from 'consts/media';
import Tooltip from 'components/Tooltip';
import MoreMenu from 'components/MoreMenu';
import Loader from 'components/Loader';
import Content from 'components/Content';
import Table from 'components/Table';
import Title from 'components/Title';
import CustomFieldEditModal from 'pages/settings/custom-fields/CustomFieldAddModal/CustomFieldEdit';
import CustomFieldAddModal from 'pages/settings/custom-fields/CustomFieldAddModal/CustomFieldAddModal';
import { logAndShowGenericError } from 'utils/log';
import confirmDeleteModal from 'utils/confirmDeleteModal';
import { PERMISSION_ORGANIZATION_MANAGE } from 'consts/permissions.js';
import useMembership from 'hooks/useMembership';
import useToggleState from 'hooks/useToggleState';
import useBrowserWidth from 'hooks/useBrowserWidth';
import usePermissions from 'hooks/usePermissions';
import InfoButton from 'components/InfoButton';
import useOrganizationCustomFields, {
  CUSTOM_FIELDS_QUERY,
} from './useOrganizationCustomFields';

import styles from './CustomFieldsSettings.less';

export const DELETE_CUSTOM_FIELD_MUTATION = gql`
  mutation DeleteFieldMutation($organizationPk: String!, $customFieldPk: Int!) {
    customFieldDelete(
      organizationPk: $organizationPk
      customFieldPk: $customFieldPk
    ) {
      status
      errors {
        message
        fields
      }
    }
  }
`;

const getMoreMenu = (t) => [
  {
    key: 'edit',
    iconComponent: EditOutlined,
    content: t('edit_customField'),
    dataRole: 'edit-custom-field',
  },
  {
    key: 'delete',
    iconComponent: DeleteOutlined,
    content: t('delete_customField'),
    dataRole: 'delete-custom-field',
  },
];

/**
 * created for adding different field types (like choice, number, boolean...)
 * when new types will be available, add other cases to switch statement
 */
const getFieldType = (t, type) => {
  switch (type) {
    case FIELD_TYPE_TEXT:
      return t('fieldType_text');
    case FIELD_TYPE_MULTICHOICE:
      return t('fieldType_multichoice');
    default:
      throw new Error('field type is required');
  }
};

function CustomFieldsSettings() {
  const { t } = useTranslation();

  const [membership] = useMembership();
  const browserWidth = useBrowserWidth();

  const organizationPk = membership ? membership.organization.pk : null;

  const [customFields, { loading }] = useOrganizationCustomFields({
    organizationPk,
  });

  const [isModalVisible, onModalOpen, onModalClose] = useToggleState(false);
  const [fieldToEdit, setFieldToEdit] = useState();
  const [isSaving, toggleIsSaving] = useState(false);
  const [deleteField] = useMutation(DELETE_CUSTOM_FIELD_MUTATION);
  const [hasOrganizationManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_ORGANIZATION_MANAGE]);

  const infoModalContent = (
    <div className={styles.utmInfoModal}>
      <div>{t('customTagsInfoModal_description')}</div>
      <div>
        <strong>{t('utmInfoModal_title')}</strong>
      </div>
      <div>{t('utmInfoModal_description')}</div>
      <div>
        <strong>utm_source </strong>
        {t('utm_source_description')}
      </div>
      <div>
        <strong>utm_medium </strong>
        {t('utm_medium_description')}
      </div>
      <div>
        <strong>utm_campaign </strong>
        {t('utm_campaign_description')}
      </div>
      <div>
        <strong>utm_content </strong>
        {t('utm_content_description')}
      </div>
      <div>
        <strong>utm_term </strong>
        {t('utm_term_description')}
      </div>
    </div>
  );

  const onDelete = useCallback(
    (customFieldPk) => {
      toggleIsSaving(true);
      deleteField({
        variables: { organizationPk, customFieldPk },
        refetchQueries: [CUSTOM_FIELDS_QUERY],
      })
        .then(({ errors }) => {
          toggleIsSaving(false);
          if (errors) {
            message.error(errors.message);
          }
        })
        .catch(
          logAndShowGenericError('deleteCustomField rejected', {
            deleteField,
            customFieldPk,
            organizationPk,
          }),
        );
    },
    [organizationPk, deleteField],
  );

  const onEdit = useCallback(
    (customField) => {
      setFieldToEdit(customField);
      onModalOpen();
    },
    [setFieldToEdit, onModalOpen],
  );

  const onCancel = useCallback(() => {
    setFieldToEdit('');
    onModalClose();
  }, [setFieldToEdit, onModalClose]);

  const onItemClick = useCallback(
    (key, customField) => {
      if (key === 'edit') {
        onEdit(customField);
      } else if (key === 'delete') {
        confirmDeleteModal(
          `${t('CustomFieldsSettings_sureWantToDeleteTag')} ${
            customField.name
          }?`,
          () => onDelete(customField.pk),
        );
      }
    },
    [onEdit, onDelete, t],
  );

  const COLUMNS = [
    {
      title: t('CustomFieldsSettings_thName'),
      dataIndex: 'name',
      key: 'name',
      className: styles.fieldName,
      render: (text, customField) => (
        <div>
          {customField.name}
          {!customField.isEditable && (
            <Tooltip
              placement="bottom"
              title={t('CustomFieldsSettings_fieldNotEditable')}
            >
              <LockOutlined className={styles.icon} />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: t('CustomFieldsSettings_thType'),
      dataIndex: 'fieldTypeLabel',
      key: 'fieldTypeLabel',
      render: (text, customField) => (
        <div>
          <div>{getFieldType(t, customField.fieldType)}</div>
          <div className={styles.options}>
            {customField.choices?.map((option) => option.value).join(', ')}
          </div>
        </div>
      ),
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      render: (text, customField) =>
        customField.isEditable && (
          <MoreMenu
            id={customField}
            items={getMoreMenu(t)}
            onItemClick={
              hasOrganizationManagePermission
                ? (key) => onItemClick(key, customField)
                : showNoPermissionMessage
            }
            menuDisabled={isSaving}
            menuTitle={customField.name}
          />
        ),
    },
  ];

  if (loading || !membership) return <Loader fullSize />;

  return (
    <Content
      title={
        <span>
          {t('CustomFieldsSettings_title')}{' '}
          <InfoButton
            modalTitle={t('customTagsModal_title')}
            modalContent={infoModalContent}
          />
        </span>
      }
      secondary
      size="small"
      condensed
      withHeaderSpacing
      CTAText={t('CustomFieldsSettings_addCustomField')}
      onCTAClick={
        hasOrganizationManagePermission ? onModalOpen : showNoPermissionMessage
      }
    >
      <Title>{t('Title_customFieldsSettings')}</Title>
      <Table
        columns={COLUMNS}
        dataSource={customFields.map((customField, pk) => ({
          key: pk,
          ...customField,
        }))}
        rowClassName={(customField) =>
          !customField.isEditable ? styles.locked : null
        }
        pagination={{
          hideOnSinglePage: true,
        }}
        showHeader={browserWidth > XS}
        className={styles.table}
      />
      {isModalVisible && fieldToEdit && (
        <CustomFieldEditModal
          customFieldPk={fieldToEdit.pk}
          initialName={fieldToEdit.name}
          choices={fieldToEdit.choices}
          fieldType={fieldToEdit.fieldType}
          isVisible={isModalVisible}
          onCancel={onCancel}
        />
      )}
      {isModalVisible && !fieldToEdit && (
        <CustomFieldAddModal isVisible={isModalVisible} onCancel={onCancel} />
      )}
    </Content>
  );
}
export default CustomFieldsSettings;

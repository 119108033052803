import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';

import { useTranslation } from 'react-i18next';
import styles from './index.less';

function StatusCell({ text }) {
  const { t } = useTranslation();
  let status;
  if (text === 'PROCESSING') {
    status = 'processing';
  } else if (text === 'PUBLISHED') {
    status = 'success';
  } else if (text === 'ERROR_GENERATING') {
    status = 'error';
  } else {
    status = 'default';
  }

  return (
    <div className={styles.badgeWrapper}>
      <Badge status={status} />
      {t(`status_${text}`)}
    </div>
  );
}

StatusCell.propTypes = {
  text: PropTypes.string.isRequired,
};

export default StatusCell;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { Select as AntSelect } from 'antd';
import cx from 'classnames';

import Button from 'components/Form/Button';

import styles from './index.less';

function MultiFilterInput({
  placeholder,
  value,
  className,
  onFocus,
  onBlur,
  onClear,
  disabled,
  ...rest
}) {
  const [isFocused, setIsFocused] = useState(false);

  const _onFocus = (e) => {
    if (onFocus) {
      onFocus(e);
    }

    setIsFocused(true);
  };

  const _onBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }

    setIsFocused(false);
  };

  const isPopupLabelActive = (value && value.length > 0) || isFocused;

  return (
    <div
      className={cx(
        styles.container,
        isPopupLabelActive && styles.active,
        className,
      )}
    >
      <div
        className={styles.selectWrapper}
        data-testid={`select--${placeholder}`}
      >
        <AntSelect
          mode="tags"
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          className={styles.select}
          onFocus={_onFocus}
          onBlur={_onBlur}
          dropdownStyle={{ display: 'none' }}
          {...rest}
        />
        <span className={styles.label}>{placeholder}</span>
      </div>
      {onClear && (
        <Button
          onClick={onClear}
          type="default"
          size="default"
          className={styles.clearBtn}
        >
          <CloseOutlined />
        </Button>
      )}
    </div>
  );
}

MultiFilterInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.array,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MultiFilterInput;

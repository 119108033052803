import React from 'react';
import PropTypes from 'prop-types';
import { Alert as AntAlert } from 'antd';
import cx from 'classnames';

import storage from 'utils/storage';

import styles from './index.less';

function Alert({ persistKey, persistValue, children, title, cta, ...rest }) {
  let isVisible = !persistKey;
  if (persistKey) {
    const dataFromStorage = storage.get(persistKey);
    if (dataFromStorage === null) {
      isVisible = true;
    } else if (persistValue) {
      isVisible = dataFromStorage !== `${persistValue}`;
    } else {
      isVisible = false;
    }
  }
  const chidlrenContent = (
    <div className={cx(styles.container, cta && styles.withCTA)}>
      <div className={styles.text}>{children}</div>
      {cta && <div className={styles.buttonWrapper}>{cta}</div>}
    </div>
  );

  return isVisible ? (
    <AntAlert
      onClose={() =>
        storage.persist({ [persistKey]: persistValue || persistKey })
      }
      message={title || chidlrenContent}
      description={title ? chidlrenContent : null}
      {...rest}
    />
  ) : null;
}

Alert.propTypes = {
  persistKey: PropTypes.string,
  persistValue: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cta: PropTypes.node,
  CTAButtonType: PropTypes.string,
  CTAButtonSize: PropTypes.string,
  className: PropTypes.string,
};

export default Alert;

import { Switch as AntSwitch } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Switch.less';

function Switch({ className, ...rest }) {
  return <AntSwitch {...rest} className={cx(className, styles.switch)} />;
}

Switch.propTypes = {
  className: PropTypes.string,
};

export default Switch;

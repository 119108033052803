import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'apollo';

import withOrganizationPk from 'hoc/withOrganizationPk';

import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';

import { validator, isRequired } from 'utils/validator';
import { logAndShowGenericError } from 'utils/log';
import { showGenericError, showError } from 'utils/message';

import emailTemplatesListQuery from '../emailTemplatesListQuery.gql';
import addEmailTemplateMutation from '../addEmailTemplateMutation.gql';

import EmailTemplateFormModal from './EmailTemplateFormModal';

const VALIDATE_FIELDS = {
  newTitle: [isRequired],
  newContent: [isRequired],
};

const getValidationErrors = validator(VALIDATE_FIELDS);

function NewEmailTemplateModal({
  mutate,
  organizationPk,
  toggleIsAddingNewEmailTemplate,
}) {
  const [isNewEmailTemplateSaving, setIsNewEmailTemplateSaving] =
    useState(false);

  const {
    mutate: addEmailTemplate,
    fieldErrors,
    setFieldErrors,
    clearFieldError,
  } = useErrorFieldsMutation(mutate);

  const onAddEmailTemplate = useCallback(
    (newTitle, newContent) => {
      const validationErrors = getValidationErrors({
        newTitle,
        newContent,
      });

      if (!validationErrors) {
        setIsNewEmailTemplateSaving(true);
        addEmailTemplate({
          variables: { organizationPk, newTitle, newContent },
        })
          .then(({ data }) => {
            if (data.addEmailTemplate.errors) {
              return data.addEmailTemplate.errors.message
                ? showError(data.addEmailTemplate.errors.message)
                : showGenericError();
            }
          })
          .catch(
            logAndShowGenericError('addEmailTemplate rejected', {
              newTitle,
              newContent,
            }),
          )
          .finally(() => {
            setIsNewEmailTemplateSaving(false);
            toggleIsAddingNewEmailTemplate();
          });
      } else {
        setFieldErrors(validationErrors);
      }
    },
    [
      organizationPk,
      addEmailTemplate,
      setFieldErrors,
      toggleIsAddingNewEmailTemplate,
    ],
  );

  return (
    <EmailTemplateFormModal
      onSubmit={onAddEmailTemplate}
      onCancel={toggleIsAddingNewEmailTemplate}
      isSaving={isNewEmailTemplateSaving}
      fieldErrors={fieldErrors}
      clearFieldError={clearFieldError}
    />
  );
}

NewEmailTemplateModal.propTypes = {
  toggleIsAddingNewEmailTemplate: PropTypes.func,
  // From HoCs
  mutate: PropTypes.func,
  organizationPk: PropTypes.string,
};

export default compose(
  withOrganizationPk,
  graphql(addEmailTemplateMutation, {
    options: {
      refetchQueries: [emailTemplatesListQuery],
    },
  }),
)(NewEmailTemplateModal);

import React from 'react';
import PropTypes from 'prop-types';
import Register from 'pages/authentication/Register';
import { withRouter } from 'utils/withRouter';
import { compose, graphql } from 'apollo';
import invitationDetailsQuery from './invitationDetailsQuery.gql';

function WithInvitationDetails({ invitationDetails, isLoading }) {
  return (
    <Register
      invitationDetails={invitationDetails}
      isInvitationDetailsLoading={isLoading}
    />
  );
}

WithInvitationDetails.propTypes = {
  invitationDetails: PropTypes.shape({
    pk: PropTypes.string,
    email: PropTypes.string,
    organizationName: PropTypes.string,
  }),
  isLoading: PropTypes.bool.isRequired,
};

export default compose(
  withRouter,
  graphql(invitationDetailsQuery, {
    options: ({ params: { id } }) => {
      const invitationHash = id;
      return { variables: { invitationHash } };
    },
    props: ({ data: { invitationDetails, loading } }) => ({
      invitationDetails,
      isLoading: loading,
    }),
  }),
)(WithInvitationDetails);

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { withTranslation, Trans } from 'i18n';

import { SM } from 'consts/media';
import styles from './ApplicationsWithPopover.less';

const onMenuClick = (onApplicationClick, candidatePk) => (e) => {
  onApplicationClick(e.key, candidatePk);
};

const onLinkClick = (browserWidth) => (e) => {
  if (browserWidth > SM) {
    e.preventDefault();
  }
};

const renderApplicationsList = (
  onApplicationClick,
  candidatePk,
  applications,
  browserWidth,
) => (
  <Menu onClick={onMenuClick(onApplicationClick, candidatePk)}>
    {applications.slice(1).map(({ node }) => (
      <Menu.Item key={node.pk}>
        <Link
          to={`/candidates/${candidatePk}/${node.pk}`}
          onClick={onLinkClick(browserWidth)}
        >
          {node.jobPosition.position}
        </Link>
      </Menu.Item>
    ))}
  </Menu>
);

function ApplicationsWithPopover({
  t,
  applications,
  onApplicationClick,
  candidatePk,
  browserWidth,
}) {
  const applicationsCount = applications.length;
  const remainingApplications = applicationsCount - 1;
  const firstApplication =
    applications.length > 0
      ? applications[0].node.jobPosition.position
      : undefined;

  return (
    <div>
      {applicationsCount > 0 && (
        <>
          {t('appliedFor')} <b>{firstApplication}</b>
        </>
      )}
      {applicationsCount > 1 && (
        <Dropdown
          overlay={renderApplicationsList(
            onApplicationClick,
            candidatePk,
            applications,
            browserWidth,
          )}
          placement="topRight"
          trigger={browserWidth > SM ? ['hover'] : ['click']}
        >
          <span>
            {' '}
            <Trans
              i18nKey="remainingApplications"
              count={remainingApplications}
            >
              and{' '}
              <span className={styles.moreApplications}>
                {{ remainingApplications }} other
              </span>
            </Trans>
          </span>
        </Dropdown>
      )}
    </div>
  );
}

ApplicationsWithPopover.propTypes = {
  t: PropTypes.func.isRequired,
  applications: PropTypes.array,
  onApplicationClick: PropTypes.func.isRequired,
  candidatePk: PropTypes.string.isRequired,
  browserWidth: PropTypes.number.isRequired,
};

export default withTranslation('translation')(ApplicationsWithPopover);

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';

import {
  LoadingOutlined,
  LogoutOutlined,
  PhoneOutlined,
  TeamOutlined,
  UserAddOutlined,
} from '@ant-design/icons';

import cx from 'classnames';

import Link from 'components/Link';
import Loader from 'components/Loader';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Logo from 'components/Logo';

import styles from './index.less';

function PersonalizeAccount({
  t,
  organizationName,
  firstName,
  lastName,
  phoneNumber,
  isInputChanged,
  onSubmit,
  onSkip,
  onInputChange,
  blurOnSubmit,
  fieldErrors,
  isUserLoading,
  currentUser,
  inSettings,
  isSaving,
}) {
  if (isUserLoading || !currentUser) {
    return <Loader fullSize />;
  }

  const hasAnyMemberships = currentUser.memberships.length > 0;

  return (
    <div
      className={cx(styles.container, inSettings && styles.containerInSettings)}
    >
      <div
        className={cx(styles.content, inSettings && styles.contentInSettings)}
      >
        <div className={styles.title}>
          {!inSettings &&
            (hasAnyMemberships
              ? t('personalizeAccountWithMembership')
              : t('personalizeAccountWithoutMembership'))}
        </div>
        <Form onFinish={onSubmit} className={styles.form}>
          {!hasAnyMemberships && (
            <Field error={fieldErrors.organizationName}>
              <Input
                data-testid="organization"
                value={organizationName}
                placeholder={t('loginCompanyNamePlaceholder')}
                prefix={<TeamOutlined className={styles.icon} />}
                onChange={onInputChange('organizationName')}
                popupLabel
                labelDark={inSettings}
              />
            </Field>
          )}
          <Field error={fieldErrors.firstName}>
            <Input
              value={firstName}
              placeholder={t('loginFirstNamePlaceholder')}
              prefix={<UserAddOutlined className={styles.icon} />}
              onChange={onInputChange('firstName')}
              onKeyDown={blurOnSubmit}
              popupLabel
              labelDark={inSettings}
            />
          </Field>
          <Field error={fieldErrors.lastName}>
            <Input
              value={lastName}
              placeholder={t('loginLastNamePlaceholder')}
              prefix={<UserAddOutlined className={styles.icon} />}
              onChange={onInputChange('lastName')}
              onKeyDown={blurOnSubmit}
              popupLabel
              labelDark={inSettings}
            />
          </Field>
          <Field error={fieldErrors.phoneNumber}>
            <Input
              value={phoneNumber}
              placeholder={t('loginPhonePlaceholder')}
              prefix={<PhoneOutlined className={styles.icon} />}
              onChange={onInputChange('phoneNumber')}
              onKeyDown={blurOnSubmit}
              type="tel"
              popupLabel
              labelDark={inSettings}
            />
          </Field>
          <Button hiddenSubmit disabled={isSaving} />
        </Form>
        {!(inSettings && !isInputChanged) && (
          <div className={styles.buttonsContainer}>
            <Button
              data-testid="submit"
              type="primary"
              className={styles.registerBtn}
              onClick={isSaving ? null : onSubmit}
            >
              {isSaving ? <LoadingOutlined /> : t('send')}
            </Button>
            <div className={styles.skipOrLogout}>
              {inSettings && (
                <Button type="default" onClick={onSkip}>
                  {t('cancel')}
                </Button>
              )}
              <span>
                {!inSettings && (
                  <Link to="/logout" className={styles.logout}>
                    <span>
                      {' '}
                      <LogoutOutlined /> {t('logout')}
                    </span>
                  </Link>
                )}
              </span>
            </div>
          </div>
        )}
      </div>
      {!inSettings && <Logo className={styles.logo} />}
    </div>
  );
}

PersonalizeAccount.propTypes = {
  t: PropTypes.func.isRequired,
  organizationName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  isInputChanged: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  blurOnSubmit: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object,
  isUserLoading: PropTypes.bool,
  currentUser: PropTypes.object,
  inSettings: PropTypes.bool,
  isSaving: PropTypes.bool,
};

export default withTranslation('translation')(PersonalizeAccount);

import React, { useContext } from 'react';
import { BrowserWidthContext } from 'providers/BrowserWidthProvider';

export function withBrowserWidth(WrappedComponent) {
  withBrowserWidth.displayName = `withBrowserWidth(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return function WihBrowserWidthBare(props) {
    const browserWidth = useContext(BrowserWidthContext);

    return <WrappedComponent browserWidth={browserWidth} {...props} />;
  };
}

export default withBrowserWidth;

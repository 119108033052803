import {
  ROLE_GUEST,
  ROLE_RESTRICTED,
  ROLE_USER,
  ROLE_ADMIN,
  ROLE_SUPERADMIN,
} from 'consts/roles';

export function roleName(t, name) {
  return [
    ROLE_GUEST,
    ROLE_RESTRICTED,
    ROLE_USER,
    ROLE_ADMIN,
    ROLE_SUPERADMIN,
  ].includes(name)
    ? t(`translation_roleName-${name}`, {
        defaultValue: name,
      })
    : name;
}

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.less';

function Paper({ children, className, transparent, condensed }) {
  return (
    <div
      className={cx(
        styles.content,
        transparent && styles.isTransparent,
        condensed && styles.isCondensed,
        className,
      )}
    >
      {children}
    </div>
  );
}

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  transparent: PropTypes.bool,
  condensed: PropTypes.bool,
};

export default Paper;

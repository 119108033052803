import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { withTranslation } from 'i18n';
import styles from './index.less';

function RemoteTag({ t, className, ...rest }) {
  return (
    <Tag className={cx(styles.tag, className)} {...rest}>
      {t('remoteTag')}
    </Tag>
  );
}

RemoteTag.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default withTranslation('translation')(RemoteTag);

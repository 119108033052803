import React from 'react';
import { Helmet } from 'react-helmet';
import appleTouchIcon from './apple-touch-icon.png';
import favicon32 from './favicon-32x32.png';
import favicon16 from './favicon-16x16.png';
import safariPinnedIcon from './safari-pinned-tab.svg';
import favicon from './favicon.ico';

function HelmetMeta() {
  return (
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link
        rel="apple-touch-icon"
        type="image/png"
        sizes="180x180"
        href={appleTouchIcon}
      />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <link rel="mask-icon" href={safariPinnedIcon} color="#19324f" />
      <link rel="shortcut icon" href={favicon} />
      <meta name="theme-color" content="#19324f" />
      <html lang="pl" />
    </Helmet>
  );
}

export default HelmetMeta;

import { graphql } from 'apollo';
import { MEMBERSHIP_QUERY } from 'hooks/useMembership';

const withCurrentUser = graphql(MEMBERSHIP_QUERY, {
  props: ({ data: { viewer, loading } }) => ({
    currentUser: viewer && viewer.currentUser,
    isCurrentUserLoading: loading,
  }),
});

export default withCurrentUser;

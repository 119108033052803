import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import IconPicker from 'pages/job-positions/JobOffers/IconPicker';
import MaterialSymbolIcon from 'components/MaterialSymbolIcon';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ControlledInput from 'components/JobOfferForm/ControlledInput';
import useToggleState from 'hooks/useToggleState';
import {
  MATERIAL_SYMBOL_ACTIVE_COLOR,
  MATERIAL_SYMBOL_SIZE,
} from 'consts/materialSymbols';
import styles from './index.less';

function AccordionHeader({ control, textFieldName, iconValueFieldName }) {
  const { t } = useTranslation();
  const [isModalVisible, showModal, hideModal] = useToggleState(false);
  const iconValue = useWatch({ control, name: iconValueFieldName });
  const { setValue } = useFormContext();

  const handleIconPickerDisplay = useCallback(() => {
    if (isModalVisible) {
      hideModal();
    } else {
      showModal();
    }
  }, [showModal, hideModal, isModalVisible]);

  const handleIconSelect = useCallback(
    (selectedIcon) => {
      setValue(iconValueFieldName, selectedIcon);
      handleIconPickerDisplay();
    },
    [iconValueFieldName, setValue, handleIconPickerDisplay],
  );

  return (
    <div className={styles.header}>
      <button
        type="button"
        className={styles.iconBtn}
        onClick={handleIconPickerDisplay}
      >
        <MaterialSymbolIcon
          value={iconValue}
          size={MATERIAL_SYMBOL_SIZE}
          color={MATERIAL_SYMBOL_ACTIVE_COLOR}
        />
      </button>
      <ControlledInput
        control={control}
        name={textFieldName}
        className={styles.itemInputHeader}
        placeholder={t('AccordionBlockItem_header')}
      />
      <ControlledInput
        control={control}
        name={iconValueFieldName}
        className={styles.iconInput}
      />
      <IconPicker
        isVisible={isModalVisible}
        onCancel={handleIconPickerDisplay}
        onSave={handleIconSelect}
      />
    </div>
  );
}

AccordionHeader.propTypes = {
  control: PropTypes.object.isRequired,
  textFieldName: PropTypes.string.isRequired,
  iconValueFieldName: PropTypes.string.isRequired,
};

export default AccordionHeader;

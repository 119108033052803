import { useQuery, gql } from '@apollo/client';
import useMembership from './useMembership';

const USERS_QUERY = gql`
  query getOrganizationUsers($organizationPk: String!) {
    viewer {
      memberships(organizationPk: $organizationPk) {
        edges {
          node {
            id
            user {
              pk
              email
              firstName
              lastName
            }
            role {
              name
              id
              permissions
            }
          }
        }
      }
    }
  }
`;

export default function useOrganizationUsers() {
  // Getting current user's organizationPk
  const [membership] = useMembership();
  const organizationPk = membership ? membership.organization.pk : '';
  const { data, loading } = useQuery(USERS_QUERY, {
    variables: { organizationPk },
  });

  if (loading || !data) {
    return [];
  }

  const {
    viewer: {
      memberships: { edges },
    },
  } = data;

  return edges.map((edge) => edge.node);
}

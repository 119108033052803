import React, { Fragment } from 'react';

export const nl2br = (input) => {
  const splitText = input.split('\n');

  return splitText.map((line, i) => (
    <Fragment key={/* eslint-disable-line react/no-array-index-key */ i}>
      {i > 0 && <br />} {line}
    </Fragment>
  ));
};

import useMembership from './useMembership';

export default function useCurrentOrganization() {
  const [currentMembership, isLoading] = useMembership();

  return [
    currentMembership ? currentMembership.organization.pk : '',
    isLoading,
  ];
}

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Radio as AntRadio } from 'antd';
import styles from './index.less';

export const { Group, Button } = AntRadio;

const themes = [
  {
    key: 'transparent',
    style: styles.radioTransparent,
  },
];

function Radio({ theme, ...rest }) {
  const themeStyle = theme && themes.find((t) => t.key === theme).style;
  return <Group className={cx(themeStyle, styles.radio)} {...rest} />;
}

Radio.propTypes = {
  theme: PropTypes.oneOf(['transparent']),
};

export default Radio;

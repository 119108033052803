import React from 'react';
import { useTranslation } from 'i18n';

import Alert from 'components/Alert';

function BrowserSupport() {
  const { t } = useTranslation();

  const isEdge = window.navigator.userAgent.indexOf('Edge') > 0;

  return (
    isEdge && (
      <Alert
        type="warning"
        banner
        message={t('browserSupport_pleaseChangeTheBrowser')}
        closable
        persistKey="browsersSupportAlertClosed"
      />
    )
  );
}

export default BrowserSupport;

import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import styles from './index.less';

/**
 * Display spinning loader. If `fullSize` prop is passed, loader will be rendered in
 * a full-width container with the loader centered. Additionally it accepts all props that AntD's Spin does.
 */
function Loader({ fullSize, ...rest }) {
  const spinner = <Spin data-testid="loader" {...rest} />;

  return fullSize ? <div className={styles.fullSize}>{spinner}</div> : spinner;
}

Loader.propTypes = {
  fullSize: PropTypes.bool,
};

export default Loader;

import React from 'react';
import { useTranslation } from 'i18n';
import PropTypes from 'prop-types';
import Link from 'components/Link';

import styles from '../NameCell.less';

function NameCell({ candidate, toEmail }) {
  const { t } = useTranslation();
  if (candidate) {
    const applicationPK = candidate.applications.edges[0].node.pk;

    return (
      <div>
        <Link to={`/candidates/${candidate.pk}/${applicationPK}`}>
          {candidate.firstName} {candidate.lastName}
        </Link>
        <div className={styles.details}>
          <div>{toEmail}</div>
        </div>
      </div>
    );
  }
  // removed candidates still exists in send bulk emails
  return (
    <div>
      <div>{t('Candidate removed')}</div>
      <div className={styles.details}>
        <div>{toEmail}</div>
      </div>
    </div>
  );
}

NameCell.propTypes = {
  candidate: PropTypes.object,
  toEmail: PropTypes.string,
};

export default NameCell;

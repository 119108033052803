import React from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Form/Input';
import { SearchOutlined } from '@ant-design/icons';
import styles from './index.less';

function Search({ className, ...rest }) {
  return (
    <Input
      className={className}
      addonAfter={<SearchOutlined className={styles.icon} />}
      {...rest}
    />
  );
}

Search.propTypes = {
  className: PropTypes.string,
};
export default Search;

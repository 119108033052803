import { useQuery } from '@apollo/client';
import vasRequestDetailsQuery from 'pages/job-positions/VasRequestDetails/vasRequestDetailsQuery.gql';
import useCurrentOrganization from './useCurrentOrganization';

function useJobPositionVAS({ jobPositionPk }) {
  const [organizationPk] = useCurrentOrganization();

  const { data, loading } = useQuery(vasRequestDetailsQuery, {
    variables: { organizationPk, jobPositionPk },
  });

  const selectedVas = data?.viewer.jobPositions.edges[0].node.selectedVas;

  return { selectedVas, loading };
}

export default useJobPositionVAS;

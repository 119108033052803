import { useEffect } from 'react';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useCurrentUser from 'hooks/useCurrentUser';
import useChangeOrganization from 'hooks/useChangeOrganization';

/**
 * Hook that sets current organization to the user's default one (first one) if no organization is loaded.
 */

const useDefaultOrganizationEffect = () => {
  const changeOrganization = useChangeOrganization();
  const [currentUser] = useCurrentUser();
  const [organizationPk, isLoading] = useCurrentOrganization();

  useEffect(() => {
    if (
      !isLoading &&
      !organizationPk &&
      currentUser &&
      currentUser.memberships.length > 0
    ) {
      changeOrganization(currentUser.memberships[0].organization.pk);
    }
  }, [currentUser, organizationPk, changeOrganization, isLoading]);
};

export default useDefaultOrganizationEffect;

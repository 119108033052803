import md5 from 'js-md5';

const getColorFromString = (string) => {
  // Since md5 is hex encoded we'll use first six characters as a custom color
  const stringHash = md5(string);
  const color = stringHash.slice(0, 6);
  // Calculate color's lightness/darkness based on average of RGB values
  const colorAverage =
    color
      .match(/.{2}/g)
      .map((o) => parseInt(o, 16))
      .reduce((a, b) => a + b) / 3;
  // Use white text color for dark backgrounds and black for light backgrounds
  const textColor = colorAverage > 155 ? '000000' : 'FFFFFF';

  return {
    color,
    textColor,
  };
};

export default getColorFromString;

export const PERMISSION_CANDIDATE_MANAGE = 'candidate_manage';
export const PERMISSION_CANDIDATE_DETAILS_MANAGE = 'candidate_details_manage';
export const PERMISSION_APPLICATION_MANAGE = 'application_manage';
export const PERMISSION_APPLICATION_DETAILS_MANAGE =
  'application_details_manage';
export const PERMISSION_APPLICATION_STATUS_MANAGE = 'application_status_manage';
export const PERMISSION_APPLICATION_COMMENT_ADD = 'application_comment_add';
export const PERMISSION_APPLICATION_EMAIL_SEND = 'application_email_send';
export const PERMISSION_JOB_POSITION_MANAGE = 'job_position_manage';
export const PERMISSION_REQUEST_MANAGE = 'request_manage';
export const PERMISSION_BILLING_PLAN_MANAGE = 'billing_plan_manage';
export const PERMISSION_EMAIL_TEMPLATES_MANAGE = 'email_templates_manage';
export const PERMISSION_USERS_MANAGE = 'users_manage';
export const PERMISSION_ORGANIZATION_MANAGE = 'organization_manage';
export const PERMISSION_JOB_POSITION_ACCESS = 'job_position_access';
export const PERMISSION_MEMBERSHIP_ACCESS = 'membership_access';
export const PERMISSION_ACTIVITIES_ACCESS = 'activities_access';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { logAndShowGenericError } from 'utils/log';
import CandidateHeader from 'pages/candidates/CandidateHeader/CandidateHeader';
import { graphql, compose } from 'apollo';
import { withTranslation } from 'i18n';
import { withRouter } from 'utils/withRouter';
import { SM } from 'consts/media';
import withOrganizationPk from 'hoc/withOrganizationPk';
import graphqlErrorFieldsMutation from 'hoc/graphqlErrorFieldsMutation';
import addCandidateMutation from './addCandidateMutation.gql';
import editCandidateMutation from './editCandidateMutation.gql';
import deleteCandidateMutation from './deleteCandidateMutation.gql';

class CandidateHeaderContainer extends PureComponent {
  static propTypes = {
    /* eslint-disable react/no-unused-prop-types */
    candidatePk: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    modifiedAt: PropTypes.string,
    /* eslint-enable */
    createdAt: PropTypes.string,
    t: PropTypes.func.isRequired,
    addCandidate: PropTypes.object,
    editCandidate: PropTypes.object,
    deleteCandidate: PropTypes.func.isRequired,
    organizationPk: PropTypes.string.isRequired,
    navigate: PropTypes.func.isRequired,
    onNewCandidateCreated: PropTypes.func,
    onCandidateDeleted: PropTypes.func,
    onCandidateCreationCancelled: PropTypes.func,
    withLinkableHeaderNames: PropTypes.bool,
    browserWidth: PropTypes.number,
    recentApplicationPk: PropTypes.string,
  };

  state = {
    firstName: '',
    lastName: '',
    createdAt: '',
    modifiedAt: '',
    candidatePk: '',
    isEdited: false,
    prevFirstName: '',
    prevLastName: '',
    recentApplicationPk: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (state.isEdited) {
      return {};
    }

    if (props.candidatePk) {
      const {
        firstName,
        lastName,
        modifiedAt,
        candidatePk,
        recentApplicationPk,
      } = props;

      return {
        firstName,
        lastName,
        modifiedAt,
        candidatePk,
        recentApplicationPk,
      };
    }

    return {};
  }

  onInputChange = (field) => (e) => {
    this.props.addCandidate.clearFieldError(field);
    this.setState({
      [field]: e.target.value,
    });
  };

  onSubmitCreate = (firstName, lastName) => () => {
    const {
      t,
      addCandidate: { mutate, setFieldErrors },
      organizationPk,
      onNewCandidateCreated,
      navigate,
      browserWidth,
    } = this.props;
    const validationErrors = {};

    if (firstName === '') {
      validationErrors.firstName = t('validationFieldRequired');
    }

    if (lastName === '') {
      validationErrors.lastName = t('validationFieldRequired');
    }

    if (Object.keys(validationErrors).length > 0) {
      return setFieldErrors(validationErrors);
    }

    mutate({ variables: { firstName, lastName, organizationPk } })
      .then(
        ({
          data: {
            addCandidate: { candidate },
          },
          ok,
        }) => {
          if (ok) {
            const { pk, createdAt, modifiedAt } = candidate;

            this.setState({
              firstName: candidate.firstName,
              lastName: candidate.lastName,
              createdAt,
              modifiedAt,
              candidatePk: pk,
            });

            if (onNewCandidateCreated) {
              onNewCandidateCreated(pk);
            }

            if (browserWidth && browserWidth <= SM) {
              navigate(`/candidates/${pk}`);
            }
          } else {
            message.error(t('createCandidateError'));
          }
        },
      )
      .catch(
        logAndShowGenericError('addCandidate rejected', {
          props: this.props,
          state: this.state,
        }),
      );
  };

  onEditCandidate = () => {
    this.setState({
      isEdited: true,
      prevFirstName: this.state.firstName,
      prevLastName: this.state.lastName,
    });
  };

  onCancelEdit = () => {
    this.setState({
      firstName: this.state.prevFirstName,
      lastName: this.state.prevLastName,
      prevFirstName: '',
      prevLastName: '',
      isEdited: false,
    });
  };

  onSubmitEdit = (firstName, lastName) => () => {
    const {
      t,
      editCandidate: { mutate, setFieldErrors },
      organizationPk,
    } = this.props;
    const { candidatePk } = this.state;
    const validationErrors = {};

    if (firstName === '') {
      validationErrors.firstName = t('validationFieldRequired');
    }

    if (lastName === '') {
      validationErrors.lastName = t('validationFieldRequired');
    }

    if (Object.keys(validationErrors).length > 0) {
      return setFieldErrors(validationErrors);
    }

    mutate({ variables: { firstName, lastName, organizationPk, candidatePk } })
      .then(
        ({
          data: {
            editCandidate: { candidate },
          },
          ok,
        }) => {
          if (ok) {
            this.setState({
              firstName: candidate.firstName,
              lastName: candidate.lastName,
              modifiedAt: candidate.modifiedAt,
              isEdited: false,
            });
          } else {
            message.error(t('editCandidateError'));
          }
        },
      )
      .catch(
        logAndShowGenericError('editCandidate rejected', {
          props: this.props,
          state: this.state,
        }),
      );
  };

  onDeleteCandidate = () => {
    const { deleteCandidate, onCandidateDeleted, organizationPk } = this.props;

    deleteCandidate(organizationPk, this.state.candidatePk)
      .then(
        ({
          data: {
            deleteCandidate: { errors },
          },
        }) => {
          if (!errors) {
            onCandidateDeleted();
          } else {
            message.error('deleteCandidateError');
          }
        },
      )
      .catch(
        logAndShowGenericError('deleteCandidate rejected', {
          props: this.props,
          state: this.state,
        }),
      );
  };

  render() {
    const {
      addCandidate: { fieldErrors },
      createdAt,
      withLinkableHeaderNames,
      onCandidateCreationCancelled,
    } = this.props;
    const {
      firstName,
      lastName,
      modifiedAt,
      isEdited,
      candidatePk,
      recentApplicationPk,
    } = this.state;
    const stateCreatedAt = this.state.createdAt;
    const isAddSaving = this.props.addCandidate.isSaving;
    const isEditSaving = this.props.editCandidate.isSaving;
    const isSaving = isAddSaving || isEditSaving;

    return (
      <CandidateHeader
        firstName={firstName}
        lastName={lastName}
        createdAt={createdAt || stateCreatedAt}
        modifiedAt={modifiedAt}
        isEdited={isEdited}
        isNew={!candidatePk}
        onSubmitCreate={this.onSubmitCreate}
        onInputChange={this.onInputChange}
        onCandidateCreationCancelled={onCandidateCreationCancelled}
        onEditCandidate={this.onEditCandidate}
        onCancelEdit={this.onCancelEdit}
        onSubmitEdit={this.onSubmitEdit}
        onDeleteCandidate={this.onDeleteCandidate}
        fieldErrors={fieldErrors}
        isSaving={isSaving}
        withLinkableHeaderNames={withLinkableHeaderNames}
        candidatePk={candidatePk}
        recentApplicationPk={recentApplicationPk}
      />
    );
  }
}

export default compose(
  withTranslation('translation'),
  withOrganizationPk,
  withRouter,
  graphqlErrorFieldsMutation(addCandidateMutation, {}, 'addCandidate'),
  graphqlErrorFieldsMutation(editCandidateMutation, {}, 'editCandidate'),
  graphql(deleteCandidateMutation, {
    props: ({ mutate }) => ({
      deleteCandidate: (organizationPk, candidatePk) =>
        mutate({
          variables: { organizationPk, candidatePk },
        }),
    }),
  }),
)(CandidateHeaderContainer);

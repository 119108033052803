import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import BlockContent from 'components/JobOfferForm/BlockContent';
import IconTile from 'components/JobOfferForm/IconTile';
import { useTranslation } from 'react-i18next';
import ControlledInput from 'components/JobOfferForm/ControlledInput';
import AddButton from 'components/JobOfferForm/AddButton';
import classNames from 'classnames';
import useDragAndDrop from 'hooks/useDragAndDrop';
import styles from './IconListBlock.less';

function IconListBlock({ blockIndex, blockType }) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const blockName = `blocks[${blockIndex}].${blockType}`;
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `${blockName}.iconList`,
    keyName: 'key',
  });

  const {
    draggedIndex,
    onDragStart,
    onDragOver,
    onDragEnd,
    onDrop,
    getItemsWithPlaceholder,
  } = useDragAndDrop(fields, move);

  const onAddPoint = useCallback(() => {
    append({ text: '', iconValue: 'add' });
  }, [append]);

  const itemsWithPlaceholder = getItemsWithPlaceholder();

  return (
    <BlockContent className={styles.container}>
      <ControlledInput
        control={control}
        name={`${blockName}.title`}
        popupLabel
        labelDark
        type="text"
        size="middle"
        placeholder={t(`IconList_title`)}
        className={styles.input}
      />
      <div className={styles.iconContainer}>
        {itemsWithPlaceholder.map((field, index) => (
          <div
            key={field.key}
            draggable={!field.isPlaceholder}
            onDragStart={(e) => onDragStart(e, index)}
            onDragOver={(e) => onDragOver(e, index)}
            onDrop={(e) => onDrop(e, index)}
            onDragEnd={onDragEnd}
            className={classNames(styles.iconWrapper, {
              [styles.dragging]: draggedIndex === index,
              [styles.placeholder]: field.isPlaceholder,
            })}
          >
            <IconTile
              control={control}
              textFieldName={`${blockName}.iconList[${index}].text`}
              valueFieldName={`${blockName}.iconList[${index}].iconValue`}
              index={index}
              onDeleteClick={() => remove(index)}
              placeholder={t('IconTile_placeholder')}
            />
          </div>
        ))}
      </div>
      <AddButton onAddClick={onAddPoint}>{t('AddIcon')}</AddButton>
    </BlockContent>
  );
}

IconListBlock.propTypes = {
  blockIndex: PropTypes.number.isRequired,
  blockType: PropTypes.string.isRequired,
};

export default IconListBlock;

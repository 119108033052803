import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';

import JobPositionAdd from 'pages/job-positions/JobPositionAdd';
import JobPositionAddVas from 'pages/job-positions/JobPositionAddVas';
import JobPositionAddPermissions from 'pages/job-positions/JobPositionAddPermissions';
import Page404 from 'components/Page404';

import ThankYou from './ThankYou';
import Steps from './Steps';

const MAP_PATH_TO_STEP = [
  {
    path: '/positions/add',
    step: 0,
  },
  {
    path: '/positions/add/vas/:jobPositionPk',
    step: 1,
  },
  {
    path: '/positions/add/permissions/:jobPositionPk',
    step: 2,
  },
  {
    path: '/positions/add/thank-you/:jobPositionPk',
    step: 3,
  },
];

function JobPositionAddWrapper() {
  const location = useLocation();
  const path = MAP_PATH_TO_STEP.find((o) =>
    matchPath({ path: o.path }, location.pathname),
  );

  return (
    <>
      <Steps current={path.step} />
      <Routes>
        <Route index element={<JobPositionAdd />} />
        <Route path="vas/:jobPositionPk" element={<JobPositionAddVas />} />
        <Route
          path="permissions/:jobPositionPk"
          element={<JobPositionAddPermissions />}
        />
        <Route path="thank-you/:jobPositionPk" element={<ThankYou />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
  );
}

export default JobPositionAddWrapper;

import React from 'react';
import PropTypes from 'prop-types';
import { Table as AntTable } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import i18n from 'i18n';
import cx from 'classnames';
import styles from './index.less';

function Table({
  locale = { emptyText: i18n.t('noData') },
  className,
  pagination,
  ...rest
}) {
  const customItem = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <button
          type="button"
          className={cx(
            styles.arrowButton,
            pagination.size === 'small' && styles.small,
          )}
          aria-label="previous"
        >
          <LeftOutlined
            className={cx(pagination.size === 'small' && styles.small)}
            aria-hidden
          />
        </button>
      );
    }
    if (type === 'next') {
      return (
        <button
          type="button"
          className={cx(
            styles.arrowButton,
            pagination.size === 'small' && styles.small,
          )}
          aria-label="next"
        >
          <RightOutlined
            className={cx(pagination.size === 'small' && styles.small)}
            aria-hidden
          />
        </button>
      );
    }
    return originalElement;
  };

  const getPaginationProps = () => {
    if (pagination) {
      return {
        hideOnSinglePage: pagination.hideOnSinglePage,
        size: pagination.size,
        pageSize: pagination.pageSize,
        showSizeChanger: pagination.showSizeChanger,
        itemRender: customItem,
      };
    }
    return false;
  };

  return (
    <AntTable
      locale={locale}
      pagination={getPaginationProps()}
      className={cx(styles.table, className)}
      {...rest}
    />
  );
}

Table.propTypes = {
  locale: PropTypes.object,
  className: PropTypes.string,
  pagination: PropTypes.node,
};

export default Table;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Timeline as AntTimeline } from 'antd';
import styles from './index.less';

export const TimelineItem = AntTimeline.Item;

function Timeline({ className, children, ...rest }) {
  return (
    <div className={cx(styles.timeline, className)}>
      <AntTimeline {...rest}>{children}</AntTimeline>
    </div>
  );
}

Timeline.propTypes = {
  className: PropTypes.string,
  children: PropTypes.array,
};

export default Timeline;

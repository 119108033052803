import React, { memo, useCallback } from 'react';

import CandidateWithApplications from 'pages/candidates/CandidateWithApplications';
import { useNavigate, useParams } from 'react-router';

function CandidateWithApplicationsStandalone() {
  const { candidatePk } = useParams();
  const { applicationPk } = useParams();
  const navigate = useNavigate();

  const onActiveApplicationChange = useCallback(
    (otherApplicationPk) => {
      navigate(
        `/candidates/${candidatePk}${
          otherApplicationPk ? `/${otherApplicationPk}` : ''
        }`,
      );
    },
    [candidatePk, navigate],
  );

  const onCandidateDeleted = useCallback(() => {
    navigate('/candidates');
  }, [navigate]);

  return (
    <CandidateWithApplications
      candidatePk={candidatePk}
      activeApplicationPk={applicationPk}
      onApplicationSelect={onActiveApplicationChange}
      onApplicationDeleted={onActiveApplicationChange}
      onCandidateDeleted={onCandidateDeleted}
    />
  );
}

export default memo(CandidateWithApplicationsStandalone);

import { useCallback } from 'react';
import { message } from 'antd';
import { useTranslation } from 'i18n';
import { useMutation } from '@apollo/client';
import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';

import { validator, isRequired, allAreRequired } from 'utils/validator';
import { logAndShowGenericError } from 'utils/log';
import { STAGE_LISTS_QUERY } from 'pages/settings/stage-lists/useOrganizationStageLists';
import useMembership from 'hooks/useMembership';
import { evolve } from 'ramda';
import { ADD_STAGE_LIST_MUTATION } from './StageListAddModal';

const VALIDATE_FIELDS = {
  name: [isRequired],
  stages: [allAreRequired],
};

/**
 * Custom hook for handling mutations and validation of adding and editing stage list
 */
const useStageListForm = ({ onFinish }) => {
  const [stageListValue] = useMutation(ADD_STAGE_LIST_MUTATION);
  const [
    {
      organization: { pk },
    },
  ] = useMembership();

  const {
    mutate,
    fieldErrors,
    setFieldErrors,
    clearFieldError,
    generalError,
    isSaving,
  } = useErrorFieldsMutation(stageListValue);

  const { t } = useTranslation();
  const getValidationErrors = validator(VALIDATE_FIELDS);

  const onSave = useCallback(
    ({ name, stages }) => {
      const validationErrors = getValidationErrors({
        name,
        stages: stages.length > 0 && stages.find((stage) => !stage.name),
      });
      if (validationErrors) {
        return setFieldErrors(validationErrors);
      }

      mutate({
        variables: {
          organizationPk: pk,
          name,
          stages,
        },
        update: (
          proxy,
          {
            data: {
              addStageList: { stageList },
            },
          },
        ) => {
          // check if mutation is successful (if stageList exists) before updating UI
          if (stageList) {
            const data = proxy.readQuery({
              query: STAGE_LISTS_QUERY,
              variables: { organizationPk: pk },
            });

            proxy.writeQuery({
              query: STAGE_LISTS_QUERY,
              variables: { organizationPk: pk },
              data: evolve(
                {
                  viewer: {
                    organizations: [
                      { stageLists: (sls) => [stageList, ...sls] },
                    ],
                  },
                },
                data,
              ),
            });
          }
        },
        refetchQueries: [
          {
            query: STAGE_LISTS_QUERY,
            variables: { organizationPk: pk },
          },
        ],
      })
        .then(({ ok }) => {
          if (ok) {
            message.info(t('SaveStageList_mutation-succesful'));
            onFinish();
          } else {
            message.error(t('SaveStageList_mutation-notSuccessfull'));
          }
        })
        .catch(
          logAndShowGenericError(
            `addStageList
          rejected`,
            { name, organizationPk: pk },
          ),
        );
    },
    [mutate, setFieldErrors, onFinish, pk, getValidationErrors, t],
  );

  return {
    onSave,
    setFieldErrors,
    fieldErrors,
    clearFieldError,
    generalError,
    isSaving,
  };
};

export default useStageListForm;

import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { message } from 'antd';

import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';
import useMembership from 'hooks/useMembership';
import { validator, isRequired } from 'utils/validator';
import { logAndShowGenericError } from 'utils/log';
import useCustomFieldValues from './useCustomFieldValues';
import customFieldMultiChoiceFragment from './customFieldMultiChoiceFragment.gql';

/**
 * Hook for filling Custom Fields Values with choices from MULTICHOICE CFs.
 * Validates entered data and updates related queries.
 */

export const OVERRIDE_CUSTOM_FIELD_VALUES_MUTATION = gql`
  mutation OverrideCustomFieldValuesMutation(
    $organizationPk: String!
    $applicationPk: String!
    $customFieldPk: Int!
    $values: [String]
  ) {
    customFieldValuesOverride(
      organizationPk: $organizationPk
      applicationPk: $applicationPk
      customFieldPk: $customFieldPk
      values: $values
    ) {
      customFieldValues {
        ...customFieldMultiChoiceFragment
      }
      errors {
        fields
        message
      }
    }
  }
  ${customFieldMultiChoiceFragment}
`;

const VALIDATE_FIELDS = {
  values: [isRequired],
};

const useCustomFieldValuesOverride = ({
  customFieldPk,
  applicationPk,
  candidatePk,
  onDone,
}) => {
  const [
    {
      organization: { pk: organizationPk },
    },
  ] = useMembership();

  const [overrideValues] = useMutation(OVERRIDE_CUSTOM_FIELD_VALUES_MUTATION);

  const { mutate, fieldErrors, setFieldErrors, clearFieldError, isSaving } =
    useErrorFieldsMutation(overrideValues);

  const getValidationErrors = validator(VALIDATE_FIELDS);

  const { addCustomFieldMultichoiceValue } = useCustomFieldValues({
    applicationPk,
    candidatePk,
  });

  const onSubmit = useCallback(
    ({ customFieldValues: values, setCustomFieldValues }) => {
      const validationErrors = getValidationErrors({
        values: values?.length === 0 ? null : values,
      });
      if (validationErrors) {
        return setFieldErrors(validationErrors);
      }

      mutate({
        variables: {
          organizationPk,
          applicationPk,
          customFieldPk,
          values,
        },
        update: (
          proxy,
          {
            data: {
              customFieldValuesOverride: { customFieldValues },
            },
          },
        ) => {
          addCustomFieldMultichoiceValue(customFieldValues);
        },
      })
        .then(({ data, ok }) => {
          if (data.customFieldValuesOverride.errors) {
            return data.customFieldValuesOverride.errors.message
              ? message.error(data.customFieldValuesOverride.errors.message)
              : logAndShowGenericError('customFieldValuesOverride rejected', {
                  values,
                });
          }
          if (ok) {
            onDone();
          }
          /**
           * We need to clear values after submit, to prevent duplicate them in tags,
           * when a user presses enter in the same multichoice CF
           * without selecting a value from the list
           */
          setCustomFieldValues(null);
        })
        .catch(
          logAndShowGenericError('customFieldValuesOverride rejected', {
            values,
          }),
        );
    },
    [
      getValidationErrors,
      mutate,
      organizationPk,
      applicationPk,
      customFieldPk,
      setFieldErrors,
      addCustomFieldMultichoiceValue,
      onDone,
    ],
  );

  return {
    onSubmit,
    fieldErrors,
    clearFieldError,
    isSaving,
  };
};

export default useCustomFieldValuesOverride;

import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Loader from 'components/Loader';
import Page404 from 'components/Page404';
import CandidateDetails from 'pages/candidates/CandidateDetails';
import ApplicationDetails from 'pages/candidates/ApplicationDetails';
import AddApplicationPlaceholder from 'pages/candidates/AddApplicationPlaceholder';

import styles from './CandidateWithApplications.less';

function CandidateWithApplications({
  isCandidateDetailsLoading,
  candidateDetails,
  activeApplicationPk,
  inDrawer,
  popupContainerRef,
  onApplicationSelect,
  onCandidateDeleted,
  onApplicationDeleted,
}) {
  const {
    id,
    pk,
    firstName,
    lastName,
    createdAt,
    modifiedAt,
    contact,
    applications,
  } = candidateDetails;

  const candidateInfo = useMemo(
    () => ({
      pk,
      id,
      firstName,
      lastName,
      createdAt,
      modifiedAt,
    }),
    [pk, id, firstName, lastName, createdAt, modifiedAt],
  );

  if (isCandidateDetailsLoading) return <Loader fullSize />;

  if (
    !candidateDetails.pk ||
    (activeApplicationPk &&
      !candidateDetails.applications.edges.find(
        ({ node }) => node.pk === activeApplicationPk,
      ))
  ) {
    return <Page404 />;
  }

  return (
    <div
      className={cx(styles.detailsView, inDrawer && styles.inDrawer)}
      data-testid="candidate-with-applications"
    >
      <div
        className={cx(
          styles.candidateDetailsWrapper,
          !activeApplicationPk && styles.noApplication,
        )}
      >
        <CandidateDetails
          candidate={candidateInfo}
          contactDetails={contact}
          applications={applications.edges}
          activeApplicationPk={activeApplicationPk}
          withLinkableHeaderNames={inDrawer}
          popupContainerRef={popupContainerRef}
          onApplicationSelect={onApplicationSelect}
          onCandidateDeleted={onCandidateDeleted}
        />
      </div>
      <div className={styles.applicationDetailsWrapper}>
        {activeApplicationPk ? (
          <ApplicationDetails
            activeApplicationPk={activeApplicationPk}
            applications={applications.edges}
            candidatePk={pk}
            withLinkableHeaderNames={inDrawer}
            popupContainerRef={popupContainerRef}
            contactDetails={contact}
            onApplicationDeleted={onApplicationDeleted}
            candidate={candidateInfo}
          />
        ) : (
          <AddApplicationPlaceholder
            candidatePk={candidateInfo.pk}
            popupContainerRef={popupContainerRef}
            onApplicationSelect={onApplicationSelect}
          />
        )}
      </div>
    </div>
  );
}

CandidateWithApplications.propTypes = {
  isCandidateDetailsLoading: PropTypes.bool,
  candidateDetails: PropTypes.object.isRequired,
  activeApplicationPk: PropTypes.string,
  inDrawer: PropTypes.bool,
  popupContainerRef: PropTypes.object,
  onApplicationSelect: PropTypes.func,
  onCandidateDeleted: PropTypes.func,
  onApplicationDeleted: PropTypes.func,
};

export default memo(CandidateWithApplications);

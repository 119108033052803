import { useCallback } from 'react';

import { logAndShowGenericError } from 'utils/log';

import { useMutation } from '@apollo/client';

import changeNotificationStatusMutation from './changeNotificationStatusMutation.gql';

export default function useNotificationStatusChange({
  notificationPk,
  notificationId,
}) {
  const [changeNotificationStatus] = useMutation(
    changeNotificationStatusMutation,
  );

  const onChangeStatus = useCallback(
    (status) => {
      changeNotificationStatus({
        variables: { notificationsPks: [notificationPk], status },
        optimisticResponse: {
          notificationStatusChange: {
            errors: null,
            notifications: [
              {
                id: notificationId,
                status,
                __typename: 'NotificationNode',
              },
            ],
            __typename: 'ChangeNotificationStatusMutation',
          },
        },
      })
        .then(({ data }) => {
          if (data.notificationStatusChange.errors) {
            logAndShowGenericError(`change rejected`, {
              notificationPk,
              status,
              data,
            })();
          }
        })
        .catch(
          logAndShowGenericError('change status rejected', {
            notificationPk,
            status,
          }),
        );
    },
    [changeNotificationStatus, notificationId, notificationPk],
  );
  return [onChangeStatus];
}

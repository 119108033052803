import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'apollo';

import JobPositions from 'pages/job-positions/JobPositions';
import withOrganizationPk from 'hoc/withOrganizationPk';

import { useNavigate } from 'react-router';
import jobPositionsQuery from './jobPositionsQuery.gql';

function JobPositionsContainer({ active, ...props }) {
  const navigate = useNavigate();
  return (
    <JobPositions
      {...props}
      isActiveVisible={active}
      onAddJobPositionClick={() => navigate('/positions/add')}
      toggleArchivedVisibility={() => {
        navigate(`/positions${active ? '/archived' : ''}`);
      }}
    />
  );
}
JobPositionsContainer.propTypes = {
  active: PropTypes.bool,

  // From HoCs
  isLoading: PropTypes.bool.isRequired,
};

export default compose(
  withOrganizationPk,
  graphql(jobPositionsQuery, {
    options: ({ organizationPk, active }) => ({
      variables: { organizationPk, isActive: active },
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading } }) => ({
      jobPositionsList:
        viewer && viewer.jobPositions ? viewer.jobPositions.edges : [],
      isLoading: loading,
    }),
  }),
)(JobPositionsContainer);

import React from 'react';
import PropTypes from 'prop-types';
import { Badge as AntBadge } from 'antd';
import cx from 'classnames';

import styles from './index.less';

function Badge({ className, ...rest }) {
  return <AntBadge className={cx(styles.badge, className)} {...rest} />;
}

Badge.propTypes = {
  className: PropTypes.string,
};
export default Badge;

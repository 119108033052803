import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import { Table as AntTable, Button, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import StatusCell from 'pages/job-positions/JobOffers/StatusCell';
import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { PROCESSING, ERROR } from 'consts/aiGenetationStatuses';
import {
  STATUS_ERROR,
  STATUS_PROCESSING,
  STATUS_PUBLISHED,
  STATUS_DRAFT,
} from 'consts/jobOfferStatus';
import { useMutation } from '@apollo/client';
import publishLandingPage from 'pages/job-positions/JobOffers/publishLandingPageMutation.gql';
import unpublishLandingPage from 'pages/job-positions/JobOffers/unpublishLandingPageMutation.gql';
import deleteLandingPage from 'pages/job-positions/JobOffers/deleteLandingPageMutation.gql';
import jobPositionDetailsQuery from 'pages/job-positions/JobPositionDetails/jobPositionDetailsQuery.gql';
import { useNavigate, useParams } from 'react-router';
import { PERMISSION_JOB_POSITION_MANAGE } from 'consts/permissions';
import usePermissions from 'hooks/usePermissions';
import { validateBlocksStructure } from 'consts/jobOffersBlocks';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import confirmDeleteModal from 'utils/confirmDeleteModal';
import useBrowserWidth from 'hooks/useBrowserWidth';
import { XS } from 'consts/media';
import NameCell from './NameCell';

const { Column } = AntTable;

function assignLpStatus(isPublic, aiGenerationStatus) {
  if (isPublic) {
    return 'PUBLISHED';
  }
  if (aiGenerationStatus !== PROCESSING && aiGenerationStatus !== ERROR) {
    return 'DRAFT';
  }
  if (aiGenerationStatus === PROCESSING) {
    return 'PROCESSING';
  }
  if (aiGenerationStatus === ERROR) {
    return 'ERROR';
  }
  throw Error();
}

function JobPositionOffers({ landingPages, loading }) {
  const { t } = useTranslation();
  const { pk } = useParams();
  const [publishJobOffer] = useMutation(publishLandingPage);
  const [unpublishJobOffer] = useMutation(unpublishLandingPage);
  const [deleteJobOffer] = useMutation(deleteLandingPage);
  const navigate = useNavigate();
  const [organizationPk] = useCurrentOrganization();
  const browserWidth = useBrowserWidth();
  const [hasJobPositionManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_JOB_POSITION_MANAGE]);

  const onEdit = useCallback(
    (item) => {
      navigate(`${item.pk}`);
    },
    [navigate],
  );

  const onPublish = useCallback(
    (item) => {
      publishJobOffer({
        variables: {
          pk: item.pk,
          organizationPk,
        },
        refetchQueries: [
          {
            query: jobPositionDetailsQuery,
            variables: { pk, organizationPk },
          },
        ],
      });
    },
    [publishJobOffer, organizationPk, pk],
  );

  const onUnpublish = useCallback(
    (item) => {
      unpublishJobOffer({
        variables: {
          pk: item.pk,
          organizationPk,
        },
        refetchQueries: [
          {
            query: jobPositionDetailsQuery,
            variables: { pk, organizationPk },
          },
        ],
      });
    },
    [unpublishJobOffer, organizationPk, pk],
  );

  const onDelete = useCallback(
    (item) => {
      deleteJobOffer({
        variables: {
          pk: item.pk,
          organizationPk,
        },
        refetchQueries: [
          {
            query: jobPositionDetailsQuery,
            variables: { pk, organizationPk },
          },
        ],
      });
    },
    [organizationPk, pk, deleteJobOffer],
  );

  const onPreview = useCallback(
    (item) => {
      navigate(`preview/${item.pk}`);
    },
    [navigate],
  );

  const onItemClick = useCallback(
    (key, item) => {
      if (!hasJobPositionManagePermission) {
        showNoPermissionMessage();
        return;
      }
      switch (key) {
        case 'edit':
          onEdit(item);
          break;
        case 'publish':
          onPublish(item);
          break;
        case 'unpublish':
          onUnpublish(item);
          break;
        case 'preview':
          onPreview(item);
          break;
        case 'delete':
          confirmDeleteModal(
            `${t('JobPositionOffers_deleteConfirm')} ${item.name}?`,
            () => onDelete(item),
          );
          break;
        default:
          throw new Error(`Unexpected key: ${key}`);
      }
    },
    [
      onEdit,
      onPublish,
      onUnpublish,
      onPreview,
      onDelete,
      t,
      hasJobPositionManagePermission,
      showNoPermissionMessage,
    ],
  );

  const renderNameCell = useCallback(
    (text, item) => <NameCell {...item} />,
    [],
  );

  const renderUrlCell = useCallback(
    (text, item) =>
      item.status === STATUS_DRAFT || item.status === STATUS_PUBLISHED ? (
        <a href={item.previewUrl} target="_blank" rel="noopener noreferrer">
          {item.previewUrl}
        </a>
      ) : (
        <span>-</span>
      ),
    [],
  );

  const renderStatusCell = useCallback(
    (text, item) => <StatusCell text={item.status} />,
    [],
  );

  const renderActionButtons = useCallback(
    (text, item) => {
      if (item.status === STATUS_PROCESSING) {
        return null;
      }

      const validBlockStructure = validateBlocksStructure(item.blocks);

      return (
        <Space size="small">
          {[STATUS_PUBLISHED, STATUS_DRAFT].includes(item.status) && (
            <Tooltip title={t('preview_jobOffer')}>
              <Button
                icon={<EyeOutlined />}
                onClick={() => onItemClick('preview', item)}
              />
            </Tooltip>
          )}

          {item.status === STATUS_PUBLISHED && (
            <Tooltip title={t('unpublish_jobOffer')}>
              <Button
                icon={<CloseSquareOutlined />}
                onClick={() => onItemClick('unpublish', item)}
              />
            </Tooltip>
          )}

          {item.status === STATUS_DRAFT && validBlockStructure && (
            <>
              <Tooltip title={t('edit_jobOffer')}>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => onItemClick('edit', item)}
                />
              </Tooltip>
              <Tooltip title={t('publish_jobOffer')}>
                <Button
                  icon={<CheckSquareOutlined />}
                  onClick={() => onItemClick('publish', item)}
                />
              </Tooltip>
            </>
          )}

          {[STATUS_DRAFT, STATUS_ERROR].includes(item.status) && (
            <Tooltip title={t('delete_jobOffer')}>
              <Button
                icon={<DeleteOutlined />}
                onClick={() => onItemClick('delete', item)}
              />
            </Tooltip>
          )}
        </Space>
      );
    },
    [onItemClick, t],
  );

  const dataSource =
    landingPages.length > 0
      ? landingPages.map((lp) => ({
          key: lp.id,
          pk: lp.pk,
          name: lp.title,
          previewUrl: lp.previewUrl,
          status: assignLpStatus(lp.isPublic, lp.aiGenerationStatus),
          blocks: lp.blocks,
        }))
      : null;

  if (loading) {
    return <Loader fullSize />;
  }

  return (
    <Table
      loading={loading}
      dataSource={dataSource}
      showHeader={browserWidth > XS}
    >
      <Column
        key="name"
        title={t('JobPositionOffers_thName')}
        render={renderNameCell}
      />
      {browserWidth > XS && (
        <>
          <Column
            key="previewUrl"
            title={t('JobPositionOffers_thPreviewUrl')}
            render={renderUrlCell}
          />
          <Column
            key="status"
            title={t('JobPositionOffers_thStatus')}
            render={renderStatusCell}
          />
        </>
      )}
      <Column
        key="actions"
        title={t('JobPositionOffers_thActions')}
        render={renderActionButtons}
      />
    </Table>
  );
}

JobPositionOffers.propTypes = {
  landingPages: PropTypes.node,
  loading: PropTypes.bool,
};

export default JobPositionOffers;

import React from 'react';
import PropTypes from 'prop-types';
import { fromNow } from 'utils/date';
import { withTranslation } from 'i18n';
import { DeleteOutlined } from '@ant-design/icons';
import { showGenericError } from 'utils/message';
import { logAndShowGenericError } from 'utils/log';
import { roleName } from 'utils/translation';
import Button from 'components/Form/Button';
import styles from './Invitation.less';

const onInvitationDelete = (invitationPk, deleteInvitation) => () => {
  deleteInvitation(invitationPk)
    .then(({ errors }) => {
      if (errors) {
        showGenericError();
      }
    })
    .catch(
      logAndShowGenericError('deleteInvitation rejected', {
        invitationPk,
        deleteInvitation,
      }),
    );
};

export function Invitation({
  invitationData,
  t,
  deleteInvitation,
  canInviteUsers,
}) {
  const {
    email,
    expiresAt,
    role: { name },
    invitedBy: { firstName, lastName },
    pk,
  } = invitationData;

  return (
    <div className={styles.container} data-testid="invitation">
      <span className={styles.textWrapper}>
        <a className={styles.email} href={`mailto:${email}`}>
          {email}
        </a>
        <span>
          {` ${t('withPermissionLevel')} ${roleName(t, name)}`}
          <br />
          {` ${t('invitedBy')} ${firstName} ${lastName} ${t(
            'expires',
          )} ${fromNow(expiresAt)}`}
        </span>
      </span>
      {canInviteUsers && (
        <Button
          iconOnly
          icon={
            <DeleteOutlined
              className={styles.deleteIcon}
              data-testid="invitationDelete"
              aria-hidden
            />
          }
          onClick={onInvitationDelete(pk, deleteInvitation)}
          aria-label="delete-invitation"
        />
      )}
    </div>
  );
}

Invitation.propTypes = {
  invitationData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  deleteInvitation: PropTypes.func.isRequired,
  canInviteUsers: PropTypes.bool.isRequired,
};

export default withTranslation('translation')(Invitation);

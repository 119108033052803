import { useEffect } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import identify from 'utils/identify';

/**
 * Hook for User identification with analytics services like LogRocket or Heap
 */

const useAnalitycsIndentificationEffect = () => {
  const [currentUser] = useCurrentUser();

  useEffect(() => {
    if (currentUser) {
      identify(currentUser);
    }
  }, [currentUser]);
};

export default useAnalitycsIndentificationEffect;

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'apollo';
import { withRouter } from 'utils/withRouter';
import withOrganizationPk from 'hoc/withOrganizationPk';
import { PUBLISHED } from 'consts/request';
import useCandidateDrawer from 'hooks/useCandidateDrawer';

// eslint-disable-next-line max-len
import changeApplicationStageMutation from 'pages/candidates/Stage/changeApplicationStageMutation.gql';
import { Route, Routes } from 'react-router';
import JobOfferEdit from 'pages/job-positions/JobOffers/JobOfferEdit';
import JobPositionDetails from './JobPositionDetails';

import jobPositionDetailsQuery from './jobPositionDetailsQuery.gql';
import jobPositionActivitiesQuery from './jobPositionActivitiesQuery.gql';
import JobOfferAdd from '../JobOffers/JobOfferAdd';
import JobOfferPreview from '../JobOffers/JobOfferPreview';

function JobPositionDetailsContainer({
  jobPositionDetails,
  isLoading,
  approveRequest,
  changeApplicationStage,
  startPolling,
  stopPolling,
}) {
  const [isRequestDetailsShown, toggleRequestDetailsShown] = useState(false);
  const onToggleRequestDetailsShown = useCallback(() => {
    toggleRequestDetailsShown(!isRequestDetailsShown);
  }, [isRequestDetailsShown]);

  const [isRequestDetailsCollapsible, setIsRequestDetailsCollapsible] =
    useState(false);

  useEffect(() => {
    if (jobPositionDetails && jobPositionDetails.status === PUBLISHED) {
      setIsRequestDetailsCollapsible(true);
    }
  }, [jobPositionDetails]);

  useEffect(() => {
    if (jobPositionDetails?.landingPages) {
      const hasProcessingLandingPage = jobPositionDetails.landingPages.some(
        (lp) => lp.aiGenerationStatus === 'PROCESSING',
      );
      if (hasProcessingLandingPage) {
        startPolling(5000);
      } else {
        stopPolling();
      }
    }
  }, [jobPositionDetails, startPolling, stopPolling]);

  const onApproveRequest = useCallback(() => {
    approveRequest(jobPositionDetails.request.pk);
  }, [jobPositionDetails, approveRequest]);

  const {
    isDrawerVisible,
    activeApplicationPk,
    setActiveApplication,
    selectedCandidatePk,
    onDrawerClose,
    onApplicationClick,
  } = useCandidateDrawer();

  return (
    <Routes>
      <Route
        index
        element={
          <JobPositionDetails
            jobPositionDetails={jobPositionDetails}
            isLoading={isLoading}
            isRequestDetailsShown={isRequestDetailsShown}
            onToggleRequestDetailsShown={onToggleRequestDetailsShown}
            isRequestDetailsCollapsible={isRequestDetailsCollapsible}
            onApproveRequest={onApproveRequest}
            changeApplicationStage={changeApplicationStage}
            isDrawerVisible={isDrawerVisible}
            activeApplicationPk={activeApplicationPk}
            setActiveApplication={setActiveApplication}
            selectedCandidatePk={selectedCandidatePk}
            onDrawerClose={onDrawerClose}
            onApplicationClick={onApplicationClick}
          />
        }
      />
      <Route
        path="add"
        element={
          <JobOfferAdd
            jobPositionDetails={jobPositionDetails}
            isLoading={isLoading}
          />
        }
      />
      <Route path=":offerPk" element={<JobOfferEdit />} />
      <Route path="preview/:offerPk" element={<JobOfferPreview />} />
    </Routes>
  );
}

JobPositionDetailsContainer.propTypes = {
  jobPositionDetails: PropTypes.object,
  isLoading: PropTypes.bool,
  approveRequest: PropTypes.func,
  changeApplicationStage: PropTypes.func,
  startPolling: PropTypes.func,
  stopPolling: PropTypes.func,
};

export default compose(
  withRouter,
  withOrganizationPk,
  graphql(jobPositionDetailsQuery, {
    options: ({ organizationPk, params: { pk } }) => ({
      variables: { organizationPk, pk },
      pollInterval: 5000,
    }),
    props: ({ data: { viewer, loading, startPolling, stopPolling } }) => ({
      jobPositionDetails:
        !loading && viewer && viewer.jobPositions
          ? viewer.jobPositions.edges[0].node
          : {},
      isLoading: loading,
      startPolling,
      stopPolling,
    }),
  }),
  graphql(changeApplicationStageMutation, {
    options: ({ organizationPk }) => ({
      refetchQueries: [
        { query: jobPositionActivitiesQuery, variables: { organizationPk } },
      ],
    }),
    props: ({ mutate, ownProps: { organizationPk } }) => ({
      changeApplicationStage: ({ applicationPk, stagePk }) =>
        mutate({
          variables: { applicationPk, organizationPk, stagePk },
        }),
    }),
  }),
)(JobPositionDetailsContainer);

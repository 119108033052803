import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';

import Modal from 'components/Modal';
import JobPositionAddVas from 'pages/job-positions/JobPositionAddVas';

function ChangeVasModal({
  isVasModalVisible,
  onToggleVasModalVisibility,
  jobPositionPk,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('ChangeVasModal_title')}
      visible={isVasModalVisible}
      footer={null}
      onCancel={onToggleVasModalVisibility}
      destroyOnClose
    >
      <JobPositionAddVas
        jobPositionPk={jobPositionPk}
        onToggleVasModalVisibility={onToggleVasModalVisibility}
      />
    </Modal>
  );
}

ChangeVasModal.propTypes = {
  isVasModalVisible: PropTypes.bool,
  onToggleVasModalVisibility: PropTypes.func,
  jobPositionPk: PropTypes.string,
};

export default ChangeVasModal;

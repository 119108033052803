import React from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'antd';
import { useTranslation } from 'i18n';

import Button from 'components/Form/Button';

import styles from './HomeSteps.less';

const Step = Steps.Step;

function HomeSteps({ current, jobPositions }) {
  const { t } = useTranslation();
  const noRequestPosition = jobPositions.find(({ node }) => !node.request);
  const noRequestPositionPk = noRequestPosition && noRequestPosition.node.pk;

  return (
    <Steps direction="vertical" current={current}>
      <Step
        className={styles.step}
        title={t('HomeSteps_addFirstJobPosition')}
        description={
          current === 0 && (
            <div className={styles.descriptionWrapper}>
              <span className={styles.descriptionText}>
                {t('HomeSteps_addFirstJobPosition--description')}
              </span>
              <Button type="primary" size="default" to="/positions/add">
                {t('HomeSteps_addFirstJobPosition--btn')}
              </Button>
            </div>
          )
        }
      />
      <Step
        className={styles.step}
        title={t('HomeSteps_getCandidate')}
        description={
          current === 1 && (
            <div className={styles.descriptionWrapper}>
              <span className={styles.descriptionText}>
                {t('HomeSteps_getCandidate--description')}
              </span>
              <Button
                type="primary"
                size="default"
                to={`/positions/${noRequestPositionPk}`}
              >
                {t('HomeSteps_getCandidate--btn')}
              </Button>
            </div>
          )
        }
      />
      <Step title={t('HomeSteps_displayActivities')} className={styles.step} />
    </Steps>
  );
}

HomeSteps.propTypes = {
  current: PropTypes.number,
  jobPositions: PropTypes.array,
};

export default HomeSteps;

import React, { createContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import { LockOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons';

import Content from 'components/Content';
import Loader from 'components/Loader';
import DateWithIcon from 'components/DateWithIcon';
import ActivateOrChangePlanButton from 'components/ActivateOrChangePlanButton';
import RemoteTag from 'components/RemoteTag';
import CandidateDrawer from 'pages/candidates/CandidateDrawer';
import {
  MODULE_JOB_POSITION_MANAGE,
  MODULE_LANDING_PAGE_MANAGE,
} from 'consts/organizationModules';
import { PERMISSION_JOB_POSITION_MANAGE } from 'consts/permissions';
import useOrganizationModules from 'hooks/useOrganizationModules';
import usePermissions from 'hooks/usePermissions';

import EditAuthorizedUsersButton from 'components/JobPositionEditAuthorizedUsersButton';
import AuthorizedUsers from 'components/JobPositionAuthorizedUsers';
import { useNavigate } from 'react-router';
import JobPositionDetailsActivityFeed from './JobPositionDetailsActivityFeed';
import styles from './JobPositionDetails.less';
import JobPositionStages from './JobPositionStages';
import JobPositionOffers from './JobPositionOffers';
import VasRequestDetails from '../VasRequestDetails';

const JobPositionContext = createContext(null);
function JobPositionDetails({
  jobPositionDetails,
  isLoading,
  isDrawerVisible,
  activeApplicationPk,
  setActiveApplication,
  selectedCandidatePk,
  onDrawerClose,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [hasJobPositionManageModule, hasJobOfferManageModule] =
    useOrganizationModules([
      MODULE_JOB_POSITION_MANAGE,
      MODULE_LANDING_PAGE_MANAGE,
    ]);

  const [hasJobPositionManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_JOB_POSITION_MANAGE]);

  const {
    pk,
    position,
    createdAt,
    location,
    isRemote,
    isActive,
    applications,
    stages,
    landingPages,
  } = jobPositionDetails;

  const onAddOffer = useCallback(() => {
    navigate(`add`);
  }, [navigate]);

  const handleAddOffer = hasJobPositionManagePermission
    ? onAddOffer
    : showNoPermissionMessage;

  const handleShowCandidates = useCallback(() => {
    navigate(`/candidates?position[]=${pk}`);
  }, [navigate, pk]);

  if (isLoading) return <Loader fullSize />;

  return (
    <JobPositionContext.Provider value={jobPositionDetails}>
      <Content
        title={
          <>
            {position}
            {!isActive && (
              <span>
                {' '}
                <LockOutlined />
              </span>
            )}
          </>
        }
        size="medium"
        customCTAButton={
          hasJobPositionManageModule && (
            <ActivateOrChangePlanButton
              type={isActive ? 'default' : 'primary'}
              iconComponent={isActive ? LockOutlined : UnlockOutlined}
              jobPositionPk={pk}
              initialStage={isActive ? 'deactivate' : 'activate'}
              dataRole={
                isActive
                  ? 'archive-process-button'
                  : 'reactivate-process-button'
              }
            >
              {isActive
                ? t('archiveJobPositionBtn')
                : t('activateJobPositionBtn')}
            </ActivateOrChangePlanButton>
          )
        }
        subtitle={
          <div className={styles.details}>
            <DateWithIcon
              date={createdAt}
              text={t('createdAt')}
              className={styles.detail}
            />
            <div className={styles.detail}>
              {location && (
                <span>
                  <b>{t('location')}</b> {location}
                </span>
              )}
              {isRemote && <RemoteTag className={styles.remoteTag} />}
            </div>
            <div className={styles.detail}>
              <b>{t('users')}</b>{' '}
              <AuthorizedUsers
                t={t}
                users={jobPositionDetails.authorizedUsers}
              />
              <EditAuthorizedUsersButton
                t={t}
                jobPositionPk={jobPositionDetails.pk}
              />
            </div>
          </div>
        }
      />
      <Content
        title={t('jobPosition_stagesHeader')}
        size="medium"
        secondary
        onCTAClick={handleShowCandidates}
        CTAText={t('JobPositionDetails_seeAllApplications')}
        buttonIcon={UserOutlined}
      >
        <JobPositionStages
          stages={stages.stages}
          applications={applications}
          positionPk={pk}
        />
      </Content>
      {hasJobOfferManageModule && (
        <Content
          condensed
          title={t('jobPosition_jobOffers')}
          size="medium"
          secondary
          onCTAClick={handleAddOffer}
          CTAText={t('JobPositionOffers_addJobOffer')}
        >
          <JobPositionOffers
            landingPages={landingPages}
            loading={isLoading}
            jobPositionPk={pk}
          />
        </Content>
      )}
      <Content title={t('jobPosition_VasHeader')} size="medium" secondary>
        <VasRequestDetails jobPositionPk={pk} />
      </Content>
      {applications.totalCount > 0 && (
        <Content
          title={t('jobPosition_activitiesHeader')}
          size="medium"
          secondary
        >
          <JobPositionDetailsActivityFeed jobPositionPk={pk} reversed />
        </Content>
      )}
      <CandidateDrawer
        visible={isDrawerVisible}
        activeApplicationPk={activeApplicationPk}
        setActiveApplication={setActiveApplication}
        selectedCandidatePk={selectedCandidatePk}
        onDrawerClose={onDrawerClose}
      />
    </JobPositionContext.Provider>
  );
}

JobPositionDetails.propTypes = {
  jobPositionDetails: PropTypes.object,
  isLoading: PropTypes.bool,
  isDrawerVisible: PropTypes.bool,
  activeApplicationPk: PropTypes.string,
  setActiveApplication: PropTypes.func,
  selectedCandidatePk: PropTypes.string,
  onDrawerClose: PropTypes.func,
};

export default JobPositionDetails;

import React, { useState, useCallback } from 'react';
import { useTranslation } from 'i18n';

import Content from 'components/Content';
import Title from 'components/Title';
import EmailTemplatesList from 'pages/settings/email-templates/EmailTemplatesList';
import usePermissions from 'hooks/usePermissions';
import { PERMISSION_EMAIL_TEMPLATES_MANAGE } from 'consts/permissions';

function EmailTemplatesSettings() {
  const { t } = useTranslation();
  const [isAddingNewEmailTemplate, setIsAddingNewEmailTemplate] =
    useState(false);

  const toggleIsAddingNewEmailTemplate = useCallback(() => {
    setIsAddingNewEmailTemplate(!isAddingNewEmailTemplate);
  }, [isAddingNewEmailTemplate]);

  const [hasEmailTemplatesManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_EMAIL_TEMPLATES_MANAGE]);

  return (
    <Content
      title={t('Settings_emailTemplates')}
      secondary
      size="small"
      condensed
      withHeaderSpacing
      CTAText={t('EmailTemplates_addTemplate')}
      onCTAClick={
        hasEmailTemplatesManagePermission
          ? toggleIsAddingNewEmailTemplate
          : showNoPermissionMessage
      }
      theme="transparent"
    >
      <Title>{t('Title_emailTemplates')}</Title>
      <EmailTemplatesList
        isAddingNewEmailTemplate={isAddingNewEmailTemplate}
        toggleIsAddingNewEmailTemplate={toggleIsAddingNewEmailTemplate}
      />
    </Content>
  );
}

export default EmailTemplatesSettings;

import React from 'react';
import { Modal } from 'antd';
import { ApolloProvider } from '@apollo/client';
import { I18nextProvider } from 'i18n';

import { client } from 'apollo';
import NoPermissionMessage from 'components/NoPermissions/NoPermissionMessage';

import i18n from '../i18n';

const showNoPermissionMessage = (t) =>
  Modal.warning({
    title: t('noPermissionMessage_title'),
    content: (
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={client}>
          <NoPermissionMessage />
        </ApolloProvider>
      </I18nextProvider>
    ),
    duration: 0,
  });

export default showNoPermissionMessage;

import React from 'react';
import { graphql, compose } from 'apollo';

import ActivityFeedContainer from 'components/ActivityFeed';

import withOrganizationPk from 'hoc/withOrganizationPk';

import jobPositionActivitiesQuery from './jobPositionActivitiesQuery.gql';

function JobPositionDetailsActivityFeed(props) {
  return <ActivityFeedContainer inJobPositionContext {...props} />;
}

export default compose(
  withOrganizationPk,
  graphql(jobPositionActivitiesQuery, {
    options: ({ organizationPk, jobPositionPk }) => ({
      variables: { organizationPk, jobPositionPk },
    }),
    props: ({ data: { viewer, loading } }) => ({
      activities:
        !loading && viewer
          ? viewer.jobPositions.edges[0].node.activities.edges
          : [],
      isLoading: loading,
    }),
  }),
)(JobPositionDetailsActivityFeed);

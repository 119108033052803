import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { message } from 'antd';

import useMembership from 'hooks/useMembership';
import { logAndShowGenericError } from 'utils/log';
import useCustomFieldValues from './useCustomFieldValues';

export const DESELECT_CUSTOM_FIELD_VALUE_MUTATION = gql`
  mutation DeselectCustomFieldValueMutation(
    $organizationPk: String!
    $applicationPk: String!
    $customChoicePk: Int!
  ) {
    customFieldChoiceDeselect(
      organizationPk: $organizationPk
      applicationPk: $applicationPk
      customChoicePk: $customChoicePk
    ) {
      status
      errors {
        message
        fields
      }
    }
  }
`;

/**
 * Hook for deselecting Custom Field Values which come from MULTICHOICE CFs
 */
const useCustomFieldValueDeselect = ({
  customChoicePk,
  applicationPk,
  candidatePk,
}) => {
  const [
    {
      organization: { pk: organizationPk },
    },
  ] = useMembership();

  const { deselectCustomFieldValue } = useCustomFieldValues({
    applicationPk,
    candidatePk,
  });

  const [deselectFieldValue] = useMutation(
    DESELECT_CUSTOM_FIELD_VALUE_MUTATION,
  );

  const onDeselect = useCallback(() => {
    deselectFieldValue({
      variables: { organizationPk, applicationPk, customChoicePk },
      optimisticResponse: {
        customFieldChoiceDeselect: {
          errors: null,
          status: 'OK',
          __typename: 'DeselectCustomFieldValueMutation',
        },
      },
      update: (
        proxy,
        {
          data: {
            customFieldChoiceDeselect: { status },
          },
        },
      ) => {
        if (status !== 'OK') return;
        deselectCustomFieldValue(customChoicePk);
      },
    })
      .then(
        ({
          data: {
            customFieldChoiceDeselect: { errors },
          },
        }) => {
          if (errors) {
            return errors.message
              ? message.error(errors.message)
              : logAndShowGenericError('customFieldValueDeselect rejected', {
                  customChoicePk,
                });
          }
        },
      )
      .catch(
        logAndShowGenericError('customFieldValueDeselect rejected', {
          customChoicePk,
        }),
      );
  }, [
    deselectFieldValue,
    organizationPk,
    applicationPk,
    customChoicePk,
    deselectCustomFieldValue,
  ]);

  return { onDeselect };
};

export default useCustomFieldValueDeselect;

import { Select as AntSelect } from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Select.less';

const { Option } = AntSelect;

const filterOption = (input, option) =>
  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

function Select({
  choices,
  className,
  dropdownClassName,
  fullWidth,
  disabled,
  placeholder,
  withBorders = true,
  value,
  onChange,
  withSearch = false,
  popupContainer,
  ...rest
}) {
  const testId = rest['data-testid'];
  const dropdownRender = useMemo(
    () =>
      function renderDropdownItem(menu) {
        return (
          <div data-testid={testId ? `${testId}-dropdown` : undefined}>
            {menu}
          </div>
        );
      },
    [testId],
  );

  return (
    <AntSelect
      value={value || undefined}
      disabled={disabled}
      showSearch={withSearch}
      dropdownRender={dropdownRender}
      className={cx(
        styles.select,
        !withBorders && styles.noBorders,
        fullWidth && styles.fullWidth,
        className,
      )}
      dropdownClassName={cx(dropdownClassName)}
      placeholder={placeholder}
      onChange={onChange}
      getPopupContainer={() =>
        popupContainer ? popupContainer.current : document.body
      }
      filterOption={filterOption}
      {...rest}
    >
      {choices.map((item) => (
        <Option
          data-testid="select-option"
          key={item.value}
          value={item.value}
          label={item.label}
          aria-label={item.label}
        >
          {item.optionElement || item.label}
        </Option>
      ))}
    </AntSelect>
  );
}

Select.propTypes = {
  size: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string,
      optionElement: PropTypes.node, // optional value to render custom option element instead of a label
    }),
  ),
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  withBorders: PropTypes.bool,
  withSearch: PropTypes.bool,
  popupContainer: PropTypes.node,
};

export default Select;

import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';
import { formatDate, fromNow } from 'utils/date';
import Icon from '@ant-design/icons';

function DateWithIcon({ date, iconComponent, text, className, ...rest }) {
  return (
    <Tooltip title={formatDate(date)}>
      <span className={className} {...rest}>
        {iconComponent && (
          <Icon
            component={iconComponent}
            data-testid="antd-icon"
            aria-label="icon"
          />
        )}{' '}
        {text && <b>{text}</b>} {fromNow(date)}
      </span>
    </Tooltip>
  );
}

DateWithIcon.propTypes = {
  date: PropTypes.string.isRequired,
  iconComponent: PropTypes.elementType,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default DateWithIcon;

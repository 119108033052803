import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/Form/Button';
import Paper from 'components/Paper';

import withBrowserWidth from 'hoc/withBrowserWidth';
import { SM } from 'consts/media';

import Icon, { PlusOutlined } from '@ant-design/icons';
import styles from './index.less';

export { default as Head } from './Head';

function Content({
  title,
  theme,
  browserWidth,
  CTAText,
  condensed,
  size = 'fluid',
  buttonIcon = PlusOutlined,
  secondary,
  withHeaderSpacing,
  subtitle,
  children,
  className,
  onCTAClick,
  customCTA,
  CTAButtonType = 'primary',
  CTADisabled = false,
  customCTAButton,
  secondaryButton,
  ...rest
}) {
  return (
    <section
      className={cx(
        secondary && styles.secondary,
        size === 'large' && styles.isLarge,
        size === 'medium' && styles.isMedium,
        size === 'small' && styles.isSmall,
      )}
      {...rest}
    >
      {(title || onCTAClick) && (
        <div
          className={cx(
            styles.header,
            withHeaderSpacing && styles.withHeaderSpacing,
          )}
        >
          <div className={styles.titleWrapper}>
            {title && <h1 className={styles.title}>{title}</h1>}
            <div className={styles.spacer} />
            <div className={styles.actions}>
              {secondaryButton}
              {(customCTAButton || onCTAClick) &&
                (customCTAButton || (
                  <Button
                    onClick={onCTAClick}
                    icon={
                      buttonIcon && (
                        <Icon
                          component={buttonIcon}
                          data-testid="antd-icon"
                          aria-hidden
                        />
                      )
                    }
                    type={CTAButtonType}
                    size="default"
                    shape={browserWidth < SM ? 'circle' : null}
                    disabled={CTADisabled}
                    data-testid="cta-button"
                  >
                    {browserWidth >= SM && CTAText}
                  </Button>
                ))}
              {customCTA}
            </div>
          </div>
          {subtitle}
        </div>
      )}
      {children && (
        <Paper
          className={className}
          transparent={theme === 'transparent'}
          condensed={condensed}
        >
          {children}
        </Paper>
      )}
    </section>
  );
}

Content.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.oneOf(['transparent']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'fluid']),
  title: PropTypes.node,
  buttonIcon: PropTypes.elementType,
  subtitle: PropTypes.node,
  secondary: PropTypes.bool,
  condensed: PropTypes.bool,
  CTAText: PropTypes.node,
  className: PropTypes.string,
  customCTA: PropTypes.node,
  onCTAClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  CTAButtonType: PropTypes.string,
  CTADisabled: PropTypes.bool,
  customCTAButton: PropTypes.node,
  secondaryButton: PropTypes.node,
  withHeaderSpacing: PropTypes.bool,
  // HoC
  browserWidth: PropTypes.number,
};

export default withBrowserWidth(Content);

import { useQuery, gql } from '@apollo/client';

/**
 * hook for getting stage lists in current organization
 * @param {string} organizationPk - current organization Pk
 *
 * additional export: STAGE_LISTS_QUERY
 */

export const STAGE_LISTS_QUERY = gql`
  query AllStagesQuery($organizationPk: String!) {
    viewer {
      allStageLists(organizationPk: $organizationPk) {
        pk
        name
        stages {
          pk
          name
        }
      }
    }
  }
`;

const useOrganizationStageLists = ({ organizationPk }) => {
  const { data, loading } = useQuery(STAGE_LISTS_QUERY, {
    variables: { organizationPk },
  });

  /**
   * CustomFieldsQuery returns custom fields only from one (current) organization,
   * but as an element of organizations array,
   * thats why we are selecting first organization from the array.
   * sortBy - to sort fields alphabetically
   */
  const organizationStageLists = data?.viewer.allStageLists;
  return [organizationStageLists, { loading }];
};

export default useOrganizationStageLists;

import React from 'react';
import { useTranslation } from 'i18n';
import Alert from 'components/Alert';
import NoPermissionMessage from './NoPermissionMessage';

/**
 * Component for displaying message about lack of required permission and list of managers of user's organization
 *
 * It should be used by default wherever using popup message is not possible (which means mostly forms)
 */

function NoPermissions() {
  const { t } = useTranslation();

  return (
    <Alert title={t('noPermissionTitle')} type="warning" showIcon>
      <NoPermissionMessage />
    </Alert>
  );
}

NoPermissions.propTypes = {};

export default NoPermissions;

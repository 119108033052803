import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { compose, graphql } from 'apollo';

import Alert from 'components/Alert';
import ActivateOrChangePlanButton from 'components/ActivateOrChangePlanButton';

import { PLAN_TYPES } from 'consts/planTypes';

import withOrganizationPk from 'hoc/withOrganizationPk';

import billingInfomationQuery from 'components/ActivateOrChangePlanButton/billingInformationQuery.gql';

function FreePlanAlert({ t, billing, isBillingLoading, quota }) {
  // Wait until all necessary data is loaded
  if (isBillingLoading) return null;

  // Only display alert when user is on Free plan
  if (billing.planType !== PLAN_TYPES.FREE) return null;

  const alertDetails = [];
  if (quota.candidate.isExceeded) {
    alertDetails.push(t('FreePlanAlert_message--maxCandidates'));
  }
  if (quota.email.isExceeded) {
    alertDetails.push(t('FreePlanAlert_message--maxEmails'));
  }

  // If there are no alerts to show, don't display anything
  if (alertDetails.length === 0) return null;

  return (
    <Alert
      type="warning"
      cta={
        <ActivateOrChangePlanButton initialStage="changePlan">
          {t('changePlan')}
        </ActivateOrChangePlanButton>
      }
    >
      {t('FreePlanAlert_message')} {`(${alertDetails.join(', ')})`}
    </Alert>
  );
}

FreePlanAlert.propTypes = {
  // From HoCs
  t: PropTypes.func.isRequired,
  billing: PropTypes.object,
  isBillingLoading: PropTypes.bool.isRequired,
  quota: PropTypes.object,
};

export default compose(
  withTranslation('translation'),
  withOrganizationPk,
  graphql(billingInfomationQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      billing: !loading && viewer ? viewer.organizations[0].billing : {},
      quota: !loading && viewer ? viewer.organizations[0].quota : {},
      isBillingLoading: loading,
    }),
  }),
)(FreePlanAlert);

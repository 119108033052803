import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { RightOutlined } from '@ant-design/icons';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './index.less';

class Drawer extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    width: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    forwardedRef: PropTypes.object,
  };

  root = document.body;

  container = document.createElement('div');

  componentDidMount() {
    this.root.appendChild(this.container);
  }

  componentWillUnmount() {
    this.root.removeChild(this.container);
  }

  render() {
    const { className, children, width, visible, onClose, forwardedRef } =
      this.props;

    return ReactDOM.createPortal(
      <div
        className={cx(styles.drawer, visible && styles.visible)}
        style={{ width }}
        ref={forwardedRef}
        tabIndex="-1"
      >
        <div className={styles.contentWrapper}>
          <button
            type="button"
            className={styles.close}
            onClick={onClose}
            aria-label="close drawer"
          >
            <RightOutlined aria-hidden />
          </button>
          <div className={cx(styles.content, className)}>{children}</div>
        </div>
      </div>,
      document.body,
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Drawer {...props} forwardedRef={ref} />
));

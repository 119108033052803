import React, { useReducer, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'apollo';

import withOrganizationPk from 'hoc/withOrganizationPk';

import Loader from 'components/Loader';
import Page404 from 'components/Page404';

import { showGenericError, showError } from 'utils/message';
import { logAndShowGenericError } from 'utils/log';

import { withRouter } from 'utils/withRouter';
import JobPositionAddVas from './JobPositionAddVas';
import allVasQuery from './allVasQuery.gql';
import changeJobPositionVasMutation from './changeJobPositionVasMutation.gql';
import checkJobPositionQuery from './checkJobPositionQuery.gql';
import selectedVasQuery from './selectedVasQuery.gql';

const initialState = {
  selected: {},
  isSaving: false,
};

const reducer = (state, action) => {
  if (action.type === 'setInitialSelected') {
    return {
      ...state,
      selected: action.newSelected,
    };
  }
  if (action.type === 'changeIsSaving') {
    return {
      ...state,
      isSaving: action.value,
    };
  }
  if (action.type === 'checkbox') {
    return {
      ...state,
      selected: {
        ...state.selected,
        [action.vasPk]: action.checkedValue,
      },
    };
  }
};

function JobPositionAddVasContainer({
  vasList,
  isJobPositionValid,
  isJobPositionLoading,
  isAllVasLoading,
  isSelectedVasLoading,
  selectedVas,
  changeJobPositionVas,
  jobPositionPk,
  onToggleVasModalVisibility,
  navigate,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isInModal = !!jobPositionPk;

  const onChange = useCallback((vasPk, checkedValue) => {
    dispatch({ type: 'checkbox', checkedValue, vasPk });
  }, []);

  useEffect(() => {
    const newSelected = selectedVas.reduce(
      (acc, curr) => ({ ...acc, [curr.pk]: true }),
      {},
    );
    dispatch({ type: 'setInitialSelected', newSelected });
  }, [selectedVas]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      dispatch({ type: 'changeIsSaving', value: true });

      changeJobPositionVas(
        Object.keys(state.selected).filter((key) => state.selected[key]),
      )
        .then(({ data }) => {
          if (data.changeJobPositionVas.errors) {
            dispatch({ type: 'changeIsSaving', value: false });
            return data.changeJobPositionVas.errors.message
              ? showError(data.changeJobPositionVas.errors.message)
              : showGenericError();
          }
          if (isInModal) {
            onToggleVasModalVisibility();
          } else {
            navigate(
              `/positions/add/permissions/${data.changeJobPositionVas.jobPosition.pk}`,
            );
          }
        })
        .catch((err) => {
          dispatch({ type: 'changeIsSaving', value: false });
          logAndShowGenericError('changeJobPositionVas rejected', { state })(
            err,
          );
        });
    },
    [
      state,
      changeJobPositionVas,
      navigate,
      isInModal,
      onToggleVasModalVisibility,
    ],
  );

  if (isJobPositionLoading || isAllVasLoading || isSelectedVasLoading) {
    return <Loader fullSize />;
  }

  if (!isJobPositionValid) {
    return <Page404 />;
  }

  return (
    <JobPositionAddVas
      {...state}
      list={vasList}
      onChange={onChange}
      handleSubmit={handleSubmit}
    />
  );
}

JobPositionAddVasContainer.propTypes = {
  vasList: PropTypes.array,
  isJobPositionValid: PropTypes.bool,
  isJobPositionLoading: PropTypes.bool,
  isAllVasLoading: PropTypes.bool,
  isSelectedVasLoading: PropTypes.bool,
  selectedVas: PropTypes.array,
  changeJobPositionVas: PropTypes.func,
  jobPositionPk: PropTypes.string,
  onToggleVasModalVisibility: PropTypes.func,
  navigate: PropTypes.func,
};

export default compose(
  withRouter,
  withOrganizationPk,
  graphql(allVasQuery, {
    props: ({ data: { viewer, loading } }) => ({
      vasList: (viewer && viewer.allVas) || [],
      isAllVasLoading: loading,
    }),
  }),
  graphql(checkJobPositionQuery, {
    options: ({ params, organizationPk, jobPositionPk }) => ({
      variables: {
        organizationPk,
        jobPositionPk: jobPositionPk || params.jobPositionPk,
      },
    }),
    props: ({ data: { viewer, loading } }) => ({
      isJobPositionValid:
        !loading && viewer && viewer.jobPositions.edges.length !== 0,
      isJobPositionLoading: loading,
    }),
  }),
  graphql(selectedVasQuery, {
    options: ({ organizationPk, jobPositionPk, params }) => ({
      variables: {
        organizationPk,
        jobPositionPk: jobPositionPk || params.jobPositionPk,
      },
    }),
    props: ({ data: { viewer, loading } }) => ({
      selectedVas:
        !loading && viewer ? viewer.jobPositions.edges[0].node.selectedVas : [],
      isSelectedVasLoading: loading,
    }),
  }),
  graphql(changeJobPositionVasMutation, {
    props: ({
      mutate,
      ownProps: { organizationPk, jobPositionPk, params },
    }) => ({
      changeJobPositionVas: (vasList) =>
        mutate({
          variables: {
            organizationPk,
            jobPositionPk: jobPositionPk || params.jobPositionPk,
            vasList,
          },
        }),
    }),
  }),
)(JobPositionAddVasContainer);

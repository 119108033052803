import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
  UserAddOutlined,
  EditOutlined,
  LinkOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { message } from 'antd';
import cx from 'classnames';
import i18n, { withTranslation } from 'i18n';
import copyToClipboard from 'copy-to-clipboard';
import Avatar from 'components/Avatar';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import DateWithIcon from 'components/DateWithIcon';
import MoreMenu from 'components/MoreMenu';
import Link from 'components/Link';
import getInitials from 'utils/getInitials';
import confirmDeleteModal from 'utils/confirmDeleteModal';
import { PERMISSION_CANDIDATE_MANAGE } from 'consts/permissions';
import usePermissions from 'hooks/usePermissions';
import styles from './CandidateHeader.less';

const getMoreMenu = (t) => [
  {
    key: 'edit',
    iconComponent: EditOutlined,
    content: t('editCandidate'),
    dataRole: 'edit-candidate',
  },
  {
    key: 'copyLink',
    iconComponent: LinkOutlined,
    content: t('copyLink'),
    dataRole: 'copy-candidate-link',
  },
  {
    key: 'delete',
    iconComponent: DeleteOutlined,
    content: t('deleteCandidate'),
    dataRole: 'delete-candidate',
  },
];

const onNameClick = (withLinkableHeaderNames) => (e) => {
  if (!withLinkableHeaderNames) {
    e.preventDefault();
  }
};

function CandidateHeader({
  firstName,
  lastName,
  createdAt,
  modifiedAt,
  isEdited,
  isNew,
  t,
  onSubmitCreate,
  onCandidateCreationCancelled,
  onInputChange,
  onEditCandidate,
  onCancelEdit,
  onSubmitEdit,
  onDeleteCandidate,
  fieldErrors,
  isSaving,
  withLinkableHeaderNames,
  candidatePk,
  recentApplicationPk,
}) {
  const [hasCandidateManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_CANDIDATE_MANAGE]);
  const currentUrl = window.location.origin;

  const formWrapperRef = useRef(null);

  return (
    <div className={cx(styles.container, isNew && styles.containerCreated)}>
      <div className={styles.avatarWrapper}>
        <Avatar
          initials={getInitials(firstName, lastName)}
          size="large"
          className={styles.avatar}
        />
      </div>
      {!(isEdited || isNew) ? (
        <div className={styles.headerText}>
          <div className={styles.headerNameWithBtns}>
            <Link
              to={
                recentApplicationPk
                  ? `/candidates/${candidatePk}/${recentApplicationPk}`
                  : `/candidates/${candidatePk}`
              }
              className={cx(
                styles.headerName,
                !withLinkableHeaderNames && styles.notLink,
              )}
              onClick={onNameClick(withLinkableHeaderNames)}
              data-testid="candidateLink"
            >
              {firstName} {lastName}
            </Link>
            <MoreMenu
              items={getMoreMenu(t)}
              onItemClick={async (key) => {
                if (key === 'edit') {
                  // If user doesn't have permission block displaying form and display popup message instead
                  if (!hasCandidateManagePermission) {
                    return showNoPermissionMessage();
                  }
                  await onEditCandidate();
                  // move focus to form wrapper after edit form render
                  formWrapperRef.current.focus();
                } else if (key === 'delete') {
                  if (!hasCandidateManagePermission) {
                    return showNoPermissionMessage();
                  }
                  confirmDeleteModal(
                    t('sureWantToDeleteCandidate'),
                    onDeleteCandidate,
                  );
                } else if (key === 'copyLink') {
                  copyToClipboard(`${currentUrl}/candidates/${candidatePk}`);
                  message.info(i18n.t('candidateLinkCopiedToClipboard'));
                }
              }}
            />
          </div>
          <div className={styles.headerInfo}>
            <DateWithIcon
              date={createdAt}
              iconComponent={UserAddOutlined}
              text={t('applied')}
              className={styles.createdAt}
            />
            <DateWithIcon
              date={modifiedAt}
              iconComponent={EditOutlined}
              text={t('lastModified')}
              className={styles.modifiedAt}
            />
          </div>
        </div>
      ) : (
        <div tabIndex="-1" ref={formWrapperRef}>
          <Form
            onFinish={
              isNew
                ? onSubmitCreate(firstName, lastName)
                : onSubmitEdit(firstName, lastName)
            }
            className={styles.form}
          >
            <Field
              error={fieldErrors.firstName}
              className={cx(styles.formField, styles.firstNameField)}
            >
              <Input
                type="text"
                size="middle"
                placeholder={t('firstName')}
                value={firstName}
                onChange={onInputChange('firstName')}
                disabled={isSaving}
              />
            </Field>
            <Field error={fieldErrors.lastName} className={styles.formField}>
              <Input
                type="text"
                size="middle"
                placeholder={t('lastName')}
                value={lastName}
                onChange={onInputChange('lastName')}
                disabled={isSaving}
              />
            </Field>
            <button
              type="submit"
              className={cx(styles.formBtn, styles.submitBtn)}
              disabled={isSaving}
              data-testid="submit-button"
              aria-label="submit"
            >
              {isSaving && <LoadingOutlined aria-hidden />}
              {!isSaving && <CheckOutlined aria-hidden />}
            </button>
            <button
              type="button"
              className={cx(styles.formBtn, styles.cancelBtn)}
              onClick={isNew ? onCandidateCreationCancelled : onCancelEdit}
              disabled={isSaving}
              aria-label="cancel"
            >
              <CloseOutlined aria-hidden />
            </button>
          </Form>
        </div>
      )}
    </div>
  );
}

CandidateHeader.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  createdAt: PropTypes.string,
  modifiedAt: PropTypes.string,
  isNew: PropTypes.bool.isRequired,
  isEdited: PropTypes.bool.isRequired,
  t: PropTypes.func,
  onSubmitCreate: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onCandidateCreationCancelled: PropTypes.func,
  fieldErrors: PropTypes.object.isRequired,
  onEditCandidate: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  onSubmitEdit: PropTypes.func.isRequired,
  onDeleteCandidate: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  withLinkableHeaderNames: PropTypes.bool,
  candidatePk: PropTypes.string,
  recentApplicationPk: PropTypes.string,
};

export default withTranslation('translation')(CandidateHeader);

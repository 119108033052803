import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'i18n';
import Link from 'components/Link';
import { Alert, Tooltip } from 'antd';
import { compose } from 'apollo';

import withCurrentUser from 'hoc/withCurrentUser';
import withResendApplicationEmail from 'hoc/withResendApplicationEmail';
import EmailStatus from 'components/EmailStatus';
import Button from 'components/Form/Button';
import { FAILED } from 'consts/emailStatuses';
import { logAndShowGenericError } from 'utils/log';
import { nl2br } from 'utils/text';

import EmailComposeButton from 'components/EmailComposeButton';

import Icon from '@ant-design/icons';
import ReplyIcon from 'icons/ReplyIcon';

import Activity from './Activity';

import styles from './OutboundEmail.less';

function OutboundEmail({
  application,
  createdBy,
  pk,
  inApplicationContext,
  t,
  currentUser,
  resendApplicationEmail,
  email,
  onClick,
  ...props
}) {
  const firstName = application.candidate.firstName;
  const lastName = application.candidate.lastName;
  const position = application.jobPosition.position;
  const url = `/candidates/${application.candidate.pk}/${application.pk}`;

  return (
    <Activity
      {...props}
      createdBy={createdBy}
      icon="mail"
      action={
        <Trans
          i18nKey={`emailActivityHeader${
            inApplicationContext ? '_inApplication' : ''
          }-v2`}
        >
          sent{' '}
          <Tooltip title={t(`emailStatus_${email.status}`)}>
            email
            <EmailStatus
              status={email.status}
              className={styles.emailStatus}
              noText
            />
          </Tooltip>{' '}
          to{' '}
          <Tooltip title={email.toEmail}>
            <Link onClick={onClick} to={url}>
              {{ firstName }} {{ lastName }}
            </Link>
          </Tooltip>
          <span>
            {!inApplicationContext ? (
              <>
                {' '}
                in <b>{{ position }}</b>
              </>
            ) : (
              ''
            )}
          </span>
        </Trans>
      }
      detailsExcerpt={email.subject}
      details={
        <>
          {email.status === FAILED && currentUser.pk === createdBy.pk && (
            <Alert
              className={styles.resendBox}
              type="warning"
              showIcon
              description={
                <div className={styles.resendTextButton}>
                  <span>{t('emailNotSent')}</span>
                  <Button
                    size="default"
                    className={styles.resendButton}
                    onClick={() =>
                      resendApplicationEmail(pk).catch(
                        logAndShowGenericError(
                          'resendApplicationEmail rejected',
                          { activityPk: pk },
                        ),
                      )
                    }
                  >
                    {t('button_resendEmail')}
                  </Button>
                </div>
              }
            />
          )}
          <div>{nl2br(email.message)}</div>
        </>
      }
      detailsToolbar={
        inApplicationContext && (
          <EmailComposeButton
            candidate={application.candidate}
            application={application}
            contactDetails={application.candidate.contact}
            replyToEmailInstance={email}
            createdBy={createdBy}
            icon={<Icon component={ReplyIcon} aria-hidden />}
            size="small"
            openButton
          >
            {t('OutboundEmail_button-replyEmail')}
          </EmailComposeButton>
        )
      }
    />
  );
}

OutboundEmail.propTypes = {
  application: PropTypes.object.isRequired,
  createdBy: PropTypes.object,
  pk: PropTypes.string.isRequired,
  inApplicationContext: PropTypes.bool,
  email: PropTypes.object.isRequired,

  // fired whenever user clicks the activity and is navigated to the link to the activities page,
  // e.g. used to set notification as read
  onClick: PropTypes.func,

  // From HoCs
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  resendApplicationEmail: PropTypes.func.isRequired,
};

export default compose(
  withCurrentUser,
  withResendApplicationEmail,
  withTranslation('translation'),
)(OutboundEmail);

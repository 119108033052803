import React, { memo } from 'react';
import { useTranslation } from 'i18n';
import AddApplicationContainer from 'pages/candidates/AddApplication';
import InlineSVG from 'components/InlineSVG';

import addApplicationIllustration from './add-application.svg?inline';
import styles from './index.less';

function AddApplicationPlaceholder({
  candidatePk,
  popupContainerRef,
  onApplicationSelect,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.container} data-testid="add-application-placeholder">
      <InlineSVG
        className={styles.illustration}
        svg={addApplicationIllustration}
      />
      <h3 className={styles.header}>
        {t('Teraz możesz przypisać kandydata do stanowiska')}
      </h3>
      <AddApplicationContainer
        candidatePk={candidatePk}
        popupContainerRef={popupContainerRef}
        onApplicationSelect={onApplicationSelect}
      />
    </div>
  );
}

AddApplicationPlaceholder.propTypes = {
  ...AddApplicationContainer.propTypes,
};

export default memo(AddApplicationPlaceholder);

import React from 'react';
import { useTranslation } from 'i18n';

import Logo from 'components/Logo';
import UserLanguageSelect from 'components/LanguageSelect/UserLanguageSelect';

import styles from './index.less';

function AccountNotVerifiedScreen() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.promptTitle}>
          {t('AccountNotVerifiedScreen_title')}
        </h2>
        <h3 className={styles.promptMessage}>
          {t('AccountNotVerifiedScreen_message')}
        </h3>
      </div>
      <div>
        <UserLanguageSelect theme="transparent" />
      </div>
      <Logo className={styles.logo} />
    </div>
  );
}

export default AccountNotVerifiedScreen;

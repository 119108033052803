import React from 'react';
import PropTypes from 'prop-types';
import Icon, {
  DeleteOutlined,
  MailOutlined,
  PhoneOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { message } from 'antd';
import { withTranslation } from 'i18n';
import CONTACT_TYPES, { EMAIL, PHONE, URL } from 'consts/contactTypes';
import DeleteItemPopover from 'components/DeleteItemPopover';
import { logAndShowGenericError } from 'utils/log';
import EmailComposeLink from './EmailComposeLink';

import styles from './ContactDetail.less';

export const CONTACT_DETAILS = {
  [EMAIL]: {
    icon: MailOutlined,
    prefix: 'mailto:',
    testid: 'mailIcon',
    target: '_self',
  },
  [PHONE]: {
    icon: PhoneOutlined,
    prefix: 'tel:',
    testid: 'phoneIcon',
    target: '_self',
  },
  [URL]: {
    icon: LinkOutlined,
    prefix: '',
    testid: 'linkIcon',
    target: '_blank',
  },
};

const handleContactDelete =
  (deleteContact, contactPk, organizationPk, t) => () => {
    deleteContact(contactPk, organizationPk)
      .then(({ errors }) => {
        if (errors) {
          message.error(t('deleteContactError'));
        }
      })
      .catch(
        logAndShowGenericError('deleteContactDetail rejected', {
          deleteContact,
          contactPk,
          organizationPk,
          t,
        }),
      );
  };

function ContactDetail({ contactType, content, contactPk, deleteContact, t }) {
  const IconName = CONTACT_DETAILS[contactType].icon;

  return (
    <div className={styles.container}>
      <Icon
        component={IconName}
        className={styles.typeIcon}
        data-testid={CONTACT_DETAILS[contactType].testid}
        aria-hidden
      />
      {contactType === EMAIL && (
        <EmailComposeLink
          pickedEmail={content}
          fallbackLink={
            <a
              href={`${CONTACT_DETAILS.EMAIL.prefix}${content}`}
              className={styles.content}
              target={CONTACT_DETAILS.EMAIL.target}
            >
              {content}
            </a>
          }
        >
          <span className={styles.content}>{content}</span>
        </EmailComposeLink>
      )}
      {contactType !== EMAIL && (
        <a
          href={`${CONTACT_DETAILS[contactType].prefix}${content}`}
          className={styles.content}
          target={CONTACT_DETAILS[contactType].target}
        >
          {content}
        </a>
      )}
      <DeleteItemPopover
        deleteFunction={handleContactDelete(deleteContact, contactPk, t)}
      >
        <button
          type="button"
          aria-label="delete contact"
          className={styles.deleteButton}
        >
          <DeleteOutlined
            className={styles.deleteIcon}
            data-testid="delete-contact-details"
            aria-hidden
          />
        </button>
      </DeleteItemPopover>
    </div>
  );
}

ContactDetail.propTypes = {
  contactType: PropTypes.oneOf(CONTACT_TYPES).isRequired,
  content: PropTypes.string.isRequired,
  contactPk: PropTypes.string.isRequired,
  deleteContact: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation('translation')(ContactDetail);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Checkbox as AntCheckbox } from 'antd';
import styles from './Checkbox.less';

function Checkbox({ className, ...rest }) {
  return <AntCheckbox {...rest} className={cx(className, styles.checkbox)} />;
}

Checkbox.propTypes = {
  className: PropTypes.string,
};

export default Checkbox;

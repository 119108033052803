import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
import BlockContent from 'components/JobOfferForm/BlockContent';
import { useTranslation } from 'react-i18next';
import ControlledInput from 'components/JobOfferForm/ControlledInput';
import { Collapse } from 'antd';
import AddButton from 'components/JobOfferForm/AddButton';
import Button from 'components/Form/Button';
import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import useDragAndDrop from 'hooks/useDragAndDrop';
import styles from './AccordionBlock.less';
import AccordionHeader from './AccordionHeader/index.js';

function AccordionBlock({ blockIndex }) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const blockName = `blocks[${blockIndex}].accordionBlock`;
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: `${blockName}.items`,
  });

  // Watch for changes in the form to trigger re-renders
  useWatch({ control, name: `${blockName}.items` });

  // State to keep track of manually opened panels
  const [openPanels, setOpenPanels] = useState([]);

  const {
    draggedIndex,
    onDragStart,
    onDragOver,
    onDragEnd,
    onDrop,
    getItemsWithPlaceholder,
  } = useDragAndDrop(fields, move);

  // Note: We've chosen to sync state within this component rather than lifting it up the tree.
  // This approach reduces prop-drilling and keeps the accordion logic encapsulated.
  // We're monitoring the stability of the errors array to ensure this doesn't cause unnecessary re-renders.
  useEffect(() => {
    const panelsWithErrors = fields.reduce((acc, field, index) => {
      const hasError =
        errors.blocks?.[blockIndex]?.accordionBlock?.items?.[index];
      if (hasError) {
        acc.push(field.id);
      }
      return acc;
    }, []);

    setOpenPanels((prevOpenPanels) => {
      const newOpenPanels = [
        ...new Set([...prevOpenPanels, ...panelsWithErrors]),
      ];
      return newOpenPanels;
    });
  }, [errors, fields, blockIndex]);

  const onAddItem = useCallback(() => {
    append({ title: '', description: '', iconValue: 'add' });
  }, [append]);

  const onPanelChange = useCallback((keys) => {
    setOpenPanels(keys);
  }, []);

  const itemsWithPlaceholder = getItemsWithPlaceholder();

  return (
    <BlockContent className={styles.container}>
      <ControlledInput
        control={control}
        name={`${blockName}.title`}
        popupLabel
        labelDark
        type="text"
        size="middle"
        placeholder={t('AccordionBlock_title')}
        className={styles.input}
      />
      <Collapse
        activeKey={openPanels}
        onChange={onPanelChange}
        expandIconPosition="end"
        collapsible="icon"
        className={styles.accordion}
      >
        {itemsWithPlaceholder.map((field, index) => (
          <Collapse.Panel
            header={
              <div
                draggable={!field.isPlaceholder}
                onDragStart={(e) => onDragStart(e, index)}
                onDragOver={(e) => onDragOver(e, index)}
                onDrop={(e) => onDrop(e, index)}
                onDragEnd={onDragEnd}
                className={classNames(styles.panelHeader, {
                  [styles.dragging]: draggedIndex === index,
                  [styles.placeholder]: field.isPlaceholder,
                })}
              >
                <MenuOutlined className={styles.dragHandle} />
                <AccordionHeader
                  control={control}
                  textFieldName={`${blockName}.items[${index}].title`}
                  iconValueFieldName={`${blockName}.items[${index}].iconValue`}
                />
              </div>
            }
            key={field.id}
            className={styles.panel}
            extra={
              fields.length > 1 && (
                <Button
                  iconOnly
                  icon={<DeleteOutlined aria-hidden />}
                  aria-label="delete-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    remove(index);
                  }}
                  className={styles.removeBtn}
                />
              )
            }
          >
            <ControlledInput
              control={control}
              name={`${blockName}.items[${index}].description`}
              type="text"
              size="middle"
              placeholder={t('AccordionBlockItem_description')}
              className={styles.itemInput}
              textarea
              autoSize={{ minRows: 3, maxRows: 6 }}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
      <AddButton onAddClick={onAddItem} short>
        {t('AddItem')}
      </AddButton>
    </BlockContent>
  );
}

AccordionBlock.propTypes = {
  blockIndex: PropTypes.number.isRequired,
};

export default AccordionBlock;

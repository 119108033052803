function getAPIUrl() {
  if (process.env.NODE_ENV === 'test') {
    return 'http://workate.mock.url/api';
  }

  const isLivemore = window.location.hostname.includes('livemorepomerania');
  const apiUrl = isLivemore
    ? process.env.LIVEMORE_API_URL
    : process.env.API_URL;
  const apiName = isLivemore ? 'LIVEMORE_API_URL' : 'API_URL';
  return apiUrl || new Error(`${apiName} not set`);
}

export const ENV = process.env.NODE_ENV || 'development';

export const IS_DEVELOPMENT = ENV !== 'production';

// Skip blocking access to app (by redirection to NotVerifiedScreen) if user lacks verification
// (see AuthenticatedLayout for conditional block)
// Since this is a temporary change, it's unnecessary to check this in Cypress
export const SKIP_VERIFICATION = Boolean(global.Cypress);

// Used for disabling tracking services like LogRocket, Analytics etc.
// Turn off tracking when not in production (or doing E2E tests in Cypress
export const SKIP_TRACKING = Boolean(IS_DEVELOPMENT || global.Cypress);

export const API_URL = getAPIUrl();

export const API_GRAPHQL_ENDPOINT = `${API_URL}/graphql/`;

export const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY;

export const SKIP_CAPTCHA = SKIP_TRACKING || !RECAPTCHA_SITE_KEY;

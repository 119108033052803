import React from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import cx from 'classnames';
import styles from './AddButton.less';

function AddButton({ onAddClick, short, children, className }) {
  return (
    <button
      type="button"
      className={cx(className, styles.tileBtn, short && styles.shortBtn)}
      onClick={onAddClick}
    >
      <PlusOutlined />
      {children}
    </button>
  );
}

AddButton.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  short: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default AddButton;

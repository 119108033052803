import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from 'components/Logo';
import { ArrowRightOutlined } from '@ant-design/icons';
import styles from './index.less';

function FullscreenMessage({ children, ctaText, url }) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.text}>{children}</div>
        {url && (
          <Link to={url}>
            {ctaText} <ArrowRightOutlined />
          </Link>
        )}
      </div>
      <Logo className={styles.logo} />
    </div>
  );
}

FullscreenMessage.propTypes = {
  children: PropTypes.node.isRequired,
  ctaText: PropTypes.node,
  url: PropTypes.string,
};

export default FullscreenMessage;

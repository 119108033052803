import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';
import styles from './index.less';

function Link({ children, className, ...rest }) {
  return (
    <RouterLink className={cx(styles.link, className)} {...rest}>
      {children}
    </RouterLink>
  );
}

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Link;

import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

import styles from './index.less';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

function RadioButtonGroup({ current, items, onChange }) {
  return (
    <RadioGroup
      value={current}
      onChange={onChange}
      className={styles.radioGroup}
    >
      {items.map((o) => (
        <RadioButton
          key={o.value}
          value={o.value}
          data-role={o.dataRole}
          className={styles.radioButton}
        >
          {o.content}
        </RadioButton>
      ))}
    </RadioGroup>
  );
}

RadioButtonGroup.propTypes = {
  current: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    }),
  ),
  onChange: PropTypes.func.isRequired,
};

export default RadioButtonGroup;

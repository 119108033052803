import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

/**
 * Form Input UI component — wrapps AntD Form item + Input
 */
function Field({ error, ...rest }) {
  return (
    <Form.Item validateStatus={error ? 'error' : ''} help={error} {...rest} />
  );
}

Field.propTypes = {
  error: PropTypes.string,
};

export default Field;

import React from 'react';
import PropTypes from 'prop-types';
import { InfoCircleOutlined } from '@ant-design/icons';
import Button from 'components/Form/Button';
import infoModal from 'utils/infoModal';

import styles from './index.less';

function InfoButton({ modalTitle, modalContent }) {
  return (
    <Button
      iconOnly
      icon={<InfoCircleOutlined style={{ fontSize: '24px' }} />}
      className={styles.infoIcon}
      onClick={() => infoModal(modalTitle, modalContent)}
    />
  );
}

InfoButton.propTypes = {
  modalTitle: PropTypes.string.isRequired,
  modalContent: PropTypes.string.isRequired,
};

export default InfoButton;

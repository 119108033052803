import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'i18n';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { notification, Button as NotificationButton } from 'antd';

import Button from 'components/Form/Button';
import Tooltip from 'components/Tooltip';

import useNotificationDelete from 'hooks/useNotificationDelete';

const DELETE_NOTIFICATION_DURATION = 5;

function DeleteNotificationButton({
  notificationPk,
  ordering,
  onRestore,
  onDelete,
}) {
  const { t } = useTranslation();
  const [handleDeleteNotification] = useNotificationDelete({
    notificationPk,
    ordering,
  });

  // closeAndDelete is triggered both in restoreNotification and in notification.open body in onClose.
  // In notification.open body it is called after 5 seconds and without sending a key
  // so a notification closes and sends a delete mutation.
  // In restoreNotification we send the key so the notification will close without sending mutation.
  const closeAndDelete = (key) => {
    if (!key) {
      handleDeleteNotification();
    }
  };

  // When user deletes a notification we allow him to recover it for 5 seconds. After that, we send a deletion mutation.
  // During this time we want to hide the notification which user wants to delete to mimic immediate optimistic update.
  const restoreNotification = (key) => {
    onRestore();
    notification.close(key);
  };

  const openAntNotification = (key) => {
    onDelete();
    notification.open({
      message: t('DeleteNotificationButton-notification_message'),
      description: t('DeleteNotificationButton-notification_description'),
      btn: (
        <NotificationButton
          type="primary"
          size="small"
          onClick={() => restoreNotification(key)}
        >
          {t('DeleteNotificationButton-notification-button_restore')}
        </NotificationButton>
      ),
      // Key is the unique identifier of the Notification
      key,
      duration: DELETE_NOTIFICATION_DURATION,
      closeIcon: <CloseOutlined data-testid="close-antd-notification" />,
      onClose: closeAndDelete,
    });
  };

  return (
    <Tooltip
      title={t('NotificationDeleteButton_tooltip-delete')}
      placement="bottomRight"
    >
      <span>
        <Button
          onClick={() => openAntNotification(notificationPk)}
          size="small"
          type="text"
          icon={<CheckOutlined />}
          data-testid="delete-notification-button"
        />
      </span>
    </Tooltip>
  );
}

DeleteNotificationButton.propTypes = {
  notificationPk: PropTypes.string,
  ordering: PropTypes.string,

  // set isVisible to false to hide a notification by adding a class with display: none to a activity
  onDelete: PropTypes.func,
  // set isVisible to true to display a notification by removing a class with display: none in a activity
  onRestore: PropTypes.func,
};

export default DeleteNotificationButton;

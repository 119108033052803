function persist(obj) {
  Object.keys(obj).forEach((key) => {
    localStorage.setItem(key, obj[key]);
  });
}

function clear(...keys) {
  keys.forEach((key) => {
    localStorage.removeItem(key);
  });
}

function get(...keys) {
  if (keys.length === 1) {
    return localStorage.getItem(keys[0]);
  }

  return keys.map((key) => localStorage.getItem(key));
}

export default {
  clear,
  persist,
  get,
};

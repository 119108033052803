import React, { memo } from 'react';
import PropTypes from 'prop-types';

import NewEmailTemplateModal from '../EmailTemplateForm/NewEmailTemplateModal';
import EmailTemplateElement from '../EmailTemplatesElement/EmailTemplateElementContainer';

function EmailTemplatesList({
  emailTemplatesList,
  isAddingNewEmailTemplate,
  toggleIsAddingNewEmailTemplate,
}) {
  return (
    <>
      {isAddingNewEmailTemplate && (
        <NewEmailTemplateModal
          toggleIsAddingNewEmailTemplate={toggleIsAddingNewEmailTemplate}
        />
      )}
      {emailTemplatesList.map(({ node }) => (
        <EmailTemplateElement
          key={node.id}
          templatePk={node.pk}
          title={node.title}
          content={node.content}
        />
      ))}
    </>
  );
}

EmailTemplatesList.propTypes = {
  emailTemplatesList: PropTypes.array,
  isAddingNewEmailTemplate: PropTypes.bool,
  toggleIsAddingNewEmailTemplate: PropTypes.func,
};

export default memo(EmailTemplatesList);

import React, { useCallback } from 'react';
import Content from 'components/Content';
import { useTranslation } from 'react-i18next';
import { Trans } from 'i18n';
import { Alert } from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import Button from 'components/Form/Button';
import { useNavigate, useParams } from 'react-router';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import Loader from 'components/Loader';
import publishLandingPage from 'pages/job-positions/JobOffers/publishLandingPageMutation.gql';
import { useMutation } from '@apollo/client';
import { logAndShowGenericError } from 'utils/log';
import { showError } from 'utils/message';
import useLandingPage from './useLandingPage';

import styles from './JobOfferPreview.less';

function JobOfferPreview() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [organizationPk] = useCurrentOrganization();
  const [publishJobOffer] = useMutation(publishLandingPage);
  const { pk, offerPk } = useParams();
  const { landingPageData, loading } = useLandingPage({
    landingPagePk: offerPk,
    organizationPk,
  });

  const handlePublish = useCallback(() => {
    publishJobOffer({
      variables: {
        pk: offerPk,
        organizationPk,
      },
    })
      .then(({ data }) => {
        if (data.publishLandingPage.errors) {
          return data.publishLandingPage.errors.message
            ? showError(data.generateLandingPage.errors.message)
            : logAndShowGenericError('publishLAndingPage rejected', {
                pk: offerPk,
                organizationPk,
              });
        }
        navigate(`/positions/${pk}`);
      })
      .catch(
        logAndShowGenericError('publishLandingPage rejected', {
          pk: offerPk,
          organizationPk,
        }),
      );
  }, [publishJobOffer, organizationPk, offerPk, pk, navigate]);

  const handleEdit = useCallback(() => {
    navigate(`/positions/${pk}/${offerPk}`);
  }, [navigate, offerPk, pk]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Content
      title={t('Title_jobOfferPreview')}
      theme="transparent"
      size="medium"
      CTAText={t('JobOfferPreview_publish')}
      onCTAClick={handlePublish}
      buttonIcon={CheckOutlined}
      secondaryButton={
        <Button
          size="default"
          icon={<EditOutlined aria-hidden />}
          className={styles.buttonSecondary}
          onClick={handleEdit}
        >
          {t('JobOfferPreview_backToEdit')}
        </Button>
      }
    >
      <Alert
        message={
          <Trans i18nKey="Info_jobOfferPreview">
            <p>
              <strong>Publish</strong>point
            </p>
            <p>
              <strong>Edit</strong>point
            </p>
            <p>
              <strong>Leave</strong>point
            </p>
          </Trans>
        }
        className={styles.infoAlert}
      />
      <iframe
        data-testid="joboffer-preview"
        className={styles.jobOfferIframe}
        src={landingPageData.previewUrl}
        title={landingPageData.title}
      />
    </Content>
  );
}

export default JobOfferPreview;

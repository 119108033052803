export const CREATED = 'CREATED';
export const SENT = 'SENT';
export const FAILED = 'FAILED';

export const MAP_EMAIL_STATUSES = {
  CREATED: 'emailStatus_CREATED',
  SENT: 'emailStatus_SENT',
  FAILED: 'emailStatus_FAILED',
  RECEIVED: 'emailStatus_RECEIVED',
  NOT_DELIVERED: 'emailStatus_NOT_DELIVERED',
};

export const MAP_BULK_EMAIL_STATUSES = {
  IN_PROGRESS: 'bulkEmailStatus_IN_PROGRESS',
  FINISHED: 'bulkEmailStatus_FINISHED',
};

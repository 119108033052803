import { useQuery } from '@apollo/client';
import { MEMBERSHIP_QUERY } from 'hooks/useMembership';

export default function useCurrentUser() {
  const { data, loading } = useQuery(MEMBERSHIP_QUERY);

  const currentUser = !loading ? data.viewer.currentUser : null;

  return [currentUser, loading];
}

import React from 'react';
import PropTypes from 'prop-types';
import { Tag as AntTag } from 'antd';
import cx from 'classnames';

import styles from './index.less';

function Tag({ children, onClick, className, ...rest }) {
  // function that enables to operate Tag by keyboard. It calls onClick function on enter.
  const keyDownHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <AntTag
      tabIndex={0}
      onKeyDown={keyDownHandler}
      onClick={onClick}
      className={cx(className, styles.tag)}
      {...rest}
    >
      {children}
    </AntTag>
  );
}

Tag.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default Tag;

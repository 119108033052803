import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import { compose } from 'apollo';

import ModalWithIframe from 'components/ModalWithIframe';
import Content from 'components/Content';
import PersonalizeAccount from 'pages/settings/profile/PersonalizeAccountContainer';
import OrganizationBillingInfo from 'pages/settings/profile/OrganizationBillingInfo';
import UserLanguageSelect from 'components/LanguageSelect/UserLanguageSelect';
import Title from 'components/Title';

import withOrganizationPk from 'hoc/withOrganizationPk';
import withCurrentUser from 'hoc/withCurrentUser';
import { url } from 'utils/api';

import styles from './UserSettings.less';

function UserSettings({ currentUser, organizationPk }) {
  const { t } = useTranslation();

  return (
    <>
      <Content
        title={t('accountSettings')}
        secondary
        size="small"
        theme="transparent"
        className={styles.section}
      >
        <Title>{t('Title_userSettings')}</Title>
        <ModalWithIframe src={url('/accounts/password/change')}>
          {t('changePassword')}
        </ModalWithIframe>
        <ModalWithIframe src={url('/accounts/email')}>
          {t('emailSettings')}
        </ModalWithIframe>
      </Content>
      <Content
        title={t('userSettings')}
        secondary
        size="small"
        theme="transparent"
        className={styles.section}
      >
        <PersonalizeAccount
          currentUser={currentUser}
          organizationPk={organizationPk}
          inSettings
        />
      </Content>
      <Content
        title={t('Settings_organizationInfoTitle')}
        secondary
        size="small"
        theme="transparent"
        className={styles.section}
      >
        <OrganizationBillingInfo />
      </Content>
      <Content
        title={t('UserSettings_languageSelect')}
        secondary
        size="small"
        theme="transparent"
        className={styles.section}
        data-testid="LanguageSelect-title"
      >
        <UserLanguageSelect />
      </Content>
    </>
  );
}

UserSettings.propTypes = {
  currentUser: PropTypes.object.isRequired,
  organizationPk: PropTypes.string.isRequired,
};

export default compose(withOrganizationPk, withCurrentUser)(UserSettings);

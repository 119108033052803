import { useState, useCallback } from 'react';

const useCandidateDrawer = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [activeApplicationPk, setActiveApplication] = useState('');
  const [selectedCandidatePk, setSelectedCandidatePk] = useState('');

  const onDrawerClose = useCallback(() => {
    setIsDrawerVisible(false);
    setActiveApplication('');
    setSelectedCandidatePk('');
  }, []);

  const onNewCandidateFormOpen = useCallback(() => {
    setIsDrawerVisible(true);
    setSelectedCandidatePk('');
  }, [setIsDrawerVisible, setSelectedCandidatePk]);

  const onCandidateSelected = useCallback(
    (pk, applications) => {
      setIsDrawerVisible(true);
      setSelectedCandidatePk(pk);
      /**  Set the most recent application as active. */
      setActiveApplication(
        applications.edges.length > 0
          ? applications.edges[applications.edges.length - 1].node.pk
          : '',
      );
    },
    [setIsDrawerVisible, setSelectedCandidatePk, setActiveApplication],
  );

  const onApplicationClick = useCallback(
    (applicationPk, candidatePk) => {
      setIsDrawerVisible(true);
      setSelectedCandidatePk(candidatePk);
      setActiveApplication(applicationPk);
    },
    [setIsDrawerVisible, setSelectedCandidatePk, setActiveApplication],
  );

  return {
    isDrawerVisible,
    activeApplicationPk,
    setActiveApplication,
    selectedCandidatePk,
    setSelectedCandidatePk,
    onDrawerClose,
    onNewCandidateFormOpen,
    onCandidateSelected,
    onApplicationClick,
  };
};

export default useCandidateDrawer;

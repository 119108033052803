import React from 'react';
import PropTypes from 'prop-types';

function InlineSVG({ tag = 'span', svg, forwardedRef, ...rest }) {
  return React.createElement(tag, {
    dangerouslySetInnerHTML: { __html: svg },
    ref: forwardedRef,
    ...rest,
  });
}

InlineSVG.propTypes = {
  tag: PropTypes.string,
  svg: PropTypes.string.isRequired,
  forwardedRef: PropTypes.func,
};

export default React.forwardRef((props, ref) => (
  <InlineSVG {...props} forwardedRef={ref} />
));

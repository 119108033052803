import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'apollo';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from 'i18n';
import { message } from 'antd';
import log, { logAndShowGenericError } from 'utils/log';
import { track } from 'utils/track';
import { getUTMs } from 'utils/utmParams';
import graphqlErrorFieldsMutation from 'hoc/graphqlErrorFieldsMutation';
import { validator, isRequired, checkPhone } from 'utils/validator';
import PersonalizeAccount from 'pages/settings/profile/PersonalizeAccount';
import { MEMBERSHIP_QUERY } from 'hooks/useMembership';
import currentOrganizationQuery from 'hoc/currentOrganizationQuery.gql';
import addDetailsUserMutation from './addDetailsUserMutation.gql';

class PersonalizeAccountContainer extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    fieldErrors: PropTypes.object.isRequired,
    clearFieldError: PropTypes.func.isRequired,
    setFieldErrors: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
    isUserLoading: PropTypes.bool,
    organizationPk: PropTypes.string,
    inSettings: PropTypes.bool,
    isSaving: PropTypes.bool.isRequired,
  };

  state = {
    organizationName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    isInitialStateSet: false,
    isInputChanged: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.isUserLoading && !state.isInitialStateSet) {
      return {
        firstName: props.currentUser.firstName,
        lastName: props.currentUser.lastName,
        phoneNumber: props.currentUser.phoneNumber,
        isInitialStateSet: true,
      };
    }

    return null;
  }

  createGetValidateErrors = () => {
    const organizationName = [];
    if (this.props.currentUser.memberships.length === 0) {
      organizationName.push(isRequired);
    }
    return validator({
      organizationName,
      phoneNumber: [checkPhone, isRequired],
      firstName: [isRequired],
      lastName: [isRequired],
    });
  };

  onSubmit = (e) => {
    const { setFieldErrors, mutate, t } = this.props;
    const { firstName, lastName, organizationName, phoneNumber } = this.state;
    const utmFields = getUTMs();
    const validationErrors = this.createGetValidateErrors()({
      organizationName,
      phoneNumber,
      lastName,
      firstName,
    });

    if (e) {
      e.preventDefault();
    }

    if (Object.keys(validationErrors).length > 0) {
      return setFieldErrors(validationErrors);
    }

    mutate({
      variables: {
        firstName,
        lastName,
        organizationName,
        phoneNumber,
        utmFields,
      },
    })
      .then((data) => {
        if (!data.ok) {
          message.error(t('addDetailsUserError'));
          log('addDetailsUser status !== OK', {
            props: this.props,
            state: this.state,
            data,
          });
        } else {
          this.setState({
            isInputChanged: false,
          });
          track('completed registration', {
            userPk: this.props.currentUser.pk,
          });
          message.success(t('dataSavedSuccessully'));
        }
      })
      .catch(
        logAndShowGenericError('addDetailsUser rejected', {
          props: this.props,
          state: this.state,
        }),
      );
  };

  onSkip = () => {
    const { currentUser } = this.props;

    if (this.props.inSettings) {
      return this.setState({
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        phoneNumber: currentUser.phoneNumber,
        isInputChanged: false,
      });
    }
  };

  onInputChange = (input) => (e) => {
    this.props.clearFieldError(input);
    this.setState({
      [input]: e.target.value,
      isInputChanged: true,
    });
  };

  blurOnSubmit = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
      this.onSubmit();
    }
  };

  render() {
    const { fieldErrors, isUserLoading, currentUser, inSettings, isSaving } =
      this.props;

    return (
      <PersonalizeAccount
        {...this.state}
        fieldErrors={fieldErrors}
        onSubmit={this.onSubmit}
        onSkip={this.onSkip}
        onInputChange={this.onInputChange}
        blurOnSubmit={this.blurOnSubmit}
        isUserLoading={isUserLoading}
        currentUser={currentUser}
        inSettings={inSettings}
        isSaving={isSaving}
      />
    );
  }
}

export default compose(
  withRouter,
  withTranslation('translation'),
  graphqlErrorFieldsMutation(addDetailsUserMutation, {
    options: () => ({
      refetchQueries: [
        { query: MEMBERSHIP_QUERY },
        { query: currentOrganizationQuery },
      ],
    }),
  }),
)(PersonalizeAccountContainer);

import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'i18n';

import { PLAN_TYPES } from 'consts/planTypes';
import { FREE_PLAN_LIMITS } from 'consts/freePlanLimits';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import billingInformationQuery from 'components/ActivateOrChangePlanButton/billingInformationQuery.gql';

const useCanSendBulkEmailing = ({ emailsToSendCount }) => {
  /**
   * This hook answers the question: can bulk emailing be send?
   * Checks if email limit is exceeded, if current bulk emailing will exceed the limit
   * and if there are any emails to send.
   *
   * @returns { boolean, string } - canSendBulkEmailing, disableSendingMessage
   *
   */

  const [organizationPk] = useCurrentOrganization();
  const { t } = useTranslation();

  const { data, loading } = useQuery(billingInformationQuery, {
    variables: { organizationPk },
  });
  const billing = !loading && data?.viewer.organizations[0].billing;
  const { emailsCount, planType } = !loading && billing;

  const isEmailLimitExceeded = useMemo(
    () =>
      planType === PLAN_TYPES.FREE && emailsCount > FREE_PLAN_LIMITS.MAX_EMAILS,
    [planType, emailsCount],
  );

  const willBeEmailLimitExceeded = useMemo(
    // Check if sent emails + emails that will be sent in current emailing, exceed the email limit.
    () =>
      planType === PLAN_TYPES.FREE &&
      emailsCount + emailsToSendCount > FREE_PLAN_LIMITS.MAX_EMAILS,
    [planType, emailsCount, emailsToSendCount],
  );

  const disableSendingMessage = useMemo(() => {
    if (isEmailLimitExceeded) {
      return t('SendBulkEmailModal-emailLimitExceeded');
    }
    if (willBeEmailLimitExceeded) {
      return t('SendBulkEmailModal-emailLimitWillBeExceed');
    }
    if (emailsToSendCount === 0) {
      return t('SendBulkEmailModal-noEmailsToSend');
    }
  }, [isEmailLimitExceeded, willBeEmailLimitExceeded, emailsToSendCount, t]);

  return {
    canSendBulkEmailing: !disableSendingMessage,
    disableSendingMessage,
    loading,
  };
};

export default useCanSendBulkEmailing;

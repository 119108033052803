import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Form/Select';
import { withTranslation } from 'i18n';
import styles from './index.less';

export const Option = Select.Option;

function AssignedToSelect({
  t,
  usersList,
  value,
  popupContainerRef,
  onChange,
  disabled,
  ...rest
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (val) => {
    setIsLoading(true);
    await onChange(val);
    setIsLoading(false);
  };

  return (
    <Select
      className={styles.assignedToSelect}
      value={value}
      choices={usersList.map(
        ({ pk, firstName, lastName, email, username }) => ({
          label:
            ((firstName || lastName) && `${firstName} ${lastName}`) ||
            email ||
            username,
          value: pk,
        }),
      )}
      withBorders={false}
      placeholder={t('assignedUser')}
      popupContainer={popupContainerRef}
      onChange={handleChange}
      disabled={disabled || isLoading}
      data-testid={rest['data-testid'] || ''}
      {...rest}
    />
  );
}

AssignedToSelect.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  usersList: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      initials: PropTypes.string.isRequired,
    }),
  ),
  popupContainerRef: PropTypes.object,
};

export default withTranslation('translation')(AssignedToSelect);

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'apollo';
import withOrganizationPk from 'hoc/withOrganizationPk';
import CandidateWithApplications from 'pages/candidates/CandidateWithApplications/CandidateWithApplications';
import CurrentCandidateProvider from 'providers/CurrentCandidateProvider';
import candidateWithApplicationsQuery from './candidateWithApplicationsQuery.gql';

function CandidateWithApplicationsContainer({
  candidatePk,
  activeApplicationPk,
  isCandidateDetailsLoading,
  candidateDetails,
  inDrawer,
  onApplicationSelect,
  onApplicationDeleted,
  onCandidateDeleted,
  popupContainerRef,
}) {
  const handleApplicationDeleted = useCallback(() => {
    const otherApplication = candidateDetails.applications.edges.find(
      ({ node }) => node.pk !== activeApplicationPk,
    );
    const otherApplicationPk = otherApplication ? otherApplication.node.pk : '';

    onApplicationDeleted(otherApplicationPk);
  }, [candidateDetails, activeApplicationPk, onApplicationDeleted]);

  return (
    <CurrentCandidateProvider candidatePk={candidatePk}>
      <CandidateWithApplications
        activeApplicationPk={activeApplicationPk}
        isCandidateDetailsLoading={isCandidateDetailsLoading}
        candidateDetails={candidateDetails}
        onApplicationSelect={onApplicationSelect}
        inDrawer={inDrawer}
        onCandidateDeleted={onCandidateDeleted}
        onApplicationDeleted={handleApplicationDeleted}
        popupContainerRef={popupContainerRef}
      />
    </CurrentCandidateProvider>
  );
}

CandidateWithApplicationsContainer.propTypes = {
  candidatePk: PropTypes.string,
  activeApplicationPk: PropTypes.string,
  isCandidateDetailsLoading: PropTypes.bool,
  candidateDetails: PropTypes.object,
  inDrawer: PropTypes.bool,
  onApplicationSelect: PropTypes.func,
  onApplicationDeleted: PropTypes.func,
  onCandidateDeleted: PropTypes.func,
  popupContainerRef: PropTypes.object,
};

export default compose(
  withOrganizationPk,
  graphql(candidateWithApplicationsQuery, {
    options: ({ organizationPk, candidatePk }) => ({
      variables: { organizationPk, candidatePk },
    }),
    props: ({ data: { viewer, loading } }) => ({
      candidateDetails:
        !loading && viewer && viewer.candidate ? viewer.candidate : {},
      isCandidateDetailsLoading: loading,
    }),
  }),
)(CandidateWithApplicationsContainer);

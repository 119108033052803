import React from 'react';
import { useTranslation } from 'i18n';
import { useQuery } from '@apollo/client';

import Content from 'components/Content';
import Title from 'components/Title';
import Table from 'components/Table';
import DateWithIcon from 'components/DateWithIcon';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useBrowserWidth from 'hooks/useBrowserWidth';
import { XS, SM, MD, LG } from 'consts/media';
import { MAP_BULK_EMAIL_STATUSES } from 'consts/emailStatuses';

import NameCell from './NameCell';
import bulkEmailsQuery from './bulkEmailsQuery.gql';

import styles from './index.less';

const getColumns = (t) => [
  {
    title: t('thBulkEmail_subject'),
    dataIndex: 'subject',
    key: 'subject',
    render: (text, item) => (
      <NameCell
        {...item}
        subject={item.emailTemplate.title}
        createdBy={item.createdBy.email}
        t={t}
      />
    ),
  },
  {
    title: t('thBulkEmail_createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, item) => <DateWithIcon date={item.createdAt} />,
  },
  {
    title: t('thBulkEmail_createdBy'),
    dataIndex: 'createdBy.email',
    key: 'createdBy',
    render: (text, item) => item.createdBy.email,
  },
  {
    title: t('thBulkEmail_Status'),
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: t('thBulkEmail_successfulEmailsCount'),
    dataIndex: 'successfulEmailsCount',
    key: 'successfulEmailsCount',
    render: (text, item) => (
      <div className={styles.deliveredWrapper}>
        {item.successfulEmailsCount}
      </div>
    ),
    onHeaderCell: () => ({
      className: styles.centeredHeader,
    }),
  },
  {
    title: t('thBulkEmail_failedEmailsCount'),
    dataIndex: 'failedEmailsCount',
    key: 'failedEmailsCount',
    render: (text, item) => (
      <div className={styles.failedWrapper}>{item.failedEmailsCount}</div>
    ),
    onHeaderCell: () => ({
      className: styles.centeredHeader,
    }),
  },
  {
    title: t('thBulkEmail_totalEmailsCount'),
    dataIndex: 'emailsCount',
    key: 'emailsCount',
    render: (text, item) => (
      <div className={styles.totalWrapper}>{item.emailsCount}</div>
    ),
    onHeaderCell: () => ({
      className: styles.centeredHeader,
    }),
  },
];

function BulkEmailing() {
  const { t } = useTranslation();
  const [organizationPk] = useCurrentOrganization();
  const browserWidth = useBrowserWidth();

  const { data, loading } = useQuery(bulkEmailsQuery, {
    variables: { organizationPk },
  });

  const bulkEmailsList = data?.viewer.organizations[0].bulkEmails;

  let COLUMNS = getColumns(t);

  if (browserWidth <= XS) {
    COLUMNS = COLUMNS.slice(0, 1);
  } else if (browserWidth <= SM) {
    COLUMNS = COLUMNS.slice(0, 3);
  } else if (browserWidth <= MD) {
    COLUMNS = COLUMNS.slice(0, 4);
  } else if (browserWidth <= LG) {
    COLUMNS = COLUMNS.slice(0, 5);
  }

  return (
    <Content condensed title={t('BulkEmailing_header')}>
      <Title>{t('Title_bulkEmailing')}</Title>
      <Table
        columns={COLUMNS}
        dataSource={bulkEmailsList?.map((item) => ({
          ...item,
          key: item.id,
          status: t(MAP_BULK_EMAIL_STATUSES[item.status]),
        }))}
        loading={loading}
        pagination={{
          hideOnSinglePage: true,
        }}
        showHeader={browserWidth > XS}
      />
    </Content>
  );
}

export default BulkEmailing;

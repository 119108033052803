import Button from 'components/Form/Button';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'i18n';
import Content from 'components/Content';
import Alert from 'components/Alert';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import JobPositionPermissionsTable from './JobPositionPermissionsTable';
import styles from './index.less';

export default function JobPositionAddPermissions() {
  const { jobPositionPk } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Content size="small" theme="transparent">
      <Alert
        type="info"
        showIcon
        className={styles.infoAlert}
        description={
          <Trans i18nKey="RestrictedUsersPermissions_info">
            <p>
              If you want to restrict access to this process for any user, first
              change his or her permission role in the
              <Link to="/teamMembers"> "Team members" tab</Link> to "Limited
              access".
            </p>
            <p>
              Attention! Only the "Owner" of the account can change users
              permission role.
            </p>
            <p>
              Users with permission roles: "Full access", "Administrator" and
              "Owner" have access to all recruitment processes.
            </p>
          </Trans>
        }
      />
      <JobPositionPermissionsTable jobPositionPk={jobPositionPk} />
      <div className={styles.buttonWrapper}>
        <Button
          size="default"
          type="primary"
          className={styles.submitBtn}
          onClick={() => navigate(`/positions/add/thank-you/${jobPositionPk}`)}
        >
          {t('CreateJobPosition')}
        </Button>
      </div>
    </Content>
  );
}

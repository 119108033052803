export function checkBrowserSupport(
  agent = window.navigator.userAgent,
  _alert = alert,
) {
  /**
   * We support Chrome, Mozilla, and Safari browsers.
   * Edge is partially supported but it is recommended to use one of the browsers listed above.
   * App doesn’t  support Internet Explorer - Trident is a browser engine used in IE.
   */
  if (agent.indexOf('MSIE') >= 0 || agent.indexOf('Trident') >= 0) {
    // eslint-disable-next-line no-alert
    _alert(
      'Internet Explorer is not supported by our app, please change the browser for Chrome, Firefox or Safari.',
    );
  }
}

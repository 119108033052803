import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import BaseLayout from 'pages/layout/BaseLayout';
import NotificationsScreen from 'pages/notifications/NotificationsScreen';
import Candidates from 'pages/candidates/Candidates';
import TeamMembers from 'pages/team-members/TeamMembersContainer';
import JobPositionsContainer from 'pages/job-positions/JobPositionsContainer';
import JobPositionAddWrapper from 'pages/job-positions/JobPositionAddWrapper';
import JobPositionDetailsContainer from 'pages/job-positions/JobPositionDetails';
import BulkEmailing from 'pages/bulk-emailing/index.js';
import BulkEmailDetails from 'pages/bulk-emailing/BulkEmailDetails/index.js';
import Page404 from 'components/Page404';
import Home from 'pages/home';
import PersonalizeAccount from 'pages/settings/profile/PersonalizeAccountContainer';
import Settings from 'pages/settings';
import Loader from 'components/Loader';
import PlanPageContainer from 'pages/plan';
import AccountNotVerifiedScreen from 'pages/authentication/AccountNotVerifiedScreen';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useCurrentUser from 'hooks/useCurrentUser';
import useBrowserWidth from 'hooks/useBrowserWidth';
import useChangeOrganization from 'hooks/useChangeOrganization';
import * as config from 'config';

import Help from 'pages/help';
import sessionStorage from 'utils/sessionStorage';
import useDefaultUserLanguageEffect from './useDefaultUserLanguageEffect';
import useAnalitycsIndentificationEffect from './useAnalyticsIdentificationEffect';
import useDefaultOrganizationEffect from './useDefaultOrganizationEffect';
import usePersistOrganizationEffect from './usePersistOrganizationEffect';

const ReportingContainer = React.lazy(
  () => import(/* webpackChunkName: "reporting" */ 'pages/reporting'),
);

function AuthenticatedLayout() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [organizationPk] = useCurrentOrganization();
  const [currentUser, isCurrentUserLoading] = useCurrentUser();
  const browserWidth = useBrowserWidth();
  const changeOrganization = useChangeOrganization();
  const navigate = useNavigate();

  // On update effects
  useDefaultUserLanguageEffect();
  useAnalitycsIndentificationEffect();
  useDefaultOrganizationEffect();
  usePersistOrganizationEffect();
  useEffect(() => {
    if (!isCurrentUserLoading && !currentUser) {
      navigate('/login');
    }
  }, [currentUser, isCurrentUserLoading, navigate]);

  const onMenuClick = ({ keyPath }) => {
    const path = keyPath.reverse();

    if (path[0] === 'switchOrg') {
      changeOrganization(path[1]); // path[1] = organizationPk
      navigate('/');
      sessionStorage.persist({ searchPath: '' });
    } else {
      navigate(`/${path.join('/')}`);
    }

    setIsMenuOpen(false);
  };

  const onMenuToggle = () => setIsMenuOpen(!isMenuOpen);

  // Show loading screen if current user query is not fetched yet,
  // or if the organizationPk i empty unless user doesn't have any memberships.
  // That means that we should still proceed and show org creation form
  if (
    isCurrentUserLoading ||
    (!isCurrentUserLoading &&
      currentUser.memberships.length > 0 &&
      !organizationPk)
  ) {
    return <Loader fullSize />;
  }

  if (currentUser.memberships.length === 0 || !currentUser.skipDetails) {
    return (
      <Routes>
        <Route path="settings" element={<Settings />} />
        <Route
          path="*"
          element={
            <PersonalizeAccount
              currentUser={currentUser}
              organizationPk={organizationPk}
            />
          }
        />
      </Routes>
    );
  }
  // Ignore verification for Cypress tests
  if (!config.SKIP_VERIFICATION && !currentUser.isVerified) {
    return (
      <Routes>
        <Route path="/*" element={<AccountNotVerifiedScreen />} />
      </Routes>
    );
  }

  return (
    <BaseLayout
      isMenuOpen={isMenuOpen}
      browserWidth={browserWidth}
      organizationPk={organizationPk}
      onMenuClick={onMenuClick}
      onMenuToggle={onMenuToggle}
      organizations={
        currentUser && currentUser.memberships.map((o) => o.organization)
      }
      isLoading={isCurrentUserLoading}
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="notifications" element={<NotificationsScreen />} />
        <Route path="candidates/*" element={<Candidates />} />
        <Route path="teamMembers" element={<TeamMembers />} />
        <Route path="settings/*" element={<Settings />} />
        <Route path="positions/add/*" element={<JobPositionAddWrapper />} />
        <Route path="positions" element={<JobPositionsContainer active />} />
        <Route
          path="positions/archived"
          element={<JobPositionsContainer active={false} />}
        />
        <Route
          path="positions/:pk/*"
          element={<JobPositionDetailsContainer />}
        />
        <Route path="reporting/*" element={<ReportingContainer />} />
        <Route path="plan" element={<PlanPageContainer />} />
        <Route path="emailing" element={<BulkEmailing />} />
        <Route path="emailing/:pk" element={<BulkEmailDetails />} />
        <Route path="help" element={<Help />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BaseLayout>
  );
}

export default AuthenticatedLayout;

const getCustomFieldsSelectedChoicesFilter = (filters) => {
  // If there are any multichoice custom filters applied, get their keys
  const customFieldsSelectedChoicesFiltersKeys = Object.keys(filters).filter(
    (f) => f.includes('customFieldMultichoiceFilter'),
  );

  // Create object containing only multichoice custom fields filters
  let customFieldsSelectedChoicesFilter = {};
  if (customFieldsSelectedChoicesFiltersKeys.length > 0) {
    customFieldsSelectedChoicesFilter =
      customFieldsSelectedChoicesFiltersKeys.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: filters[curr],
        }),
        {},
      );
  }

  return customFieldsSelectedChoicesFilter;
};

export default getCustomFieldsSelectedChoicesFilter;

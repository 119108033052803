import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Menu as AntMenu } from 'antd';
import styles from './index.less';

const Menu = forwardRef(({ items, ...rest }, ref) => (
  <AntMenu className={styles.menu} items={items} {...rest} ref={ref} />
));

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      key: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
      onClick: PropTypes.func,
      icon: PropTypes.node,
    }),
  ).isRequired,
};

export default Menu;

import React from 'react';
import { graphql, compose } from 'apollo';
import moment from 'moment';
import AssignedToSelect from 'pages/candidates/ApplicationDetails/AssignedTo/AssignedToSelect';

import withOrganizationPk from 'hoc/withOrganizationPk';
import activitiesQuery from 'pages/candidates/ApplicationActivityFeed/activitiesQuery.gql';
// eslint-disable-next-line max-len
import candidateWithApplicationsQuery from 'pages/candidates/CandidateWithApplications/candidateWithApplicationsQuery.gql';
import usersList from './usersList.gql';
import changeApplicationAssignToMutation from './changeApplicationAssignToMutation.gql';

function AssignedTo(props) {
  return <AssignedToSelect {...props} />;
}

export default compose(
  withOrganizationPk,
  graphql(usersList, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      usersList:
        viewer && !loading
          ? viewer.memberships.edges.map(({ node }) => node.user)
          : [],
    }),
  }),
  graphql(changeApplicationAssignToMutation, {
    props: ({
      mutate,
      ownProps: { applicationPk, organizationPk, applicationId, candidatePk },
    }) => ({
      onChange: (assignedTo) =>
        mutate({
          variables: { applicationPk, organizationPk, assignedTo },
          optimisticResponse: {
            __typename: 'Mutation',
            changeApplicationAssignTo: {
              application: {
                pk: applicationPk,
                id: applicationId,
                modifiedAt: moment().format(),
                assignedTo: {
                  pk: assignedTo,
                  __typename: 'UserNode',
                },
                __typename: 'ApplicationNode',
              },
              errors: null,
              __typename: 'ChangeApplicationAssignMutation',
            },
          },
          update: (proxy, { data: { changeApplicationAssignTo } }) => {
            const candidateQueryData = proxy.readQuery({
              query: candidateWithApplicationsQuery,
              variables: { organizationPk, candidatePk },
            });

            proxy.writeQuery({
              query: candidateWithApplicationsQuery,
              variables: { organizationPk, candidatePk },
              data: {
                ...candidateQueryData,
                viewer: {
                  ...candidateQueryData.viewer,
                  candidate: {
                    ...candidateQueryData.viewer.candidate,
                    modifiedAt:
                      changeApplicationAssignTo.application.modifiedAt,
                  },
                },
              },
            });
          },
        }),
    }),
    options: () => ({
      refetchQueries: [activitiesQuery],
    }),
  }),
)(AssignedTo);

import { useQuery } from '@apollo/client';

import useCurrentOrganization from 'hooks/useCurrentOrganization';

// TODO For NotificationsCounter we should add unreadCount and totalCount to the connection NotificationConnection
// on the backend and only read that data
import NOTIFICATIONS_QUERY from './notificationsQuery.gql';

export default function useNotificationsQuery({ DEFAULT_ORDERING }) {
  const [organizationPk] = useCurrentOrganization();
  const { data, loading } = useQuery(NOTIFICATIONS_QUERY, {
    variables: { organizationPk, ordering: DEFAULT_ORDERING },
    fetchPolicy: 'cache-and-network',
  });

  return [data, loading];
}

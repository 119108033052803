import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'i18n';
import Link from 'components/Link';

import { nl2br } from 'utils/text';
import Activity from './Activity';

function BulkEmail({
  createdAt,
  createdBy,
  bulkEmail,
  emailsCount,
  onClick,
  ...props
}) {
  return (
    <Activity
      {...props}
      createdBy={createdBy}
      createdAt={createdAt}
      icon="send"
      action={
        <Trans i18nKey="bulkEmailActivityHeader" count={emailsCount}>
          sent
          <Link onClick={onClick} to={`/emailing/${bulkEmail.id}`}>
            {{ count: emailsCount }} email
          </Link>
        </Trans>
      }
      detailsExcerpt={bulkEmail.emailTemplate.title}
      details={<div>{nl2br(bulkEmail.emailTemplate.content)}</div>}
    />
  );
}

BulkEmail.propTypes = {
  createdBy: PropTypes.object,
  createdAt: PropTypes.string,
  bulkEmail: PropTypes.object,
  emailsCount: PropTypes.number,

  // fired whenever user clicks the activity and is navigated to the link to the activities page,
  // e.g. used to set notification as read
  onClick: PropTypes.func,
};

export default BulkEmail;

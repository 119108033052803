import log from 'utils/log';

export const getUsername = (user) => {
  if (!user) {
    return null;
  }

  const { firstName, lastName, email } = user;

  if (firstName || lastName) {
    return `${firstName} ${lastName}`;
  }

  if (email) {
    return email;
  }

  log("User w/o email. Shouldn't happen.", user);

  return null;
};

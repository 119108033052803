import { camelCase } from 'lodash-es';
import { UTM_PARAM_NAMES } from 'hooks/useSaveUTMs';

/**
 * Retrieves utm parameters from local storage
 * @returns {object} - object containing utm parameters
 */
export function getUTMs() {
  return UTM_PARAM_NAMES.reduce(
    (acc, paramName) => ({
      ...acc,
      [camelCase(paramName)]: localStorage.getItem(paramName),
    }),
    {},
  );
}

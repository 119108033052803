import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { Alert } from 'antd';
import FullscreenMessage from 'components/FullscreenMessage';
import { logout } from 'apollo/client';

import styles from './index.less';

class Logout extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
  };

  state = {
    error: '',
    success: false,
  };

  componentDidMount() {
    logout();
  }

  render() {
    const { t } = this.props;
    const { error, success } = this.state;

    return (
      <div>
        {error && (
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            className={styles.alert}
          />
        )}
        {success && (
          <FullscreenMessage ctaText={t('loginBackLabel')} url="/login">
            {t('signedOutSuccessfully')}
            <iframe
              title="logout"
              src="/api/accounts/logout"
              className={styles.logoutIframe}
            />
          </FullscreenMessage>
        )}
      </div>
    );
  }
}

export default withTranslation('translation')(Logout);

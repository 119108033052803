import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import useMembership from 'hooks/useMembership';

function Title({ children, withOrganizationTitle }) {
  const [membership] = useMembership();

  return (
    <Helmet titleTemplate="%s | Workate">
      <title>
        {withOrganizationTitle && membership && membership.organization
          ? `${children} | ${membership.organization.name}`
          : `${children}`}
      </title>
    </Helmet>
  );
}

Title.propTypes = {
  children: PropTypes.node,
  withOrganizationTitle: PropTypes.bool,
};

export default Title;

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';

import Input from 'components/Form/Input';
import Field from 'components/Form/Field';
import Modal from 'components/Modal';
import confirmDeleteModal from 'utils/confirmDeleteModal';

import useAddComment from './useAddComment';

import styles from './CommentModal.less';

function CommentModal({ application, candidatePk, isFormOpen, onFormClose }) {
  const [msgText, setMsgText] = useState('');

  useEffect(() => {
    // form should be closed every time component is rendered in different application
    onFormClose();
  }, [onFormClose, application]);

  const { onCommentSend, fieldErrors, clearFieldError, isSaving } =
    useAddComment({ application, candidatePk, onFormClose, setMsgText });

  const onClose = useCallback(() => {
    onFormClose();
    setMsgText('');
    clearFieldError('content');
  }, [onFormClose, setMsgText, clearFieldError]);

  const { t } = useTranslation();

  // If user leaves some text, display the "confirm close" modal,
  // before closing the form to prevent accidental loss of content.
  const onCancel = useCallback(() => {
    if (msgText.trim() === '') {
      onClose();
    } else {
      confirmDeleteModal(t('Comment_confirmClose'), onClose);
    }
  }, [msgText, onClose, t]);

  const onTextInputChange = useCallback(
    (e) => {
      setMsgText(e.target.value);
      clearFieldError('content');
    },
    [setMsgText, clearFieldError],
  );

  const onFormSubmit = useCallback(() => {
    onCommentSend(msgText);
  }, [onCommentSend, msgText]);

  const isSaveButtonDisabled = useMemo(
    () => isSaving || !msgText,
    [isSaving, msgText],
  );

  const onEnter = useCallback(
    (e) => {
      if (!isSaveButtonDisabled && e.metaKey) {
        onFormSubmit();
      }
    },
    [isSaveButtonDisabled, onFormSubmit],
  );

  return (
    isFormOpen && (
      <Modal
        visible={isFormOpen}
        onOk={onFormSubmit}
        onCancel={onCancel}
        okDisabled={isSaveButtonDisabled}
        okText={t('comment')}
        okDataRole="button-add-comment"
        maskClosable={false}
        title={t('Comment_addForm--title')}
      >
        <Field error={fieldErrors.content} className={styles.textField}>
          <Input
            value={msgText}
            onChange={onTextInputChange}
            disabled={isSaving}
            textarea
            data-testid="application-textarea"
            data-role="textarea-comment-text"
            autoSize={{ minRows: 3 }}
            onPressEnter={onEnter}
          />
        </Field>
      </Modal>
    )
  );
}

CommentModal.propTypes = {
  application: PropTypes.object,
  candidatePk: PropTypes.string,
  isFormOpen: PropTypes.bool,
  onFormClose: PropTypes.func,
};

export default CommentModal;

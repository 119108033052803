import React from 'react';
import { useTranslation } from 'i18n';

import {
  THEME_DEFAULT,
  THEME_LIVEMOREPOMERANIA,
  COMPONENT_SIGNUP_BOX,
  COMPONENT_FOOTER,
  COMPONENT_SIGNIN_TITLE,
} from 'consts/theme';
import { getTheme } from 'utils/theme';

import LiveMoreFooter from 'components/theme/livemorepomerania/Footer';

export const getThemeComponents = (t) => ({
  [THEME_DEFAULT]: {
    [COMPONENT_SIGNUP_BOX]: true,
    [COMPONENT_FOOTER]: null,
  },
  [THEME_LIVEMOREPOMERANIA]: {
    [COMPONENT_SIGNIN_TITLE]: t('livemorepomerania_signIn'),
    [COMPONENT_FOOTER]: <LiveMoreFooter />,
  },
});

/**
 * Hook for requesting theme components.
 *
 * @returns a list of components that be displayed for the current theme (boolean, object, string etc.)
 *
 * @export
 * @param {Array} requestedComponents list of components to check against
 *
 * @example
 *
 * const [hasSignupBox, welcomeTitle] = useThemeComponents(COMPONENT_SIGNUP_BOX, COMPONENT_SIGNIN_TITLE);
 */
export default function useThemeComponents(...requestedComponents) {
  const { t } = useTranslation();
  const themeComponents = getThemeComponents(t);
  const currentThemeComponents = themeComponents[getTheme()];

  return requestedComponents.map(
    (component) => currentThemeComponents[component] || null,
  );
}

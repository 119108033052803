import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'i18n';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import useBrowserWidth from 'hooks/useBrowserWidth';
import usePermissions from 'hooks/usePermissions';

import { MD } from 'consts/media';
import { PERMISSION_EMAIL_TEMPLATES_MANAGE } from 'consts/permissions';

import { nl2br } from 'utils/text';
import confirmDeleteModal from 'utils/confirmDeleteModal';
import MoreMenu from 'components/MoreMenu';

import EmailTemplateFormModal from '../EmailTemplateForm/EmailTemplateFormModal';

import styles from './EmailTemplateElement.less';

function EmailTemplateElement({
  isElementEdited,
  toggleIsElementEdited,
  title,
  content,
  fieldErrors,
  clearFieldError,
  onEditEmailTemplate,
  isEditEmailTemplateSaving,
  onDeleteEmailTemplate,
}) {
  const browserWidth = useBrowserWidth();
  const { t } = useTranslation();

  const [hasEmailTemplatesManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_EMAIL_TEMPLATES_MANAGE]);

  const getMoreMenu = () => [
    {
      key: 'edit',
      iconComponent: EditOutlined,
      content: t('edit_emailTemplate'),
      dataRole: 'edit',
    },
    {
      key: 'delete',
      iconComponent: DeleteOutlined,
      content: t('delete_emailTemplate'),
      dataRole: 'delete',
    },
  ];

  const isTouchScreen = !!('ontouchstart' in document.documentElement);

  return (
    <div className={styles.templateWrapper}>
      {isElementEdited && (
        <EmailTemplateFormModal
          onSubmit={onEditEmailTemplate}
          onCancel={toggleIsElementEdited}
          fieldErrors={fieldErrors}
          clearFieldError={clearFieldError}
          isSaving={isEditEmailTemplateSaving}
          title={title}
          content={content}
        />
      )}
      <div className={styles.templateContainer}>
        <div
          className={styles.templateTitleBar}
          data-testid="emailTemplateElement"
        >
          <div className={styles.templateTitle}>{title}</div>
          <div
            className={cx(
              styles.menuWrapper,
              (isTouchScreen || browserWidth <= MD) && styles.visible,
            )}
          >
            <MoreMenu
              items={getMoreMenu()}
              onItemClick={
                hasEmailTemplatesManagePermission
                  ? (key) => {
                      if (key === 'delete') {
                        confirmDeleteModal(
                          `${t('EmailTemplate_delete')} "${title}"`,
                          onDeleteEmailTemplate,
                        );
                      } else if (key === 'edit') {
                        toggleIsElementEdited();
                      }
                    }
                  : showNoPermissionMessage
              }
              menuTitle={title}
            />
          </div>
        </div>
        <div className={styles.templateText}>{nl2br(content)}</div>
      </div>
    </div>
  );
}

EmailTemplateElement.propTypes = {
  isElementEdited: PropTypes.bool,
  toggleIsElementEdited: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  fieldErrors: PropTypes.object,
  clearFieldError: PropTypes.func,
  onEditEmailTemplate: PropTypes.func,
  isEditEmailTemplateSaving: PropTypes.bool,
  onDeleteEmailTemplate: PropTypes.func,
};

export default memo(EmailTemplateElement);

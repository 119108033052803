import moment from 'moment';

const DATE_TYPES = {
  long: 'LLL',
  short: 'L',
};

export function formatDate(date, type = 'long') {
  return moment(date).format(DATE_TYPES[type]);
}

export function fromNow(date) {
  return moment(date).subtract(60, 'seconds').fromNow();
}

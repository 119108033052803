import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { url } from 'utils/api';

const DOMAIN_TO_LNG = {
  'workate.com': 'en',
  'workate.pl': 'pl',
  'workate.com.localhost': 'en',
  'workate.pl.localhost': 'pl',
};

const lngDetector = new LanguageDetector();
const domainDetector = {
  name: 'domain',

  lookup() {
    const domain = Object.keys(DOMAIN_TO_LNG).find((o) =>
      window.location.hostname.match(o),
    );
    return DOMAIN_TO_LNG[domain] || 'pl';
  },
};
lngDetector.addDetector(domainDetector);

i18n
  .use(HttpApi)
  .use(lngDetector)
  .init({
    backend: {
      loadPath: url('/locales/resources/{{lng}}/{{ns}}.json'),
      addPath: url('/locales/add/{{lng}}/{{ns}}'),
      allowMultiLoading: false,
      customHeaders: {
        'x-origin': window.location.origin,
      },
    },
    detection: {
      order: ['localStorage', 'navigator', 'domain'],
      lookupQuerystring: 'lng',
      lookupLocalStorage: 'lng',
    },
    supportedLngs: ['pl', 'en'],
    saveMissing: true,
    saveMissingTo: 'all',
    // fallbackLng: 'en',
    // debug: config.IS_DEVELOPMENT,
    interpolation: {
      escapeValue: false,
    },
    initImmediate: true,
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

export {
  Trans,
  withTranslation,
  useTranslation,
  I18nextProvider,
} from 'react-i18next';
export default i18n;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import useOrganizationStageLists from 'hooks/useOrganizationStageLists';
import Loader from 'components/Loader';
import StageListSelect from './StageListSelect';

export const MANAGE_STAGE_LIST = 'MANAGE_STAGE_LIST';

function StageListSelectContainer({ onSelectStageList, stageListPk }) {
  const { stageLists, loading } = useOrganizationStageLists();

  const onSelect = useCallback(
    ({ pk }) => {
      onSelectStageList(pk);
    },
    [onSelectStageList],
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <StageListSelect
      stageListPk={stageListPk}
      stageLists={stageLists}
      onSelect={onSelect}
      isDataLoading={loading}
    />
  );
}

StageListSelectContainer.propTypes = {
  onSelectStageList: PropTypes.func,
  stageListPk: PropTypes.string,
};

export default StageListSelectContainer;

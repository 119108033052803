import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Form/Select';
import cx from 'classnames';
import { withTranslation } from 'i18n';
import styles from './index.less';

export const Option = Select.Option;

const generateStageIcons = (stages, idx) =>
  stages.map((o, i) => (
    <div key={o.pk} className={cx(styles.dot, i <= idx && styles.filled)} />
  ));

function StageSelect({
  stages,
  className,
  selected,
  displayCards,
  nonChangeable,
  onChange,
  t,
  popupContainerRef,
  disabled,
  ...rest
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (val) => {
    setIsLoading(true);
    await onChange(val);
    setIsLoading(false);
  };

  const selectedStage = stages.find((o) => o.pk === selected);
  const selectedIndex = stages.findIndex((o) => o.pk === selected);

  return nonChangeable ? (
    <div className={cx(className, styles.label)}>
      {selectedStage && (
        <>
          <div>{selectedStage.name}</div>
          <div className={styles.stageDots}>
            {generateStageIcons(stages, selectedIndex)}
          </div>
        </>
      )}
    </div>
  ) : (
    <Select
      data-testid={rest['data-testid'] || ''}
      selected={selected}
      className={cx(
        styles.stageSelect,
        className,
        displayCards && styles.cardStageSelect,
      )}
      dropdownClassName={styles.dropdown}
      value={stages.length > 0 && selected}
      choices={stages.map(({ pk, name }, i) => ({
        value: pk,
        optionElement: (
          <div className={styles.label}>
            <div>{t(name)}</div>
            <div className={styles.stageDots}>
              {generateStageIcons(stages, i)}
            </div>
          </div>
        ),
      }))}
      withBorders={false}
      placeholder={t('placeholderStage')}
      popupContainer={popupContainerRef}
      onChange={handleChange}
      disabled={disabled || isLoading}
      {...rest}
    />
  );
}

StageSelect.propTypes = {
  className: PropTypes.string,
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  selected: PropTypes.string,
  displayCards: PropTypes.bool,
  nonChangeable: PropTypes.bool,
  t: PropTypes.func,
  popupContainerRef: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default withTranslation('translation')(StageSelect);

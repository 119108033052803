import { useEffect } from 'react';

/**
 * Hook for moving focus to DOM element with ref={elementRef} defined
 * if the 'check' condition is met
 * @param {Object} elementRef
 * @param {boolean} check
 *
 * @example
 * const [isChecked, setChecked] = useState();
 * const menuRef = useRef(null);
 * useFocusEffect(menuRef, isChecked);
 * ...
 * return <Menu ref={menuRef} />
 */

const useFocusEffect = (elementRef, check) => {
  useEffect(() => {
    if (check) {
      const timer = setTimeout(() => {
        // remember window position as focus function cause unexpected window scrolling
        const x = window.scrollX;
        const y = window.scrollY;
        // move focus
        elementRef.current.focus();
        // force scrolling to start position
        window.scrollTo(x, y);
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [check, elementRef]);
};

export default useFocusEffect;

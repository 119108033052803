import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'utils/withRouter';
import { compose } from 'apollo';
import { logout } from 'apollo/client';
import graphqlErrorFieldsMutation from 'hoc/graphqlErrorFieldsMutation';
import PropTypes from 'prop-types';
import {
  ArrowRightOutlined,
  IdcardOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { Alert } from 'antd';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Checkbox from 'components/Form/Checkbox';
import Logo from 'components/Logo';
import Loader from 'components/Loader';
import LanguageSelect from 'components/LanguageSelect';
import { SUPPORTED_LANGUAGES } from 'components/LanguageSelect/SupportedLanguages';
import i18n, { withTranslation, Trans } from 'i18n';

import { validator, isRequired, checkEmail } from 'utils/validator';
import { logAndShowGenericError } from 'utils/log';
import { track } from 'utils/track';
import { getTheme } from 'utils/theme';
import { THEME_LIVEMOREPOMERANIA } from 'consts/theme';
import get from 'lodash-es/get';
import * as config from 'config';
import { RECAPTCHA_SITE_KEY } from 'config';
import styles from './index.less';
import registerUserMutation from './registerUserMutation.gql';

const VALIDATE_FIELDS = {
  email: [isRequired, checkEmail],
  password1: [isRequired],
  isAgree: [isRequired],
};

const getValidationErrors = validator(VALIDATE_FIELDS);

class Register extends PureComponent {
  static propTypes = {
    generalError: PropTypes.string,
    fieldErrors: PropTypes.object.isRequired,
    mutate: PropTypes.func.isRequired,
    setFieldErrors: PropTypes.func.isRequired,
    clearFieldError: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    invitationDetails: PropTypes.shape({
      pk: PropTypes.string,
      email: PropTypes.string,
      organizationName: PropTypes.string,
    }),
    isInvitationDetailsLoading: PropTypes.bool,
  };

  state = {
    email: '',
    password1: '',
    isAgree: false,
  };

  register(recaptchaToken = '') {
    const { mutate, navigate, setFieldErrors, invitationDetails } = this.props;
    const { email, password1, isAgree } = this.state;
    const validationErrors = getValidationErrors({
      email: get(invitationDetails, 'email', email),
      password1,
      isAgree,
    });
    const language = i18n.language;

    if (Object.keys(validationErrors).length > 0) {
      return setFieldErrors(validationErrors);
    }
    mutate({
      variables: {
        email: get(invitationDetails, 'email', email),
        password1,
        invitationHash: get(invitationDetails, 'pk'),
        language: SUPPORTED_LANGUAGES.find((l) => l.code === language).enum,
        recaptchaToken,
      },
    })
      .then(({ data, ok }) => {
        if (ok) {
          track('registered');
          navigate('/');
        } else if (data.registerUser.errors) {
          setFieldErrors(data.registerUser.errors);
        }
      })
      .catch(
        logAndShowGenericError('Register rejected', {
          props: this.props,
          state: this.state,
        }),
      );
  }

  onSubmit = () => {
    if (config.SKIP_CAPTCHA) {
      this.register();
    } else {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(RECAPTCHA_SITE_KEY, {
            action: 'submit',
          })
          .then((recaptchaToken) => {
            this.register(recaptchaToken);
          });
      });
    }
  };

  componentDidMount() {
    logout(false);
  }

  onInputChange = (input) => (e) => {
    this.props.clearFieldError(input);
    this.setState({
      [input]: input === 'isAgree' ? e.target.checked : e.target.value,
    });
  };

  render() {
    const {
      t,
      generalError,
      fieldErrors,
      invitationDetails,
      isInvitationDetailsLoading,
      params,
    } = this.props;
    const { email, password1, isAgree } = this.state;

    const invitationEmail = get(invitationDetails, 'email');
    const organizationName = get(invitationDetails, 'organizationName');

    if (isInvitationDetailsLoading) {
      return <Loader fullSize />;
    }

    const termsUrl =
      getTheme() === THEME_LIVEMOREPOMERANIA
        ? 'https://workate.pl/regulamin-arp/'
        : 'https://workate.pl/regulamin/';

    return (
      <div className={styles.container}>
        {generalError && (
          <Alert
            message={generalError}
            type="error"
            showIcon
            closable
            className={styles.alert}
          />
        )}
        {params.id && !invitationDetails ? (
          <div className={styles.invalidInvitation}>
            <Trans i18nKey="invalidInvitation">
              This invitation is invalid or has expired.
              <br />
              Please contact your Organization Manager or
              <br />
              <Link to="/register" className={styles.logInLink}>
                create new organization <ArrowRightOutlined />
              </Link>
            </Trans>
          </div>
        ) : (
          <div className={styles.content}>
            <h2 className={styles.loginTitle}>
              {invitationDetails
                ? t('signUpToWorkate_withInvitation')
                : t('signUpToWorkate')}
            </h2>
            <div className={styles.formContainer}>
              {invitationDetails && (
                <div className={styles.invitationWelcomeText}>
                  <Trans
                    i18nKey="invitationWelcomeText"
                    organizationName={organizationName}
                  >
                    It seems that you've been invited to join{' '}
                    {{ organizationName }} at Workate! Just enter your password
                    and you're good to go!
                  </Trans>
                </div>
              )}
              <Form onFinish={this.onSubmit}>
                <Field error={fieldErrors.email} className={styles.formField}>
                  <Input
                    data-testid="email"
                    value={invitationDetails ? invitationEmail : email}
                    placeholder={t('loginEmailPlaceholder')}
                    prefix={<IdcardOutlined className={styles.icon} />}
                    onChange={this.onInputChange('email')}
                    popupLabel
                    disabled={!!invitationDetails}
                    type="email"
                  />
                </Field>
                <Field error={fieldErrors.password1}>
                  <Input
                    data-testid="password"
                    value={password1}
                    placeholder={t('loginPasswordPlaceholder')}
                    prefix={<LockOutlined className={styles.icon} />}
                    onChange={this.onInputChange('password1')}
                    type="password"
                    className={styles.formField}
                    popupLabel
                  />
                </Field>
                <Field error={fieldErrors.isAgree}>
                  <Checkbox
                    data-testid="terms"
                    className={styles.checkbox}
                    checked={isAgree}
                    onChange={this.onInputChange('isAgree')}
                  >
                    <Trans i18nKey="agreeToTermsAndConditions">
                      I agree to workate's{' '}
                      <a
                        href={termsUrl}
                        className={styles.termsLink}
                        target="_blank"
                      >
                        terms and conditions
                      </a>{' '}
                      and{' '}
                      <a
                        href="https://workate.pl/polityka-prywatnosci/"
                        className={styles.termsLink}
                        target="_blank"
                      >
                        privacy policy
                      </a>
                    </Trans>
                  </Checkbox>
                </Field>
                <div className={styles.formActionBox}>
                  <Button
                    data-testid="submit"
                    type="primary"
                    htmlType="submit"
                    className={styles.registerBtn}
                  >
                    {t('register')}
                  </Button>
                </div>
              </Form>
            </div>
            {!invitationDetails ? (
              <div className={styles.backToLogin}>
                <Trans i18nKey="backToLogin">
                  Already have an account?
                  <Link to="/login" className={styles.logInLink}>
                    Log in <ArrowRightOutlined />
                  </Link>
                </Trans>
              </div>
            ) : null}
          </div>
        )}
        <div>
          <LanguageSelect theme="transparent" />
        </div>
        <Logo className={styles.logo} />
      </div>
    );
  }
}

export default compose(
  withRouter,
  withTranslation('translation'),
  graphqlErrorFieldsMutation(registerUserMutation),
)(Register);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { graphql, compose } from 'apollo';
import { withTranslation } from 'i18n';
import { logAndShowGenericError } from 'utils/log';
import withOrganizationPk from 'hoc/withOrganizationPk';
import ContactDetails from 'pages/candidates/CandidateDetails/ContactDetails';
import graphqlErrorFieldsMutation from 'hoc/graphqlErrorFieldsMutation';
import {
  validator,
  isRequired,
  emailOrPhoneOrUrl,
  getContactType,
} from 'utils/validator';
import { gql } from '@apollo/client';
import { evolve } from 'ramda';
import deleteContactMutation from './deleteContactMutation.gql';
import addContactDetailsMutation from './addContactDetailsMutation.gql';

const CANDIDATE_CONTACT_DETAILS_FRAGMENT = gql`
  fragment CandidateFragment on CandidateTypeNode {
    contact {
      id
      pk
      contactType
      content
    }
  }
`;

const VALIDATE_FIELDS = {
  contactContent: [isRequired, emailOrPhoneOrUrl],
};

const getValidationErrors = validator(VALIDATE_FIELDS);

class ContactDetailsContainer extends PureComponent {
  static propTypes = {
    contactDetails: PropTypes.array,
    candidate: PropTypes.object,
    applications: PropTypes.array,
    t: PropTypes.func.isRequired,
    deleteContact: PropTypes.func.isRequired,
    organizationPk: PropTypes.string.isRequired,
    candidatePk: PropTypes.string.isRequired,
    addContactDetails: PropTypes.object.isRequired,
  };

  state = {
    isFormDisplayed: false,
    contactContent: '',
  };

  static getDerivedStateFromProps(props, state) {
    if (state.candidatePk !== props.candidatePk) {
      return {
        isFormDisplayed: false,
        contactContent: '',
        candidatePk: props.candidatePk,
      };
    }
    return null;
  }

  onInputChange = (e) => {
    this.setState({
      contactContent: e.target.value,
    });

    this.props.addContactDetails.clearFieldError('contactContent');
    message.destroy();
  };

  onSubmit = () => {
    const { candidatePk, organizationPk, t, addContactDetails } = this.props;
    const { contactContent } = this.state;
    const { setFieldErrors, mutate } = addContactDetails;
    const validationErrors = getValidationErrors({ contactContent });

    if (Object.keys(validationErrors).length > 0) {
      return setFieldErrors(validationErrors);
    }
    mutate({
      variables: { candidatePk, contactContent, organizationPk },
      optimisticResponse: {
        addContactDetailsToCandidate: {
          __typename: 'AddContactDetailsMutation',
          errors: null,
          contactDetails: {
            __typename: 'ContactDetailsNode',
            id: '-1',
            pk: '-1',
            contactType: getContactType(contactContent),
            content: contactContent,
          },
        },
      },
    })
      .then(
        ({
          data: {
            addContactDetailsToCandidate: { errors },
          },
        }) => {
          if (errors) {
            message.error(t('invalidContactInput'), 0);
            validationErrors.contactContent = t(errors.fields.content);
            this.setState({
              isFormDisplayed: true,
            });
            return setFieldErrors(validationErrors);
          }
        },
      )
      .catch(
        logAndShowGenericError('addContactDetails rejected', {
          props: this.props,
          state: this.state,
        }),
      );

    this.setState({
      contactContent: '',
      isFormDisplayed: false,
    });
  };

  onCancel = () => {
    this.setState({
      contactContent: '',
      isFormDisplayed: false,
    });

    this.props.addContactDetails.clearFieldError('contactContent');
    message.destroy();
  };

  onAddClick = () => {
    this.setState({
      isFormDisplayed: true,
    });
  };

  render() {
    const {
      addContactDetails: { fieldErrors },
      contactDetails,
      candidate,
      applications,
    } = this.props;
    return (
      <ContactDetails
        {...this.state}
        onInputChange={this.onInputChange}
        onSubmit={this.onSubmit}
        onCancel={this.onCancel}
        onAddClick={this.onAddClick}
        deleteContact={this.props.deleteContact}
        fieldErrors={fieldErrors}
        contactDetails={contactDetails}
        candidate={candidate}
        applications={applications}
      />
    );
  }
}

export default compose(
  withOrganizationPk,
  withTranslation('translation'),
  graphqlErrorFieldsMutation(
    addContactDetailsMutation,
    {
      options: ({ candidatePk }) => ({
        update: (proxy, response) => {
          proxy.updateFragment(
            {
              id: proxy.identify({
                __typename: 'CandidateTypeNode',
                pk: candidatePk,
              }),
              fragment: CANDIDATE_CONTACT_DETAILS_FRAGMENT,
            },
            evolve({
              contact: (contactDetails) => [
                ...contactDetails,
                response.data.addContactDetailsToCandidate.contactDetails,
              ],
            }),
          );
        },
      }),
    },
    'addContactDetails',
  ),
  graphql(deleteContactMutation, {
    props: ({ mutate, ownProps: { candidatePk, organizationPk } }) => ({
      deleteContact: (contactPk) =>
        mutate({
          variables: { contactPk, organizationPk },
          optimisticResponse: {
            deleteContactDetails: {
              errors: null,
              status: 'OK',
              __typename: 'DeleteContactMutation',
            },
          },
          update: (proxy) => {
            proxy.updateFragment(
              {
                id: proxy.identify({
                  __typename: 'CandidateTypeNode',
                  pk: candidatePk,
                }),
                fragment: CANDIDATE_CONTACT_DETAILS_FRAGMENT,
              },
              evolve({
                contact: (contactDetails) =>
                  contactDetails.filter(({ pk }) => pk !== contactPk),
              }),
            );
          },
        }),
    }),
  }),
)(ContactDetailsContainer);

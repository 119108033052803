import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'apollo';

import withOrganizationPk from 'hoc/withOrganizationPk';
import { MAP_STAGES } from 'consts/activateOrChangePlanStages';
import { PLAN_TYPES } from 'consts/planTypes';
import log, { logAndShowGenericError } from 'utils/log';

import hiddenApplicationsQuery from 'pages/candidates/CandidatesList/hiddenApplicationsQuery.gql';
import candidatesQuery from 'pages/candidates/CandidatesList/candidatesQuery.gql';
import jobPositionsQuery from 'pages/candidates/AddApplication/jobPositionsQuery.gql';

import { evolve } from 'ramda';
import ChangePlanModal from './ChangePlanModal';
import changeJobPositionIsActiveMutation from './changeJobPositionIsActiveMutation.gql';
import billingInformationQuery from './billingInformationQuery.gql';
import changeOrganizationMaxCredits from './changeOrganizationMaxCredits.gql';
import activeJobPositionsCountQuery from './activeJobPositionsCountQuery.gql';

class ChangePlanModalContainer extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    initialStage: PropTypes.oneOf(['activate', 'deactivate', 'changePlan']),
    toggleModalVisible: PropTypes.func.isRequired,
    isModalVisible: PropTypes.bool,
    jobPositionPk: PropTypes.string,
    // From HoCs
    billing: PropTypes.object.isRequired,
    changeJobPositionIsActive: PropTypes.func.isRequired,
    changeOrganizationMaxCredits: PropTypes.func.isRequired,
    activeJobPositionsCount: PropTypes.number,
  };

  static getDerivedStateFromProps(props, state) {
    let modalStage;
    let initialStageState;
    let newCreditsState;

    if (
      /**
       * Check if the initialStage was already set OR if the props have changed.
       * This second condition allows to update the non active instances after mutation
       * Also check if the number of availableCredits and active jobPositions
       * was successfully fetched from the API
       */
      (!state.isInitialStageSet ||
        (!props.isModalVisible &&
          props.billing.monthlyCredits !== state.newSubscriptionCredits)) &&
      Number.isInteger(props.billing.availableCredits) &&
      Number.isInteger(props.activeJobPositionsCount)
    ) {
      const isJobPositionUsed = !!props.billing.creditUsageList.find(
        ({ jobPositionPk }) => jobPositionPk === props.jobPositionPk,
      );

      // Allow for one active jobPosition in free plan
      if (
        props.initialStage === MAP_STAGES.ACTIVATE &&
        props.billing.planType === PLAN_TYPES.FREE &&
        props.activeJobPositionsCount < 1
      ) {
        modalStage = MAP_STAGES.ACTIVATE;
        /**
         * Display "activate" card when organization has available credits
         * or if this jobPosition has already been active in current subscription
         */
      } else if (
        props.initialStage === MAP_STAGES.ACTIVATE &&
        (props.billing.availableCredits > 0 || isJobPositionUsed)
      ) {
        modalStage = MAP_STAGES.ACTIVATE;
        /**
         * If the button is set to activate jobPosition, but the organization doesn't have any availableCredits
         * Users will be redirected to a card where they can upgrade the plan
         */
      } else if (
        props.initialStage === MAP_STAGES.ACTIVATE &&
        props.billing.availableCredits === 0
      ) {
        modalStage = MAP_STAGES.UPGRADE;
      } else if (props.initialStage === MAP_STAGES.DEACTIVATE) {
        modalStage = MAP_STAGES.DEACTIVATE;
      } else {
        modalStage = MAP_STAGES.CHANGE_PLAN;
      }
    }

    if (modalStage) {
      initialStageState = {
        modalStage,
        isInitialStageSet: true,
      };
    }

    if (
      !state.isNewSubscriptionCreditsSet &&
      Number.isInteger(props.billing.monthlyCredits)
    ) {
      newCreditsState = {
        newSubscriptionCredits: props.billing.monthlyCredits,
        isNewSubscriptionCreditsSet: true,
      };
    }

    if (initialStageState || newCreditsState) {
      return {
        ...(initialStageState || {}),
        ...(newCreditsState || {}),
      };
    }

    return null;
  }

  state = {
    modalStage: '',
    newSubscriptionCredits: 0,
    isInitialStageSet: false,
    isNewSubscriptionCreditsSet: false,
    isDataSaving: false,
  };

  onCancel = () => {
    this.props.toggleModalVisible(false);
    this.setState({
      isInitialStageSet: false,
      isNewSubscriptionCreditsSet: false,
    });
  };

  onSliderChange = (value) => {
    this.setState({ newSubscriptionCredits: value });
  };

  handleError = (errors) => {
    log(errors.message, {
      fields: errors.fields,
      props: this.props,
      state: this.state,
    });
    this.setState({ modalStage: MAP_STAGES.ERROR });
  };

  onIsActiveToggle = (bool) => () => {
    this.setState({ isDataSaving: true });
    // bool = true activates jobPosition, bool = false deactivates it
    this.props
      .changeJobPositionIsActive(bool)
      .then(
        ({
          data: {
            changeJobPositionIsActive: { errors },
          },
        }) => {
          if (!errors) {
            this.props.toggleModalVisible(false);
          } else {
            this.handleError(errors);
          }
        },
      )
      .catch(
        logAndShowGenericError('changeJobPositionIsActive rejected', {
          props: this.props,
          state: this.state,
        }),
      )
      .finally(() =>
        this.setState({
          // Prevent resetting modal if there is an error
          isInitialStageSet: this.state.modalStage === MAP_STAGES.ERROR,
          isDataSaving: false,
        }),
      );
  };

  onUpgrade = () => {
    this.setState({ modalStage: MAP_STAGES.CHANGE_PLAN });
  };

  onPlanDecrease = () => {
    this.setState({ modalStage: MAP_STAGES.CONFIRM_CHANGE_PLAN });
  };

  onChangeMaxCredits = () => {
    this.setState({ isDataSaving: true });
    this.props
      .changeOrganizationMaxCredits(this.state.newSubscriptionCredits)
      .then(
        ({
          data: {
            changeOrganizationMaxCredits: { errors },
          },
        }) => {
          if (!errors) {
            this.setState({ modalStage: MAP_STAGES.THANK_YOU });
          } else {
            this.handleError(errors);
          }
        },
      )
      .catch(
        logAndShowGenericError('changeOrganizationMaxCredits rejected', {
          props: this.props,
          state: this.state,
        }),
      )
      .finally(() => this.setState({ isDataSaving: false }));
  };

  onPlanIncrease = () => {
    this.onChangeMaxCredits();
  };

  onConfirmChangePlan = () => {
    this.onChangeMaxCredits();
  };

  onThankYou = () => {
    if (this.props.initialStage === MAP_STAGES.ACTIVATE) {
      this.setState({ modalStage: MAP_STAGES.ACTIVATE });
    } else {
      this.onCancel();
    }
  };

  render() {
    return (
      <ChangePlanModal
        {...this.props}
        {...this.state}
        onCancel={this.onCancel}
        onSliderChange={this.onSliderChange}
        onIsActiveToggle={this.onIsActiveToggle}
        onUpgrade={this.onUpgrade}
        onPlanDecrease={this.onPlanDecrease}
        onPlanIncrease={this.onPlanIncrease}
        onConfirmChangePlan={this.onConfirmChangePlan}
        onThankYou={this.onThankYou}
      />
    );
  }
}

export default compose(
  withOrganizationPk,
  graphql(changeJobPositionIsActiveMutation, {
    options: ({ organizationPk }) => ({
      refetchQueries: [
        { query: hiddenApplicationsQuery, variables: { organizationPk } },
        { query: candidatesQuery, variables: { organizationPk } },
        { query: billingInformationQuery, variables: { organizationPk } },
        { query: jobPositionsQuery, variables: { organizationPk } },
      ],
    }),
    props: ({ mutate, ownProps: { organizationPk, jobPositionPk } }) => ({
      changeJobPositionIsActive: (isActive) =>
        mutate({
          variables: { organizationPk, jobPositionPk, isActive },
        }),
    }),
  }),
  graphql(billingInformationQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      billing: !loading && viewer ? viewer.organizations[0].billing : {},
      isVerified: !loading && viewer && viewer.organizations[0].isVerified,
    }),
  }),
  graphql(activeJobPositionsCountQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      activeJobPositionsCount:
        !loading &&
        viewer &&
        viewer.jobPositions &&
        viewer.jobPositions.edges.length,
    }),
  }),
  graphql(changeOrganizationMaxCredits, {
    props: ({ mutate, ownProps: { organizationPk } }) => ({
      changeOrganizationMaxCredits: (maxCredits) =>
        mutate({
          variables: { organizationPk, maxCredits },
          update: (
            proxy,
            {
              data: {
                changeOrganizationMaxCredits: { organization: billing },
              },
            },
          ) => {
            const query = billingInformationQuery;
            const variables = { organizationPk };

            const data = proxy.readQuery({ query, variables });

            proxy.writeQuery({
              query,
              variables,
              data: evolve(
                {
                  viewer: {
                    organizations: [
                      {
                        billing: () => billing,
                      },
                    ],
                  },
                },
                data,
              ),
            });
          },
        }),
    }),
  }),
)(ChangePlanModalContainer);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TeamMembers from 'pages/team-members';
import { compose, graphql } from 'apollo';
import { withTranslation } from 'i18n';
import { message } from 'antd';
import log, { logAndShowGenericError } from 'utils/log';
import withOrganizationPk from 'hoc/withOrganizationPk';
import graphqlErrorFieldsMutation from 'hoc/graphqlErrorFieldsMutation';
import { validator, isRequired, checkEmail } from 'utils/validator';
import { evolve } from 'ramda';
import membershipsQuery from './membershipsQuery.gql';
import invitationsQuery from './invitationsQuery.gql';
import inviteUserMutation from './inviteUserMutation.gql';
import deleteInvitationMutation from './deleteInvitationMutation.gql';
import rolesQuery from './rolesQuery.gql';

const VALIDATE_FIELDS = {
  newUserEmail: [isRequired, checkEmail],
  newUserRole: [isRequired],
};

const getValidationErrors = validator(VALIDATE_FIELDS);

class TeamMembersContainer extends PureComponent {
  static propTypes = {
    memberships: PropTypes.array.isRequired,
    isMembershipsLoading: PropTypes.bool.isRequired,
    invitations: PropTypes.array.isRequired,
    isInvitationsLoading: PropTypes.bool.isRequired,
    organizationPk: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    inviteUser: PropTypes.object.isRequired,
    deleteInvitation: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    isRolesLoading: PropTypes.bool.isRequired,
  };

  state = {
    isFormDisplayed: false,
    newUserEmail: '',
    newUserRole: undefined,
  };

  onInputChange = (e) => {
    this.setState({
      newUserEmail: e.target.value,
    });

    this.props.inviteUser.clearFieldError('newUserEmail');
  };

  onRoleSelection = (value) => {
    this.setState({
      newUserRole: value,
    });

    this.props.inviteUser.clearFieldError('newUserRole');
  };

  onAddClick = () => {
    this.setState({
      isFormDisplayed: true,
    });
  };

  onSubmit = () => {
    const { organizationPk, t, inviteUser } = this.props;
    const { newUserEmail, newUserRole } = this.state;
    const { setFieldErrors, mutate } = inviteUser;
    const validationErrors = getValidationErrors({ newUserEmail, newUserRole });

    if (
      this.props.invitations.some(
        (invitation) => invitation.email === newUserEmail,
      )
    ) {
      return setFieldErrors({ newUserEmail: t('invitationAlreadyExists') });
    }
    if (Object.keys(validationErrors).length > 0) {
      return setFieldErrors(validationErrors);
    }

    mutate({
      variables: { newUserEmail, organizationPk, newUserRole },
    })
      .then((data) => {
        if (data.ok) {
          this.setState({
            newUserEmail: '',
            newUserRole: undefined,
            isFormDisplayed: false,
          });
        } else {
          message.error(t('sendInvitationError'));
          log('Invitation status !== OK', { props: this.props, data });
        }
      })
      .catch(
        logAndShowGenericError('inviteUser rejected', {
          props: this.props,
          state: this.state,
        }),
      );
  };

  render() {
    const {
      memberships,
      isMembershipsLoading,
      invitations,
      isInvitationsLoading,
      inviteUser: { fieldErrors, isSaving },
      deleteInvitation,
      roles,
      isRolesLoading,
    } = this.props;

    return (
      <TeamMembers
        {...this.state}
        memberships={memberships}
        isMembershipsLoading={isMembershipsLoading}
        invitations={invitations}
        isInvitationsLoading={isInvitationsLoading}
        onInputChange={this.onInputChange}
        onRoleSelection={this.onRoleSelection}
        onAddClick={this.onAddClick}
        onSubmit={this.onSubmit}
        fieldErrors={fieldErrors}
        isInviteUserSaving={isSaving}
        deleteInvitation={deleteInvitation}
        roles={roles}
        isRolesLoading={isRolesLoading}
      />
    );
  }
}

export default compose(
  withOrganizationPk,
  withTranslation('translation'),
  graphql(membershipsQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      memberships: viewer && !loading ? viewer.memberships.edges : [],
      isMembershipsLoading: loading,
    }),
  }),
  graphql(rolesQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      roles: viewer && !loading ? viewer.organizations['0'].roles : [],
      isRolesLoading: loading,
    }),
  }),
  graphql(invitationsQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      invitations: viewer && !loading ? viewer.invitations : [],
      isInvitationsLoading: loading,
    }),
  }),
  graphqlErrorFieldsMutation(
    inviteUserMutation,
    {
      options: () => ({
        refetchQueries: [invitationsQuery, membershipsQuery],
      }),
    },
    'inviteUser',
  ),
  graphql(deleteInvitationMutation, {
    props: ({ mutate, ownProps: { organizationPk } }) => ({
      deleteInvitation: (invitationPk) =>
        mutate({
          variables: { invitationPk, organizationPk },
          optimisticResponse: {
            deleteInvitation: {
              errors: null,
              status: 'OK',
              __typename: 'DeleteInvitationMutation',
            },
          },
          update: (proxy) => {
            const variables = { organizationPk };
            const query = invitationsQuery;

            const data = proxy.readQuery({ query, variables });

            proxy.writeQuery({
              query,
              variables,
              data: evolve(
                {
                  viewer: {
                    invitations: (invitations) =>
                      invitations.filter(({ pk }) => pk !== invitationPk),
                  },
                },
                data,
              ),
            });
          },
        }),
    }),
  }),
)(TeamMembersContainer);

import React from 'react';
import PropTypes from 'prop-types';
import { MATERIAL_SYMBOLS_OUTLINED } from 'consts/materialSymbols';

function MaterialSymbolIcon({ value, size, color }) {
  return (
    <div>
      <span
        className={MATERIAL_SYMBOLS_OUTLINED}
        style={{ fontSize: `${size}px`, color }}
        data-testid={`icon-${value}`}
      >
        {value}
      </span>
    </div>
  );
}

MaterialSymbolIcon.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

export default MaterialSymbolIcon;

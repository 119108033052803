import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import Input from 'components/Form/Input';
import cx from 'classnames';
import styles from './ControlledInput.less';

// Compnent to handle custom controlled inputs in react-hook-form
function ControlledInput({ control, name, className, ...rest }) {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    field: { ref, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const errorMessage = error ? error.message : '';

  return (
    <div className={cx(styles.container, className)}>
      <Input {...inputProps} {...rest} className={error && styles.error} />
      {errorMessage && <p className={styles.errorText}>{errorMessage}</p>}
    </div>
  );
}

ControlledInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ControlledInput;

import { useTranslation } from 'i18n';
import React from 'react';
import { PERMISSION_USERS_MANAGE } from 'consts/permissions';
import useOrganizationUsers from 'hooks/useOrganizationUsers';

import styles from './NoPermissionMessage.less';

/**
 * Component containing message content about lack of required permission and list of managers of user's organization
 * It is displayed by calling showNoPermissionMessage function from utils
 * Function is by default exported within hook so there is no need to call it explicitly from utils
 *
 * It is a default way for blocking user from proceeding to execute the operation which he is not authorized to
 * thus should be used wherever possible
 */

function NoPermissionMessage() {
  const memberships = useOrganizationUsers();

  const managersList = memberships
    .filter((membership) =>
      membership.role.permissions.includes(PERMISSION_USERS_MANAGE),
    )
    .map((membership) => membership.user);

  const { t } = useTranslation();

  return (
    <div>
      {t('noPermissionMessage_managersList')}
      <ul>
        {managersList.map(({ email, firstName, lastName }) => (
          <li key={email}>
            <a href={`mailto:${email}`} target="_blank" className={styles.link}>
              {firstName} {lastName}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default NoPermissionMessage;

import { organizationPkVar } from 'apollo/state';

/**
 * Hook for changing User's active organization
 *
 * @returns function performing org change
 */
export default function useChangeOrganization() {
  return organizationPkVar;
}

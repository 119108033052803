import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';

export const BrowserWidthContext = React.createContext(0);

function BrowserWidthProvider({ children }) {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const updateWidth = () => setWidth(document.documentElement.clientWidth);
    const debouncedUpdateWidth = debounce(updateWidth, 150);

    updateWidth();
    window.addEventListener('resize', debouncedUpdateWidth);

    return () => window.removeEventListener('resize', debouncedUpdateWidth);
  }, []);

  return (
    <BrowserWidthContext.Provider value={width}>
      {children}
    </BrowserWidthContext.Provider>
  );
}

BrowserWidthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BrowserWidthProvider;

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import {
  CheckOutlined,
  CloseOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import cx from 'classnames';
import { PERMISSION_CANDIDATE_DETAILS_MANAGE } from 'consts/permissions';
import usePermissions from 'hooks/usePermissions';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import ContactDetail from './ContactDetail';
import styles from './index.less';

function ContactDetails({
  isFormDisplayed,
  contactContent,
  t,
  onInputChange,
  onSubmit,
  onCancel,
  onAddClick,
  deleteContact,
  fieldErrors,
  contactDetails,
}) {
  const [hasCandidateDetailsManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_CANDIDATE_DETAILS_MANAGE]);

  return (
    <div className={styles.contact}>
      <div className={styles.sectionTitle}>
        <span className={styles.sectionTitleText}>{t('contact')}</span>
        {!isFormDisplayed && (
          <Button
            iconOnly
            icon={
              <PlusCircleOutlined
                className={styles.addButton}
                data-testid="add-contact-details"
                aria-hidden
              />
            }
            onClick={
              hasCandidateDetailsManagePermission
                ? onAddClick
                : showNoPermissionMessage
            }
            aria-label="add-contact"
          />
        )}
      </div>
      <div className={styles.contactWrapper}>
        {contactDetails
          .filter((o) => o)
          .map((o) => (
            <ContactDetail
              contactType={o.contactType}
              content={o.content}
              contactPk={o.pk}
              deleteContact={deleteContact}
              key={o.id}
            />
          ))}
      </div>
      {isFormDisplayed && (
        <Form onFinish={onSubmit} className={styles.form}>
          <Field
            error={fieldErrors.contactContent}
            className={styles.formField}
          >
            <Input
              type="text"
              size="middle"
              placeholder={t('phoneOrEmailOrUrl')}
              value={contactContent}
              onChange={onInputChange}
              autoFocus
            />
          </Field>
          <button
            type="submit"
            className={cx(styles.formBtn, styles.submitBtn)}
            data-testid="submitContactDetails"
            aria-label="submit contact details"
          >
            <CheckOutlined aria-hidden />
          </button>
          <button
            type="button"
            className={cx(styles.formBtn, styles.cancelBtn)}
            onClick={onCancel}
            data-testid="cancelContactDetails"
            aria-label="cancel contact details"
          >
            <CloseOutlined aria-hidden />
          </button>
        </Form>
      )}
    </div>
  );
}

ContactDetails.propTypes = {
  isFormDisplayed: PropTypes.bool.isRequired,
  contactContent: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  deleteContact: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  contactDetails: PropTypes.array.isRequired,
};

export default withTranslation('translation')(ContactDetails);

import { useQuery } from '@apollo/client';
import landingPageQuery from './landingPageQuery.gql';

/**
 * hook for getting landing page data for current organization
 * @param {string} organizationPk - current organization Pk
 *@param {string} landingPagePk - current landingPage Pk
 */

const useLandingPage = ({ landingPagePk, organizationPk }) => {
  const { data, loading } = useQuery(landingPageQuery, {
    variables: { landingPagePk, organizationPk },
  });

  const landingPageData = data?.viewer?.landingPage;
  return { landingPageData, loading };
};

export default useLandingPage;

import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'apollo';
import moment from 'moment';
import { withRouter } from 'utils/withRouter';

import StageSelect from 'pages/candidates/Stage/StageSelect';

import withOrganizationPk from 'hoc/withOrganizationPk';

import log from 'utils/log';

import activitiesQuery from 'pages/candidates/ApplicationActivityFeed/activitiesQuery.gql';

import { gql } from '@apollo/client';
import allStagesQuery from './allStagesQuery.gql';
import changeApplicationStageMutation from './changeApplicationStageMutation.gql';

function Stage({ stagePk, stageList, ...rest }) {
  return <StageSelect {...rest} selected={stagePk} stages={stageList} />;
}

Stage.propTypes = {
  stagePk: PropTypes.string.isRequired,
  stageList: PropTypes.array.isRequired,
};

export default compose(
  withOrganizationPk,
  withRouter,
  graphql(allStagesQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: (props) => {
      const {
        data: { viewer, loading },
        ownProps: { stageListPk },
      } = props;

      let stageList = [];
      try {
        stageList =
          viewer && !loading
            ? viewer.allStageLists.find((o) => o.pk === stageListPk).stages
            : [];
      } catch (error) {
        log("Couldn't find allStageLists", { props, error });
      }

      return {
        stageList,
        isLoading: loading,
      };
    },
  }),
  graphql(changeApplicationStageMutation, {
    options: ({ organizationPk, applicationPk }) => ({
      refetchQueries: [
        {
          query: activitiesQuery,
          variables: { organizationPk, applicationPk },
        },
      ],
    }),
    props: ({
      mutate,
      ownProps: { applicationPk, organizationPk, applicationId, candidatePk },
    }) => ({
      onChange: (stagePk) =>
        mutate({
          variables: { applicationPk, organizationPk, stagePk },
          optimisticResponse: {
            __typename: 'Mutation',
            changeApplicationStage: {
              application: {
                pk: applicationPk,
                id: applicationId,
                modifiedAt: moment().format(),
                stage: {
                  pk: stagePk,
                  __typename: 'StageNode',
                },
                __typename: 'ApplicationNode',
              },
              errors: null,
              __typename: 'ChangeApplicationStageMutation',
            },
          },
          update: (cache, { data: { changeApplicationStage } }) => {
            cache.writeFragment({
              id: cache.identify({
                __typename: 'CandidateTypeNode',
                pk: candidatePk,
              }),
              fragment: gql`
                fragment CandidateModifiedFragment on CandidateTypeNode {
                  modifiedAt
                }
              `,
              data: {
                modifiedAt: changeApplicationStage.application.modifiedAt,
              },
            });
          },
        }),
    }),
  }),
)(Stage);

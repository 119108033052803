import { InMemoryCache, makeVar } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { sort } from 'ramda';

import storage from 'utils/storage';

import possibleTypes from '../../possibleTypes.json';

export const organizationPkVar = makeVar(storage.get('organizationPk') || null);

export const createCache = (...opts) =>
  new InMemoryCache({
    possibleTypes,
    typePolicies: {
      Viewer: {
        // Singleton type
        keyFields: [],
        fields: {
          allCandidates: relayStylePagination([
            'organizationPk',
            'candidateSearchFilterInput',
          ]),
        },
      },
      UserNode: {
        keyFields: ['pk'],
      },
      StageNode: {
        keyFields: ['pk'],
      },
      StageListNode: {
        keyFields: ['pk'],
      },
      CandidateTypeNode: {
        keyFields: ['pk'],
        fields: {
          contact: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
      ApplicationCommentNode: {
        keyFields: ['pk'],
      },
      OrganizationNode: {
        fields: {
          roles: {
            read(existing, { readField }) {
              return (
                existing &&
                sort(
                  (roleARef, roleBRef) =>
                    readField('permissions', roleARef).length -
                    readField('permissions', roleBRef).length,
                  existing,
                )
              );
            },
          },
        },
      },

      Query: {
        fields: {
          organizationPk: {
            read() {
              return organizationPkVar();
            },
          },
        },
      },
    },
    ...opts,
  });

export const appCache = createCache();

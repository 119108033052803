import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { message } from 'antd';

import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';
import useMembership from 'hooks/useMembership';
import { validator, isRequired } from 'utils/validator';
import { logAndShowGenericError } from 'utils/log';
import useCustomFieldValues from './useCustomFieldValues';
import customFieldValueFragment from './customFieldValueFragment.gql';
/**
 * Hook for filling (adding or editing) Custom Fields Values.
 * Validates entered data and updates related queries.
 */

const FILL_CUSTOM_FIELD_VALUE_MUTATION = gql`
  mutation FillCustomFieldValueMutation(
    $organizationPk: String!
    $applicationPk: String!
    $customFieldPk: Int!
    $value: String!
  ) {
    customFieldValueFill(
      organizationPk: $organizationPk
      applicationPk: $applicationPk
      customFieldPk: $customFieldPk
      value: $value
    ) {
      customFieldValue {
        ...customFieldValueFragment
      }
      errors {
        fields
        message
      }
    }
  }
  ${customFieldValueFragment}
`;

const VALIDATE_FIELDS = {
  value: [isRequired],
};

const useCustomFieldValueFill = ({
  customFieldPk,
  applicationPk,
  candidatePk,
  onDone,
}) => {
  const [
    {
      organization: { pk: organizationPk },
    },
  ] = useMembership();

  const [fillValue] = useMutation(FILL_CUSTOM_FIELD_VALUE_MUTATION);

  const { mutate, fieldErrors, setFieldErrors, clearFieldError, isSaving } =
    useErrorFieldsMutation(fillValue);

  const getValidationErrors = validator(VALIDATE_FIELDS);

  const { addCustomFieldValueUpdate } = useCustomFieldValues({
    applicationPk,
    candidatePk,
  });

  const onSubmit = useCallback(
    ({ customFieldValue: value, initialValue }) => {
      const validationErrors = getValidationErrors({
        value: value && value.trim(),
      });

      if (validationErrors) {
        return setFieldErrors(validationErrors);
      }

      mutate({
        variables: {
          organizationPk,
          applicationPk,
          customFieldPk,
          value,
        },
        update: (
          proxy,
          {
            data: {
              customFieldValueFill: { customFieldValue },
            },
          },
        ) => {
          /**
           * Update function is necessary only when adding new custom field value.
           * When editing element, Apollo automatically updates its value (mutation must return id).
           * Checking for initialValue gives us information about mutation type,
           * absence of initialValue means that element is added (not edited).
           */
          if (!initialValue) {
            addCustomFieldValueUpdate(customFieldValue);
          }
        },
      })
        .then(({ data, ok }) => {
          if (data.customFieldValueFill.errors) {
            return data.customFieldValueFill.errors.message
              ? message.error(data.customFieldValueFill.errors.message)
              : logAndShowGenericError('customFieldValueFill rejected', {
                  value,
                });
          }
          if (ok) {
            onDone();
          }
        })
        .catch(
          logAndShowGenericError('customFieldValueFill rejected', { value }),
        );
    },
    [
      setFieldErrors,
      getValidationErrors,
      organizationPk,
      applicationPk,
      customFieldPk,
      addCustomFieldValueUpdate,
      mutate,
      onDone,
    ],
  );

  return {
    onSubmit,
    fieldErrors,
    clearFieldError,
    isSaving,
  };
};

export default useCustomFieldValueFill;

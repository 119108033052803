// eslint-disable-next-line max-len
import MAP_TYPENAME_TO_COMPONENT from 'pages/notifications/NotificationsScreen/Notification/notificationsMapTypnameToComponent';

const selectSupportedNotifications = (notifications) => {
  if (notifications) {
    return notifications.filter(
      (notification) =>
        MAP_TYPENAME_TO_COMPONENT[notification.node.activity.__typename],
    );
  }
};

export default selectSupportedNotifications;

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';

import Content from 'components/Content';
import Loader from 'components/Loader/index';
import Title from 'components/Title';
import ActivityFeedContainer from 'components/ActivityFeed';
import InlineSVG from 'components/InlineSVG';

import peopleIllustration from './people.svg?inline';
import HomeSteps from './HomeSteps/HomeSteps';

import styles from './Home.less';

const ACCOUNT_STAGE = {
  CREATE_JOB_POSITION: 0,
  ACTIVITIES: 2,
};

function Home({
  t,
  activities,
  isActivitiesLoading,
  jobPositionsCount,
  jobPositions,
  isJobPositionsLoading,
  currentUser,
}) {
  const isLoading = isJobPositionsLoading;
  if (isLoading) {
    return (
      <>
        <Title>{t('Title_homePage')}</Title>
        <Loader fullSize />
      </>
    );
  }

  let currentAccountStage = ACCOUNT_STAGE.ACTIVITIES;
  if (jobPositionsCount === 0) {
    currentAccountStage = ACCOUNT_STAGE.CREATE_JOB_POSITION;
  }

  return (
    <>
      <Title>{t('Title_homePage')}</Title>
      {currentAccountStage !== ACCOUNT_STAGE.ACTIVITIES && (
        <Content
          theme="transparent"
          size="small"
          className={styles.introScreenWrapper}
        >
          <InlineSVG svg={peopleIllustration} />
          <div className={styles.welcomeMessage}>
            <p className={styles.welcomeTitle}>
              {t('Home_welcomeToWorkate')}
              {currentUser && currentUser.firstName
                ? `, ${currentUser.firstName}`
                : ''}
            </p>
            <p className={styles.welcomeSubtitle}>
              {currentAccountStage === ACCOUNT_STAGE.CREATE_JOB_POSITION &&
                t('Home_welcomeToWorkate--jobPosition')}
            </p>
          </div>
          <HomeSteps
            current={currentAccountStage}
            jobPositions={jobPositions}
          />
        </Content>
      )}
      {currentAccountStage === ACCOUNT_STAGE.ACTIVITIES && (
        <Content
          title={t('Home_LatestActivity')}
          size="small"
          theme="transparent"
          className={styles.contentWrapper}
        >
          <ActivityFeedContainer
            activities={activities}
            reversed
            isLoading={isActivitiesLoading}
          />
        </Content>
      )}
    </>
  );
}

Home.propTypes = {
  activities: PropTypes.array,
  isActivitiesLoading: PropTypes.bool,
  jobPositionsCount: PropTypes.number,
  isJobPositionsLoading: PropTypes.bool,
  jobPositions: PropTypes.array,
  currentUser: PropTypes.object,
  // From HoCs
  t: PropTypes.func.isRequired,
};

export default withTranslation('translation')(Home);

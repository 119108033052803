import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'apollo';
import { withTranslation } from 'i18n';

import { logAndShowGenericError } from 'utils/log';

import withOrganizationPk from 'hoc/withOrganizationPk';

import { evolve } from 'ramda';
import CandidateFiles from './CandidateFiles';

import candidateWithApplicationsQuery from '../../CandidateWithApplications/candidateWithApplicationsQuery.gql';
import addCandidateFileMutation from './addCandidateFileMutation.gql';
import candidateFilesQuery from './candidateFilesQuery.gql';

class CandidateFilesContainer extends PureComponent {
  static propTypes = {
    candidatePk: PropTypes.string.isRequired,
    // From HoC
    files: PropTypes.array.isRequired,
    addCandidateFile: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  state = {
    file: {},
  };

  onFileUpload = ({ file }) =>
    this.props
      .addCandidateFile(file.response, false)
      .then(
        ({
          data: {
            addCandidateFile: { errors },
          },
        }) => {
          if (errors) {
            return Promise.reject(
              (errors.fields && errors.fields.file) || errors.message,
            );
          }
        },
      )
      .catch(
        logAndShowGenericError('onFileUpload rejected', {
          props: this.props,
          state: this.state,
        }),
      );

  render() {
    const { files, candidatePk } = this.props;

    return (
      <CandidateFiles
        {...this.state}
        files={files}
        onFileUpload={this.onFileUpload}
        candidatePk={candidatePk}
      />
    );
  }
}

export default compose(
  withOrganizationPk,
  withTranslation('translation'),
  graphql(candidateFilesQuery, {
    options: ({ organizationPk, candidatePk }) => ({
      variables: { organizationPk, candidatePk },
    }),
    props: ({ data: { viewer, loading } }) => ({
      files: viewer && !loading ? viewer.candidate.files : [],
    }),
  }),
  graphql(addCandidateFileMutation, {
    props: ({ mutate, ownProps: { organizationPk, candidatePk } }) => ({
      addCandidateFile: (addedAttachment, isCv) =>
        mutate({
          variables: {
            organizationPk,
            file: addedAttachment,
            candidatePk,
            isCv,
          },
          update: (
            proxy,
            {
              data: {
                addCandidateFile: { file },
              },
            },
          ) => {
            if (!file) return;

            const query = candidateWithApplicationsQuery;
            const variables = {
              organizationPk,
              candidatePk,
            };
            const data = proxy.readQuery({ query, variables });

            proxy.writeQuery({
              query,
              variables,
              data: evolve(
                {
                  viewer: {
                    candidate: {
                      files: (f) => [...f, { ...file, application: null }],
                    },
                  },
                },
                data,
              ),
            });
          },
        }),
    }),
  }),
)(CandidateFilesContainer);

import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';
import { validator, isRequired } from 'utils/validator';
import { logAndShowGenericError } from 'utils/log';

import sendApplicationEmailMutation from 'pages/candidates/ApplicationDetails/sendApplicationEmailMutation.gql';
import activitiesQuery from 'pages/candidates/ApplicationActivityFeed/activitiesQuery.gql';
// eslint-disable-next-line max-len
import candidateWithApplicationsQuery from 'pages/candidates/CandidateWithApplications/candidateWithApplicationsQuery.gql';

const VALIDATE_EMAIL_FIELDS = {
  subject: [isRequired],
  message: [isRequired],
};
const getEmailValidationErrors = validator(VALIDATE_EMAIL_FIELDS);

const useSendEmail = ({ application, onClose }) => {
  const [organizationPk] = useCurrentOrganization();

  const [sendEmail] = useMutation(sendApplicationEmailMutation);

  const { mutate, fieldErrors, setFieldErrors, clearFieldError, isSaving } =
    useErrorFieldsMutation(sendEmail);

  const onEmailSend = useCallback(
    ({ selectedEmail, emailSubject, emailText }) => {
      const applicationPk = application.pk;

      const subject = emailSubject.trim();
      const message = emailText.trim();

      const validationErrors = getEmailValidationErrors({
        subject,
        message,
      });

      if (validationErrors) {
        return setFieldErrors(validationErrors);
      }

      mutate({
        variables: {
          organizationPk,
          applicationPk,
          selectedEmail,
          emailSubject,
          emailText,
        },
        refetchQueries: [candidateWithApplicationsQuery, activitiesQuery],
      })
        .catch(
          logAndShowGenericError('sendApplicationEmail rejected', {
            emailSubject,
            emailText,
          }),
        )
        .finally(() => {
          onClose();
          clearFieldError('subject');
          clearFieldError('message');
        });
    },
    [
      application,
      onClose,
      setFieldErrors,
      mutate,
      organizationPk,
      clearFieldError,
    ],
  );

  return { onEmailSend, fieldErrors, clearFieldError, isSaving };
};

export default useSendEmail;

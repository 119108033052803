import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import { Modal as AntModal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Button from '../Form/Button';

import styles from './index.less';

function Modal({
  children,
  onCancel,
  onOk,
  okDisabled,
  cancelText,
  okText,
  okDataRole,
  cancelDataRole,
  ...rest
}) {
  const { t } = useTranslation();
  return (
    <AntModal
      footer={[
        <Button
          key="cancel"
          onClick={onCancel}
          size="default"
          data-role={cancelDataRole}
          data-testid="cancel"
        >
          {cancelText || t('Modal_cancel')}
        </Button>,
        <Button
          key="submit"
          onClick={onOk}
          type="primary"
          size="default"
          disabled={okDisabled}
          data-testid="modal-submit-button"
          data-role={okDataRole}
        >
          {okText || t('Modal_save')}
        </Button>,
      ]}
      onCancel={onCancel}
      closeIcon={<CloseOutlined aria-hidden="true" />}
      {...rest}
      className={styles.modal}
    >
      {children}
    </AntModal>
  );
}

Modal.propTypes = {
  children: PropTypes.node,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  okDisabled: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  okDataRole: PropTypes.string,
  cancelDataRole: PropTypes.string,
};

export default Modal;

import React from 'react';
import { DraggerConsumer } from 'providers/DraggerProvider';

const withDragger = (WrappedComponent) => {
  withDragger.displayName = `withDragger(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return function withDraggerBare(props) {
    return (
      <DraggerConsumer>
        {(isDragged) => <WrappedComponent isDragged={isDragged} {...props} />}
      </DraggerConsumer>
    );
  };
};

export default withDragger;

import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as AntAvatar } from 'antd';
import cx from 'classnames';

import md5 from 'js-md5';
import getColorFromString from 'utils/getColorFromString';

import styles from './index.less';

function Avatar({ initials, size = 'default', email }) {
  let gravatarUrl;
  let backgroundColor = '999999';
  let textColor = 'FFFFFF';

  if (email) {
    const userEmailHash = md5(email.trim().toLowerCase());
    gravatarUrl = `https://www.gravatar.com/avatar/${userEmailHash}?d=blank`;

    const customColors = getColorFromString(email);
    backgroundColor = customColors.color;
    textColor = customColors.textColor;
  }

  return (
    <AntAvatar
      size={size}
      style={{
        color: `#${textColor}`,
        backgroundColor: `#${backgroundColor}`,
      }}
      className={cx(
        styles.avatar,
        size === 'mini' && styles.mini,
        size === 'small' && styles.small,
      )}
      aria-label="avatar"
    >
      {initials}
      {email && (
        <img src={gravatarUrl} alt="avatar" className={styles.gravatar} />
      )}
    </AntAvatar>
  );
}

Avatar.propTypes = {
  initials: PropTypes.string,
  size: PropTypes.oneOf(['mini', 'small', 'default', 'large']),
  email: PropTypes.string,
};

export default Avatar;

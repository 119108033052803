import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';

import { RightOutlined } from '@ant-design/icons';
import useBrowserWidth from 'hooks/useBrowserWidth';
import { XS } from 'consts/media';
import styles from './JobPositionStages.less';

// function  that returns number of candidates on given stage
const getCandidatesCount = (stagePk, applications) =>
  applications.filter((application) => application.node.stage.pk === stagePk)
    .length;

function JobPositionStages({ stages, applications, positionPk }) {
  const stagesData = stages.map((stage) => ({
    pk: stage.pk,
    value: getCandidatesCount(stage.pk, applications.edges),
    name: stage.name,
  }));

  const browserWidth = useBrowserWidth();

  return (
    <div className={styles.stagesBox}>
      {stagesData.map((stage, index) => (
        <>
          <Link
            to={`/candidates?position[]=${positionPk}&stage[]=${stage.pk}`}
            className={styles.stageLink}
          >
            {`${stage.name} (${stage.value})`}
          </Link>
          {index < stagesData.length - 1 && browserWidth >= XS && (
            <RightOutlined className={styles.arrow} />
          )}
        </>
      ))}
    </div>
  );
}

JobPositionStages.propTypes = {
  stages: PropTypes.node,
  applications: PropTypes.node,
  positionPk: PropTypes.string,
};

export default JobPositionStages;

export const validateBlocksStructure = (blocks) => {
  const requiredBlocks = [
    { __typename: 'AccordionBlockNode', semanticMeaning: 'jobBenefits' },
    { __typename: 'AccordionBlockNode', semanticMeaning: 'responsibilities' },
    { __typename: 'AccordionBlockNode', semanticMeaning: 'skills' },
    { __typename: 'IconListBlockNode' },
    { __typename: 'AboutUsBlockNode' },
    { __typename: 'ApplyBlockNode' },
    { __typename: 'ProcessBlockNode' },
  ];

  if (blocks.length === 0) {
    return false;
  }

  // Check if every required block is present in the input blocks
  const hasAllRequired = requiredBlocks.every((reqBlock) =>
    blocks.some(
      (block) =>
        block.__typename === reqBlock.__typename &&
        (reqBlock.semanticMeaning
          ? block.semanticMeaning === reqBlock.semanticMeaning
          : true),
    ),
  );

  // Check for any extra blocks in the input that are not in requiredBlocks
  const hasNoExtras = blocks.every((block) =>
    requiredBlocks.some(
      (reqBlock) =>
        block.__typename === reqBlock.__typename &&
        (reqBlock.semanticMeaning
          ? block.semanticMeaning === reqBlock.semanticMeaning
          : true),
    ),
  );

  return hasAllRequired && hasNoExtras;
};

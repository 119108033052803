import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { useTranslation } from 'i18n';
import { logAndShowGenericError } from 'utils/log';
import { MEMBERSHIP_QUERY } from 'hooks/useMembership';
import LanguageSelect from '.';
import { SUPPORTED_LANGUAGES } from './SupportedLanguages';

export const LANGUAGE_CHANGE_MUTATION = gql`
  mutation changeCurrentUserLanguage($newLanguage: LanguageEnum!) {
    changeUserLanguage(language: $newLanguage) {
      user {
        pk
        language
      }
    }
  }
`;

function UserLanguageSelect({ theme }) {
  const [changeLanguage] = useMutation(LANGUAGE_CHANGE_MUTATION, {
    update(
      cache,
      {
        data: {
          changeUserLanguage: {
            user: { pk, language },
          },
        },
      },
    ) {
      cache.writeQuery({
        query: MEMBERSHIP_QUERY,
        data: {
          viewer: {
            currentUser: {
              pk,
              language,
              __typename: 'UserNode',
            },
            __typename: 'Viewer',
          },
        },
      });
    },
  });
  const { i18n } = useTranslation();

  const updateUserLanguageSettings = useCallback(() => {
    const newLanguage = SUPPORTED_LANGUAGES.find(
      (l) => l.code === i18n.language,
    ).enum;

    changeLanguage({
      variables: {
        newLanguage,
      },
    }).catch(
      logAndShowGenericError('changeLanguage rejected', {
        newLanguage,
      }),
    );
  }, [changeLanguage, i18n.language]);

  return <LanguageSelect onChange={updateUserLanguageSettings} theme={theme} />;
}

UserLanguageSelect.propTypes = {
  theme: PropTypes.string,
};

export default UserLanguageSelect;

import { ApolloClient, ApolloLink, HttpLink, gql } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import loggerLink from 'apollo-link-logger';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';

import log from 'utils/log';
import i18n from 'i18n';

import * as config from 'config';
import { appCache } from './state';

export function logout(withRedirect = true) {
  // eslint-disable-next-line no-use-before-define
  client
    .mutate({
      mutation: gql`
        mutation LogoutUserMutation {
          logoutUser {
            errors {
              message
              fields
            }
            status
          }
        }
      `,
    })
    .then(() => {
      client.clearStore(); // eslint-disable-line no-use-before-define
    });

  if (withRedirect) {
    window.location = '/login';
  }
}

const { NetworkStatusNotifier, link: networkStatusNotifierLink } =
  createNetworkStatusNotifier();

export { NetworkStatusNotifier };

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        {
          message,
          locations,
          path,
          operation,
        },
      ),
    );
  }

  if (networkError) {
    log(`[Network error]: ${networkError}`, networkError);
  }
});

/* eslint-disable no-param-reassign */
const fetchWithCustomHeaders = (uri, options) => {
  const customHeaders = {
    'x-language': i18n.language,
    'x-origin': window.location.origin,
  };
  options.headers = Object.assign(options.headers, customHeaders);

  return fetch(uri, options);
};

const httpLink = new HttpLink({
  uri: config.API_GRAPHQL_ENDPOINT,
  credentials: 'include',
  fetch: async (uri, options) => {
    const response = await fetchWithCustomHeaders(uri, options);
    const json = await response.json();

    // If the query has a viewer node but it's empty, it means the user is not authenticated
    if (json.data && json.data.hasOwnProperty('viewer') && !json.data.viewer) {
      logout();
    }

    return {
      ...response,
      text: async () => JSON.stringify(json),
    };
  },
});

export const links = [errorLink, networkStatusNotifierLink];

if (config.IS_DEVELOPMENT && typeof jest === 'undefined') {
  links.push(loggerLink);
}

links.push(httpLink);

const client = new ApolloClient({
  link: ApolloLink.from(links),
  cache: appCache,
  shouldBatch: true,
});

export default client;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';

import UnseenDot from 'components/UnseenDot';
import DateWithIcon from 'components/DateWithIcon';
import { XS } from 'consts/media';
import useBrowserWidth from 'hooks/useBrowserWidth';

import { UserAddOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ApplicationsWithPopover from './ApplicationsWithPopover';

import styles from './NameCell.less';

function NameCell({
  unseen,
  pk,
  firstName,
  lastName,
  applications,
  candidatePk,
  onApplicationClick,
  createdAt,
}) {
  const { t } = useTranslation();
  const browserWidth = useBrowserWidth();

  // disable link as it's used only for copy purpose
  const onNameClick = (e) => {
    e.preventDefault();
  };

  /**
   * Set last (the most recent) application as active
   * in candidate view for the beginning.
   */
  const activeApplication =
    applications.edges.length > 0
      ? applications.edges[applications.edges.length - 1].node.pk
      : null;

  return (
    <div className={styles.container}>
      <Link
        className={styles.link}
        role="link"
        onClick={onNameClick}
        to={
          activeApplication
            ? `/candidates/${pk}/${activeApplication}`
            : `/candidates/${pk}`
        }
      >
        <div className={styles.name} data-testid="candidate-name">
          {unseen && <UnseenDot />} {firstName} {lastName}
        </div>
      </Link>
      {browserWidth <= XS && (
        <>
          <ApplicationsWithPopover
            applications={applications.edges}
            candidatePk={candidatePk}
            onApplicationClick={onApplicationClick}
            browserWidth={browserWidth}
          />
          <DateWithIcon
            date={createdAt}
            iconComponent={UserAddOutlined}
            text={t('created')}
            className={styles.date}
          />
        </>
      )}
    </div>
  );
}

NameCell.propTypes = {
  unseen: PropTypes.bool,
  pk: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  applications: PropTypes.object,
  candidatePk: PropTypes.string.isRequired,
  onApplicationClick: PropTypes.func.isRequired,
  createdAt: PropTypes.string,
};

export default memo(NameCell);

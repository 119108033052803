import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'i18n';
import {
  CloseOutlined,
  MailOutlined,
  PaperClipOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { Checkbox, message } from 'antd';

import Tooltip from 'components/Tooltip';
import { formatDate } from 'utils/date';
import useMembership from 'hooks/useMembership';
import { logAndShowGenericError } from 'utils/log';
import confirmDeleteModal from 'utils/confirmDeleteModal';
import EDIT_CANDIDATE_FILE_MUTATION from './editCandidateFileMutation.gql';
import REMOVE_CANDIDATE_FILE_MUTATION from './removeCandidateFileMutation.gql';
import candidateFilesQuery from './candidateFilesQuery.gql';

import styles from './CandidateFile.less';

function CandidateFile({ file, candidatePk }) {
  const { t } = useTranslation();
  const [currentMembership] = useMembership();
  const organizationPk = currentMembership && currentMembership.organization.pk;
  const [editCandidateFile] = useMutation(EDIT_CANDIDATE_FILE_MUTATION);

  const [removeCandidateFile] = useMutation(REMOVE_CANDIDATE_FILE_MUTATION, {
    update(
      cache,
      {
        data: {
          removeCandidateFile: { status },
        },
      },
    ) {
      if (status !== 'OK') return;

      // update candidateFilesQuery - to not display the deleted file
      const query = candidateFilesQuery;
      const variables = {
        organizationPk,
        candidatePk,
      };
      const data = cache.readQuery({ query, variables });

      cache.writeQuery({
        query,
        variables,
        data: {
          viewer: {
            ...data.viewer,
            candidate: {
              ...data.viewer.candidate,
              files: data.viewer.candidate.files.filter(
                (f) => f.pk !== file.pk,
              ),
            },
          },
        },
      });
    },
  });

  const onFileRemove = useCallback(() => {
    confirmDeleteModal(t('sureWantToDeleteFile'), () => {
      removeCandidateFile({
        variables: { filePk: file.pk, organizationPk },
      })
        .then(
          ({
            data: {
              removeCandidateFile: { errors },
            },
          }) => {
            if (errors) {
              return errors.message
                ? message.error(errors.message)
                : logAndShowGenericError('removeCandidateFile rejected', {
                    filePk: file.pk,
                  });
            }
          },
        )
        .catch(
          logAndShowGenericError('removeCandidateFile rejected', {
            filePk: file.pk,
          }),
        );
    });
  }, [removeCandidateFile, file, organizationPk, t]);

  const onEdit = useCallback(() => {
    editCandidateFile({
      variables: { organizationPk, filePk: file.pk, isCv: !file.isCv },
      optimisticResponse: {
        editCandidateFile: {
          errors: null,
          file: {
            application: null,
            createdAt: file.createdAt,
            email: null,
            id: file.pk,
            isCv: !file.isCv,
            pk: file.pk,
            title: file.title,
            url: file.url,
            __typename: 'CandidateFileNode',
          },
          __typename: 'EditCandidateFileMutation',
        },
      },
    })
      .then(
        ({
          data: {
            editCandidateFile: { errors },
          },
        }) => {
          if (errors) {
            return errors.message
              ? message.error(errors.message)
              : logAndShowGenericError('editCandidateFile rejected', {
                  filePk: file.pk,
                  isCv: !file.isCv,
                });
          }
        },
      )
      .catch(
        logAndShowGenericError('editCandidateFile rejected', {
          filePk: file.pk,
          isCv: !file.isCv,
        }),
      );
  }, [editCandidateFile, organizationPk, file]);

  return (
    <Tooltip
      key={file.pk}
      mouseEnterDelay={0.7}
      mouseLeaveDelay={0}
      title={
        <div className={styles.tooltipText}>
          {file.isCv && <span>{t('CandidateCV_fileList_createdAt')}</span>}
          {!file.isCv && <span>{t('CandidateFile_fileList_createdAt')}</span>}
          <span>{formatDate(file.createdAt)}</span>
        </div>
      }
    >
      <div className={styles.title}>
        <div>
          {!file.isCv && (
            <PaperClipOutlined
              className={styles.icon}
              data-testid="icon-paper-clip"
            />
          )}
          {file.isCv && (
            <SolutionOutlined
              className={styles.icon}
              data-testid="icon-solution"
            />
          )}
          <a className={styles.link} href={file.url} target="_blank">
            {file.title}
          </a>
        </div>
        <Checkbox
          className={cx(styles.checkbox, file.isCv && styles.checked)}
          checked={file.isCv}
          onChange={onEdit}
        >
          CV
        </Checkbox>
        {file.email && <MailOutlined className={styles.iconMail} />}
        <button
          type="button"
          aria-label="close"
          className={styles.closeButton}
          onClick={onFileRemove}
        >
          <CloseOutlined
            className={styles.iconClose}
            data-testid="icon-close"
          />
        </button>
      </div>
    </Tooltip>
  );
}

CandidateFile.propTypes = {
  file: PropTypes.object,
  candidatePk: PropTypes.string,
};

export default CandidateFile;

import React from 'react';
import PropTypes from 'prop-types';
import DateWithIcon from 'components/DateWithIcon';
import Link from 'components/Link';

import styles from './NameCell.less';

function NameCell({
  pk,
  subject,
  createdAt,
  createdBy,
  status,
  successfulEmailsCount,
  t,
}) {
  return (
    <div>
      <Link to={`${pk}`} className={styles.title}>
        {subject}
      </Link>

      <div className={styles.details}>
        <div>
          {t('bulkEmailNameCell_from')}
          <strong> {createdBy}</strong>
        </div>
        <div>
          {successfulEmailsCount}{' '}
          {t('bulkEmailNameCell_emailsSent', {
            count: successfulEmailsCount,
          })}
        </div>
        <div>{status}</div>
        <DateWithIcon date={createdAt} text={t('created')} />
      </div>
    </div>
  );
}

NameCell.propTypes = {
  pk: PropTypes.string,
  subject: PropTypes.string,
  createdAt: PropTypes.string,
  createdBy: PropTypes.string,
  status: PropTypes.string,
  successfulEmailsCount: PropTypes.number,
  t: PropTypes.func,
};

export default NameCell;

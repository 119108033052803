import { Modal } from 'antd';
import i18n from 'i18n';

const confirm = Modal.confirm;

const confirmDeleteModal = (text, onOk, onCancel) => {
  confirm({
    title: text,
    okText: i18n.t('confirmDelete'),
    cancelText: i18n.t('cancel'),
    okType: 'danger',
    onOk,
    onCancel,
    autoFocusButton: 'cancel',
  });
};

export default confirmDeleteModal;

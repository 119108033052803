import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import { Steps as AntSteps } from 'antd';

import styles from './index.less';

const Step = AntSteps.Step;

function Steps({ current }) {
  const { t } = useTranslation();

  return (
    <AntSteps size="small" current={current} className={styles.progressBar}>
      <Step title={t('JobPositionAdd_steps--create')} />
      <Step title={t('JobPositionAdd_steps--vas')} />
      <Step title={t('JobPositionAdd_steps--permissions')} />
      <Step title={t('JobPositionAdd_steps--thankYou')} />
    </AntSteps>
  );
}

Steps.propTypes = {
  current: PropTypes.number,
};

export default Steps;

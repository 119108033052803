import React from 'react';
import FullscreenMessage from 'components/FullscreenMessage';
import { withTranslation, Trans } from 'i18n';

function AccountVerified() {
  return (
    <FullscreenMessage>
      <Trans i18nKey="youVerifiedAccountMessageTitle">
        <b>Title</b>
        <p>text</p>
      </Trans>
    </FullscreenMessage>
  );
}

export default withTranslation('translation')(AccountVerified);

import React from 'react';
import PropTypes from 'prop-types';

import styles from './Head.less';

function Head({ element = 'h2', children }) {
  return React.createElement(
    element,
    {
      className: styles.head,
    },
    children,
  );
}

Head.propTypes = {
  element: PropTypes.string,
  children: PropTypes.node,
};

export default Head;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';
import Button from 'components/Form/Button';
import IconPicker from 'pages/job-positions/JobOffers/IconPicker';
import MaterialSymbolIcon from 'components/MaterialSymbolIcon';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  MATERIAL_SYMBOL_ACTIVE_COLOR,
  MATERIAL_SYMBOL_SIZE,
} from 'consts/materialSymbols';
import ControlledInput from './ControlledInput';
import styles from './IconTile.less';

function IconTile({
  control,
  textFieldName,
  valueFieldName,
  onDeleteClick,
  placeholder,
}) {
  const [isModalVisible, toggleModalVisible] = useState(false);
  const iconValue = useWatch({ control, name: valueFieldName });
  const { setValue } = useFormContext();

  const handleIconPickerDisplay = useCallback(() => {
    toggleModalVisible(!isModalVisible);
  }, [toggleModalVisible, isModalVisible]);

  const handleIconSelect = useCallback(
    (selectedIcon) => {
      setValue(valueFieldName, selectedIcon);
      toggleModalVisible(!isModalVisible);
    },
    [valueFieldName, setValue, toggleModalVisible, isModalVisible],
  );

  return (
    <div className={styles.tile}>
      <button
        type="button"
        className={styles.iconBtn}
        onClick={handleIconPickerDisplay}
      >
        <MaterialSymbolIcon
          value={iconValue}
          size={MATERIAL_SYMBOL_SIZE}
          color={MATERIAL_SYMBOL_ACTIVE_COLOR}
        />
      </button>
      <ControlledInput
        control={control}
        name={textFieldName}
        className={styles.descInput}
        textarea
        placeholder={placeholder}
        autoSize={{ maxRows: 2 }}
      />
      <ControlledInput
        control={control}
        name={valueFieldName}
        className={styles.iconInput}
      />
      <Button
        className={styles.removeBtn}
        iconOnly
        icon={<DeleteOutlined aria-hidden />}
        onClick={onDeleteClick}
        aria-label="remove-main-point"
      />
      <IconPicker
        isVisible={isModalVisible}
        onCancel={() => toggleModalVisible(!isModalVisible)}
        onSave={handleIconSelect}
      />
    </div>
  );
}

IconTile.propTypes = {
  control: PropTypes.object.isRequired,
  textFieldName: PropTypes.string.isRequired,
  valueFieldName: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default IconTile;

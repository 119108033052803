import React, { useCallback } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Popover, Table as AntTable } from 'antd';
import { useTranslation } from 'i18n';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { XS, SM, MD } from 'consts/media';
import _useBrowserWidth from 'hooks/useBrowserWidth';
import Stage from 'pages/candidates/Stage';
import Table from 'components/Table';
import DateWithIcon from 'components/DateWithIcon';

import NameCell from './NameCell';
import ApplicationsWithPopover from './ApplicationsWithPopover';

import styles from './CandidatesTable.less';

const { Column } = AntTable;

function CandidatesTable({
  loading,
  dataSource,
  rowSelection,
  useBrowserWidth = _useBrowserWidth,
}) {
  const { t } = useTranslation();
  const browserWidth = useBrowserWidth();
  // used for redirecting
  const navigate = useNavigate();

  const rowHandle = useCallback(
    (row) => ({
      onClick: (e) => {
        /*
         Set last (the most recent) application as active
         in candidate view for the beginning.
        */
        const activeApplication =
          row.applications.edges.length > 0
            ? row.applications.edges[row.applications.edges.length - 1].node.pk
            : null;

        /*
          Open details: popup on LG screens, redirect on SM screens 
          or open in the new tab when metaKey pressed
        */
        if (browserWidth > SM && !e.metaKey && !e.ctrlKey) {
          e.preventDefault();
          row.onCandidateSelected(row.pk, row.applications);
        } else if (browserWidth <= SM && !e.metaKey && !e.ctrlKey) {
          navigate(
            activeApplication
              ? `/candidates/${row.pk}/${activeApplication}`
              : `/candidates/${row.pk}`,
          );
        } else {
          window.open(
            activeApplication
              ? `/candidates/${row.pk}/${activeApplication}`
              : `/candidates/${row.pk}`,
            '_blank',
          );
        }
      },
    }),
    [browserWidth, navigate],
  );

  return (
    <Table
      loading={loading}
      rowKey="pk"
      dataSource={dataSource}
      showHeader={browserWidth > XS}
      className={styles.table}
      pagination={false}
      rowSelection={rowSelection}
      onRow={rowHandle}
      data-testid="candidates-table"
      rowClassName={styles.candidateField}
    >
      <Column
        key="name"
        title={t('thName')}
        render={(item) => <NameCell {...item} />}
      />
      {browserWidth >= SM && (
        <Column
          key="stage"
          title={t('thStage')}
          render={(item) => {
            if (item.applications.edges.length === 1) {
              return (
                <div className={styles.stageName}>
                  <Stage
                    stagePk={item.applications.edges[0].node.stage.pk}
                    stageListPk={
                      item.applications.edges[0].node.stage.stageList.pk
                    }
                    nonChangeable
                  />
                </div>
              );
            }
            if (item.applications.edges.length > 1) {
              return (
                <Popover
                  content={item.applications.edges.map((o) => (
                    <div key={o.node.id}>
                      <span>{o.node.jobPosition.position}: </span>
                      <b>{o.node.stage.name}</b>
                    </div>
                  ))}
                  placement="top"
                >
                  <EllipsisOutlined className={styles.stageName} />
                </Popover>
              );
            }
          }}
        />
      )}
      {browserWidth > XS && (
        <Column
          key="applications"
          title={t('jobPositions')}
          render={(item) => (
            <ApplicationsWithPopover
              applications={item.applications.edges}
              candidatePk={item.candidatePk}
              onApplicationClick={item.onApplicationClick}
              browserWidth={item.browserWidth}
            />
          )}
        />
      )}
      {browserWidth >= MD && (
        <Column
          key="modifiedAt"
          title={t('thModified')}
          render={(item) => (
            <DateWithIcon
              date={item.modifiedAt}
              className={styles.createdAt}
              data-testid="modifiedAt"
            />
          )}
        />
      )}
      {browserWidth >= MD && (
        <Column
          key="createdAt"
          title={t('thCreated')}
          render={(item) => (
            <DateWithIcon date={item.createdAt} className={styles.createdAt} />
          )}
        />
      )}
    </Table>
  );
}

CandidatesTable.propTypes = {
  loading: PropTypes.bool,
  dataSource: PropTypes.array.isRequired,
  rowSelection: PropTypes.object,
  useBrowserWidth: PropTypes.func,
  useOrganizationModules: PropTypes.func,
};

export default CandidatesTable;

import React from 'react';
import ReactDOM from 'react-dom';
import App from 'containers/App';

const rootNode = document.createElement('div');

if (document.body) {
  document.body.appendChild(rootNode);
}

ReactDOM.render(<App />, rootNode);

import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { message } from 'antd';

import useMembership from 'hooks/useMembership';
import { logAndShowGenericError } from 'utils/log';
import { CUSTOM_FIELDS_VALUES_QUERY } from './useCustomFieldValues';

const DELETE_CUSTOM_FIELD_VALUE_MUTATION = gql`
  mutation DeleteCustomFieldValueMutation(
    $organizationPk: String!
    $applicationPk: String!
    $customFieldPk: Int!
  ) {
    customFieldValueDelete(
      organizationPk: $organizationPk
      applicationPk: $applicationPk
      customFieldPk: $customFieldPk
    ) {
      status
      errors {
        message
        fields
      }
    }
  }
`;

/**
 * Hook for deleting Custom Field Values
 */
const useCustomFieldValueDelete = ({ customFieldPk, applicationPk }) => {
  const [
    {
      organization: { pk: organizationPk },
    },
  ] = useMembership();

  const [deleteFieldValue] = useMutation(DELETE_CUSTOM_FIELD_VALUE_MUTATION);

  const onDelete = useCallback(() => {
    deleteFieldValue({
      variables: { organizationPk, applicationPk, customFieldPk },
      refetchQueries: [CUSTOM_FIELDS_VALUES_QUERY],
    })
      .then(
        ({
          data: {
            customFieldValueDelete: { errors },
          },
        }) => {
          if (errors) {
            return errors.message
              ? message.error(errors.message)
              : logAndShowGenericError('customFieldValueDelete rejected', {
                  customFieldPk,
                });
          }
        },
      )
      .catch(
        logAndShowGenericError('customFieldValueDelete rejected', {
          customFieldPk,
        }),
      );
  }, [organizationPk, applicationPk, customFieldPk, deleteFieldValue]);

  return { onDelete };
};

export default useCustomFieldValueDelete;

import { graphql } from 'apollo';
import currentOrganizationQuery from './currentOrganizationQuery.gql';

const withOrganizationPk = graphql(currentOrganizationQuery, {
  props: ({ data: { organizationPk, viewer } }) => {
    const membership = (organizationPk &&
      viewer &&
      viewer.currentUser.memberships.find(
        ({ organization }) => organizationPk === organization.pk,
      )) || { organization: {} };

    return {
      organizationPk: membership.organization.pk || '',
      organizationName: membership.organization.name || '',
    };
  },
});

export default withOrganizationPk;

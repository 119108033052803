const createCustomFieldFilterObject = (customFieldFilters) =>
  Object.keys(customFieldFilters).reduce(
    (result, filter) => [
      ...result,
      {
        customField: filter.replace('customFieldFilter_', ''),
        values: customFieldFilters[filter],
      },
    ],
    [],
  );

export default createCustomFieldFilterObject;

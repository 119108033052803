import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { Tooltip } from 'antd';
import cx from 'classnames';

import Form from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import Loader from 'components/Loader';
import Content from 'components/Content';
import Select from 'components/Form/Select';
import Title from 'components/Title';
import usePermissions from 'hooks/usePermissions';
import { PERMISSION_USERS_MANAGE } from 'consts/permissions';
import { roleName } from 'utils/translation';
import { PlusOutlined } from '@ant-design/icons';
import PermissionsDescriptionTable from './PermissionsDescriptionTable';
import Member from './Member';
import RawInvitation from './Invitation';
import styles from './index.less';

const renderInviteButton = (canInviteUsers, t, onAddClick) => (
  <Button
    className={cx(!canInviteUsers && styles.disabledButton)}
    disabled={!canInviteUsers}
    onClick={onAddClick}
    type="primary"
    size="default"
    icon={<PlusOutlined aria-hidden />}
    data-role="invite-user-button"
  >
    {t('inviteUserToOrganization')}
  </Button>
);

function TeamMembers({
  t,
  memberships,
  isMembershipsLoading,
  invitations,
  isInvitationsLoading,
  isFormDisplayed,
  newUserEmail,
  newUserRole,
  onInputChange,
  onRoleSelection,
  onAddClick,
  onSubmit,
  fieldErrors,
  isInviteUserSaving,
  deleteInvitation,
  roles,
}) {
  const [hasUsersManagePermission, { loading }] = usePermissions([
    PERMISSION_USERS_MANAGE,
  ]);

  return (
    <>
      <Content
        title={t('teamMembers')}
        className={styles.container}
        size="small"
      >
        <div data-testid="members-container">
          <Title withOrganizationTitle>{t('Title_teamMembers')}</Title>
          {isMembershipsLoading || loading ? (
            <Loader />
          ) : (
            memberships.map(({ node }) => (
              <Member
                memberData={node}
                key={node.pk}
                roles={roles}
                canChangeRole={hasUsersManagePermission}
                t={t}
              />
            ))
          )}
        </div>
      </Content>
      <Content
        title={t('pendingInvitations')}
        secondary
        size="small"
        data-testid="invitations-container"
      >
        {
          // eslint-disable-next-line no-nested-ternary
          isInvitationsLoading ? (
            <Loader />
          ) : invitations.length > 0 ? (
            invitations.map((o) => (
              <RawInvitation
                invitationData={o}
                key={o.pk}
                deleteInvitation={deleteInvitation}
                canInviteUsers={hasUsersManagePermission}
              />
            ))
          ) : (
            <span>{t('noPendingInvitations')}</span>
          )
        }
        {
          <div className={styles.invite}>
            {!isFormDisplayed &&
              (hasUsersManagePermission ? (
                renderInviteButton(hasUsersManagePermission, t, onAddClick)
              ) : (
                <Tooltip title={t('onlyManagerCanInvite')}>
                  <span>
                    {renderInviteButton(
                      hasUsersManagePermission,
                      t,
                      onAddClick,
                    )}
                  </span>
                </Tooltip>
              ))}
            {isFormDisplayed && (
              <Form onFinish={onSubmit} layout="inline">
                <Field
                  error={fieldErrors.newUserEmail}
                  className={styles.field}
                >
                  <Input
                    popupLabel
                    labelDark
                    type="email"
                    className={styles.email}
                    placeholder={t('emailAddress')}
                    value={newUserEmail}
                    onChange={onInputChange}
                    autoFocus
                    disabled={isInviteUserSaving}
                    data-testid="email"
                  />
                </Field>
                <Field error={fieldErrors.newUserRole} className={styles.field}>
                  <Select
                    data-testid="roleSelect"
                    className={styles.roleSelect}
                    placeholder={t('invitation_selectRole')}
                    onChange={onRoleSelection}
                    value={newUserRole}
                    // map roles with translated versions (used for standard roles like User/Admin/Superadmin),
                    // but fall back to role's name so custom roles can be displayed without translation
                    choices={roles.map(({ id, name }) => ({
                      value: id,
                      label: roleName(t, name),
                    }))}
                    aria-label={t('changeRoleSelect')}
                  />
                </Field>
                <Field className={styles.field}>
                  <Button
                    size="default"
                    loading={isInviteUserSaving}
                    type="primary"
                    htmlType="submit"
                    data-testid="submit"
                    data-role="user-invitation-save-button"
                  >
                    {t('invitation_save')}
                  </Button>
                </Field>
              </Form>
            )}
          </div>
        }
      </Content>
      <PermissionsDescriptionTable roles={roles} t={t} />
    </>
  );
}

TeamMembers.propTypes = {
  newUserRole: PropTypes.string,
  t: PropTypes.func.isRequired,
  memberships: PropTypes.array,
  isMembershipsLoading: PropTypes.bool,
  invitations: PropTypes.array,
  isInvitationsLoading: PropTypes.bool,
  isFormDisplayed: PropTypes.bool.isRequired,
  newUserEmail: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onRoleSelection: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  isInviteUserSaving: PropTypes.bool.isRequired,
  deleteInvitation: PropTypes.func.isRequired,
  roles: PropTypes.array,
};

export default withTranslation('translation')(TeamMembers);

import plFlag from './flags/pl.svg';
import enFlag from './flags/us.svg';

export const SUPPORTED_LANGUAGES = [
  {
    code: 'pl',
    name: 'PL',
    label: 'polish',
    enum: 'PL',
    flagIcon: plFlag,
  },
  {
    code: 'en',
    name: 'EN',
    label: 'english',
    enum: 'ENG',
    flagIcon: enFlag,
  },
];

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import cx from 'classnames';

import { EMAIL } from 'consts/contactTypes';
import Input from 'components/Form/Input';
import Field from 'components/Form/Field';
import Modal from 'components/Modal';
import EmailSelect from 'components/EmailSelect';
import TemplateSelect from 'pages/candidates/ApplicationDetails/TemplateSelect';
import confirmDeleteModal from 'utils/confirmDeleteModal';
import { formatDate } from 'utils/date';
import ApplicationSelect from './ApplicationSelect';
import useSendEmail from './useSendEmail';

import styles from './EmailModal.less';

const prependMessage = (input) => input.replace(/\n/g, '\n> ');

const createReplyMessage = (t, reply) =>
  '\n' +
  '\n' +
  `${t('EmailModal_input-emailInformation', {
    createdTime: `${formatDate(reply.createdAt)}`,
    user: `${reply.fromIdentity}`,
  })}` +
  `\n` +
  `${prependMessage(`\n${reply.message}`)}`;

const createReplySubject = (subject) =>
  subject.startsWith('Re') ? subject : `Re: ${subject}`;

function EmailModal({
  application,
  candidateApplications,
  contactDetails,
  pickedEmail,
  isOpen,
  onClose: handleClose,
  replyToEmailInstance,
}) {
  const { t } = useTranslation();

  const [emailSubject, setEmailSubject] = useState(
    replyToEmailInstance
      ? createReplySubject(replyToEmailInstance.subject)
      : '',
  );
  const [selectedEmail, setSelectedEmail] = useState('');
  const [selectedApplication, setSelectedApplication] = useState(application);
  const [emailText, setEmailText] = useState(
    replyToEmailInstance ? createReplyMessage(t, replyToEmailInstance) : '',
  );

  const candidateEmails = useMemo(
    () => contactDetails.filter(({ contactType }) => contactType === EMAIL),
    [contactDetails],
  );

  useEffect(() => {
    // If specific email is already picked, it should be set as selected email
    if (pickedEmail) {
      setSelectedEmail(pickedEmail);
      // Otherwise selected email should be set to first from the candidateEmails list on start.
    } else if (candidateEmails.length > 0) {
      setSelectedEmail(candidateEmails[0].content);
    }
  }, [candidateEmails, pickedEmail]);

  useEffect(() => {
    if (application) {
      setSelectedApplication(application);
    } else {
      setSelectedApplication(null);
    }
  }, [application]);

  const onClose = useCallback(() => {
    handleClose();
    setEmailText(
      replyToEmailInstance ? createReplyMessage(t, replyToEmailInstance) : '',
    );
    setEmailSubject(
      replyToEmailInstance
        ? createReplySubject(replyToEmailInstance.subject)
        : '',
    );
  }, [replyToEmailInstance, setEmailSubject, setEmailText, t, handleClose]);

  const { onEmailSend, fieldErrors, clearFieldError, isSaving } = useSendEmail({
    application: selectedApplication,
    onClose,
    setEmailSubject,
    setEmailText,
  });

  // If user leaves some content in emailSubject or emailText field,
  // display the "confirm close" modal before closing the form,
  // to prevent accidental loss of content.
  const onEmailFormCancel = useCallback(() => {
    if (emailSubject.trim() === '' && emailText.trim() === '') {
      onClose();
    } else {
      confirmDeleteModal(t('Email_confirmClose'), onClose);
    }
  }, [emailSubject, emailText, onClose, t]);

  const onTextInputChange = useCallback(
    (field) => (e) => {
      if (field === 'emailSubject') {
        clearFieldError('subject');
        setEmailSubject(e.target.value);
      }

      if (field === 'emailText') {
        clearFieldError('message');
        setEmailText(e.target.value);
      }
    },
    [clearFieldError, setEmailText, setEmailSubject],
  );

  const onSelectEmailTemplate = useCallback(
    (msgSubject, msgText) => {
      let newMsgText = msgText;

      if (emailText) {
        newMsgText = `${msgText}\n\n${emailText}`;
      }
      setEmailText(newMsgText);
      setEmailSubject(msgSubject);
    },
    [setEmailText, setEmailSubject, emailText],
  );

  const onFormSubmit = useCallback(() => {
    onEmailSend({ selectedEmail, emailSubject, emailText });
  }, [onEmailSend, selectedEmail, emailSubject, emailText]);

  const isSaveButtonDisabled = useMemo(
    () => !emailText || !emailSubject || isSaving,
    [isSaving, emailText, emailSubject],
  );

  const onEnter = useCallback(
    (e) => {
      if (!isSaveButtonDisabled && e.metaKey) {
        onFormSubmit();
      }
    },
    [isSaveButtonDisabled, onFormSubmit],
  );

  return (
    isOpen &&
    candidateEmails.length > 0 && (
      <Modal
        visible={isOpen}
        onOk={onFormSubmit}
        onCancel={onEmailFormCancel}
        okDisabled={isSaveButtonDisabled}
        okText={t('sendEmail')}
        okDataRole="button-send-email"
        maskClosable={false}
        title={t('Email_sendForm--title')}
      >
        <div className={styles.selectWrapper} data-testid="email-select">
          <EmailSelect
            className={styles.select}
            candidateEmails={candidateEmails}
            selectedEmail={selectedEmail}
            onSelectedEmailChange={setSelectedEmail}
            disabled={isSaving}
          />
        </div>
        {candidateApplications && (
          <div className={styles.selectWrapper}>
            <ApplicationSelect
              className={styles.select}
              candidateApplications={candidateApplications}
              selectedAplication={selectedApplication}
              onSelectedApplication={setSelectedApplication}
              disabled={isSaving}
            />
          </div>
        )}
        <div
          className={cx(
            styles.emailSubjectWrapper,
            fieldErrors.subject && styles.withError,
          )}
        >
          <Field error={fieldErrors.subject}>
            <Input
              value={emailSubject}
              onChange={onTextInputChange('emailSubject')}
              placeholder={t('emailSubject')}
              disabled={isSaving}
            />
          </Field>
        </div>
        <Field error={fieldErrors.message}>
          <Input
            value={emailText}
            onChange={onTextInputChange('emailText')}
            disabled={isSaving}
            textarea
            data-testid="application-textarea"
            data-role="textarea-email-text"
            placeholder={t('emailText')}
            autoSize={{ minRows: 5, maxRows: 10 }}
            onPressEnter={onEnter}
            style={{ height: '100vh' }}
          />
        </Field>
        <TemplateSelect
          onSelectEmailTemplate={onSelectEmailTemplate}
          applicationPk={selectedApplication.pk}
        />
      </Modal>
    )
  );
}

EmailModal.propTypes = {
  application: PropTypes.object,
  candidateApplications: PropTypes.array,
  contactDetails: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,

  // selected email address - passed if modal is opened by clicking contact link
  pickedEmail: PropTypes.string,

  // contains an object with information about email meessage
  replyToEmailInstance: PropTypes.object,
};

export default EmailModal;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';

import customFieldFragment from 'pages/settings/custom-fields/CustomFieldAddModal/customFieldFragment.gql';
import { useTranslation } from 'i18n';
import useCustomFieldForm from './useCustomFieldForm';
import CustomFieldFormModal from './CustomFieldFormModal';

export const ADD_CUSTOM_FIELD_MUTATION = gql`
  mutation AddFieldMutation(
    $organizationPk: String!
    $name: String!
    $choices: [ApplicationCustomFieldChoiceType]
    $fieldType: ApplicationCustomFieldEnum
  ) {
    customFieldAdd(
      organizationPk: $organizationPk
      name: $name
      choices: $choices
      fieldType: $fieldType
    ) {
      customField {
        ...customFieldFragment
      }
      errors {
        message
        fields
      }
    }
  }
  ${customFieldFragment}
`;

function CustomFieldAddModal({ isVisible, onCancel }) {
  const { t } = useTranslation();
  const {
    onSave,
    setFieldErrors,
    fieldErrors,
    clearFieldError,
    generalError,
    isSaving,
  } = useCustomFieldForm({
    mutation: ADD_CUSTOM_FIELD_MUTATION,
    mutationName: 'customFieldAdd',
    onCancel,
  });

  const onAdd = useCallback(
    (customFieldName, customFieldChoices, customFieldType) =>
      onSave({
        name: customFieldName,
        choices: customFieldChoices,
        fieldType: customFieldType,
      }),
    [onSave],
  );

  return (
    <CustomFieldFormModal
      onSave={onAdd}
      title={t('CustomFieldFormModal_title')}
      isVisible={isVisible}
      onCancel={onCancel}
      fieldErrors={fieldErrors}
      setFieldErrors={setFieldErrors}
      clearFieldError={clearFieldError}
      generalError={generalError}
      isSaving={isSaving}
    />
  );
}

CustomFieldAddModal.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
};

export default CustomFieldAddModal;

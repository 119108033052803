import React, { Suspense } from 'react';
import { hot, setConfig } from 'react-hot-loader';
import { I18nextProvider } from 'i18n';
import { ApolloProvider } from '@apollo/client';
import { client } from 'apollo';
// import { NetworkStatusNotifier } from 'apollo/client';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import LogRocket from 'logrocket';
import moment from 'moment';

import DraggerProvider from 'providers/DraggerProvider';
import BrowserWidthProvider from 'providers/BrowserWidthProvider';
import Login from 'pages/authentication/Login';
import Logout from 'pages/authentication/Logout';
import AccountVerified from 'pages/authentication/AccountVerified';
import Register from 'pages/authentication/Register';
import WithInvitationDetails from 'pages/authentication/Register/WithInvitationDetails';
import AuthenticatedLayout from 'pages/layout/AuthenticatedLayout';
// import GlobalLoader from 'components/GlobalLoader';
import Loader from 'components/Loader';
import HelmetMeta from 'pages/layout/HelmetMeta';
import { useSaveUTMs } from 'hooks/useSaveUTMs';

import * as config from 'config';
import i18n from '../i18n';

setConfig({
  pureSFC: true,
});

if (!config.SKIP_TRACKING) {
  LogRocket.init('405zcj/workate-app');
}

i18n.on('loaded', () => moment.locale(i18n.language));

function App() {
  useSaveUTMs();
  return (
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={client}>
        <BrowserWidthProvider>
          <DraggerProvider>
            <>
              <HelmetMeta />
              {/* <NetworkStatusNotifier
            render={({ loading }) => <GlobalLoader loading={loading} />}
          /> */}
              <Suspense fallback={<Loader />}>
                <BrowserRouter>
                  <Routes>
                    <Route path="login" element={<Login />} />
                    <Route path="logout" element={<Logout />} />
                    <Route
                      path="register/:id"
                      element={<WithInvitationDetails />}
                    />
                    <Route path="register" element={<Register />} />
                    <Route
                      path="account-verified"
                      element={<AccountVerified />}
                    />
                    <Route path="/*" element={<AuthenticatedLayout />} />
                  </Routes>
                </BrowserRouter>
              </Suspense>
            </>
          </DraggerProvider>
        </BrowserWidthProvider>
      </ApolloProvider>
    </I18nextProvider>
  );
}

export default hot(module)(App);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Loader from 'components/Loader';
import Content from 'components/Content';
import { ROLE_SUPERADMIN } from 'consts/roles';
import { roleName } from 'utils/translation';
import Icon, { CheckOutlined } from '@ant-design/icons';

import styles from './PermissionsDescriptionTable.less';

function PermissionsDescriptionTable({ roles, t }) {
  if (roles.length === 0) {
    return (
      <Content
        title={t('PermissionsDescriptionTable_title')}
        secondary
        size="small"
      >
        <Loader />
      </Content>
    );
  }

  const countRolesWithPermission = (permission) =>
    roles.filter((role) => role.permissions.includes(permission)).length;

  // All possible permissions from superadmin, used for comparison in roleGuide
  const allPermissions = roles.find(
    (r) => r.name === ROLE_SUPERADMIN,
  ).permissions;

  const sortedPermissions = [...allPermissions].sort(
    (a, b) => countRolesWithPermission(b) - countRolesWithPermission(a),
  );

  return (
    <Content
      className={cx(styles.tableWrapper)}
      title={t('PermissionsDescriptionTable_title')}
      secondary
      size="small"
    >
      <table className={cx(styles.table)}>
        <thead>
          <tr>
            <th aria-label={t('PermissionsDescriptionTable_title')}> </th>
            {roles.map((role) => (
              <th key={role.id}>{roleName(t, role.name)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedPermissions.map((permission) => (
            <tr key={permission}>
              <td className={cx(styles.permissionColumn)}>
                {t(`PermissionsDescriptionTable_role-${permission}`)}
              </td>
              {roles.map((role) => {
                const hasPermission = role.permissions.includes(permission);
                return (
                  <td
                    className={cx(
                      styles.permissionCheckbox,
                      hasPermission && styles.iconHasPermission,
                    )}
                    key={role.id}
                  >
                    <Icon
                      component={hasPermission ? CheckOutlined : null}
                      data-testid="permissions-table-icon"
                      aria-label={hasPermission ? 'check' : 'empty'}
                    />
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </Content>
  );
}

PermissionsDescriptionTable.propTypes = {
  t: PropTypes.func.isRequired,
  roles: PropTypes.array,
};

export default PermissionsDescriptionTable;

import { useCallback } from 'react';

import { logAndShowGenericError } from 'utils/log';

import { evolve, filter } from 'ramda';

import { useMutation } from '@apollo/client';

import useCurrentOrganization from 'hooks/useCurrentOrganization';

import notificationsQuery from 'hooks/useNotificationsQuery/notificationsQuery.gql';
import deleteNotificationMutation from './deleteNotificationMutation.gql';

export default function useNotificationDelete({ notificationPk, ordering }) {
  const [deleteNotification] = useMutation(deleteNotificationMutation);
  const [organizationPk] = useCurrentOrganization();

  const handleDeleteNotification = useCallback(() => {
    deleteNotification({
      variables: { notificationsPks: [notificationPk] },
      optimisticResponse: {
        notificationsDelete: {
          errors: null,
          __typename: 'DeleteNotificationMutation',
        },
      },
      update: (proxy) => {
        const query = notificationsQuery;
        const variables = { organizationPk, ordering };
        const data = proxy.readQuery({
          query,
          variables,
        });
        proxy.writeQuery({
          query,
          variables,
          data: evolve(
            {
              viewer: {
                notifications: {
                  edges: filter((edge) => edge.node.pk !== notificationPk),
                },
              },
            },
            data,
          ),
        });
      },
    })
      .then(({ data }) => {
        if (data.notificationsDelete.errors) {
          logAndShowGenericError(`delete notification rejected`, {
            notificationPk,
            data,
          })();
        }
      })
      .catch(
        logAndShowGenericError('delete notification rejected', {
          notificationPk,
        }),
      );
  }, [deleteNotification, notificationPk, ordering, organizationPk]);

  return [handleDeleteNotification];
}

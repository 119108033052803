import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'i18n';

import { MODULE_EMAIL_SEND } from 'consts/organizationModules';
import { EMAIL } from 'consts/contactTypes';
import { FREE_PLAN_LIMITS } from 'consts/freePlanLimits';
import { PLAN_TYPES } from 'consts/planTypes';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useOrganizationModules from 'hooks/useOrganizationModules';

import billingInformationQuery from 'components/ActivateOrChangePlanButton/billingInformationQuery.gql';

/**
 * This hook answers the question: can email be send?
 * Checks if organisation has emailing module,
 * if email limit is exceeded and if candidate has email adress provided.
 * If email can not be send, hook returns proper message giving the reason.
 * hasEmailingModule is returned as a separate variable,
 * because without email module 'compose email' button shouldn't be rendered at all.
 *
 * @returns { boolean, boolean, string } - hasEmailingModule, canSendEmail, disabledSendingMessage
 *
 *
 * @example
 *  const {
 *    hasEmailingModule,
 *    canSendEmail,
 *    disabledSendingMessage,
 * } = useCanSendEmail({ contactDetails });
 *
 * console.log(canSendEmail);
 * // false
 *
 * console.log(disabledSendingMessage);
 * // "noCandidateEmail"
 *
 */

const useCanSendEmail = ({ contactDetails }) => {
  const { t } = useTranslation();
  const [organizationPk] = useCurrentOrganization();
  const { data: billingInformationData, loading } = useQuery(
    billingInformationQuery,
    {
      variables: { organizationPk },
    },
  );

  const billing =
    billingInformationData &&
    billingInformationData.viewer.organizations[0].billing;

  const [hasEmailingModule] = useOrganizationModules([MODULE_EMAIL_SEND]);
  const candidateEmails = useMemo(
    () => contactDetails.filter(({ contactType }) => contactType === EMAIL),
    [contactDetails],
  );

  const isEmailLimitExceeded = useMemo(
    () =>
      billing &&
      billing.planType === PLAN_TYPES.FREE &&
      billing.emailsCount > FREE_PLAN_LIMITS.MAX_EMAILS,
    [billing],
  );

  const canSendEmail = useMemo(
    () => !isEmailLimitExceeded && candidateEmails.length > 0,
    [candidateEmails, isEmailLimitExceeded],
  );

  const disabledSendingMessage = useMemo(() => {
    // return just one message, begining from the crucial one about EmailLimitExceeded
    // only if limit is not exceeded check candidate emails
    if (isEmailLimitExceeded) {
      return t('ApplicationMessage_tooltip--maxEmails');
    }
    if (!isEmailLimitExceeded && candidateEmails.length === 0) {
      return t('noCandidateEmail');
    }
  }, [isEmailLimitExceeded, t, candidateEmails]);

  return {
    hasEmailingModule,
    disabledSendingMessage,
    canSendEmail,
    loading,
  };
};

export default useCanSendEmail;

import { useCallback } from 'react';
import { useTranslation } from 'i18n';
import showNoPermissionMessage from 'utils/showNoPermissionMessage';
import useMembership from './useMembership';

/**
 * Hook for checking user permissions fetched from Membership query match permission passed as arguments
 * (array of permissions can be passed)
 * If the permissions array is not loaded yet, an empty array will be returned.
 * Array also contains translated showNoPermissionMessage for displaying popup
 * in case user doesn't have permissions required
 *
 * @returns [Array]
 *
 * @example
 * [hasPermission, { showNoPermissionMessage: showNoPermissionMessageMemoized }] = usePermissions([PERMISSION_MANAGE]);
 *
 * console.log(hasPermission);
 * // false
 */
export default function usePermissions(requiredPermissions) {
  const [membership] = useMembership();

  const { t } = useTranslation();
  const showNoPermissionMessageMemoized = useCallback(
    () => showNoPermissionMessage(t),
    [t],
  );

  const grantedPermissions = membership ? membership.role.permissions : [];
  const loading = !membership;

  return [
    ...requiredPermissions.map((permission) =>
      grantedPermissions.includes(permission),
    ),
    { showNoPermissionMessage: showNoPermissionMessageMemoized, loading },
  ];
}

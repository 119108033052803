import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

import styles from './ActiveFilterTag.less';

function ActiveFilterTag({
  filterKey,
  filterValues,
  filterName,
  handleFilterChange,
}) {
  return (
    <Tag
      key={filterKey}
      closable
      onClose={() => handleFilterChange(filterName)([])}
      className={styles.filterTag}
      data-testid={`filter-tag-${filterKey}`}
    >
      {`${filterKey}: ${
        Array.isArray(filterValues) ? filterValues.join(', ') : filterValues
      }`}
    </Tag>
  );
}

ActiveFilterTag.propTypes = {
  filterKey: PropTypes.string.isRequired,
  filterValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  filterName: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

export default ActiveFilterTag;

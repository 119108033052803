import { useCallback } from 'react';
import { message } from 'antd';
import { useTranslation } from 'i18n';
import { useMutation } from '@apollo/client';
import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';

import { validator, isRequired, allAreRequired } from 'utils/validator';
import { logAndShowGenericError } from 'utils/log';
import { CUSTOM_FIELDS_QUERY } from 'pages/settings/custom-fields/useOrganizationCustomFields';
import useMembership from 'hooks/useMembership';
import { evolve } from 'ramda';

const VALIDATE_FIELDS = {
  name: [isRequired],
  choices: [allAreRequired],
};

/**
 * Custom hook for handling mutations and validation of adding and editing custom fields
 */
const useCustomFieldForm = ({ mutation, mutationName, onCancel }) => {
  const [saveMutation] = useMutation(mutation);
  const [
    {
      organization: { pk },
    },
  ] = useMembership();

  const {
    mutate,
    fieldErrors,
    setFieldErrors,
    clearFieldError,
    generalError,
    isSaving,
  } = useErrorFieldsMutation(saveMutation);

  const { t } = useTranslation();
  const getValidationErrors = validator(VALIDATE_FIELDS);

  const onSave = useCallback(
    ({ name, customFieldPk, choices, fieldType }) => {
      const validationErrors = getValidationErrors({
        name: name && name.trim(),
        choices:
          choices.length > 0 && choices.find((choice) => choice.value === ''),
      });

      if (validationErrors) {
        return setFieldErrors(validationErrors);
      }

      mutate({
        variables: {
          organizationPk: pk,
          name,
          customFieldPk,
          choices,
          fieldType,
        },
        update: (
          proxy,
          {
            data: {
              [mutationName]: { customField },
            },
          },
        ) => {
          // check if mutation is successful (if customField exists) before updating UI
          if (customField && !customFieldPk) {
            const data = proxy.readQuery({
              query: CUSTOM_FIELDS_QUERY,
              variables: { organizationPk: pk },
            });

            proxy.writeQuery({
              query: CUSTOM_FIELDS_QUERY,
              variables: { organizationPk: pk },
              data: evolve(
                {
                  viewer: {
                    organizations: [
                      { customFields: (cfs) => [customField, ...cfs] },
                    ],
                  },
                },
                data,
              ),
            });
          }
        },
      })
        .then(({ ok }) => {
          if (ok) {
            message.info(t('SaveCustomField_mutation-succesful'));
            onCancel();
          }
        })
        .catch(
          logAndShowGenericError(
            `${mutationName}
          rejected`,
            { name, organizationPk: pk, customFieldPk },
          ),
        );
    },
    [
      mutate,
      setFieldErrors,
      onCancel,
      pk,
      getValidationErrors,
      mutationName,
      t,
    ],
  );

  return {
    onSave,
    setFieldErrors,
    fieldErrors,
    clearFieldError,
    generalError,
    isSaving,
  };
};

export default useCustomFieldForm;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'apollo';
import cx from 'classnames';
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { Trans, withTranslation } from 'i18n';
import S3Upload from 'react-s3-uploader/s3upload';
import uniqBy from 'lodash-es/uniqBy';

import withDragger from 'hoc/withDragger';
import { showError } from 'utils/message';

import styles from './index.less';

class FileUpload extends PureComponent {
  static propTypes = {
    signingUrl: PropTypes.string.isRequired,
    onFileUpload: PropTypes.func.isRequired,
    onFileRemove: PropTypes.func,
    multiple: PropTypes.bool,
    forwardedRef: PropTypes.object,
    uploadedFileList: PropTypes.array,
    acceptedFormats: PropTypes.array,
    visible: PropTypes.bool,
    miniDropzone: PropTypes.bool,
    uploadMessage: PropTypes.string,
    // From HoCs
    t: PropTypes.func.isRequired,
    isDragged: PropTypes.bool.isRequired,
    withFilesList: PropTypes.bool,
  };

  static defaultProps = {
    uploadedFileList: [],
    multiple: true,
  };

  state = {
    fileList: [],
  };

  onChange = (info) => {
    if (info.file.status === 'done') {
      return this.props
        .onFileUpload(info)
        .then(() => {
          this.setState({
            fileList: info.fileList.filter(
              ({ isInitial, status }) => !isInitial && status !== 'done',
            ),
          });
        })
        .catch((message) => {
          this.setState({
            fileList: [
              ...info.fileList.filter(
                ({ uid, isInitial, status }) =>
                  !isInitial && status !== 'done' && uid !== info.file.uid,
              ),
              {
                ...info.file,
                status: 'error',
                response: message,
              },
            ],
          });
        });
    }

    this.setState({
      fileList: info.fileList.filter(
        ({ isInitial, status }) => !isInitial && status !== 'done',
      ),
    });
  };

  onUpload = ({ file, onError, onProgress, onSuccess }) => {
    // eslint-disable-next-line no-new
    new S3Upload({
      files: [file],
      signingUrl: this.props.signingUrl,
      onProgress: (percent) => onProgress({ percent }),
      onFinishS3Put: (response) => onSuccess(response),
      onError: (error) => onError({ statusText: error }),
      uploadRequestHeaders: {},
      contentDisposition: 'auto',
      signingUrlWithCredentials: true,
    });
  };

  onRemove = (file) => {
    if (file.isInitial) {
      return this.props.onFileRemove(file).catch((err) => {
        showError(err);
        return Promise.reject();
      });
    }

    return true;
  };

  render() {
    const {
      t,
      isDragged,
      forwardedRef,
      visible,
      miniDropzone,
      uploadedFileList,
      acceptedFormats,
      uploadMessage,
      withFilesList = true,
      ...props
    } = this.props;

    return (
      <Upload
        type="drag"
        ref={forwardedRef}
        className={cx(
          styles.dropzone,
          miniDropzone && styles.miniDropzone,
          visible && styles.visible,
          isDragged && styles.dragged,
          !withFilesList && styles.candidateDropzone,
        )}
        name="file"
        onChange={this.onChange}
        customRequest={this.onUpload}
        onRemove={this.onRemove}
        fileList={uniqBy(
          [...uploadedFileList, ...this.state.fileList],
          (o) => o.uid,
        )}
        acceptedFormats={acceptedFormats}
        {...props}
      >
        <>
          <p className={cx('ant-upload-drag-icon', styles.dragIcon)}>
            <InboxOutlined />
          </p>
          <p className={cx('ant-upload-text', styles.uploadMessage)}>
            {uploadMessage || t('dragAndDropFilesHereToUpload')}
          </p>
          <p className={cx('ant-upload-hint', styles.uploadHint)}>
            {/* Key depends on the accepted files list */}
            <Trans
              i18nKey={`fileUploadPrompt--${
                acceptedFormats ? acceptedFormats.join(', ') : 'anyFormat'
              }`}
            >
              You can upload{' '}
              {acceptedFormats ? acceptedFormats.join(', ') : 'any'} format.
            </Trans>
          </p>
        </>
      </Upload>
    );
  }
}

const WrappedComponent = compose(
  withTranslation('translation'),
  withDragger,
)(FileUpload);

export default React.forwardRef((props, ref) => (
  <WrappedComponent {...props} forwardedRef={ref} />
));

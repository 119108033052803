import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation, Trans } from 'i18n';
import Link from 'components/Link';
import { compose } from 'apollo';

import { nl2br } from 'utils/text';

import EmailComposeButton from 'components/EmailComposeButton';

import Icon from '@ant-design/icons';
import ReplyIcon from 'icons/ReplyIcon';

import Activity from './Activity';

function InboundEmail({
  application,
  inApplicationContext,
  email,
  onClick,
  ...props
}) {
  const firstName = application.candidate.firstName;
  const lastName = application.candidate.lastName;
  const position = application.jobPosition.position;
  const url = `/candidates/${application.candidate.pk}/${application.pk}`;

  const { t } = useTranslation();

  return (
    <Activity
      {...props}
      createdBy={email.fromIdentity}
      attachments={email.candidateFiles}
      contactDetails={application.candidate.contact}
      icon="mail"
      action={
        <Trans
          i18nKey={`inboundEmailActivityHeader${
            inApplicationContext ? '_inApplication' : ''
          }`}
        >
          sent an email{' '}
          <span>
            {!inApplicationContext ? (
              <>
                in{' '}
                <Link onClick={onClick} to={url}>
                  {{ firstName }} {{ lastName }}
                </Link>{' '}
                application for {{ position }}
              </>
            ) : (
              ''
            )}
          </span>
        </Trans>
      }
      detailsExcerpt={email.subject}
      details={<div>{nl2br(email.message)}</div>}
      detailsToolbar={
        inApplicationContext && (
          <EmailComposeButton
            candidate={application.candidate}
            application={application}
            contactDetails={application.candidate.contact}
            replyToEmailInstance={email}
            icon={<Icon component={ReplyIcon} aria-hidden />}
            size="small"
            openButton
          >
            {t('InboundEmail_button-replyEmail')}
          </EmailComposeButton>
        )
      }
    />
  );
}

InboundEmail.propTypes = {
  application: PropTypes.object.isRequired,
  inApplicationContext: PropTypes.bool,
  email: PropTypes.object.isRequired,

  // fired whenever user clicks the activity and is navigated to the link to the activities page,
  // e.g. used to set notification as read
  onClick: PropTypes.func,
};

export default compose(withTranslation('translation'))(InboundEmail);

/**
 * Takse ruleSet with validation rules and returns function that
 * takes valuesDict — an object that should be validated against rules.
 *
 * @example
 * const errors = createValidator({
 *   field1: [isRequired],
 *   field2: [isRequired],
 * })({
 *   field1: 'My Name',
 *   field_x: 'Some text',
 * });
 *
 * if (!errors) {
 *  // ... process request ...
 * } else {
 *  console.log(errors); // --> { field2: 'fieldIsRequired' }
 * }
 *
 * @param  {Object} ruleSet       validation rules
 * @param  {Object} valuesDict    object whith fields to be validated
 * @return {boolean|Object}       true on success, object with errors
 */
import i18n from 'i18n';
import * as yup from 'yup';

export const validator = (ruleSet) => (valuesDict) => {
  // Go through all rules in ruleSet.
  const errors = Object.entries(ruleSet).reduce(
    (accErrors, [fieldName, rules]) => {
      try {
        // Go through all defined rules. If no error is thrown,
        // return previously accumulated errors. Notice, that we're passing
        // both current field value as well as the whole valuesDict (so we can
        // validate current field using values from different fields as well)
        return (
          rules.every((rule) => rule(valuesDict[fieldName], valuesDict)) &&
          accErrors
        );
      } catch (validationError) {
        // If an Error is thrown, add current field to the accumulated errors.
        return {
          ...accErrors,
          [fieldName]: validationError.message,
        };
      }
    },
    {},
  );

  return Object.keys(errors).length > 0 ? errors : false;
};

/**
 * @param  {any}  value
 * @return {Boolean}    true if value is not empty
 */
export const isRequired = (value) => {
  if (value) {
    return true;
  }

  throw Error(i18n.t('fieldIsRequired'));
};

/**
 * @param  {any}  value
 * @return {Boolean}    true if there is no empty element
 */

export const allAreRequired = (emptyElement) => {
  if (emptyElement) {
    throw Error(i18n.t('allFieldsAreRequired'));
  }
  return true;
};

/**
 * Returns function that checks if current `value` or any of the passed `fields` is not empty.
 *
 * @param  {...String}    fields
 * @return {function}
 */
export const someRequired =
  (...fields) =>
  (value, valuesDict) => {
    if (value || fields.some((field) => valuesDict[field])) {
      return true;
    }

    throw Error(i18n.t(`fieldIsRequired_orCheck_${fields.join('_')}`));
  };

/* eslint-disable max-len, no-useless-escape */
const emailRegex =
  /(^(([^\s<>()\[\]\\.,;:@"]+(\.[^<>()\[\]\\.,;:@"]+)*))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/;
const phoneRegex =
  /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|x)[\-\.\ \\\/]?(\d+))?$/;
// The same regex as used by django UrlValidator used on the BE
const urlRegex =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
/* eslint-enable */

export const checkEmail = (address) => {
  if (address.match(emailRegex)) {
    return true;
  }

  throw Error(i18n.t('enterValidEmail'));
};

export const checkPhone = (number) => {
  if (number.match(phoneRegex)) {
    return true;
  }

  throw Error(i18n.t('enterValidPhoneNumber'));
};

export const getContactType = (contact) => {
  if (contact.match(emailRegex)) return 'EMAIL';
  if (contact.match(phoneRegex)) return 'PHONE';
  if (contact.match(urlRegex)) return 'URL';
};

export const emailOrPhoneOrUrl = (input) => {
  if (input.match(emailRegex) || input.match(phoneRegex)) return true;
  if (input.match(urlRegex)) {
    const url = new URL(input);
    if (['linkedin.com', 'www.linkedin.com'].includes(url.hostname))
      return true;
  }

  throw Error(i18n.t('enterEmailOrPhoneOrUrl'));
};

export const validationSchema = (t) => {
  const inputSchema = yup.string().required(t('fieldIsRequired'));

  const iconItemSchema = yup.object().shape({
    text: inputSchema,
    iconValue: inputSchema,
  });

  const accordionItemSchema = yup.object().shape({
    title: inputSchema,
    description: inputSchema,
    iconValue: inputSchema,
  });

  const accordionBlockSchema = yup.object().shape({
    accordionBlock: yup.object().shape({
      title: inputSchema,
      items: yup.array().of(accordionItemSchema),
    }),
  });

  const iconListBlockSchema = yup.object().shape({
    iconListBlock: yup.object().shape({
      title: inputSchema,
      iconList: yup.array().of(iconItemSchema),
    }),
  });

  const aboutUsBlockSchema = yup.object().shape({
    aboutUsBlock: yup.object().shape({
      title: inputSchema,
      description: inputSchema,
    }),
  });

  const applyBlockSchema = yup.object().shape({
    applyBlock: yup.object().shape({
      title: inputSchema,
    }),
  });

  const processBlockSchema = yup.object().shape({
    processBlock: yup.object().shape({
      title: inputSchema,
      iconList: yup.array().of(iconItemSchema),
    }),
  });

  return yup.object().shape({
    title: inputSchema,
    subtitle: inputSchema,
    iconList: yup.array().of(iconItemSchema),
    blocks: yup.array().of(
      yup.lazy((obj) => {
        // Check the keys in the object to determine the schema
        const key = Object.keys(obj)[0]; // Assuming each object has only one key
        switch (key) {
          case 'accordionBlock':
            return accordionBlockSchema;
          case 'iconListBlock':
            return iconListBlockSchema;
          case 'aboutUsBlock':
            return aboutUsBlockSchema;
          case 'applyBlock':
            return applyBlockSchema;
          case 'processBlock':
            return processBlockSchema;
          default:
            return yup.mixed().oneOf([]);
        }
      }),
    ),
  });
};

import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import CandidateWithApplications from 'pages/candidates/CandidateWithApplications';

function CandidateWithApplicationsDrawer({
  candidatePk,
  activeApplicationPk,
  setActiveApplication,
  onCandidateDeleted,
  popupContainerRef,
}) {
  const onActiveApplicationChange = useCallback(
    (newApplicationPk) => {
      setActiveApplication(newApplicationPk);
    },
    [setActiveApplication],
  );

  return (
    <CandidateWithApplications
      candidatePk={candidatePk}
      activeApplicationPk={activeApplicationPk}
      onApplicationSelect={onActiveApplicationChange}
      onApplicationDeleted={onActiveApplicationChange}
      onCandidateDeleted={onCandidateDeleted}
      popupContainerRef={popupContainerRef}
      inDrawer
    />
  );
}

CandidateWithApplicationsDrawer.propTypes = {
  candidatePk: PropTypes.string,
  activeApplicationPk: PropTypes.string,
  setActiveApplication: PropTypes.func,
  onCandidateDeleted: PropTypes.func,
  popupContainerRef: PropTypes.object,
};

export default memo(CandidateWithApplicationsDrawer);

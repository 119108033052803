import React from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';

import RatingSelect from 'pages/candidates/Rating/RatingSelect';
import useCurrentOrganization from 'hooks/useCurrentOrganization';

// eslint-disable-next-line max-len
import candidateWithApplicationsQuery from 'pages/candidates/CandidateWithApplications/candidateWithApplicationsQuery.gql';
import activitiesQuery from 'pages/candidates/ApplicationActivityFeed/activitiesQuery.gql';
import { propTypes } from 'react-markdown';
import changeApplicationRatingMutation from './changeApplicationRatingMutation.gql';
import ratingScaleQuery from './ratingScaleQuery.gql';

function Rating({
  rating,
  applicationPk,
  applicationId,
  ratingScalePk,
  candidatePk,
  ...rest
}) {
  const [organizationPk] = useCurrentOrganization();

  const { data: ratingData } = useQuery(ratingScaleQuery, {
    variables: { organizationPk, ratingScalePk },
  });

  const ratingList = ratingData?.viewer.ratingScale.options || [];

  const [changeRating, { loading: isChangingRating }] = useMutation(
    changeApplicationRatingMutation,
    {
      refetchQueries: [candidateWithApplicationsQuery, activitiesQuery],
      update: (
        proxy,
        {
          data: {
            changeApplicationRatingOption: { application },
          },
        },
      ) => {
        const candidateQueryData = proxy.readQuery({
          query: candidateWithApplicationsQuery,
          variables: { organizationPk, candidatePk },
        });
        const updatedData = {
          ...candidateQueryData,
          viewer: {
            ...candidateQueryData.viewer,
            candidate: {
              ...candidateQueryData.viewer.candidate,
              modifiedAt: application.modifiedAt, // Manually updating the modifiedAt field
            },
          },
        };

        proxy.writeQuery({
          query: candidateWithApplicationsQuery,
          variables: { organizationPk, candidatePk },
          data: updatedData,
        });
      },
    },
  );

  const onChange = (ratingOption) => {
    if (!ratingOption) {
      return;
    }
    changeRating({
      variables: {
        applicationPk,
        organizationPk,
        ratingOption: ratingOption.pk,
      },
      optimisticResponse: ratingOption
        ? {
            __typename: 'Mutation',
            changeApplicationRatingOption: {
              application: {
                pk: applicationPk,
                id: applicationId,
                name: '',
                ratingOption: {
                  pk: ratingOption.pk,
                  value: ratingOption.value,
                  id: ratingOption.id,
                  __typename: 'RatingOptionNode',
                },
                candidate: null,
                modifiedAt: moment().format(),
                __typename: 'ApplicationNode',
              },
              errors: null,
              __typename: 'ChangeApplicationRatingMutation',
            },
          }
        : undefined,
    });
  };

  return (
    <RatingSelect
      {...rest}
      isLoading={isChangingRating}
      rating={rating}
      ratingList={ratingList}
      onChange={onChange}
    />
  );
}

Rating.propTypes = {
  rating: propTypes.object,
  applicationPk: PropTypes.string.isRequired,
  applicationId: PropTypes.string,
  ratingScalePk: PropTypes.string.isRequired,
  candidatePk: PropTypes.string.isRequired,
};

export default Rating;

import { useCallback, useMemo } from 'react';

import { logAndShowGenericError } from 'utils/log';
import { useTranslation } from 'i18n';
import { notification } from 'antd';

import { useMutation } from '@apollo/client';

import notificationSubscriptionsQuery from 'components/NotificationSubscribeButton/notificationSubscriptionsQuery.gql';
import addNotificationSubscriptionMutation from './addNotificationSubscriptionMutation.gql';
import removeNotificationSubscriptionMutation from './removeNotificationSubscriptionMutation.gql';

const showSubscribeNotification = (t, jobPosition) => {
  notification.open({
    message: `${t('NotificationSubscribeButton_subscribeNotification-message', {
      jobPosition: `${jobPosition.position}`,
    })}`,
    description: `${t(
      'NotificationSubscribeButton_subscribeNotification-description',
    )}`,
    duration: 5.5,
  });
};

const showUnsubscribeNotification = (t, jobPosition) => {
  notification.open({
    message: `${t(
      'NotificationSubscribeButton_unsubscribeNotification-message',
      {
        jobPosition: `${jobPosition.position}`,
      },
    )}`,
    description: `${t(
      'NotificationSubscribeButton_unsubscribeNotification-description',
    )}`,
  });
};

export default function useNotificationSubscription({
  organizationPk,
  jobPosition,
}) {
  const [subscribeJobPostion] = useMutation(
    addNotificationSubscriptionMutation,
  );
  const [unsubscribeJobPosition] = useMutation(
    removeNotificationSubscriptionMutation,
  );
  const { t } = useTranslation();

  const jobPositionPk = jobPosition.pk;

  const query = notificationSubscriptionsQuery;
  const variables = useMemo(
    () => ({ organizationPk, jobPositionPk }),
    [organizationPk, jobPositionPk],
  );

  const handleSubscribe = useCallback(() => {
    subscribeJobPostion({
      variables: { organizationPk, jobPositionPk },
      optimisticResponse: {
        notificationSubscriptionAdd: {
          errors: null,
          subscription: {
            id: '-1',
            jobPosition: {
              id: jobPosition.id,
              pk: jobPositionPk,
              position: jobPosition.position,
              __typename: 'JobPositionNodeConnection',
            },
            __typename: 'NotificationSubscriptionNode',
          },
          __typename: 'AddNotificationSubscriptionMutation',
        },
      },
      update: (
        proxy,
        {
          data: {
            notificationSubscriptionAdd: { subscription },
          },
        },
      ) => {
        const data = proxy.readQuery({
          query,
          variables,
        });

        proxy.writeQuery({
          query,
          variables,
          data: {
            ...data,
            viewer: {
              ...data.viewer,
              notificationSubscriptions: [
                ...data.viewer.notificationSubscriptions,
                subscription,
              ],
            },
          },
        });
      },
    })
      .then(({ data }) => {
        if (data.notificationSubscriptionAdd.errors) {
          logAndShowGenericError(`subscription rejected`, {
            organizationPk,
            jobPositionPk,
            data,
          })();
        } else {
          showSubscribeNotification(t, jobPosition);
        }
      })
      .catch(
        logAndShowGenericError('subscription rejected', {
          organizationPk,
          jobPositionPk,
        }),
      );
  }, [
    subscribeJobPostion,
    organizationPk,
    jobPositionPk,
    jobPosition,
    query,
    variables,
    t,
  ]);

  const handleUnsubscribe = useCallback(() => {
    unsubscribeJobPosition({
      variables: { organizationPk, jobPositionPk },
      optimisticResponse: {
        notificationSubscriptionDelete: {
          errors: null,
          status: 'OK',
          __typename: 'RemoveNotificationSubscriptionMutation',
        },
      },
      update: (proxy) => {
        const data = proxy.readQuery({
          query,
          variables,
        });
        proxy.writeQuery({
          query,
          variables,
          data: {
            ...data,
            viewer: {
              ...data.viewer,
              notificationSubscriptions:
                data.viewer.notificationSubscriptions.filter(
                  (notificationSubscription) =>
                    notificationSubscription.jobPosition.pk !== jobPositionPk,
                ),
            },
          },
        });
      },
    })
      .then(({ data }) => {
        if (data.notificationSubscriptionDelete.errors) {
          logAndShowGenericError(`unsubscription rejected`, {
            organizationPk,
            jobPositionPk,
            data,
          })();
        } else {
          showUnsubscribeNotification(t, jobPosition);
        }
      })
      .catch(
        logAndShowGenericError('unsubscription rejected', {
          organizationPk,
          jobPositionPk,
        }),
      );
  }, [
    jobPosition,
    jobPositionPk,
    organizationPk,
    query,
    t,
    unsubscribeJobPosition,
    variables,
  ]);

  return [handleSubscribe, handleUnsubscribe];
}

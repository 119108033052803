import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { evolve } from 'ramda';

import useCurrentUser from 'hooks/useCurrentUser';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';

import { validator, isRequired } from 'utils/validator';
import { logAndShowGenericError } from 'utils/log';

import addApplicationCommentMutation from 'pages/candidates/ApplicationDetails/addApplicationCommentMutation.gql';
import activitiesQuery from 'pages/candidates/ApplicationActivityFeed/activitiesQuery.gql';
// eslint-disable-next-line max-len
import candidateWithApplicationsQuery from 'pages/candidates/CandidateWithApplications/candidateWithApplicationsQuery.gql';

const VALIDATE_COMMENT_FIELDS = {
  content: [isRequired],
};
const getCommentValidationErrors = validator(VALIDATE_COMMENT_FIELDS);

const useAddComment = ({
  application,
  candidatePk,
  onFormClose,
  setMsgText,
}) => {
  const [organizationPk] = useCurrentOrganization();
  const [currentUser] = useCurrentUser();

  const [addApplicationComment] = useMutation(addApplicationCommentMutation);

  const {
    mutate: addApplicationCommentMutate,
    fieldErrors,
    setFieldErrors,
    clearFieldError,
    isSaving,
  } = useErrorFieldsMutation(addApplicationComment);

  const onCommentSend = useCallback(
    (msgText) => {
      const content = msgText.trim();
      const validationErrors = getCommentValidationErrors({ content });

      const applicationPk = application.pk;

      if (validationErrors) {
        return setFieldErrors(validationErrors);
      }

      addApplicationCommentMutate({
        variables: {
          content,
          organizationPk,
          applicationPk,
        },
        optimisticResponse: {
          addApplicationComment: {
            __typename: 'AddApplicationCommentMutation',
            errors: null,
            applicationActivity: {
              __typename: 'ApplicationCommentActivityNode',
              id: '-1',
              pk: '-1',
              createdAt: moment().format(),
              createdBy: currentUser,
              comment: {
                __typename: 'ApplicationCommentNode',
                content,
                pk: '-1',
              },
              application: {
                __typename: 'ApplicationNode',
                pk: application.pk,
                jobPosition: {
                  id: application.jobPosition.id,
                  pk: application.jobPosition.pk,
                  __typename: 'JobPositionNode',
                  position: application.jobPosition.position,
                },
                candidate: {
                  __typename: 'CandidateTypeNode',
                  firstName: 'firstName',
                  lastName: 'lastName',
                  pk: '-1',
                  modifiedAt: moment().format(),
                  id: '-1',
                },
              },
            },
          },
        },
        update: (
          proxy,
          {
            data: {
              addApplicationComment: { applicationActivity },
            },
          },
        ) => {
          const query = activitiesQuery;
          const variables = {
            organizationPk,
            applicationPk,
          };

          // Update Activity Feed
          const activitiesQueryData = proxy.readQuery({ query, variables });

          // Helper variable that references the last activity (that is the most recent comment in this case)
          // on given application (from candidateData)
          const lastActivity = {
            node: applicationActivity,
            __typename: 'ActivityEdge',
          };
          proxy.writeQuery({
            query,
            variables,
            data: evolve(
              {
                viewer: {
                  jobApplications: {
                    edges: [
                      {
                        node: {
                          activities: {
                            edges: (edges) => [...edges, lastActivity],
                          },
                        },
                      },
                    ],
                  },
                },
              },
              activitiesQueryData,
            ),
          });

          // Update Candidate's Data
          const candidateQueryData = proxy.readQuery({
            query: candidateWithApplicationsQuery,
            variables: { organizationPk, candidatePk },
          });

          const evolvedData = evolve(
            {
              viewer: {
                candidate: {
                  modifiedAt: () => moment().format(),
                },
              },
            },
            candidateQueryData,
          );

          proxy.writeQuery({
            query: candidateWithApplicationsQuery,
            variables: { organizationPk, candidatePk },
            data: evolvedData,
          });
        },
      })
        .catch(
          logAndShowGenericError('addApplicationComment rejected', {
            content,
          }),
        )
        .finally(() => {
          onFormClose();
          setMsgText('');
          clearFieldError('content');
        });
    },
    [
      application,
      onFormClose,
      setMsgText,
      setFieldErrors,
      currentUser,
      organizationPk,
      addApplicationCommentMutate,
      clearFieldError,
      candidatePk,
    ],
  );
  return { onCommentSend, fieldErrors, clearFieldError, isSaving };
};

export default useAddComment;

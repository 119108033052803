import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const DraggerContext = React.createContext(false);

export const { Consumer: DraggerConsumer } = DraggerContext;

export default class DraggerProvider extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = {
    isDragging: false,
  };

  first;

  second;

  componentDidMount() {
    document.body.addEventListener('dragenter', this.onDragEnter);
    document.body.addEventListener('dragleave', this.onDragLeave);
    document.body.addEventListener('drop', this.onDrop);
  }

  componentWillUnmount() {
    document.body.removeEventListener('dragenter', this.onDragEnter);
    document.body.removeEventListener('dragleave', this.onDragLeave);
    document.body.removeEventListener('drop', this.onDrop);
  }

  onDragEnter = (e) => {
    if (this.first) {
      this.second = true;
      return;
    }

    this.first = true;

    const { types } = e.dataTransfer;
    if (
      types && types.indexOf
        ? types.indexOf('Files') !== -1
        : types.contains('Files')
    ) {
      this.setState({ isDragging: true });
    }

    e.preventDefault();
  };

  onDragLeave = (e) => {
    if (this.second) {
      this.second = false;
    } else if (this.first) {
      this.first = false;
    }

    if (!this.first && !this.second) {
      this.setState({ isDragging: false });
    }

    e.preventDefault();
  };

  onDrop = () => {
    this.first = false;
    this.second = false;
    this.setState({ isDragging: false });
  };

  render() {
    return (
      <DraggerContext.Provider value={this.state.isDragging}>
        {this.props.children}
      </DraggerContext.Provider>
    );
  }
}

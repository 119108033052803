import { graphql } from 'apollo';
import { MEMBERSHIP_QUERY } from 'hooks/useMembership';

/**
 *  @deprecated
 *  Due to change of design patterns (preferable function components instead of class components)
 *  you should use hooks/useOrganizationModules instead whenever it is possible
 */

const withOrganizationModules = graphql(MEMBERSHIP_QUERY, {
  props: ({ data: { viewer, organizationPk, loading } }) => {
    const currentMembership = viewer.currentUser.memberships.find(
      ({ organization }) => organizationPk === organization.pk,
    );
    const {
      organization: { modules },
    } = currentMembership;

    if (loading) return {};

    return {
      modules,
    };
  },
});

export default withOrganizationModules;

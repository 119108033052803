import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import styles from './index.less';

export default class ModalWithIframe extends PureComponent {
  static propTypes = {
    src: PropTypes.string,
    children: PropTypes.node,
  };

  state = {
    isOpen: false,
    iframeHeight: 300,
  };

  onModalShow = () => this.setState({ isOpen: true });

  onModalHide = () => this.setState({ isOpen: false });

  render() {
    const { isOpen, iframeHeight } = this.state;
    const { src, children } = this.props;

    return (
      <>
        <button
          type="button"
          className={styles.button}
          onClick={this.onModalShow}
        >
          {children}
        </button>
        <Modal
          visible={isOpen}
          onCancel={this.onModalHide}
          footer={null}
          wrapClassName={styles.modalWrapper}
        >
          <iframe
            data-testid="iframe"
            className={styles.frame}
            src={src}
            title={src}
            style={{ height: `${iframeHeight + 30}px` }}
          />
        </Modal>
      </>
    );
  }
}

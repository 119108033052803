import React, { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

/**
 * Component for adding event listener and cleaning it up on component unmount.
 */

function OutsideClickHandler({ onOutsideClick, children, displayInline }) {
  const boxRef = useRef();

  const onOutsideClickEvent = useCallback(
    (e) => {
      /**
       * Handle onOutsideClick only when clicking outside of the box.
       */
      if (boxRef.current && !boxRef.current.contains(e.target)) {
        onOutsideClick();
      }
    },
    [onOutsideClick],
  );

  useEffect(() => {
    document.addEventListener('click', onOutsideClickEvent, { capture: true });

    return () => {
      document.removeEventListener('click', onOutsideClickEvent, {
        capture: true,
      });
    };
  }, [onOutsideClickEvent]);

  if (displayInline) {
    return <span ref={boxRef}>{children}</span>;
  }
  return <div ref={boxRef}>{children}</div>;
}

OutsideClickHandler.propTypes = {
  onOutsideClick: PropTypes.func,
  children: PropTypes.node,
  displayInline: PropTypes.bool,
};

export default OutsideClickHandler;

import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'components/Badge';
import { withTranslation } from 'i18n';
import cx from 'classnames';

import { MAP_EMAIL_STATUSES } from 'consts/emailStatuses';

import styles from './index.less';

function EmailStatus({ status, t, noText, className }) {
  const MAP_STATUS_TO_BADGE = {
    CREATED: 'default',
    SENT: 'success',
    RECEIVED: 'success',
    FAILED: 'error',
    NOT_DELIVERED: 'error',
  };

  return (
    <span className={className}>
      {!noText && t(MAP_EMAIL_STATUSES[status])}
      <Badge
        status={MAP_STATUS_TO_BADGE[status]}
        className={cx(styles.badge, noText && styles.noText)}
      />
    </span>
  );
}

EmailStatus.propTypes = {
  status: PropTypes.string.isRequired,
  noText: PropTypes.bool,
  className: PropTypes.string,
  // From HoCs
  t: PropTypes.func.isRequired,
};

export default withTranslation('translation')(EmailStatus);

import React from 'react';
import PropTypes from 'prop-types';
import { XS } from 'consts/media';
import { withTranslation, Trans } from 'i18n';
import DateWithIcon from 'components/DateWithIcon';
import { Link } from 'react-router-dom';
import styles from './NameCell.less';

function NameCell({ pk, position, browserWidth, applications, createdAt, t }) {
  const applicationsCount = applications.totalCount;
  return (
    <div className={styles.container}>
      <Link
        className={styles.position}
        to={`/positions/${pk}`}
        data-testid="job-position-name"
      >
        {position}
      </Link>
      {browserWidth <= XS && (
        <div className={styles.details}>
          <div className={styles.applicationsCount}>
            <Trans i18nKey="applicationsCount" count={applicationsCount}>
              {{ applicationsCount }} applications
            </Trans>
          </div>
          <DateWithIcon date={createdAt} text={t('created')} />
        </div>
      )}
    </div>
  );
}

NameCell.propTypes = {
  position: PropTypes.string.isRequired,
  pk: PropTypes.string.isRequired,
  browserWidth: PropTypes.number.isRequired,
  applications: PropTypes.object.isRequired,
  createdAt: PropTypes.string.isRequired,
  // From HoCs
  t: PropTypes.func.isRequired,
};

export default withTranslation('translation')(NameCell);

import React from 'react';
import { useTranslation } from 'i18n';
import Content from 'components/Content';

function Help() {
  const { t } = useTranslation();
  return (
    <Content title={t('Help')} size="small" theme="transparent">
      <Content
        title={t('Help_supporEmailTitle')}
        secondary
        size="small"
        theme="transparent"
      >
        <a href={`mailto:${t('Help_supportEmailAddress')}`}>
          {t('Contact_supportEmailAddress')}
        </a>
      </Content>
      <Content
        title={t('Help_supportPhoneTitle')}
        secondary
        size="small"
        theme="transparent"
      >
        {t('Help_supportPhoneNumber')}
      </Content>
    </Content>
  );
}

export default Help;

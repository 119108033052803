import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';

import MoreMenu from 'components/MoreMenu';

const getMenu = (t, isHighlighted) => {
  if (!isHighlighted) {
    return [
      {
        key: 'UNREAD',
        content: t('changeNotificationStatus_menuItem-unread'),
      },
    ];
  }
  return [
    {
      key: 'READ',
      content: t('changeNotificationStatus_menuItem-read'),
    },
  ];
};

function ChangeStatusButton({ onChangeStatus, isHighlighted }) {
  const { t } = useTranslation();

  return (
    <MoreMenu
      items={getMenu(t, isHighlighted)}
      buttonType="text"
      trigger="hover"
      onItemClick={onChangeStatus}
      placement="bottomRight"
    />
  );
}

ChangeStatusButton.propTypes = {
  onChangeStatus: PropTypes.func,
  isHighlighted: PropTypes.bool,
};

export default ChangeStatusButton;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Alert from 'components/Alert';
import { useTranslation } from 'react-i18next';
import Content from 'components/Content';
import TextArea from 'components/Form/TextArea';
import Button from 'components/Form/Button';
import Field from 'components/Form/Field';
import Loader from 'components/Loader';
import { useNavigate } from 'react-router';
import AddLandingPageMutation from 'pages/job-positions/JobOffers/addLandingPageMutation.gql';
import GenerateLandingPageMutation from 'pages/job-positions/JobOffers/generateLandingPageMutation.gql';
import jobPositionDetailsQuery from 'pages/job-positions/JobPositionDetails/jobPositionDetailsQuery.gql';
import { useMutation } from '@apollo/client';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import { logAndShowGenericError } from 'utils/log';
import { showError } from 'utils/message';

import styles from './JobOfferAdd.less';

function JobOfferAdd({ jobPositionDetails, isLoading }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [organizationPk] = useCurrentOrganization();
  const { pk } = jobPositionDetails;
  const [addLandingPage] = useMutation(AddLandingPageMutation);
  const [generateLandingPage] = useMutation(GenerateLandingPageMutation);
  const [userInput, setUserInput] = useState('');
  const [inputError, setInputError] = useState('');

  const handleFormFillClick = useCallback(() => {
    addLandingPage({
      variables: { jobPositionPk: pk, organizationPk },
    })
      .then(({ data }) => {
        if (data.addLandingPage.errors) {
          return data.addLandingPage.errors.message
            ? showError(data.addLandingPage.errors.message)
            : logAndShowGenericError('addJobOffer rejected', {
                organizationPk,
                jobPositionPk: pk,
              });
        }
        navigate(`/positions/${pk}/${data.addLandingPage.landingPage.pk}`);
      })
      .catch(
        logAndShowGenericError('addJobOffer rejected', {
          organizationPk,
          jobPositionPk: pk,
        }),
      );
  }, [addLandingPage, navigate, pk, organizationPk]);

  const handleAiFillClick = useCallback(() => {
    if (!userInput.trim()) {
      setInputError(t('JobOfferAdd_error--emptyInput'));
      return;
    }
    setInputError('');
    generateLandingPage({
      variables: { jobPositionPk: pk, organizationPk, userInput },
      refetchQueries: [
        {
          query: jobPositionDetailsQuery,
          variables: { organizationPk, pk },
        },
      ],
    })
      .then(({ data }) => {
        if (data.generateLandingPage.errors) {
          return data.generateLandingPage.errors.message
            ? showError(data.generateLandingPage.errors.message)
            : logAndShowGenericError('generateLandingPage rejected', {
                organizationPk,
                jobPositionPk: pk,
                userInput,
              });
        }
        navigate(`/positions/${pk}/${data.generateLandingPage.landingPage.pk}`);
      })
      .catch(
        logAndShowGenericError('generateLandingPage rejected', {
          organizationPk,
          jobPositionPk: pk,
          userInput,
        }),
      );
  }, [generateLandingPage, navigate, pk, organizationPk, userInput, t]);

  const handleInputChange = useCallback(
    (e) => {
      setUserInput(e.target.value);
      if (e.target.value.trim()) {
        setInputError('');
      }
    },
    [setUserInput],
  );

  if (isLoading) {
    return <Loader fullSize data-testid="loader" />;
  }

  return (
    <Content
      title={t('CreateJobOffer')}
      size="small"
      theme="transparent"
      className={styles.content}
    >
      <Alert>{t('JobOfferAdd--info')}</Alert>
      <Field error={inputError}>
        <TextArea
          className={styles.input}
          placeholder={t('JobOfferAdd_textarea--placeholder')}
          onChange={handleInputChange}
          value={userInput}
        />
      </Field>
      <div className={styles.buttonBox}>
        <Button
          className={styles.button}
          onClick={handleFormFillClick}
          data-testid="add-offer-form"
        >
          {t('JobOfferAdd_button--form')}
        </Button>
        <Button
          className={styles.button}
          type="primary"
          data-testid="add-offer-AI"
          onClick={handleAiFillClick}
        >
          {t('JobOfferAdd_button--withAI')}
        </Button>
      </div>
    </Content>
  );
}

JobOfferAdd.propTypes = {
  jobPositionDetails: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default JobOfferAdd;

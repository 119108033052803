import React, {
  useCallback,
  memo,
  useState,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { DownOutlined, UserAddOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { useTranslation } from 'i18n';

import Modal from 'components/Modal';
import Form from 'components/Form';
import Button from 'components/Form/Button';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Menu from 'components/Menu';
import Dropdown from 'components/Dropdown';

import MAP_MAGIC_TAGS from 'consts/magicTags';

import useFocusEffect from 'hooks/useFocusEffect';
import styles from './EmailTemplateFormModal.less';

const FIELD_NAMES = {
  NEW_TITLE: 'newTitle',
  NEW_CONTENT: 'newContent',
};

function EmailTemplateFormModal({
  onSubmit,
  onCancel,
  fieldErrors,
  clearFieldError,
  isSaving,
  title,
  content,
}) {
  const { t } = useTranslation();

  const [newTitle, setNewTitle] = useState('');
  const [newContent, setNewContent] = useState('');
  const [cursorPosition, setCursorPosition] = useState([
    FIELD_NAMES.NEW_TITLE,
    0,
    0,
  ]);

  const buttonRef = useRef(null);
  const inputRef = useRef(null);
  const menuRef = useRef(null);

  // state to manage dropdown visiility
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const isTemplateEdited = Boolean(title);

  // moves focus to dropdown trigger on dropdown close
  useFocusEffect(buttonRef, !isDropdownOpen);

  // moves focus to dropdown menu on dropdown open
  useFocusEffect(menuRef, isDropdownOpen);

  // move trigger to first modal element on Modal component mount
  useEffect(() => {
    const timer = setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (title && content) {
      setNewTitle(title);
      setNewContent(content);
    }
  }, [title, content]);

  const handleTitleChange = useCallback(
    (e) => {
      clearFieldError(FIELD_NAMES.NEW_TITLE);
      setNewTitle(e.target.value);
    },
    [clearFieldError],
  );

  const handleContentChange = useCallback(
    (e) => {
      clearFieldError(FIELD_NAMES.NEW_CONTENT);
      setNewContent(e.target.value);
    },
    [clearFieldError],
  );

  const handleSubmit = useCallback(() => {
    onSubmit(newTitle, newContent);
  }, [onSubmit, newTitle, newContent]);

  const stringSplice = useCallback(
    (string, startIdx, endIdx, newText) =>
      `${string.slice(0, startIdx)}${newText}${string.slice(endIdx)}`,
    [],
  );

  const onMenuItemClick = useCallback(
    ({ key }) => {
      const textToInsert = `{{${key}}}`;
      const [lastFocusedField, selectionStart, selectionEnd] = cursorPosition;

      clearFieldError(lastFocusedField);

      const mapFunctionToField = {
        newTitle: setNewTitle,
        newContent: setNewContent,
      };

      const mapFields = { newTitle, newContent };

      mapFunctionToField[lastFocusedField](
        stringSplice(
          mapFields[lastFocusedField],
          selectionStart,
          selectionEnd,
          textToInsert,
        ),
      );

      setCursorPosition([
        lastFocusedField,
        selectionStart + textToInsert.length,
        selectionStart + textToInsert.length,
      ]);

      setDropdownOpen(false);
    },
    [
      clearFieldError,
      newTitle,
      newContent,
      cursorPosition,
      stringSplice,
      setDropdownOpen,
    ],
  );
  const items = useMemo(
    () =>
      Object.keys(MAP_MAGIC_TAGS).map((tag) => ({
        key: tag,
        label: t(MAP_MAGIC_TAGS[tag]),
        onClick: onMenuItemClick,
      })),
    [onMenuItemClick, t],
  );
  const magicTagsList = <Menu items={items} ref={menuRef} />;

  const handleTitleCursorPositionChange = useCallback((e) => {
    setCursorPosition([
      FIELD_NAMES.NEW_TITLE,
      e.target.selectionStart,
      e.target.selectionEnd,
    ]);

    // enalble jump from title input straight to magicTags button in order to add magicTag to input.
    if (e.key === 'ArrowDown') {
      const timer = setTimeout(() => {
        buttonRef.current?.focus();
      }, 0);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleContentCursorPositionChange = useCallback((e) => {
    setCursorPosition([
      FIELD_NAMES.NEW_CONTENT,
      e.target.selectionStart,
      e.target.selectionEnd,
    ]);
  }, []);

  return (
    <Modal
      visible
      title={
        isTemplateEdited
          ? t('EmailTemplateForm_edit')
          : t('EmailTemplateForm_add')
      }
      onCancel={onCancel}
      onOk={handleSubmit}
      cancelText={t('EmailTemplateForm_cancel')}
      okText={t('EmailTemplateForm_submit')}
      okDisabled={isSaving}
      maskClosable={false}
      // disable closing modal on Escape when dropdown is open
      keyboard={!isDropdownOpen}
      data-testid="EmailTemplateFormModal"
    >
      <div className={styles.container}>
        <Form>
          <Field error={fieldErrors.newTitle}>
            <div ref={inputRef} tabIndex="-1">
              <Input
                value={newTitle}
                placeholder={t('EmailTemplateForm_titlePlaceholder')}
                onChange={handleTitleChange}
                popupLabel
                labelDark
                disabled={isSaving}
                onClick={handleTitleCursorPositionChange}
                onKeyUp={handleTitleCursorPositionChange}
              />
            </div>
          </Field>
          <Field error={fieldErrors.newContent}>
            <Input
              value={newContent}
              placeholder={t('EmailTemplateForm_contentPlaceholder')}
              onChange={handleContentChange}
              popupLabel
              labelDark
              textarea
              autoSize={{ minRows: 4 }}
              className={styles.templateTextInput}
              disabled={isSaving}
              onClick={handleContentCursorPositionChange}
              onKeyUp={handleContentCursorPositionChange}
            />
          </Field>
        </Form>
        <div className={styles.magicTagsButton}>
          <Popover content={t('MagicTags_infoPopover')}>
            <Dropdown
              overlay={magicTagsList}
              trigger={['click']}
              data-testid="magic-tags-list"
              visible={isDropdownOpen}
              onVisibleChange={setDropdownOpen}
            >
              <Button size="default" aria-label="magic tags" ref={buttonRef}>
                <UserAddOutlined aria-hidden /> <DownOutlined aria-hidden />
              </Button>
            </Dropdown>
          </Popover>
        </div>
      </div>
    </Modal>
  );
}

EmailTemplateFormModal.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  fieldErrors: PropTypes.object,
  clearFieldError: PropTypes.func,
  isSaving: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default memo(EmailTemplateFormModal);

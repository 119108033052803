import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-use-form-state';
import { useTranslation } from 'i18n';

import Form from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import Alert from 'components/Alert';

import { showGenericError, showError } from 'utils/message';
import { validator, isRequired } from 'utils/validator';
import usePermissions from 'hooks/usePermissions';
import { PERMISSION_BILLING_PLAN_MANAGE } from 'consts/permissions';

import styles from './OrganizationBillingInfo.less';

const ORG_DETAILS_STAGE = {
  NEW: 0,
  SUBMITTED: 1,
  VERIFIED: 2,
};

const FORM_FIELDS = ['address', 'city', 'zipCode', 'state', 'country', 'taxId'];

const VALIDATE_FIELDS = FORM_FIELDS.reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: [isRequired],
  }),
  {},
);

const getValidationErrors = validator(VALIDATE_FIELDS);

function OrganizationBillingInfo({
  organizationBillingInfo,
  isBillingInfoLoading,
  onSave,
  fieldErrors,
  setFieldErrors,
  clearFieldError,
}) {
  const [formState, { text }] = useFormState(
    {
      ...FORM_FIELDS.reduce(
        (acc, curr) => ({
          ...acc,
          [curr]: '',
        }),
        {},
      ),
    },
    {
      onChange(e) {
        clearFieldError(e.target.name);
      },
    },
  );
  const [hasBillingManagePermissions, { loading }] = usePermissions([
    PERMISSION_BILLING_PLAN_MANAGE,
  ]);
  const { t } = useTranslation();
  const [isFormSaving, toggleFormSaving] = useState(false);
  const onSubmit = useCallback(() => {
    toggleFormSaving(true);

    const validationErrors = getValidationErrors(formState.values);

    if (!validationErrors) {
      onSave(formState.values)
        .catch(({ errors }) =>
          errors ? showError(errors) : showGenericError(),
        )
        .finally(() => toggleFormSaving(false));
    } else {
      setFieldErrors(validationErrors);
      toggleFormSaving(false);
    }
  }, [formState, onSave, setFieldErrors]);

  if (isBillingInfoLoading || loading) return null;

  let currentDetailsStage = ORG_DETAILS_STAGE.NEW;
  if (organizationBillingInfo.isVerified) {
    currentDetailsStage = ORG_DETAILS_STAGE.VERIFIED;
  } else if (FORM_FIELDS.every((field) => organizationBillingInfo[field])) {
    currentDetailsStage = ORG_DETAILS_STAGE.SUBMITTED;
  }

  return (
    <div className={styles.wrapper}>
      {(currentDetailsStage === ORG_DETAILS_STAGE.VERIFIED ||
        currentDetailsStage === ORG_DETAILS_STAGE.SUBMITTED) && (
        <div
          className={styles.orgInfo}
          data-testid="OrgBillingInfo-infoElement"
        >
          <b>{organizationBillingInfo.name}</b>
          <div>
            {organizationBillingInfo.address}, {organizationBillingInfo.city}{' '}
            {organizationBillingInfo.zipCode}
          </div>
          <div>
            {organizationBillingInfo.state}, {organizationBillingInfo.country}
          </div>
          <div>{organizationBillingInfo.taxId}</div>
          <div className={styles.mutedText}>
            <div>
              {currentDetailsStage === ORG_DETAILS_STAGE.VERIFIED &&
                t('OrgBillingInfo_organizationIsVerified')}
              {currentDetailsStage === ORG_DETAILS_STAGE.SUBMITTED &&
                t('OrgBillingInfo_organizationAwaitsVerification')}
            </div>
            <div>{t('OrgBillingInfo_contactUsToChangeData')}</div>
          </div>
        </div>
      )}
      {currentDetailsStage === ORG_DETAILS_STAGE.NEW && (
        <>
          {!hasBillingManagePermissions && (
            <Alert type="warning" showIcon className={styles.managerAlert}>
              {t('OrgBillingInfo_onlyManagerCanChangeAlert')}
            </Alert>
          )}
          <div className={styles.infoRequiredText}>
            {t('OrgBillingInfo_dataRequiredForVerification')}
          </div>
          <Form
            onFinish={onSubmit}
            className={styles.form}
            data-testid="OrgBillingInfo-form"
          >
            {FORM_FIELDS.map((field) => (
              <Field key={field} error={fieldErrors[field]}>
                <Input
                  {...text(field)}
                  placeholder={t(`OrgBillingInfo_${field}Field`)}
                  popupLabel
                  labelDark
                  disabled={!hasBillingManagePermissions}
                />
              </Field>
            ))}
            <Button
              type="primary"
              size="default"
              className={styles.submitBtn}
              htmlType="submit"
              disabled={!hasBillingManagePermissions || isFormSaving}
              data-testid="OrgBillingInfo-submitBtn"
            >
              {t('OrgBillingInfo_saveData')}
            </Button>
          </Form>
        </>
      )}
    </div>
  );
}

OrganizationBillingInfo.propTypes = {
  organizationBillingInfo: PropTypes.object,
  isBillingInfoLoading: PropTypes.bool,
  onSave: PropTypes.func,
  fieldErrors: PropTypes.object,
  setFieldErrors: PropTypes.func,
  clearFieldError: PropTypes.func,
};

export default OrganizationBillingInfo;

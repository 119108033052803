import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'i18n';
import { BellOutlined, BellFilled } from '@ant-design/icons';

import Button from 'components/Form/Button';
import Tooltip from 'components/Tooltip';
import Loader from 'components/Loader';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useNotificationSubscription from 'hooks/useNotificationSubscription';
import { useQuery } from '@apollo/client';

import notificationSubscriptionsQuery from './notificationSubscriptionsQuery.gql';

function SubscribeButton({ jobPosition }) {
  const [organizationPk] = useCurrentOrganization();
  const jobPositionPk = jobPosition.pk;
  const { data: subscriptions, loading } = useQuery(
    notificationSubscriptionsQuery,
    {
      variables: { organizationPk },
    },
  );
  const { t } = useTranslation();
  const isSubscribed = Boolean(
    subscriptions?.viewer.notificationSubscriptions.find(
      (subscription) => subscription.jobPosition.pk === jobPositionPk,
    ),
  );

  const subscriptionTooltip = isSubscribed
    ? t('NotificationSubscribeButton_tooltip-unsubscribe')
    : t('NotificationSubscribeButton_tooltip-subscribe');
  const [handleSubscribe, handleUnsubscribe] = useNotificationSubscription({
    organizationPk,
    jobPosition,
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <Tooltip title={subscriptionTooltip}>
      <span>
        <Button
          onClick={isSubscribed ? handleUnsubscribe : handleSubscribe}
          size="default"
          type="link"
          data-testid="subscription-action"
          aria-label="subscription bell"
        >
          {isSubscribed ? (
            <BellFilled aria-hidden />
          ) : (
            <BellOutlined aria-hidden />
          )}
        </Button>
      </span>
    </Tooltip>
  );
}

SubscribeButton.propTypes = {
  jobPosition: PropTypes.object,
};

export default SubscribeButton;

import React from 'react';
import { Form as AntForm } from 'antd';

/**
 * Abstraction wrapper around AntD Form.
 */
function Form(props) {
  return <AntForm {...props} />;
}

export default Form;

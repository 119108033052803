import { useQuery } from '@apollo/client';
import MEMBERSHIP_QUERY from './currentUserMembershipsQuery.gql';

export { MEMBERSHIP_QUERY };

/**
 * Hook for fetching current's user membership basic data as listed in the MEMBERSHIP_QUERY.
 * If the membership is not loaded yet, an empty object `{ organization: {} }` will be returned.
 *
 * @returns {Object} an membership object
 *
 * @example
 * const [{ organization }, isLoading] = useMembership();
 *
 * console.log(organization);
 * // { id: "fsdfi943m==", pk: "orejf94m4", name: "Test Organization", modules: ["candidate_add"] }
 */
export default function useMembership() {
  const { data: { viewer, organizationPk } = {}, loading } =
    useQuery(MEMBERSHIP_QUERY);

  if (loading || !organizationPk || !viewer) {
    return [null, loading];
  }

  const currentMembership = viewer.currentUser.memberships.find(
    ({ organization }) => organizationPk === organization.pk,
  );

  return [currentMembership, loading];
}

import React from 'react';
import { compose, graphql } from 'apollo';

import ActivityFeedContainer from 'components/ActivityFeed';

import withOrganizationPk from 'hoc/withOrganizationPk';

import activitiesQuery from './activitiesQuery.gql';

function ApplicationActivityFeed(props) {
  return (
    <ActivityFeedContainer
      inJobPositionContext
      inApplicationContext
      {...props}
    />
  );
}

export default compose(
  withOrganizationPk,
  graphql(activitiesQuery, {
    options: ({ organizationPk, applicationPk }) => ({
      variables: { organizationPk, applicationPk },
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { viewer, loading } }) => ({
      activities: viewer
        ? viewer.jobApplications.edges[0].node.activities.edges
        : [],
      isLoading: loading && !viewer,
    }),
  }),
)(ApplicationActivityFeed);

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { withTranslation } from 'i18n';
import styles from './index.less';

function RoleTag({ className, children, ...rest }) {
  return (
    <Tag className={cx(styles.tag, className)} {...rest}>
      {children}
    </Tag>
  );
}

RoleTag.propTypes = {
  className: PropTypes.func,
  children: PropTypes.string.isRequired,
};

export default withTranslation('translation')(RoleTag);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import { message } from 'antd';
import { compose, graphql } from 'apollo';
import { withRouter } from 'utils/withRouter';
import candidatesQuery from 'pages/candidates/CandidatesList/candidatesQuery.gql';

import withOrganizationPk from 'hoc/withOrganizationPk';
import { logAndShowGenericError } from 'utils/log';
import AddApplication from './AddAplication';
import jobPositionsQuery from './jobPositionsQuery.gql';
import addApplicationMutation from './addApplicationMutation.gql';

class AddApplicationContainer extends PureComponent {
  static propTypes = {
    candidatePk: PropTypes.string,
    toggleApplicationSelectVisibility: PropTypes.func,
    popupContainerRef: PropTypes.object,
    onApplicationSelect: PropTypes.func,

    // From HoCs
    addApplication: PropTypes.func.isRequired,
    jobPositions: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    organizationPk: PropTypes.string.isRequired,
  };

  state = {
    selectedJobPosition: '',
    isLoading: false,
  };

  onAddApplication = (jobPositionPk) => {
    const {
      candidatePk,
      organizationPk,
      addApplication,
      toggleApplicationSelectVisibility,
      onApplicationSelect,
    } = this.props;

    this.setState({
      selectedJobPosition: jobPositionPk,
      isLoading: true,
    });

    addApplication(jobPositionPk, candidatePk, organizationPk)
      .then(
        ({
          data: {
            addApplication: {
              errors,
              application: { pk },
            },
          },
        }) => {
          if (errors) {
            message.error(this.props.t('addApplicationError'));
          }

          if (toggleApplicationSelectVisibility) {
            toggleApplicationSelectVisibility();
          }

          onApplicationSelect(pk);
        },
      )
      .catch(
        logAndShowGenericError('addApplication rejected', {
          props: this.props,
          state: this.state,
        }),
      );
  };

  render() {
    const {
      jobPositions,
      toggleApplicationSelectVisibility,
      popupContainerRef,
    } = this.props;
    const { selectedJobPosition, isLoading } = this.state;

    return (
      <AddApplication
        jobPositions={jobPositions}
        selectedJobPosition={selectedJobPosition}
        onAddApplication={this.onAddApplication}
        onClose={toggleApplicationSelectVisibility}
        isLoading={isLoading}
        popupContainerRef={popupContainerRef}
      />
    );
  }
}

export default compose(
  withOrganizationPk,
  withRouter,
  withTranslation('translation'),
  graphql(jobPositionsQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      jobPositions: viewer && !loading ? viewer.jobPositions.edges : [],
    }),
  }),
  graphql(addApplicationMutation, {
    props: ({ mutate }) => ({
      addApplication: (jobPositionPk, candidatePk, organizationPk) =>
        mutate({
          variables: { jobPositionPk, candidatePk, organizationPk },
          refetchQueries: [candidatesQuery],
        }),
    }),
  }),
)(AddApplicationContainer);

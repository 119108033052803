const getCustomFieldsFilters = (filters) => {
  // If there are any custom filters applied, get their keys
  const customFieldsFiltersKeys = Object.keys(filters).filter((f) =>
    f.includes('customFieldFilter'),
  );

  // Create object containing only custom fields filters
  let customFieldsFilters = {};
  if (customFieldsFiltersKeys.length > 0) {
    customFieldsFiltersKeys.forEach((f) => {
      customFieldsFilters = {
        ...customFieldsFilters,
        [f]: filters[f],
      };
    });
  }

  return customFieldsFilters;
};

export default getCustomFieldsFilters;

import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import BlockContent from 'components/JobOfferForm/BlockContent';

import { useTranslation } from 'react-i18next';
import ControlledInput from 'components/JobOfferForm/ControlledInput';
import styles from './ApplyBlock.less';

function ApplyBlock({ blockIndex }) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const blockName = `blocks[${blockIndex}].applyBlock`;

  return (
    <BlockContent className={styles.container}>
      <ControlledInput
        control={control}
        name={`${blockName}.title`}
        popupLabel
        labelDark
        type="text"
        size="middle"
        placeholder={t('ApplyBlock_title')}
        className={styles.input}
      />
      <ControlledInput
        control={control}
        name={`${blockName}.subtitle`}
        popupLabel
        labelDark
        type="text"
        size="middle"
        placeholder={t('ApplyBlock_subtitle')}
        className={styles.input}
      />
    </BlockContent>
  );
}

ApplyBlock.propTypes = {
  blockIndex: PropTypes.number.isRequired,
};

export default ApplyBlock;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import BlockContent from 'components/JobOfferForm/BlockContent';

import { useTranslation } from 'react-i18next';
import ControlledInput from 'components/JobOfferForm/ControlledInput';
import AddButton from 'components/JobOfferForm/AddButton';
import { DeleteOutlined } from '@ant-design/icons';
import Button from 'components/Form/Button';
import styles from './AboutUsBlock.less';

function AboutUsBlock({ blockIndex }) {
  const { t } = useTranslation();
  const { control, setValue, getValues } = useFormContext();
  const [addressVisible, setAddressVisible] = useState(
    Boolean(getValues('aboutUs_address') || getValues('aboutUs_city')),
  );
  const [ytLinkVisible, setYtLinkVisible] = useState(
    Boolean(getValues('aboutUs_YT_link')),
  );

  const blockName = `blocks[${blockIndex}].aboutUsBlock`;

  // Function to clear and hide YT Link
  const handleYtLinkDelete = () => {
    setValue('aboutUs_YT_link', ''); // Clear the input
    setYtLinkVisible(false);
  };

  // Function to clear and hide Address
  const handleAddressDelete = () => {
    setValue('aboutUs_address', '');
    setValue('aboutUs_city', '');
    setAddressVisible(false);
  };

  return (
    <BlockContent className={styles.container}>
      <div className={styles.inputsWrapper}>
        <div>
          <ControlledInput
            control={control}
            name={`${blockName}.title`}
            popupLabel
            labelDark
            type="text"
            size="middle"
            placeholder={t('AboutUs_title')}
            className={styles.input}
          />
          {ytLinkVisible && (
            <div className={styles.optionalInputWrapper}>
              <ControlledInput
                control={control}
                name={`${blockName}.videoUrl`}
                popupLabel
                labelDark
                type="text"
                size="middle"
                placeholder={t('AboutUs_YT_link')}
                className={styles.input}
              />
              <Button
                iconOnly
                icon={<DeleteOutlined aria-hidden />}
                aria-label="delete-YouTube-link"
                className={styles.deleteButton}
                onClick={handleYtLinkDelete}
              />
            </div>
          )}
          <div className={styles.buttonsWrapper}>
            {!ytLinkVisible && (
              <AddButton onAddClick={() => setYtLinkVisible(true)} short>
                {t('AddYtLink')}
              </AddButton>
            )}
            {!addressVisible && (
              <AddButton onAddClick={() => setAddressVisible(true)} short>
                {t('AddAddress')}
              </AddButton>
            )}
          </div>
        </div>
        {addressVisible && (
          <div className={styles.localizationWrapper}>
            <div className={styles.optionalInputWrapper}>
              <ControlledInput
                control={control}
                name={`${blockName}.address`}
                popupLabel
                labelDark
                type="text"
                size="middle"
                placeholder={t('AboutUs_address')}
                className={styles.input}
              />
              <Button
                iconOnly
                icon={<DeleteOutlined aria-hidden />}
                aria-label="delete-address"
                className={styles.deleteButton}
                onClick={handleAddressDelete}
              />
            </div>
            <ControlledInput
              control={control}
              name={`${blockName}.city`}
              popupLabel
              labelDark
              type="text"
              size="middle"
              placeholder={t('AboutUs_city')}
              className={styles.input}
            />
          </div>
        )}
      </div>
      <ControlledInput
        control={control}
        name={`${blockName}.description`}
        popupLabel
        labelDark
        type="text"
        autoSize={{ minRows: 3, maxRows: 10 }}
        placeholder={t('AboutUs_description')}
        className={styles.textarea}
        textarea
      />
    </BlockContent>
  );
}

AboutUsBlock.propTypes = {
  blockIndex: PropTypes.number.isRequired,
};

export default AboutUsBlock;

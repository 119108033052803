import { useEffect } from 'react';

export const UTM_PARAM_NAMES = [
  'utm_source',
  'utm_campaign',
  'utm_content',
  'utm_medium',
];

/**
 * Saves utm parameters from url to local storage
 */
export const useSaveUTMs = () => {
  useEffect(() => {
    const params = new URL(document.location).searchParams;
    UTM_PARAM_NAMES.forEach((paramName) => {
      const value = params.get(paramName);
      if (value) {
        localStorage.setItem(paramName, value);
      }
    });
  });
};

import { Modal } from 'antd';

const infoModal = (title, content) => {
  Modal.info({
    title,
    content,
    width: '600px',
  });
};

export default infoModal;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { Select as AntSelect } from 'antd';
import cx from 'classnames';

import Button from 'components/Form/Button';

import styles from './index.less';

const { Option, OptGroup } = AntSelect;

class SelectFilters extends PureComponent {
  static propTypes = {
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.array,
    mode: PropTypes.string,
    className: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onClear: PropTypes.func,
    disabled: PropTypes.bool,
  };

  state = {
    isFocused: false,
  };

  onFocus = (e) => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }

    this.setState({
      isFocused: true,
    });
  };

  onBlur = (e) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    this.setState({
      isFocused: false,
    });
  };

  renderOptions = (item) => {
    if (item.list) {
      return (
        <OptGroup label={item.name} key={item.name}>
          {item.list.map(this.renderOptions)}
        </OptGroup>
      );
    }
    return (
      <Option value={item.value} key={item.value}>
        {item.text}
      </Option>
    );
  };

  render() {
    const {
      options,
      placeholder,
      value,
      mode,
      className,
      onClear,
      disabled = false,
      ...rest
    } = this.props;
    const { isFocused } = this.state;
    const isPopupLabelActive = (value && value.length > 0) || isFocused;

    return (
      <div className={cx(isPopupLabelActive && styles.active, className)}>
        <div
          className={styles.selectWrapper}
          data-testid={`select--${placeholder}`}
        >
          <AntSelect
            mode={mode}
            disabled={disabled}
            placeholder={placeholder}
            value={value}
            className={styles.select}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            {...rest}
          >
            {options.map(this.renderOptions)}
          </AntSelect>
          <span className={styles.label}>{placeholder}</span>
        </div>
        {onClear && (
          <Button
            onClick={onClear}
            type="default"
            size="default"
            className={styles.clearBtn}
          >
            <CloseOutlined />
          </Button>
        )}
      </div>
    );
  }
}

export default SelectFilters;

import React from 'react';
import { XS } from 'consts/media';
import Content from 'components/Content';
import Table from 'components/Table';
import Title from 'components/Title';
import { PERMISSION_ORGANIZATION_MANAGE } from 'consts/permissions.js';
import useBrowserWidth from 'hooks/useBrowserWidth';
import usePermissions from 'hooks/usePermissions';

import useMembership from 'hooks/useMembership';
import Loader from 'components/Loader';
import useToggleState from 'hooks/useToggleState';
import { useTranslation } from 'react-i18next';
import styles from './StageListsSettings.less';
import useOrganizationStageLists from './useOrganizationStageLists';
import StageListAddModal from './StageListAddModal/StageListAddModal';

function StageListsSettings() {
  const [membership] = useMembership();
  const browserWidth = useBrowserWidth();
  const { t } = useTranslation();

  const organizationPk = membership ? membership.organization.pk : null;

  const [stageLists, { loading }] = useOrganizationStageLists({
    organizationPk,
  });

  const [isModalVisible, onModalOpen, onModalClose] = useToggleState(false);
  const [hasOrganizationManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_ORGANIZATION_MANAGE]);

  const COLUMNS = [
    {
      title: t('StageListsSettings_thName'),
      dataIndex: 'name',
      key: 'name',
      className: styles.listName,
    },
    {
      title: t('StageListsSettings_thStages'),
      dataIndex: 'stages',
      key: 'stages',
      className: styles.listName,
    },
    {
      key: 'actions',
      dataIndex: 'actions',
    },
  ];

  if (loading) return <Loader fullSize />;

  return (
    <Content
      title={t('StageListsSettings_title')}
      secondary
      size="small"
      condensed
      withHeaderSpacing
      CTAText={t('StageListsSettings_addStageList')}
      onCTAClick={
        hasOrganizationManagePermission ? onModalOpen : showNoPermissionMessage
      }
    >
      <Title>Title_stageListsSettings</Title>
      <Table
        columns={COLUMNS}
        dataSource={stageLists.map((stageList, pk) => ({
          key: pk,
          name: stageList.name,
          stages: stageList.stages.map((stage) => stage.name).join(' > '),
        }))}
        pagination={{
          hideOnSinglePage: true,
        }}
        showHeader={browserWidth > XS}
        className={styles.table}
      />
      {isModalVisible && (
        <StageListAddModal isVisible={isModalVisible} onFinish={onModalClose} />
      )}
    </Content>
  );
}
export default StageListsSettings;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'i18n';
import { compose, graphql } from 'apollo';

import Link from 'components/Link';
import Alert from 'components/Alert';
import Button from 'components/Form/Button';
import ActivateOrChangePlanButton from 'components/ActivateOrChangePlanButton';

import withOrganizationPk from 'hoc/withOrganizationPk';

import hiddenApplicationsQuery from './hiddenApplicationsQuery.gql';

import styles from './HiddenApplicationsAlert.less';

function HiddenApplicationsAlert({ t, hidden, hiddenTotalCount }) {
  const [isAlertExpanded, toggleAlertExpanded] = useState(false);
  const isMultiple = hidden.length > 1;

  if (hidden.length === 0) return null;

  return (
    <Alert
      type="info"
      persistKey={
        isMultiple ? 'CandidatesListAlert--multiple' : 'CandidatesListAlert'
      }
      persistValue={isMultiple && hiddenTotalCount}
      closable
      cta={
        isMultiple ? (
          ''
        ) : (
          <ActivateOrChangePlanButton
            jobPositionPk={hidden[0].jobPositionPk}
            initialStage="activate"
          >
            {t('activate')}
          </ActivateOrChangePlanButton>
        )
      }
    >
      {isMultiple ? (
        <>
          {t('There are {{ count }} new applications', {
            count: hiddenTotalCount,
          })}{' '}
          {t('for {{ count }} inactive job positions', {
            count: hidden.length,
          })}
          {'. '}
          {isAlertExpanded ? (
            t('To view new applications, activate selected job position')
          ) : (
            <Button
              size="small"
              className={styles.showMore}
              onClick={() => toggleAlertExpanded(true)}
            >
              {t('more')} &rarr;
            </Button>
          )}
          {isAlertExpanded && (
            <div className={styles.alertDetails}>
              <ul className={styles.alertList}>
                {hidden.map((o) => (
                  <li key={o.jobPositionPk} className={styles.alertItem}>
                    <div className={styles.alertItemContent}>
                      <div className={styles.alertItemContentText}>
                        <Link to={`/positions/${o.jobPositionPk}`}>
                          {o.position}
                        </Link>{' '}
                        {t('has {{ count }} new applications', {
                          count: o.count,
                        })}
                      </div>
                      <ActivateOrChangePlanButton
                        size="small"
                        jobPositionPk={o.jobPositionPk}
                        initialStage="activate"
                      >
                        {t('activate')}
                      </ActivateOrChangePlanButton>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      ) : (
        <Trans i18nKey="NewCandidatesInArchivedJP" count={hidden[0].count}>
          There are {{ hiddenCount: hidden[0].count }} new applications for
          inactive{' '}
          <Link to={`/positions/${hidden[0].jobPositionPk}`}>
            {{ hiddenPosition: hidden[0].position }}
          </Link>{' '}
          position
        </Trans>
      )}
    </Alert>
  );
}

HiddenApplicationsAlert.propTypes = {
  // From HoCs
  t: PropTypes.func.isRequired,
  hidden: PropTypes.array.isRequired,
  hiddenTotalCount: PropTypes.number.isRequired,
};

export default compose(
  withTranslation('translation'),
  withOrganizationPk,
  graphql(hiddenApplicationsQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      hidden: !loading && viewer ? viewer.hiddenApplications.hidden : [],
      hiddenTotalCount:
        !loading && viewer ? viewer.hiddenApplications.hiddenTotalCount : 0,
    }),
  }),
)(HiddenApplicationsAlert);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon, {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import styles from './ProsAndConsList.less';

const MAP_ICON_TO_TYPE = {
  pros: CheckCircleOutlined,
  cons: CloseCircleOutlined,
};

function ProsAndConsList({ title, listTitle, list, type }) {
  return (
    <>
      {title && <div className={styles.bodyTitle}>{title}</div>}
      {listTitle && <div className={styles.listTitle}>{listTitle}</div>}
      <ul className={styles.list}>
        {list.map((o) => (
          <li key={o} className={styles.listItem}>
            <Icon
              component={MAP_ICON_TO_TYPE[type]}
              className={cx(
                styles.listIcon,
                type === 'pros' && styles.iconPrimary,
                type === 'cons' && styles.iconDanger,
              )}
              data-testid="antd-icon"
            />
            {o}
          </li>
        ))}
      </ul>
    </>
  );
}

ProsAndConsList.propTypes = {
  title: PropTypes.node,
  listTitle: PropTypes.node,
  list: PropTypes.array,
  type: PropTypes.oneOf(['pros', 'cons']),
};

export default ProsAndConsList;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.less';

function ArrowIcon({ className, inverted, active }) {
  return (
    <i
      className={cx(
        styles.arrow,
        className,
        inverted && styles.inverted,
        active && styles.active,
      )}
    />
  );
}

ArrowIcon.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
  active: PropTypes.bool,
};

export default ArrowIcon;

import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect } from 'antd';

const Option = AntSelect.Option;

function EmailSelect({
  candidateEmails,
  selectedEmail,
  onSelectedEmailChange,
  ...rest
}) {
  return (
    <AntSelect value={selectedEmail} onChange={onSelectedEmailChange} {...rest}>
      {candidateEmails.map(({ content }) => (
        <Option key={content} value={content}>
          {content}
        </Option>
      ))}
    </AntSelect>
  );
}

EmailSelect.propTypes = {
  candidateEmails: PropTypes.array.isRequired,
  selectedEmail: PropTypes.string.isRequired,
  onSelectedEmailChange: PropTypes.func.isRequired,
};

export default EmailSelect;

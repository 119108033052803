import React, { useEffect, useCallback, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'utils/withRouter';
import { graphql, compose } from 'apollo';
import { logout } from 'apollo/client';
import {
  ArrowRightOutlined,
  IdcardOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { message } from 'antd';
import { useTranslation, Trans } from 'i18n';
import { url } from 'utils/api';
import { track } from 'utils/track';

import Form from 'components/Form';
import Button from 'components/Form/Button';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Logo from 'components/Logo';
import LanguageSelect from 'components/LanguageSelect';
import ModalWithIframe from 'components/ModalWithIframe';
import { logAndShowGenericError } from 'utils/log';
import { validator, isRequired, checkEmail } from 'utils/validator';
import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';
import useThemeComponents from 'hooks/useThemeComponents';
import { COMPONENT_SIGNUP_BOX, COMPONENT_SIGNIN_TITLE } from 'consts/theme';
import styles from './index.less';
import tokenAuthQuery from './tokenAuth.gql';

const VALIDATE_FIELDS = {
  email: [isRequired, checkEmail],
  password: [isRequired],
};

const getValidationErrors = validator(VALIDATE_FIELDS);

function Login({ navigate, mutate }) {
  const {
    mutate: login,
    generalError,
    fieldErrors,
    setFieldErrors,
    clearFieldError,
  } = useErrorFieldsMutation(mutate);

  const initialState = {
    email: '',
    password: '',
  };

  const reducer = (state, data) => {
    if (fieldErrors[data.field]) {
      clearFieldError(data.field);
    }
    return {
      ...state,
      [data.field]: data.value,
    };
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const { t } = useTranslation();

  // TODO: We should also ping backend for logging out user. Currently only local storage is cleaned.
  useEffect(() => {
    logout(false);
  }, []);

  const onSubmit = useCallback(() => {
    const { email, password } = state;
    const validationErrors = getValidationErrors({ ...state });

    if (!validationErrors) {
      login({ variables: { email, password } })
        .then(({ data, ok }) => {
          if (ok) {
            track('login');
            navigate('/');
          } else {
            message.error(generalError || data.tokenAuth.errors.message);
          }
        })
        .catch(
          logAndShowGenericError('Login rejected', {
            email,
          }),
        );
    } else {
      setFieldErrors(validationErrors);
    }
  }, [state, generalError, navigate, login, setFieldErrors]);

  const [hasSignupBox, signInTitle] = useThemeComponents(
    COMPONENT_SIGNUP_BOX,
    COMPONENT_SIGNIN_TITLE,
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={styles.loginTitle}>
          {signInTitle || t('signInToWorkate')}
        </h2>
        <div className={styles.formContainer}>
          <Form onFinish={onSubmit}>
            <Field error={fieldErrors.email} className={styles.formField}>
              <Input
                data-testid="email"
                value={state.email}
                type="email"
                placeholder={t('loginEmailPlaceholder')}
                prefix={<IdcardOutlined className={styles.icon} />}
                onChange={(e) =>
                  dispatch({ value: e.target.value, field: 'email' })
                }
                popupLabel
                onPressEnter={onSubmit}
              />
            </Field>
            <Field error={fieldErrors.password}>
              <Input
                data-testid="password"
                value={state.password}
                placeholder={t('loginPassowrdPlaceholder')}
                prefix={<LockOutlined className={styles.icon} />}
                onChange={(e) =>
                  dispatch({ value: e.target.value, field: 'password' })
                }
                type="password"
                popupLabel
                onPressEnter={onSubmit}
              />
            </Field>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.hiddenBtn}
            />
          </Form>
          <div className={styles.formActionBox}>
            <ModalWithIframe src={url('/accounts/password/reset')}>
              <span className={styles.link}>{t('forgotPassword?')}</span>
            </ModalWithIframe>
            <Button
              data-testid="submit"
              type="primary"
              onClick={onSubmit}
              className={styles.button}
            >
              {t('loginButton')}
            </Button>
          </div>
        </div>
        {hasSignupBox && (
          <div className={styles.signUpBox}>
            <Trans i18nKey="signUpBox">
              New to Workate? &nbsp;
              <Link to="/register" className={styles.signUpLink}>
                Create an Account <ArrowRightOutlined />
              </Link>
            </Trans>
          </div>
        )}
      </div>
      <div>
        <LanguageSelect theme="transparent" />
      </div>
      <Logo className={styles.logo} />
    </div>
  );
}

Login.propTypes = {
  navigate: PropTypes.func.isRequired,
  mutate: PropTypes.func.isRequired,
};

export default compose(withRouter, graphql(tokenAuthQuery))(Login);

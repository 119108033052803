import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import useOrganizationUsers from 'hooks/useOrganizationUsers';
import { useMutation, useQuery } from '@apollo/client';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import jobPositionDetailsQuery from 'pages/job-positions/JobPositionDetails/jobPositionDetailsQuery.gql';
import usePermissions from 'hooks/usePermissions';
import { useTranslation } from 'i18n';
import { Tooltip } from 'antd';
import {
  PERMISSION_JOB_POSITION_MANAGE,
  PERMISSION_JOB_POSITION_ACCESS,
} from 'consts/permissions';
import { roleName } from 'utils/translation';
import addUsersMutation from './addJobPositionAuthorizedUsers.gql';
import removeUsersMutation from './removeJobPositionAuthorizedUsers.gql';
import styles from './JobPositionAddPermissions.less';

const isAdmin = (permissions) =>
  permissions.includes(PERMISSION_JOB_POSITION_MANAGE);

const isViewer = (permissions) =>
  permissions.includes(PERMISSION_JOB_POSITION_ACCESS);

const canHaveAccess = (permissions) =>
  isAdmin(permissions) || isViewer(permissions);

export default function JobPositionPermissionsTable({ jobPositionPk }) {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const members = useOrganizationUsers();
  const [isCurrentUserAdmin] = usePermissions([PERMISSION_JOB_POSITION_MANAGE]);
  const [orgPk, orgPkLoading] = useCurrentOrganization();
  const { loading: jobPositionLoading } = useQuery(jobPositionDetailsQuery, {
    variables: {
      organizationPk: orgPk,
      pk: jobPositionPk,
    },
    onCompleted: (result) => {
      const jobPosition = result?.viewer?.jobPositions?.edges?.[0].node;
      if (jobPosition?.authorizedUsers) {
        setSelectedRowKeys(
          Array.from(
            new Set([
              ...members
                .filter(({ role }) => isAdmin(role.permissions))
                .map(({ user }) => user.pk),
              ...members
                .filter(({ role }) => isViewer(role.permissions))
                .map(({ user }) => user.pk),
              ...jobPosition.authorizedUsers.map((u) => u.pk),
            ]),
          ),
        );
      }
    },
  });
  const [addResponsibles] = useMutation(addUsersMutation);
  const [removeResponsibles] = useMutation(removeUsersMutation);

  const columns = [
    {
      title: t('Name'),
      dataIndex: 'fullName',
    },
    {
      title: t('Email'),
      dataIndex: 'email',
    },
    {
      title: t('Role'),
      dataIndex: 'role',
    },
  ];

  const handleChange = async (updatedKeys) => {
    const added = updatedKeys.filter((k) => !selectedRowKeys.includes(k));
    const removed = selectedRowKeys.filter((k) => !updatedKeys.includes(k));

    if (added.length) {
      await addResponsibles({
        variables: {
          organizationPk: orgPk,
          jobPositionPk,
          authorizedUsersPks: added,
        },
      });

      setSelectedRowKeys((oldKeys) => oldKeys.concat(added));
    } else if (removed.length) {
      await removeResponsibles({
        variables: {
          organizationPk: orgPk,
          jobPositionPk,
          authorizedUsersPks: removed,
        },
      });

      setSelectedRowKeys(updatedKeys.filter((key) => !removed.includes(key)));
    }
  };

  const renderCell = useCallback(
    (checked, record, index, node) => {
      if (!isCurrentUserAdmin) {
        return (
          <Tooltip title={t('PermissionsTable_no_access_tooltip')}>
            {node}
          </Tooltip>
        );
      }
      return node;
    },
    [t, isCurrentUserAdmin],
  );

  const getCheckboxProps = useCallback(
    (record) => ({
      disabled: canHaveAccess(record.permissions) || !isCurrentUserAdmin,
      name: record.fullName,
    }),
    [isCurrentUserAdmin],
  );

  const dataSource = useMemo(() => {
    if (members) {
      return members
        .sort((member) => {
          if (canHaveAccess(member.role.permissions)) {
            return 1;
          }
          return -1;
        })
        .map(({ user, role }) => ({
          key: user.pk,
          role: roleName(t, role.name),
          fullName: `${user.firstName} ${user.lastName}`,
          email: user.email,
          permissions: role.permissions,
        }));
    }
    return null;
  }, [members, t]);

  if (!dataSource || jobPositionLoading || orgPkLoading) return null;

  return (
    <Table
      className={styles.table}
      rowSelection={{
        type: 'checkbox',
        onChange: handleChange,
        getCheckboxProps,
        renderCell,
        selectedRowKeys,
      }}
      pagination={false}
      columns={columns}
      dataSource={dataSource}
    />
  );
}

JobPositionPermissionsTable.propTypes = {
  jobPositionPk: PropTypes.string,
};

import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Form/Select';
import { useTranslation } from 'i18n';
import { useNavigate } from 'react-router-dom';

import useBrowserWidth from 'hooks/useBrowserWidth';
import { XS } from 'consts/media';
import { MANAGE_STAGE_LIST } from './index';

import styles from './StageListSelect.less';

function StageListSelect({ stageLists, onSelect, isDataLoading, stageListPk }) {
  const { t } = useTranslation();
  const browserWidth = useBrowserWidth();
  const navigate = useNavigate();
  const handleManageClick = useCallback(
    () => navigate('/settings/stage-lists'),
    [navigate],
  );

  const onStageListSelect = useCallback(
    (_, { value }) => {
      // 'MANAGE STAGE LIST' info is displayed as a position of Stage List Menu,
      // clicking this item should not cause "SelectStageList" action.
      if (value === MANAGE_STAGE_LIST) {
        handleManageClick();
        return;
      }
      const selectedStageList = stageLists.find(
        (stageList) => stageList.pk === value,
      );
      const { name, pk, stages } = selectedStageList;
      onSelect({ name, pk, stages });
    },
    [stageLists, onSelect, handleManageClick],
  );

  const stageListsChoices = useMemo(() => {
    const choices = stageLists.map(({ pk, name, stages }) => ({
      value: pk,
      label: name,
      optionElement: (
        <div>
          <div className={styles.stageListTitle}>{name}</div>
          <div className={styles.stageListText}>
            {stages.map((stage) => stage.name).join(' > ')}
          </div>
        </div>
      ),
    }));

    choices.push({
      value: MANAGE_STAGE_LIST,
      label: t('StageLists_manage'),
      optionElement: (
        <div className={styles.manageLink}>{t('StageLists_manage')}</div>
      ),
    });

    return choices;
  }, [stageLists, t]);

  return (
    <Select
      data-testid="stage-list-select"
      autoClearSearchValue
      value={stageListPk}
      className={styles.select}
      choices={stageListsChoices}
      dropdownMatchSelectWidth={browserWidth > XS ? 400 : undefined}
      placeholder={t('stageListPlaceholder')}
      onSelect={onStageListSelect}
      loading={isDataLoading}
      withSearch
      aria-label={t('StageLists')}
    />
  );
}

StageListSelect.propTypes = {
  isDataLoading: PropTypes.bool,
  stageListPk: PropTypes.string,
  stageLists: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      stages: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          pk: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  onSelect: PropTypes.func,
};

export default StageListSelect;

import React from 'react';
import { compose, graphql } from 'apollo';

import billingInformationQuery from 'components/ActivateOrChangePlanButton/billingInformationQuery.gql';

import withOrganizationPk from 'hoc/withOrganizationPk';

import PlanPage from './PlanPage';

function PlanPageContainer(props) {
  return <PlanPage {...props} />;
}

export default compose(
  withOrganizationPk,
  graphql(billingInformationQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      planInfo: !loading && viewer ? viewer.organizations[0].billing : {},
      isLoading: loading,
    }),
  }),
)(PlanPageContainer);

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useOrganizationUsers from 'hooks/useOrganizationUsers';
import { PERMISSION_JOB_POSITION_ACCESS } from 'consts/permissions';

import styles from './index.less';

export default function AuthorizedUsers({ users = [], t }) {
  const admins = useOrganizationUsers()
    .filter(({ role }) =>
      role.permissions.includes(PERMISSION_JOB_POSITION_ACCESS),
    )
    .map(({ user }) => user);

  const firstTwo = useMemo(
    () => [...admins, ...users].slice(0, 2),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users.length, admins.length],
  );

  const rest = useMemo(() => [...admins, ...users].slice(2), [users, admins]);

  return (
    <div className={styles.tag}>
      {firstTwo.map((user) => user.firstName).join(', ')}{' '}
      {rest.length > 0 && (
        <span style={{ textIndent: '5px' }}>
          {' '}
          + {rest.length} {t('responsibleUsersBtn__persons')}
        </span>
      )}
    </div>
  );
}

AuthorizedUsers.propTypes = {
  users: PropTypes.array,
  t: PropTypes.func,
};

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { useTranslation } from 'i18n';

import Content from 'components/Content';
import Loader from 'components/Loader';
import DateWithIcon from 'components/DateWithIcon';
import Table from 'components/Table';
import Tooltip from 'components/Tooltip';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useBrowserWidth from 'hooks/useBrowserWidth';
import { XS } from 'consts/media';
import { MAP_EMAIL_STATUSES, CREATED } from 'consts/emailStatuses';
import { nl2br } from 'utils/text';

import Icon, {
  LoadingOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import bulkEmailsQuery from '../bulkEmailsQuery.gql';
import NameCell from './NameCell';

import styles from './index.less';

const MAP_STATUS_TO_ICON = {
  CREATED: LoadingOutlined,
  SENT: CheckOutlined,
  RECEIVED: CheckOutlined,
  FAILED: CloseOutlined,
  NOT_DELIVERED: CloseOutlined,
};

const getColumns = (t) => [
  {
    title: t('thCandidate'),
    dataIndex: 'candidate',
    key: 'candiddate',

    render: (text, { candidate, toEmail }) => (
      <NameCell candidate={candidate} toEmail={toEmail} />
    ),
  },
  {
    title: t('thEmail'),
    dataIndex: 'toEmail',
    key: 'toEmail',
  },
  {
    title: t('thStatus'),
    dataIndex: 'status',
    key: 'status',
    render: (text, item) => (
      <Tooltip title={t(MAP_EMAIL_STATUSES[item.status])}>
        <Icon
          component={MAP_STATUS_TO_ICON[item.status]}
          data-testid={`icon-${MAP_STATUS_TO_ICON[item.status]}`}
          aria-label={`icon-${MAP_STATUS_TO_ICON[item.status]}`}
        />
      </Tooltip>
    ),
  },
];

function BulkEmailDetails() {
  const [organizationPk] = useCurrentOrganization();
  const { t } = useTranslation();
  const { pk } = useParams();
  const browserWidth = useBrowserWidth();

  const { data, loading, stopPolling } = useQuery(bulkEmailsQuery, {
    variables: { organizationPk, bulkEmailPk: pk },
  });

  // Getting first element form bulkEmails array,
  // because when bulkEmailPk is passed to bulkEmailsQuery,
  // single bulkEmail item is returned from the query.
  const { emailTemplate, createdAt, createdBy, emails } =
    data?.viewer.organizations[0].bulkEmails[0] || {};

  useEffect(() => {
    // poll bulkEmailsQuery until all emails change status from created
    // to either sent/received or failed/not delivered.
    const anyHasStatusCreated = emails?.edges.some(
      (email) => email.node.status === CREATED,
    );
    if (emails && !anyHasStatusCreated) {
      stopPolling();
    }
  }, [emails, stopPolling]);

  if (loading) return <Loader fullSize />;

  let COLUMNS = getColumns(t);

  if (browserWidth <= XS) {
    // To make table responsive, display only first and last column (here with index 2, hence COLUMNS[2]) on XS screens
    // Data from the middle column is displayed in NameCell component in the first column.
    COLUMNS = [COLUMNS[0], COLUMNS[2]];
  }

  return (
    <Content
      title={emailTemplate.title}
      subtitle={
        <div className={styles.dateWrapper}>
          <DateWithIcon date={createdAt} text={t('bulkEmailing_createdAt')} />{' '}
          {t('bulkEmailing_createdBy', { createdBy: createdBy.email })}
        </div>
      }
      size="small"
      condensed
      withHeaderSpacing
      theme="transparent"
    >
      <div className={styles.templateWrapper}>
        {nl2br(emailTemplate.content)}
      </div>

      <div className={styles.tableTitle}>
        {t('bulkEmailing_deliveryStatus')}
      </div>
      <Table
        columns={COLUMNS}
        dataSource={emails.edges.map(({ node }) => ({
          ...node,
          key: node.id,
        }))}
        pagination={{
          hideOnSinglePage: true,
          size: 'small',
        }}
        rowClassName={({ candidate }) => (!candidate ? styles.locked : null)}
        className={styles.table}
      />
    </Content>
  );
}

export default BulkEmailDetails;

import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'i18n';
import Select from 'components/Form/Select';

function JobPositionSelect({
  jobPositions,
  className,
  selectedJobPosition,
  onAddApplication,
  t,
  popupContainerRef,
  disabled,
  ...rest
}) {
  const [isLoading, setIsLoading] = useState(false);

  const memoizedChoices = useMemo(
    () =>
      jobPositions.map(({ node: { pk, position } }) => ({
        value: pk,
        label: position,
      })),
    [jobPositions],
  );

  const handleChange = useCallback(
    async (val) => {
      setIsLoading(true);
      await onAddApplication(val);
      setIsLoading(false);
    },
    [onAddApplication],
  );

  return (
    <Select
      data-testid={rest['data-testid'] || ''}
      value={selectedJobPosition}
      choices={memoizedChoices}
      placeholder={t('chooseJobPosition')}
      className={className}
      popupContainer={popupContainerRef}
      onChange={handleChange}
      disabled={disabled || isLoading}
    />
  );
}

JobPositionSelect.propTypes = {
  jobPositions: PropTypes.array,
  className: PropTypes.string,
  selectedJobPosition: PropTypes.string,
  onAddApplication: PropTypes.func,
  t: PropTypes.func.isRequired,
  popupContainerRef: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default withTranslation('translation')(JobPositionSelect);

import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown as AntDropdown } from 'antd';

function Dropdown({ children, ...rest }) {
  return (
    <AntDropdown destroyPopupOnHide {...rest}>
      {children}
    </AntDropdown>
  );
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Dropdown;

import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'apollo';

import withOrganizationPk from 'hoc/withOrganizationPk';
import Loader from 'components/Loader';

import emailTemplatesListQuery from '../emailTemplatesListQuery.gql';

import EmailTemplatesList from './EmailTemplatesList';

function EmailTemplatesListContainer({
  emailTemplatesList,
  isAddingNewEmailTemplate,
  toggleIsAddingNewEmailTemplate,
  loading,
}) {
  return (
    <>
      {loading && <Loader fullSize />}
      <EmailTemplatesList
        emailTemplatesList={emailTemplatesList}
        isAddingNewEmailTemplate={isAddingNewEmailTemplate}
        toggleIsAddingNewEmailTemplate={toggleIsAddingNewEmailTemplate}
      />
    </>
  );
}

EmailTemplatesListContainer.propTypes = {
  emailTemplatesList: PropTypes.array,
  isAddingNewEmailTemplate: PropTypes.bool,
  toggleIsAddingNewEmailTemplate: PropTypes.func,
  loading: PropTypes.bool,
};

export default compose(
  withOrganizationPk,
  graphql(emailTemplatesListQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      emailTemplatesList: !loading && viewer ? viewer.emailTemplates.edges : [],
      loading,
    }),
  }),
)(EmailTemplatesListContainer);

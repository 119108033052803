import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'i18n';
import Link from 'components/Link';
import Markdown from 'components/Markdown';

import Activity from './Activity';

function ApplicationComment({
  application,
  comment,
  onClick,
  inApplicationContext,
  inJobPositionContext,

  // From HoCs
  t,

  ...props
}) {
  const firstName = application.candidate.firstName;
  const lastName = application.candidate.lastName;
  const position = application.jobPosition.position;
  const url = `/candidates/${application.candidate.pk}/${application.pk}`;

  return (
    <Activity
      {...props}
      icon="form"
      isEditable={inApplicationContext}
      applicationPk={application.pk}
      commentPk={comment.pk}
      action={
        inApplicationContext ? (
          t('ApplicationComment_commented')
        ) : (
          <Trans
            i18nKey={`activity_applicationComment${
              inJobPositionContext ? '_inJobPosition' : ''
            }-v2`}
          >
            commented on{' '}
            <Link onClick={onClick} to={url}>
              {{ firstName }} {{ lastName }}
            </Link>{' '}
            application{' '}
            {!inJobPositionContext ? (
              <span>
                for <b>{{ position }}</b>
              </span>
            ) : (
              ''
            )}
          </Trans>
        )
      }
      details={<Markdown>{comment.content}</Markdown>}
    />
  );
}

ApplicationComment.propTypes = {
  createdBy: PropTypes.object,
  createdAt: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  inApplicationContext: PropTypes.bool,
  inJobPositionContext: PropTypes.bool,
  application: PropTypes.object.isRequired,

  // fired whenever user clicks the activity and is navigated to the link to the activities page,
  // e.g. used to set notification as read
  onClick: PropTypes.func,

  // From HoCs
  t: PropTypes.func.isRequired,
};

export default withTranslation('translation')(ApplicationComment);

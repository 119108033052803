import { showGenericError } from 'utils/message';
import * as config from 'config';

const log = (message, data) => {
  if (config.IS_DEVELOPMENT || !window.Rollbar) {
    console.error('LOG', { message, data }); // eslint-disable-line no-console
  } else {
    window.Rollbar.error(message, data);
  }
};

export const logAndShowGenericError =
  (message, data = {}) =>
  (catchError) => {
    log(message, { data, catchError });
    showGenericError();
  };

export default log;

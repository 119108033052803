import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Radio, { Button } from 'components/Radio';
import { useTranslation } from 'i18n';
import { SUPPORTED_LANGUAGES } from './SupportedLanguages';
import styles from './index.less';

function LanguageSelect({ onChange, theme }) {
  const { i18n } = useTranslation();

  const handleOnChange = useCallback(
    (e) => {
      /**
       * Function that sets selected language in i18n local storage
       * onChange function can also call optional function (if it is provided) from upper component,
       */
      i18n.changeLanguage(e.target.value).then(() => {
        if (onChange) {
          onChange();
        }
      });
    },
    [i18n, onChange],
  );

  return (
    <Radio
      value={i18n.language}
      size="small"
      buttonStyle="solid"
      onChange={handleOnChange}
      theme={theme}
    >
      {SUPPORTED_LANGUAGES.map((language) => (
        <Button
          key={language.code}
          value={language.code}
          aria-label={language.label}
        >
          <span className={styles.label}>
            {language.name}
            <img
              src={language.flagIcon}
              alt={language.code}
              height="15"
              width="30"
            />
          </span>
        </Button>
      ))}
    </Radio>
  );
}

LanguageSelect.propTypes = {
  onChange: PropTypes.func,
  theme: PropTypes.string,
};

export default LanguageSelect;

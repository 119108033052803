import { useState, useCallback } from 'react';

/**
 * Hook for toggling state - turning something on and of
 * for example opening and closing the modal
 * or changig state of any boolean value
 * @param {boolean} state
 *
 * use destructuring assignment to use it in your component
 * and set initial state:
 * @example const [isModalVisible, onModalOpen, onModalClose] = useToggleState(false)
 */

function useToggleState(state) {
  const [isToggled, setToggle] = useState(state);

  const onToggleOn = useCallback(() => setToggle(true), []);
  const onToggleOff = useCallback(() => setToggle(false), []);

  return [isToggled, onToggleOn, onToggleOff];
}

export default useToggleState;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import cx from 'classnames';

import Switch from 'components/Form/Switch';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Checkbox from 'components/Form/Checkbox';
import Button from 'components/Form/Button';
import Content from 'components/Content';
import Alert from 'components/Alert';
import ActivateOrChangePlanButton from 'components/ActivateOrChangePlanButton';
import { PLAN_TYPES } from 'consts/planTypes';
import StageListSelect from 'pages/job-positions/JobPositionAdd/StageListSelect';
import sessionStorage from 'utils/sessionStorage';

import styles from './JobPositionAdd.less';

function JobPositionAdd({
  position,
  location,
  isRemote,
  stageList,
  isActive,
  fieldErrors,
  onFieldChange,
  handleSubmit,
  billing,
  jobPositions,
  isBillingInfoLoading,
  isJobPositionsLoading,
}) {
  const { t } = useTranslation();

  const formItemLayout = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const { availableCredits, planType } = billing;
  const jobPositionsCount = jobPositions.length;
  const isActiveLimitExceeded =
    planType === PLAN_TYPES.FREE
      ? jobPositionsCount > 0
      : availableCredits === 0;
  const isFreePlanExceeded =
    planType === PLAN_TYPES.FREE && jobPositionsCount > 0;
  const isLoading = isBillingInfoLoading || isJobPositionsLoading;

  // Form Input Callbacks
  const onPositionFieldChange = useCallback(
    (e) => {
      onFieldChange({ field: 'position', value: e.target.value });
      sessionStorage.persist({ position: e.target.value });
    },
    [onFieldChange],
  );
  const onLocationFieldChange = useCallback(
    (e) => {
      onFieldChange({ field: 'location', value: e.target.value });
      sessionStorage.persist({ location: e.target.value });
    },
    [onFieldChange],
  );
  const onIsRemoteFieldChange = useCallback(
    (e) => {
      onFieldChange({
        field: 'isRemote',
        value: e.target.checked,
        relatedField: 'location',
      });
      sessionStorage.persist({ isRemote: e.target.checked });
    },
    [onFieldChange],
  );
  const onIsActiveFieldChange = useCallback(
    (value) => {
      onFieldChange({
        field: 'isActive',
        IDBCursorWithValue,
      });
      sessionStorage.persist({ isActive: value });
    },
    [onFieldChange],
  );

  const onSelectStageListChange = useCallback(
    (value) =>
      onFieldChange({
        field: 'stageList',
        value,
      }),
    [onFieldChange],
  );

  return (
    <Content className={styles.container} size="small" theme="transparent">
      <Form layout="vertical" className={styles.form} onFinish={handleSubmit}>
        <Field
          error={fieldErrors.position}
          label={t('position')}
          {...formItemLayout}
        >
          <Input
            type="text"
            value={position}
            onChange={onPositionFieldChange}
            placeholder={t('positionPlaceholder')}
            disabled={isFreePlanExceeded}
            data-role="job-position-input"
            data-testid="job-position-input"
          />
        </Field>
        <Field error={fieldErrors.location} label={t('location')}>
          <Col span={14}>
            <Input
              type="text"
              value={location}
              onChange={onLocationFieldChange}
              placeholder={t('locationPlaceholder')}
              disabled={isFreePlanExceeded}
              data-role="job-location-input"
            />
          </Col>
          <Col span={10}>
            <Checkbox
              className={styles.checkbox}
              value={isRemote}
              checked={isRemote}
              onChange={onIsRemoteFieldChange}
              disabled={isFreePlanExceeded}
              data-testid="job-isRemote-checkbox"
            >
              {t('isRemote')}
            </Checkbox>
          </Col>
        </Field>
        <Field error={fieldErrors.initialStatus} label={t('initialStatus')}>
          <Switch
            checked={isActive}
            onChange={onIsActiveFieldChange}
            disabled={isActiveLimitExceeded}
            data-role="switch-position-activate"
            data-testid="switch-position-activate"
            aria-label="switch job position activate"
          />
          <span className={styles.switchText}>
            {t('active')} {isLoading && <LoadingOutlined />}
          </span>
          <div
            className={cx(
              styles.alertWrapper,
              !isActiveLimitExceeded && styles.hidden,
            )}
          >
            <Alert
              type="warning"
              cta={
                <ActivateOrChangePlanButton initialStage="changePlan">
                  {t('upgradePlan')}
                </ActivateOrChangePlanButton>
              }
            >
              {t('youHaveNoRemainingCredits')}
            </Alert>
          </div>
        </Field>
        <Field error={fieldErrors.stageList} label={t('stageList')}>
          <Col span={14}>
            <StageListSelect
              onSelectStageList={onSelectStageListChange}
              stageListPk={stageList}
            />
          </Col>
        </Field>
        <Row>
          <Button
            type="primary"
            htmlType="submit"
            data-testid="submit"
            className={styles.sendBtn}
            disabled={isFreePlanExceeded}
          >
            {t('send')}
          </Button>
        </Row>
      </Form>
    </Content>
  );
}

JobPositionAdd.propTypes = {
  position: PropTypes.string,
  location: PropTypes.string,
  stageList: PropTypes.string,
  isRemote: PropTypes.bool,
  isActive: PropTypes.bool,
  fieldErrors: PropTypes.object,
  onFieldChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  billing: PropTypes.object.isRequired,
  jobPositions: PropTypes.array.isRequired,
  isBillingInfoLoading: PropTypes.bool,
  isJobPositionsLoading: PropTypes.bool,
};

export default JobPositionAdd;

import { useState } from 'react';
import { useTranslation } from 'i18n';

const useErrorFieldsMutation = (mutate, propName) => {
  const { t } = useTranslation();
  const [isSaving, toggleIsSaving] = useState(false);
  const [generalError, setGeneralError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});

  const mutation = (options) => {
    toggleIsSaving(true);
    let dataField = options.dataField;

    return mutate(options)
      .then((result) => {
        toggleIsSaving(false);

        if (!result.data) {
          setGeneralError(t('contactSupport'));
          return result;
        }

        if (!dataField) {
          const keys = Object.keys(result.data);

          if (keys.length > 1) {
            // If there is no dataField option set and there is more keys
            // than one in the result, throw error — IDK which one to choose
            throw Error();
          }

          dataField = keys[0];
        }

        const errorField = result.data[dataField].errors;

        if (errorField) {
          // If there is a general message, display it
          if (errorField.message) {
            setGeneralError(t(errorField.message));
          }

          // Pass per-field errors
          if (errorField.fields) {
            setFieldErrors(errorField.fields);
          }

          return result;
        }

        return { ok: true, ...result };
      })
      .then(async (result) => result);
  };

  const objToReturn = {
    mutate: mutation,
    isSaving,
    generalError,
    fieldErrors,
    setGeneralError,
    setFieldErrors: (newFieldErrors) => {
      setFieldErrors({
        ...fieldErrors,
        ...newFieldErrors,
      });
    },
    clearFieldError: (field) => {
      const tempFieldErrors = { ...fieldErrors };
      delete tempFieldErrors[field];
      setFieldErrors({ ...tempFieldErrors });
    },
  };

  // If propName was passed, all props will be wrapped in it
  if (propName) {
    return {
      [propName]: objToReturn,
    };
  }

  return objToReturn;
};

export default useErrorFieldsMutation;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Input as AntInput } from 'antd';
import cx from 'classnames';

import styles from './Input.less';

const AntSearch = AntInput.Search;
const AntPassword = AntInput.Password;

class Input extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.string,
    popupLabel: PropTypes.bool,
    labelDark: PropTypes.bool,
    search: PropTypes.bool,
    className: PropTypes.string,
    textarea: PropTypes.bool,
    type: PropTypes.string,
  };

  state = {
    isFocused: false,
  };

  onFocus = (e) => {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }

    this.setState({
      isFocused: true,
    });
  };

  onBlur = (e) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    this.setState({
      isFocused: false,
    });
  };

  render() {
    const {
      popupLabel,
      labelDark,
      value,
      placeholder,
      search,
      className,
      textarea,
      size,
      type,
      ...rest
    } = this.props;
    const { isFocused } = this.state;
    const isPopupLabelActive = popupLabel && (value || isFocused);

    let RenderInput = AntInput;
    if (textarea) {
      RenderInput = AntInput.TextArea;
    } else if (search) {
      RenderInput = AntSearch;
    } else if (type === 'password') {
      RenderInput = AntPassword;
    }

    return (
      <div
        className={cx(
          styles.wrapper,
          isPopupLabelActive && styles.active,
          className,
          size === 'large' && styles.large,
        )}
      >
        <RenderInput
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          value={value}
          placeholder={placeholder}
          size={size}
          type={type}
          {...rest}
        />
        {popupLabel && (
          <span className={cx(styles.label, labelDark && styles.labelDark)}>
            {placeholder}
          </span>
        )}
      </div>
    );
  }
}

export default Input;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';

import Button from 'components/Form/Button';
import Checkbox from 'components/Form/Checkbox';

import { Trans } from 'react-i18next';
import styles from './JobPositionAddVas.less';

function JobPositionAddVas({
  selected,
  list,
  handleSubmit,
  isSaving,
  onChange,
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <form className={styles.VASwrapper} onSubmit={handleSubmit}>
        {list.map(({ name, pk, readMoreUrl }) => (
          <div className={styles.item} key={pk}>
            <Checkbox
              id={`checkbox-${pk}`}
              className={styles.checkbox}
              checked={!!selected[pk]}
              onChange={(e) => onChange(pk, e.target.checked)}
              disabled={isSaving}
            />
            <div>
              <label htmlFor={`checkbox-${pk}`} className={styles.itemName}>
                {t(name)}
              </label>
              <div className={styles.itemDescritption}>
                <Trans i18nKey={`VasItemDesc--${name}`}>
                  <p>description_part1</p>
                  {/* used only for the vas: Workate Attract */}
                  <p>description_part2</p>
                  <p>title</p>
                  <p>item</p>
                  <p>item</p>
                  <p>item</p>
                  <p>item</p>
                  <br />
                  <p>title</p>
                  <p>item</p>
                  <p>item</p>
                  <p>item</p>
                  <p>item</p>
                  <p>item</p>
                  <p>item</p>
                </Trans>{' '}
                {readMoreUrl && (
                  <a
                    href={readMoreUrl}
                    rel="noopener"
                    target="_blank"
                    className={styles.link}
                  >
                    {t('ReadMore')} →
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
        <Button
          size="default"
          type="primary"
          htmlType="submit"
          className={styles.submitBtn}
          disabled={isSaving}
        >
          {t('vasFormSave')}
        </Button>
      </form>
    </div>
  );
}

JobPositionAddVas.propTypes = {
  isSaving: PropTypes.bool,
  selected: PropTypes.object,
  list: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default JobPositionAddVas;

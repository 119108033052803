import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'apollo';

/**
 * Wrapper around apollo's `graphql` HoC, that'll process and manage error message and
 * error fields that were passed in the mutation payload. Expected payload format:
 *
 * @example
 * mutation (myField: String!) {
 *   someMutation(myField: $myField) {
 *     errors {
 *       message
 *       fields
 *     }
 *     ...
 *   }
 * }
 *
 * It'll pass to the wrapped component following props, that descriptions
 * you can find above their definition.
 *
 * Functions:
 * - mutate
 * - clearFieldError
 * - setFieldErrors
 * - setGeneralError
 *
 * Data:
 * - generalError
 * - fieldErrors
 *
 * If the `propName` is passed, all mutation-related fields will be wrapped in it before passing down to the component.
 *
 * @param  {String} gqlQuery
 * @param  {Object} gqlOptions
 * @param  {String} propName
 * @return {Component}
 */
const graphqlErrorFieldsMutation =
  (gqlQuery, qglOptions, propName) => (WrappedComponent) => {
    class GraphqlErrorFieldsMutation extends PureComponent {
      static propTypes = {
        mutate: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
      };

      state = {
        generalError: '', // Content of the "message" field from the mutation
        fieldErrors: {}, // An array of strings with errors for particular fields
        isSaving: false, // Determines if mutation is in saving state
      };

      /**
       * Sets a general error message.
       *
       * @param {String} generalError
       */
      setGeneralError = (generalError) => {
        this.setState({ generalError });
      };

      /**
       * A function for setting fields' errors manually.
       *
       * @param {Object} fieldErrors e.g. { field1: 'Error for Field 1',
       *                                    field2: 'Error for FIeld 2', ... }
       */
      setFieldErrors = (fieldErrors) => {
        this.setState({
          fieldErrors: {
            ...this.state.fieldErrors,
            ...fieldErrors,
          },
        });
      };

      /**
       * A function to clear an error for a particular `field`.
       *
       * @param  {String} field
       */
      clearFieldError = (field) => {
        const fieldErrors = { ...this.state.fieldErrors };
        delete fieldErrors[field];

        this.setState({ fieldErrors });
      };

      /**
       * Function similar to the one from `graphql` but that optionally expects
       * a `dataField` in the options object. You should set the `dataField`
       * when there is more then mutation object passed (eg. `dataField: 'someMutation'`)
       *
       * It'll also add additional `ok` to field to the result object returned by promise,
       * if it's set to true it means the mutation resolved without errors.
       *
       * @param  {Object} options
       * @return {Promise}
       */
      mutate = (options) => {
        const { t } = this.props;
        let dataField = options.dataField;

        this.setState({ isSaving: true });

        return this.props
          .mutate(options)
          .then((result) => {
            this.setState({ isSaving: false });

            if (!result.data) {
              this.setState({ generalError: t('contactSupport') });
              return result;
            }

            if (!dataField) {
              const keys = Object.keys(result.data);

              if (keys.length > 1) {
                // If there is no dataField option set and there is more keys
                // than one in the result, throw error — IDK which one to choose
                throw Error();
              }

              dataField = keys[0];
            }

            const errorField = result.data[dataField].errors;

            if (errorField) {
              // If there is a general message, display it
              if (errorField.message) {
                this.setState({ generalError: t(errorField.message) });
              }

              // Pass per-field errors
              if (errorField.fields) {
                this.setState({ fieldErrors: errorField.fields });
              }

              return result;
            }

            return { ok: true, ...result };
          })
          .then(async (result) => result);
      };

      render() {
        const { ...rest } = this.props;

        let props = {
          mutate: this.mutate,
          clearFieldError: this.clearFieldError,
          setFieldErrors: this.setFieldErrors,
          setGeneralError: this.setGeneralError,
          ...this.state,
        };

        if (propName) {
          props = { [propName]: props };
        }

        return <WrappedComponent {...rest} {...props} />;
      }
    }

    graphqlErrorFieldsMutation.displayName = `graphqlErrorFieldsMutation(${
      WrappedComponent.displayName || WrappedComponent.name || 'Component'
    })`;

    return graphql(gqlQuery, qglOptions)(GraphqlErrorFieldsMutation);
  };

export default graphqlErrorFieldsMutation;

const SYMBOL_CURRENCIES = {
  USD: '$',
  EUR: '€',
  GBP: '£',
};

const STRING_CURRENCIES = {
  PLN: 'zł',
};

const formatCurrency = (price, currency) => {
  if (SYMBOL_CURRENCIES[currency]) {
    return `${SYMBOL_CURRENCIES[currency]}${price}`;
  }

  if (STRING_CURRENCIES[currency]) {
    return `${price} ${STRING_CURRENCIES[currency]}`;
  }

  return `${price} ${currency}`;
};

export default formatCurrency;

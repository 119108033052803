import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'apollo';

import withOrganizationPk from 'hoc/withOrganizationPk';

import useErrorFieldsMutation from 'hooks/useErrorFieldsMutation';

import { validator, isRequired } from 'utils/validator';
import { logAndShowGenericError } from 'utils/log';
import { showGenericError, showError } from 'utils/message';

import emailTemplatesListQuery from '../emailTemplatesListQuery.gql';
import editEmailTemplateMutation from '../editEmailTemplateMutation.gql';
import deleteEmailTemplateMutation from '../deleteEmailTemplateMutation.gql';

import EmailTemplateElement from './EmailTemplateElement';

const VALIDATE_FIELDS = {
  newTitle: [isRequired],
  newContent: [isRequired],
};

const getValidationErrors = validator(VALIDATE_FIELDS);

function EmailTemplateElementContainer({
  templatePk,
  title,
  content,
  mutate,
  organizationPk,
  deleteEmailTemplate,
}) {
  const [isElementEdited, setIsElementEdited] = useState(false);
  const [isEditEmailTemplateSaving, setIsEditEmailTemplateSaving] =
    useState(false);

  const toggleIsElementEdited = useCallback(() => {
    setIsElementEdited(!isElementEdited);
  }, [isElementEdited]);

  const {
    mutate: editEmailTemplate,
    fieldErrors,
    setFieldErrors,
    clearFieldError,
  } = useErrorFieldsMutation(mutate);

  const onEditEmailTemplate = useCallback(
    (newTitle, newContent) => {
      const validationErrors = getValidationErrors({
        newTitle,
        newContent,
      });

      if (!validationErrors) {
        setIsEditEmailTemplateSaving(true);
        editEmailTemplate({
          variables: { organizationPk, templatePk, newTitle, newContent },
        })
          .then(({ data }) => {
            if (data.editEmailTemplate.errors) {
              return data.editEmailTemplate.errors.message
                ? showError(data.editEmailTemplate.errors.message)
                : showGenericError();
            }
          })
          .catch(
            logAndShowGenericError('editEmailTemplate rejected', {
              templatePk,
              newTitle,
              newContent,
            }),
          )
          .finally(() => {
            setIsEditEmailTemplateSaving(false);
            toggleIsElementEdited();
          });
      } else {
        setFieldErrors(validationErrors);
      }
    },
    [
      organizationPk,
      templatePk,
      editEmailTemplate,
      setFieldErrors,
      toggleIsElementEdited,
    ],
  );

  const onDeleteEmailTemplate = useCallback(() => {
    deleteEmailTemplate()
      .then(({ data }) => {
        if (data.deleteEmailTemplate.errors) {
          return data.deleteEmailTemplate.errors.message
            ? showError(data.deleteEmailTemplate.errors.message)
            : showGenericError;
        }
      })
      .catch(
        logAndShowGenericError('deleteEmailTemplate rejected', { templatePk }),
      );
  }, [deleteEmailTemplate, templatePk]);

  return (
    <EmailTemplateElement
      isElementEdited={isElementEdited}
      toggleIsElementEdited={toggleIsElementEdited}
      title={title}
      content={content}
      onEditEmailTemplate={onEditEmailTemplate}
      isEditEmailTemplateSaving={isEditEmailTemplateSaving}
      fieldErrors={fieldErrors}
      clearFieldError={clearFieldError}
      onDeleteEmailTemplate={onDeleteEmailTemplate}
    />
  );
}

EmailTemplateElementContainer.propTypes = {
  templatePk: PropTypes.number,
  title: PropTypes.string,
  content: PropTypes.string,
  mutate: PropTypes.func,
  organizationPk: PropTypes.string,
  deleteEmailTemplate: PropTypes.func,
};

export default compose(
  withOrganizationPk,
  graphql(editEmailTemplateMutation, {
    options: ({ organizationPk }) => ({
      refetchQueries: [
        { query: emailTemplatesListQuery, variables: { organizationPk } },
      ],
    }),
  }),
  graphql(deleteEmailTemplateMutation, {
    props: ({ mutate, ownProps: { organizationPk, templatePk } }) => ({
      deleteEmailTemplate: () =>
        mutate({
          variables: { organizationPk, templatePk },
          optimisticResponse: {
            deleteEmailTemplate: {
              errors: null,
              status: 'OK',
              __typename: 'DeleteEmailTemplateMutation',
            },
          },
          update: (proxy) => {
            const query = emailTemplatesListQuery;
            const variables = { organizationPk };
            const data = proxy.readQuery({ query, variables });
            const emailTemplateEdges = data.viewer.emailTemplates.edges.filter(
              (o) => o.node.pk !== templatePk,
            );
            const updatedData = {
              viewer: {
                emailTemplates: {
                  edges: emailTemplateEdges,
                  __typename: 'EmailTemplateNodeConnection',
                },
                __typename: 'Viewer',
              },
            };
            proxy.writeQuery({ query, variables, data: updatedData });
          },
        }),
    }),
  }),
)(EmailTemplateElementContainer);

import React from 'react';
import PropTypes from 'prop-types';
import { XS } from 'consts/media';
import useBrowserWidth from 'hooks/useBrowserWidth';
import { Link, useParams } from 'react-router-dom';

import styles from './NameCell.less';
import StatusCell from '../JobOffers/StatusCell';

function NameCell({ name, status, pk }) {
  const browserWidth = useBrowserWidth();
  const { pk: jobPositionPk } = useParams();

  return (
    <div className={styles.container}>
      <Link to={`/positions/${jobPositionPk}/${pk}`} className={styles.name}>
        {name}
      </Link>
      {browserWidth <= XS && (
        <div className={styles.status}>
          <StatusCell text={status} />
        </div>
      )}
    </div>
  );
}

NameCell.propTypes = {
  name: PropTypes.string,
  status: PropTypes.string,
  pk: PropTypes.string,
};

export default NameCell;

import { graphql, compose } from 'apollo';
import withOrganizationPk from './withOrganizationPk';
import resendApplicationEmailMutation from './resendApplicationEmailMutation.gql';

const withResendApplicationEmail = (WrappedComponent) =>
  compose(
    withOrganizationPk,
    graphql(resendApplicationEmailMutation, {
      props: ({ mutate, ownProps: { organizationPk } }) => ({
        resendApplicationEmail: (activityPk) =>
          mutate({
            variables: { organizationPk, activityPk },
          }),
      }),
    }),
  )(WrappedComponent);

export default withResendApplicationEmail;

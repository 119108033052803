import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect } from 'antd';

const Option = AntSelect.Option;

function ApplicationSelect({
  candidateApplications,
  selectedAplication,
  onSelectedApplication,
  ...rest
}) {
  const onChange = (pickedAppPk) => {
    const pickedApp = candidateApplications.find(
      ({ node }) => node.pk === pickedAppPk,
    ).node;
    onSelectedApplication(pickedApp);
  };

  return (
    <AntSelect value={selectedAplication.pk} onChange={onChange} {...rest}>
      {candidateApplications.map(({ node }) => (
        <Option key={node.pk} value={node.pk}>
          {node.jobPosition.position}
        </Option>
      ))}
    </AntSelect>
  );
}

ApplicationSelect.propTypes = {
  candidateApplications: PropTypes.array.isRequired,
  selectedAplication: PropTypes.object.isRequired,
  onSelectedApplication: PropTypes.func.isRequired,
};

export default ApplicationSelect;

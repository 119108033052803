import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';

import Table from 'components/Table';
import Content from 'components/Content';
import DateWithIcon from 'components/DateWithIcon';
import SubscribeButton from 'components/NotificationSubscribeButton';
import RadioButtonGroup from 'components/RadioButtonGroup';
import Title from 'components/Title';
import { XS, SM, MD } from 'consts/media';
import { MODULE_JOB_POSITION_MANAGE } from 'consts/organizationModules';
import { PERMISSION_JOB_POSITION_MANAGE } from 'consts/permissions';
import useOrganizationModules from 'hooks/useOrganizationModules';
import useBrowserWidth from 'hooks/useBrowserWidth';
import usePermissions from 'hooks/usePermissions';

import NameCell from './NameCell';

import styles from './index.less';

const getColumns = (t, browserWidth) =>
  [
    {
      title: t('thJobPositions'),
      dataIndex: 'title',
      key: 'title',
      render: (text, item) => (
        <NameCell {...item} browserWidth={browserWidth} />
      ),
    },
    {
      title: t('thJobPositionSubscription'),
      key: 'subscription',
      render: (item) => (
        <div className={styles.subscription}>
          <SubscribeButton jobPosition={item} />
        </div>
      ),
      onHeaderCell: () => ({
        className: styles.centeredHeader,
      }),
    },
    {
      title: t('thApplicationsCount'),
      dataIndex: 'applicationsCount',
      key: 'applicationsCount',
      render: (text, item) => (
        <div className={styles.applicationsCount}>
          {item.applications.totalCount}
        </div>
      ),
      onHeaderCell: () => ({
        className: styles.centeredHeader,
      }),
    },
    {
      title: t('thJobPositionCreated'),
      key: 'createdAt',
      render: (item) => (
        <div className={styles.createdAtWrapper}>
          <DateWithIcon date={item.createdAt} className={styles.createdAt} />
        </div>
      ),
      onHeaderCell: () => ({
        className: styles.centeredHeader,
      }),
    },
    // Filter out empty entries, produced when e.g. 'tester flag' is active in local storage.
  ].filter((element) => element);

function JobPositions({
  jobPositionsList,
  isLoading,
  isActiveVisible,
  onAddJobPositionClick,
  toggleArchivedVisibility,
}) {
  const [hasJobPositionManageModule] = useOrganizationModules([
    MODULE_JOB_POSITION_MANAGE,
  ]);
  const { t } = useTranslation();
  const browserWidth = useBrowserWidth();
  const [hasJobPositionManagePermission, { showNoPermissionMessage }] =
    usePermissions([PERMISSION_JOB_POSITION_MANAGE]);

  let columns = getColumns(t, browserWidth);
  /* Because the array of columns increases by one element 
  (the column with subscribe buttons)
  when 'tester' item is set in local storage 
  and we want this column to always be displayed,
  we add +1 to displayed columns
  */

  if (browserWidth <= XS) {
    columns = columns.slice(0, 2);
  } else if (browserWidth <= SM) {
    columns = columns.slice(0, 3);
  } else if (browserWidth <= MD) {
    columns = columns.slice(0, 4);
  }

  const handleClick = hasJobPositionManagePermission
    ? onAddJobPositionClick
    : showNoPermissionMessage;

  return (
    <Content
      condensed
      title={t('JobPositions_header')}
      subtitle={
        <div className={styles.subtitle}>
          <RadioButtonGroup
            current={isActiveVisible ? 'active' : 'archived'}
            items={[
              {
                value: 'active',
                content: t('button_active'),
                dataRole: 'button-position-active',
              },
              {
                value: 'archived',
                content: t('button_archived'),
                dataRole: 'button-position-archived',
              },
            ]}
            onChange={toggleArchivedVisibility}
          />
        </div>
      }
      CTAText={t('addJobPosition')}
      onCTAClick={hasJobPositionManageModule ? handleClick : null}
    >
      <Title>{t('Title_jobPositions')}</Title>
      <Table
        loading={isLoading}
        dataSource={jobPositionsList.map(({ node }) => ({
          key: node.id,
          ...node,
        }))}
        columns={columns}
        showHeader={browserWidth > XS}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </Content>
  );
}

JobPositions.propTypes = {
  jobPositionsList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isActiveVisible: PropTypes.bool.isRequired,
  onAddJobPositionClick: PropTypes.func.isRequired,
  toggleArchivedVisibility: PropTypes.func.isRequired,
};

export default JobPositions;

import React, { useCallback } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import BlockContent from 'components/JobOfferForm/BlockContent';
import IconTile from 'components/JobOfferForm/IconTile';
import { useTranslation } from 'react-i18next';
import ControlledInput from 'components/JobOfferForm/ControlledInput';
import AddButton from 'components/JobOfferForm/AddButton';
import classNames from 'classnames';
import useDragAndDrop from 'hooks/useDragAndDrop';
import styles from './HeaderBlock.less';

function HeaderBlock() {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'iconList',
  });

  const {
    draggedIndex,
    onDragStart,
    onDragOver,
    onDragEnd,
    onDrop,
    getItemsWithPlaceholder,
  } = useDragAndDrop(fields, move);

  const onAddPoint = useCallback(() => {
    append({ text: '', iconValue: 'add' });
  }, [append]);

  const itemsWithPlaceholder = getItemsWithPlaceholder();

  return (
    <BlockContent className={styles.container}>
      <ControlledInput
        control={control}
        name="title"
        popupLabel
        labelDark
        type="text"
        size="middle"
        placeholder={t('HeaderBlock_title')}
        className={styles.input}
      />
      <ControlledInput
        control={control}
        name="subtitle"
        popupLabel
        labelDark
        type="text"
        size="middle"
        placeholder={t('HeaderBlock_subtitle')}
        className={styles.input}
      />
      <div className={styles.mainPoints}>
        <div className={styles.mainPointsTitle}>
          {t('HeaderBlock_mainPoints')}
        </div>
        <div className={styles.mainPointsBox}>
          {itemsWithPlaceholder.map((field, index) => (
            <div
              key={field.id}
              draggable={!field.isPlaceholder}
              onDragStart={(e) => onDragStart(e, index)}
              onDragOver={(e) => onDragOver(e, index)}
              onDrop={(e) => onDrop(e, index)}
              onDragEnd={onDragEnd}
              className={classNames(styles.iconWrapper, {
                [styles.dragging]: draggedIndex === index,
                [styles.placeholder]: field.isPlaceholder,
              })}
            >
              <IconTile
                control={control}
                textFieldName={`iconList.${index}.text`}
                valueFieldName={`iconList.${index}.iconValue`}
                index={index}
                onDeleteClick={() => remove(index)}
                placeholder={t('IconTile_placeholder')}
              />
            </div>
          ))}
          <AddButton onAddClick={onAddPoint}>{t('AddIcon')}</AddButton>
        </div>
      </div>
    </BlockContent>
  );
}

export default HeaderBlock;

const createCustomFieldSelectedChoicesFilterObject = (
  customFieldSelectedChoicesFilters,
) =>
  Object.keys(customFieldSelectedChoicesFilters).reduce(
    (result, filter) => [
      ...result,
      {
        choiceField: filter.replace('customFieldMultichoiceFilter_', ''),
        values: customFieldSelectedChoicesFilters[filter],
      },
    ],
    [],
  );

export default createCustomFieldSelectedChoicesFilterObject;

import { useState, useCallback } from 'react';

const useDragAndDrop = (fields, move) => {
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [dropIndex, setDropIndex] = useState(null);

  const onDragStart = useCallback((e, index) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
  }, []);

  const onDragOver = useCallback((e, index) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Event/preventDefault
    // Prevents the default action of the event, such as a page reload or navigation,
    // which can interfere with the drag-and-drop functionality.
    e.preventDefault();
    setDropIndex(index);
  }, []);

  const onDragEnd = useCallback(() => {
    setDraggedIndex(null);
    setDropIndex(null);
  }, []);

  const onDrop = useCallback(
    (e, finalDropIndex) => {
      e.preventDefault();
      if (draggedIndex !== null && draggedIndex !== finalDropIndex) {
        move(draggedIndex, finalDropIndex);
      }
      setDraggedIndex(null);
      setDropIndex(null);
    },
    [draggedIndex, move],
  );

  const getItemsWithPlaceholder = useCallback(() => {
    if (draggedIndex === null || dropIndex === null) {
      return fields;
    }

    const itemsCopy = [...fields];
    const draggedItem = itemsCopy[draggedIndex];
    itemsCopy.splice(draggedIndex, 1);
    itemsCopy.splice(dropIndex, 0, { ...draggedItem, isPlaceholder: true });

    return itemsCopy;
  }, [fields, draggedIndex, dropIndex]);

  return {
    draggedIndex,
    dropIndex,
    onDragStart,
    onDragOver,
    onDragEnd,
    onDrop,
    getItemsWithPlaceholder,
  };
};

export default useDragAndDrop;

import { useMemo } from 'react';

/**
 * hook for setting default job offer form data based on landingPageQuery
 * @param {string} landingPageData - current landingpage data from landingPageQuery
 */

const parseIconInput = (iconList) => {
  if (!iconList || iconList.length === 0) {
    return [{ text: '', iconValue: 'add' }];
  }
  return iconList.map((icon) => ({
    pk: icon.pk,
    text: icon.text,
    iconValue: icon.iconValue,
  }));
};

const parseAccordionBlockItems = (items) =>
  items?.map((item) => ({
    pk: item.pk,
    title: item.title,
    description: item.description,
    iconValue: item.iconValue,
  }));

const parseBlocks = (blocks) =>
  blocks?.map((block) => {
    const parsedBlock = {};

    switch (block.__typename) {
      case 'AccordionBlockNode':
        parsedBlock.accordionBlock = {
          pk: block.pk,
          title: block.title,
          semanticMeaning: block.semanticMeaning,
          items:
            block.items.length > 0
              ? parseAccordionBlockItems(block.items)
              : [{ title: '', description: '', iconValue: 'add' }],
        };
        break;
      case 'IconListBlockNode':
        parsedBlock.iconListBlock = {
          pk: block.pk,
          title: block.title,
          iconList: parseIconInput(block.iconList),
        };
        break;
      case 'AboutUsBlockNode':
        parsedBlock.aboutUsBlock = {
          pk: block.pk,
          title: block.title,
          description: block.description,
        };
        break;
      case 'ApplyBlockNode':
        parsedBlock.applyBlock = {
          pk: block.pk,
          title: block.title,
          subtitle: block.subtitle,
        };
        break;
      case 'ProcessBlockNode':
        parsedBlock.processBlock = {
          pk: block.pk,
          title: block.title,
          iconList: parseIconInput(block.iconList),
        };
        break;
      default:
        break;
    }
    return parsedBlock;
  });

function useDefaultOfferValues(landingPageData) {
  return useMemo(() => {
    if (!landingPageData) return {};

    return {
      title: landingPageData.title,
      subtitle: landingPageData.subtitle,
      iconList: parseIconInput(landingPageData.iconList),
      blocks: parseBlocks(landingPageData.blocks),
    };
  }, [landingPageData]);
}

export default useDefaultOfferValues;

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'i18n';
import Link from 'components/Link';

import Activity from './Activity';

function ApplicationCreated({
  createdBy,
  application,
  onClick,
  inJobPositionContext,
  inApplicationContext,
  t,
  ...props
}) {
  return (
    <Activity
      {...props}
      createdBy={createdBy}
      icon="userAdd"
      action={
        createdBy ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {inApplicationContext ? (
              t('addedThisApplication') // in the application context, just display text w/o details
            ) : (
              <>
                {t('addedNewApplication')}{' '}
                <Link
                  onClick={onClick}
                  to={`/candidates/${application.candidate.pk}/${application.pk}`}
                >
                  {application.candidate.firstName}{' '}
                  {application.candidate.lastName}
                </Link>
                {!inJobPositionContext && (
                  <>
                    {' '}
                    {t('forPosition')} <b>{application.jobPosition.position}</b>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          // if doesn't have createdBy, user applied via landing page
          <Trans
            i18nKey={`externalApplication${
              inJobPositionContext ? '_withJobPosition' : ''
            }-v2`}
          >
            <Link
              onClick={onClick}
              to={`/candidates/${application.candidate.pk}/${application.pk}`}
            >
              {{ firstName: application.candidate.firstName }}{' '}
              {{ lastName: application.candidate.lastName }}
            </Link>{' '}
            applied{' '}
            {!inJobPositionContext ? (
              <>
                for <b>{{ position: application.jobPosition.position }}</b>
              </>
            ) : (
              ''
            )}{' '}
            via Landing Page
          </Trans>
        )
      }
    />
  );
}

ApplicationCreated.propTypes = {
  createdBy: PropTypes.object,
  application: PropTypes.object,
  inJobPositionContext: PropTypes.bool,
  inApplicationContext: PropTypes.bool,
  t: PropTypes.func.isRequired,

  // fired whenever user clicks the activity and is navigated to the link to the activities page,
  // e.g. used to set notification as read
  onClick: PropTypes.func,
};

export default withTranslation('translation')(ApplicationCreated);

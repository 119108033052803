import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'i18n';
import { CheckCircleOutlined } from '@ant-design/icons';
import Link from 'components/Link';
import { useParams } from 'react-router';
import styles from './index.less';

function ThankYou({ children }) {
  const { t } = useTranslation();
  const { jobPositionPk } = useParams();

  return (
    <div className={styles.container}>
      <CheckCircleOutlined className={styles.tickIcon} />
      <p className={styles.thankYouText}>{t('JobPositionAdd_thankYou')}</p>
      <div>
        <Trans i18nKey="JobPositionAdd_thankYou--links">
          See the <Link to={`/positions/${jobPositionPk}`}>details</Link> of the
          position you've just created or go to{' '}
          <Link to="/positions">list of all positions</Link>.
        </Trans>
      </div>
      {children}
    </div>
  );
}

ThankYou.propTypes = {
  children: PropTypes.node,
};

export default ThankYou;

import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'i18n';

import styles from './ConfirmBulkEmail.less';

function ConfirmBulkEmail() {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <CheckCircleOutlined className={styles.successIcon} />
      <p>{t('ConfirmSendingBulkEmail_successText')}</p>
    </div>
  );
}

export default ConfirmBulkEmail;

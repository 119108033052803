import React, { createContext } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import useCurrentOrganization from 'hooks/useCurrentOrganization';
import Loader from 'components/Loader';
// eslint-disable-next-line max-len
import candidateWithApplicationsQuery from 'pages/candidates/CandidateWithApplications/candidateWithApplicationsQuery.gql';

export const CurrentCandidateContext = createContext();

function CurrentCandidateProvider({ candidatePk, children }) {
  const [organizationPk] = useCurrentOrganization();
  const { data, loading } = useQuery(candidateWithApplicationsQuery, {
    variables: {
      organizationPk,
      candidatePk,
    },
  });

  const candidate = data?.viewer?.candidate;

  if (loading) {
    return <Loader />;
  }

  return (
    <CurrentCandidateContext.Provider value={candidate}>
      {children}
    </CurrentCandidateContext.Provider>
  );
}

CurrentCandidateProvider.propTypes = {
  children: PropTypes.node,
  candidatePk: PropTypes.string,
};

export default CurrentCandidateProvider;

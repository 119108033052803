import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.less';

export { default as ProgressLegend } from './ProgressLegend';

function Progress({ percent = 0, plannedPercent = 0 }) {
  return (
    <div className={styles.container}>
      <div
        className={styles.planned}
        style={{
          width: plannedPercent <= 100 ? `${plannedPercent}%` : `100%`,
        }}
        data-testid="plannedPercent"
      />
      <div
        className={styles.progress}
        style={{
          width:
            plannedPercent <= 100
              ? `${percent}%`
              : `${(percent * 100) / plannedPercent}%`,
        }}
        data-testid="percent"
      />
    </div>
  );
}

Progress.propTypes = {
  percent: PropTypes.number,
  plannedPercent: PropTypes.number,
};

export default Progress;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './BlockContent.less';

function BlockContent({ children, className }) {
  return <div className={cx(styles.content, className)}>{children}</div>;
}

BlockContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default BlockContent;

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'i18n';
import Link from 'components/Link';

import { getUsername } from 'utils/user';

import Activity from './Activity';

function ApplicationChangeAssign({
  createdAt,
  createdBy,
  assignedTo,
  inApplicationContext,
  inJobPositionContext,
  application,
  onClick,
  t,
  ...rest
}) {
  return (
    <Activity
      icon="user"
      createdAt={createdAt}
      createdBy={createdBy}
      {...rest}
      action={
        <>
          {assignedTo &&
            (inApplicationContext ? (
              <>
                {t('ApplicationChangeAssign_assignedThisTo')}{' '}
                <b>{getUsername(assignedTo)}</b>
              </>
            ) : (
              <Trans
                i18nKey={`ApplicationChangeAssign_assignedThisTo--extended${
                  inJobPositionContext ? 'InJobPosition' : ''
                }`}
              >
                assigned{' '}
                <Link
                  onClick={onClick}
                  to={`/candidates/${application.candidate.pk}/${application.pk}`}
                >
                  {{
                    candidate: `${application.candidate.firstName} ${application.candidate.lastName}`,
                  }}
                </Link>{' '}
                application{' '}
                {!inJobPositionContext ? (
                  <>
                    for position{' '}
                    <b>{{ position: application.jobPosition.position }}</b>
                  </>
                ) : (
                  ''
                )}{' '}
                to <b>{{ assignedTo: getUsername(assignedTo) }}</b>
              </Trans>
            ))}
          {!assignedTo &&
            (inApplicationContext ? (
              <>{t('ApplicationChangeAssign_unassignedThis')} </>
            ) : (
              <Trans
                i18nKey={`ApplicationChangeAssign_unassignedThis--extended${
                  inJobPositionContext ? 'InJobPosition' : ''
                }`}
              >
                unassigned{' '}
                <Link
                  to={`/candidates/${application.candidate.pk}/${application.pk}`}
                >
                  {{
                    candidate: `${application.candidate.firstName} ${application.candidate.lastName}`,
                  }}
                </Link>{' '}
                application{' '}
                {!inJobPositionContext ? (
                  <>
                    for position{' '}
                    <b>{{ position: application.jobPosition.position }}</b>
                  </>
                ) : (
                  ''
                )}
              </Trans>
            ))}
        </>
      }
    />
  );
}

ApplicationChangeAssign.propTypes = {
  createdAt: PropTypes.string,
  createdBy: PropTypes.object,
  assignedTo: PropTypes.object,
  inApplicationContext: PropTypes.bool,
  inJobPositionContext: PropTypes.bool,
  application: PropTypes.object,

  // fired whenever user clicks the activity and is navigated to the link to the activities page,
  // e.g. used to set notification as read
  onClick: PropTypes.func,

  // From HoCs
  t: PropTypes.func.isRequired,
};

export default withTranslation('translation')(ApplicationChangeAssign);

import React from 'react';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';

// eslint-disable-next-line react/prop-types
function LinkRenderer({ href, title, children }) {
  return (
    <a href={href} title={title} target="_blank" rel="noopener">
      {children}
    </a>
  );
}

function Markdown({ ...props }) {
  return (
    <ReactMarkdown
      escapeHtml
      components={{
        link: LinkRenderer,
      }}
      plugins={[breaks]}
      {...props}
    />
  );
}

export default Markdown;

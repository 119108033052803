import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './ProgressLegend.less';

function ProgressLegend({ type = 'progress', children }) {
  return (
    <div className={cx(styles.legend, styles[`legend--${type}`])}>
      <span className={styles.dot} /> {children}
    </div>
  );
}

ProgressLegend.propTypes = {
  type: PropTypes.oneOf(['progress', 'planned', 'trail']),
  children: PropTypes.node,
};

export default ProgressLegend;

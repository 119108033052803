import { useQuery, gql } from '@apollo/client';
import sortBy from 'lodash-es/sortBy';

import customFieldFragment from 'pages/settings/custom-fields/CustomFieldAddModal/customFieldFragment.gql';

/**
 * hook for getting custom fields in current organization
 * @param {string} organizationPk - current organization Pk
 *
 * additional export: CUSTOM_FIELDS_QUERY
 */

export const CUSTOM_FIELDS_QUERY = gql`
  query CustomFieldsQuery($organizationPk: String) {
    viewer {
      organizations(organizationPk: $organizationPk) {
        customFields {
          ...customFieldFragment
        }
      }
    }
  }
  ${customFieldFragment}
`;

const useOrganizationCustomFields = ({ organizationPk }) => {
  const { data, loading } = useQuery(CUSTOM_FIELDS_QUERY, {
    variables: { organizationPk },
  });

  /**
   * CustomFieldsQuery returns custom fields only from one (current) organization,
   * but as an element of organizations array,
   * thats why we are selecting first organization from the array.
   * sortBy - to sort fields alphabetically
   */
  const organizationCustomFields =
    data && sortBy(data.viewer.organizations[0].customFields, ['name']);

  return [organizationCustomFields, { loading }];
};

export default useOrganizationCustomFields;

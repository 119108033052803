import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'i18n';

import { PERMISSION_APPLICATION_EMAIL_SEND } from 'consts/permissions';
import useToggleState from 'hooks/useToggleState';
import usePermissions from 'hooks/usePermissions';
import useCurrentCandidate from 'hooks/useCurrentCandidate';
import Tooltip from 'components/Tooltip';
import EmailModal from 'components/EmailComposeButton/EmailModal';
import useCanSendEmail from 'components/EmailComposeButton/useCanSendEmail';
import Link from 'components/Link';

function EmailComposeLink({ pickedEmail, children, fallbackLink }) {
  const { t } = useTranslation();

  const candidate = useCurrentCandidate();

  const {
    contact: contactDetails,
    applications: { edges: applications },
  } = candidate;

  const [isEmailModalOpen, onEmailModalOpen, onEmailModalClose] =
    useToggleState(false);

  const { hasEmailingModule, canSendEmail, disabledSendingMessage, loading } =
    useCanSendEmail({ contactDetails });

  const [hasApplicationEmailPermission] = usePermissions([
    PERMISSION_APPLICATION_EMAIL_SEND,
  ]);

  const activeApplications =
    applications?.length > 0 &&
    applications.filter((app) => app.node.jobPosition.isActive);

  // get most recent application, to be selected initially
  const recentApplication =
    activeApplications?.length > 0
      ? activeApplications[activeApplications.length - 1].node
      : null;

  const noApplicationMessage =
    !recentApplication &&
    t('EmailComposeLink_cantSendEmail-noActiveApplication');

  return (
    <>
      {hasEmailingModule && hasApplicationEmailPermission && (
        <>
          <Tooltip title={disabledSendingMessage || noApplicationMessage}>
            <span>
              <Link
                onClick={onEmailModalOpen}
                disabled={loading || !canSendEmail || !recentApplication}
                role="button"
                type="button"
              >
                {children}
              </Link>
            </span>
          </Tooltip>
          <EmailModal
            application={recentApplication}
            candidateApplications={
              activeApplications?.length > 1 ? activeApplications : null
            }
            contactDetails={contactDetails}
            pickedEmail={pickedEmail}
            isOpen={isEmailModalOpen}
            onClose={onEmailModalClose}
          />
        </>
      )}
      {(!hasEmailingModule || !hasApplicationEmailPermission) && fallbackLink}
    </>
  );
}

EmailComposeLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  pickedEmail: PropTypes.string,
  fallbackLink: PropTypes.node,
};

export default EmailComposeLink;

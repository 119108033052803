import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose, graphql } from 'apollo';
import { withTranslation } from 'i18n';

import withOrganizationPk from 'hoc/withOrganizationPk';

import { logAndShowGenericError } from 'utils/log';

import graphqlErrorFieldsMutation from 'hoc/graphqlErrorFieldsMutation';

import organizationBillingInfoQuery from './organizationBillingInfoQuery.gql';
import changeOrganizationDetailsMutation from './changeOrganizationDetailsMutation.gql';

import OrganizationBillingInfo from './OrganizationBillingInfo';

function OrganizationBillingInfoContainer({
  organizationBillingInfo,
  isBillingInfoLoading,
  changeOrganizationDetails,
  fieldErrors,
  setFieldErrors,
  clearFieldError,
}) {
  const onSave = useCallback(
    ({ address, city, zipCode, state, country, taxId }) =>
      changeOrganizationDetails({
        address,
        city,
        zipCode,
        state,
        country,
        taxId,
      })
        .then(({ data }) => {
          if (data.changeOrganizationDetails.errors) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({
              errors: data.changeOrganizationDetails.errors,
            });
          }
          return Promise.resolve();
        })
        .catch((errors) => {
          logAndShowGenericError('changeOrganizationDetails rejected', {
            errors,
          })(errors);
          return Promise.reject();
        }),
    [changeOrganizationDetails],
  );

  return (
    <OrganizationBillingInfo
      organizationBillingInfo={organizationBillingInfo}
      isBillingInfoLoading={isBillingInfoLoading}
      onSave={onSave}
      fieldErrors={fieldErrors}
      setFieldErrors={setFieldErrors}
      clearFieldError={clearFieldError}
    />
  );
}

OrganizationBillingInfoContainer.propTypes = {
  organizationBillingInfo: PropTypes.object.isRequired,
  isBillingInfoLoading: PropTypes.bool.isRequired,
  changeOrganizationDetails: PropTypes.func,
  fieldErrors: PropTypes.object,
  setFieldErrors: PropTypes.func,
  clearFieldError: PropTypes.func,
};

export default compose(
  withOrganizationPk,
  withTranslation('translation'),
  graphql(organizationBillingInfoQuery, {
    options: ({ organizationPk }) => ({ variables: { organizationPk } }),
    props: ({ data: { viewer, loading } }) => ({
      organizationBillingInfo:
        !loading && viewer ? viewer.organizations[0] : {},
      isBillingInfoLoading: loading,
    }),
  }),
  graphqlErrorFieldsMutation(changeOrganizationDetailsMutation, {
    props: ({ mutate, ownProps: { organizationPk } }) => ({
      changeOrganizationDetails: ({
        address,
        city,
        zipCode,
        state,
        country,
        taxId,
      }) =>
        mutate({
          variables: {
            organizationPk,
            address,
            city,
            zipCode,
            state,
            country,
            taxId,
          },
        }),
      mutate,
    }),
  }),
)(OrganizationBillingInfoContainer);
